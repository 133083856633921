import React,{Component} from 'react';
import Banner from './Banner';
import FollowCard from './FollowCard';
import ShowProfile from './ShowProfile';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import MetaTags from "react-meta-tags";

class Home extends Component{
	constructor(props){
		super(props)
		this.state = {
			userId : '',
			isSideBar : 0,
			// wishListCount : 0,
			// subscribeClick : 0,
		}
		// this.addToDesiredList = this.addToDesiredList.bind(this)
	}

	componentDidMount(){
		//for mob view if in browse profile page
		if(localStorage.getItem('redirectToProfilePage') !== null && localStorage.getItem('redirectToProfilePage') !== 'null' && localStorage.getItem('redirectToProfilePage') !== ''){
			let profileName = localStorage.getItem('redirectToProfilePage')
			localStorage.removeItem('redirectToProfilePage')
			this.props.history.push('/profiles?name='+profileName)
		}

		if(localStorage.getItem('redirectToProfilePageCategory') !== null && localStorage.getItem('redirectToProfilePageCategory') !== 'null' && localStorage.getItem('redirectToProfilePageCategory') !== ''){
			let profileName = localStorage.getItem('redirectToProfilePageCategory')
			localStorage.removeItem('redirectToProfilePageCategory')
			this.props.history.push('/profiles?category='+profileName)
		}
		//for mob view end

		localStorage.removeItem('searchedBlog')
		let user_id = ''
        if(this.props.authenticatedUserData.userId){
            user_id = this.props.authenticatedUserData.userId
        }
        this.setState({
            userId : user_id
        })
    }

  	// addToDesiredList(wishListCount){
  	// 	this.setState({
  	// 		wishListCount : wishListCount,
  	// 	})
  	// }

	render(){
		const {userId } = this.state

		return(
			<div id="body">
			<MetaTags>
				<title>Share and Discover Exclusive Content on DesireMe</title>
				<meta property="og:type" content="website"/>
				<meta property="og:url" content="__OGURL__"/>
 				<link rel="canonical" href="__OGURL__" />
				<meta property="og:image" content="https://desire.me/images/ogimage.png"/>
				<meta property="og:site_name" content="Desire Me"/>
				<meta property="og:description" content="__METADESCRIPTION__"/>
				<meta property="og:title" content="__METATITLE__"/>
				<meta
					name="description"
					content="__METADESCRIPTION__"
				/>
				<meta name="title" content="__METATITLE__"/>
			</MetaTags>
			<Header/>
					<Banner />
					{userId.userId === '' || userId === ''
						?
							<FollowCard />
						:
							""
					}
					<ShowProfile addToDesiredList = {this.addToDesiredList}/>
				<Footer />
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
    authenticatedUserData : state.auth.loginSuccessResult,
})

export default connect(
    mapStateToProps
)(withRouter(Home));
