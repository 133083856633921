import React from 'react';
import AuxFile from '../../../hoc/AuxFile';
import {ReactComponent as MessageIcon} from '../../../assets/images/svgs/message.svg'
const messageIcon = () => {
    return (
        <AuxFile>
            <MessageIcon className="chat_message_icon_img mr-2" height="2em" width="2em"/>
            <h2 className="font-weight-bold-messages chat_messages_desire">Messages</h2>
        </AuxFile>
    )
}
export default messageIcon;
