import React, { Component, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  getUserAlbumAction,
  addAlbumAction,
  addPostAction,
} from "../../actions/post";
// import { ToastContainer, toast } from 'react-toastify';
import Datetime from "react-datetime";
// import { CircularProgressbar } from 'react-circular-progressbar';
//import "react-circular-progressbar/dist/styles.css";
import "react-datetime/css/react-datetime.css";
import Modal from "react-bootstrap/Modal";
import ModalComponent from "../ModalComponent";
import { ERROR_MESSAGE } from "../Constant";
import MetaTags from "react-meta-tags";
import {
  getFileExtension,
  supportedImageExtension,
  supportedVideoExtension,
} from "../../utils/mediaUtils";
import { ReactComponent as PostIcon } from "../../assets/images/svgs/post.svg";
import { ReactComponent as CameraIcon } from "../../assets/images/svgs/camera.svg";
import { ReactComponent as ClapperIcon } from "../../assets/images/svgs/clapper.svg";
import { ReactComponent as ImageIcon } from "../../assets/images/svgs/image.svg";
import Slider from "react-slick";
import { ReactComponent as ArrowrightIcon } from "../../assets/images/svgs/arrow-right.svg";
import { ReactComponent as ArrowleftIcon } from "../../assets/images/svgs/arrow-left.svg";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowrightIcon className="arrow_img_custom" onClick={onclick} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowleftIcon className="arrow_img_custom" onClick={onclick} />
    </div>
  );
}

class Post extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginUserId: 0,
      Albums: [],
      Name: "", //album name,
      PhotoorVideo: [],
      imageView: [],
      Title: "",
      Caption: "",
      ScheduleDateTime: "",
      ChooseAlbum: 0,
      showAlbumModal: false,
      isAlbumError: false,
      albumErrors: [],
      albumAdded: 0, //set flag for album is added if yes then call getAlbum Api for that set this flag
      Publish: "",
      isPostError: false,
      postErrors: [],
      albumSubmitClick: 0, //set for toaster
      postSubmitClick: 0, //set for toaster
      ButtonLoader: false, //when click on submit button disable
      userName: "",
      errorMessage: "",
      modalShow: false,
      is_public: false,
    };

    this.imageUpload = this.imageUpload.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.albumSubmit = this.albumSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.show = this.show.bind(this);
    this.handleCheckClick = this.handleCheckClick.bind(this);
    // this.is_public = this.is_public.bind(this)
  }

  handleCheckClick(e) {
    this.setState((prevState) => ({
      is_public: !prevState.is_public,
    }));
  }

  componentDidMount() {
    localStorage.removeItem("searchedBlog");
    let loginUserId = this.props.authenticatedUserData.userId;
    let userName = this.props.authenticatedUserData.Username;
    this.setState({
      loginUserId: loginUserId,
      userName: userName,
    });
    this.props.getUserAlbumAction(loginUserId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //get album name of user
    if (
      nextProps.getAlbums &&
      nextProps.getAlbums.data &&
      nextProps.getAlbums.data.length > 0
    ) {
      this.setState({
        Albums: nextProps.getAlbums.data,
      });
    }

    if (
      nextProps.addAlbum &&
      nextProps.addAlbum !== "" &&
      this.state.albumSubmitClick === 1
    ) {
      this.setState({
        albumSubmitClick: 0,
      });
      if (!nextProps.addAlbum.isError) {
        if (this.state.albumAdded === 1) {
          this.setState({
            showAlbumModal: false,
            albumAdded: 0,
            ButtonLoader: false,
            isAlbumError: false,
            errorMessage: nextProps.addAlbum.message,
            modalShow: true,
          });
          //call for fetching lastes added album name
          this.props.getUserAlbumAction(this.state.loginUserId);
          // toast.success(nextProps.addAlbum.message);
        }
        this.setState({
          isAlbumError: false,
        });
      } else {
        this.setState({
          isAlbumError: true,
          albumErrors: nextProps.addAlbum.error,
          ButtonLoader: false,
          // errorMessage : "Oops, Something is wrong !",
          modalShow: false,
        });
        // toast.error('Error!');
      }
    } else if (
      nextProps.addPost &&
      nextProps.addPost !== "" &&
      this.state.postSubmitClick === 1
    ) {
      this.setState({
        postSubmitClick: 0,
        ButtonLoader: false,
      });
      if (!nextProps.addPost.isError) {
        this.setState({
          isPostError: false,
          postErrors: [],
          errorMessage: nextProps.addPost.message,
          modalShow: true,
        });

        /* if(nextProps.addPost.conversion_process){

        } else {
          let userName = this.state.userName;
          setTimeout(
              function () {
                this.props.history.push("/" + userName);
              }.bind(this),
              2000
          );
        }*/
        // toast.success(nextProps.addPost.message);
      } else {
        if (
          nextProps.addPost.error.hasOwnProperty("PhotoorVideo.0") ||
          nextProps.addPost.error.hasOwnProperty("PhotoorVideo.1") ||
          nextProps.addPost.error.hasOwnProperty("PhotoorVideo.2")
        ) {
          this.setState({
            PhotoorVideo: [],
            imageView: []
          });
        }
        this.setState({
          isPostError: true,
          postErrors: nextProps.addPost.error,
          errorMessage: ERROR_MESSAGE,
          modalShow: true,
        });
      }
    }
  }

  //on imput change save value in state
  inputChange(e) {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  imageUpload(e) {
    e.preventDefault();
    let files = e.target.files;

    const { PhotoorVideo } = this.state;
    const allNames = PhotoorVideo.map((file) => file.name);

    const isUploadedFilesHasVideo = Array.from(PhotoorVideo).some((file) =>
      supportedVideoExtension.includes(getFileExtension(file))
    );

    const isImageMedia = Array.from(files).every((file) =>
      supportedImageExtension.includes(getFileExtension(file))
    );   

    if (files.length) {
      if (PhotoorVideo.length + files.length > 50) {
        return; // TODO::error to display for max length
      }

      const updatedFilesArray = Array.from(files).filter(
        (file) => !allNames.includes(file.name)
      );
      const updatedFiles = [];
      Array.from(updatedFilesArray).forEach((file) => {
        if (supportedImageExtension.includes(getFileExtension(file)) || supportedVideoExtension.includes(getFileExtension(file))) {
          updatedFiles.push(file)
        }
      });

      this.setState((prevState) => ({
        PhotoorVideo: [...prevState.PhotoorVideo, ...updatedFiles],
      }));

      Array.from(updatedFiles).forEach((file) => {
        let reader = new FileReader();
        reader.onload = (e) =>
          this.setState((prevState) => ({
            imageView: [
              ...prevState.imageView,
              {
                url: reader.result,
                extension: getFileExtension(file),
                fileName: file.name,
              },
            ],
          }));
        reader.readAsDataURL(file);
      });
      return;
    }

    const isVideoMedia = Array.from(files).every((file) =>
      supportedVideoExtension.includes(getFileExtension(file))
    );
    
  }

  removeImage = (selectedImage) => {
    if (selectedImage && selectedImage.fileName && selectedImage.url) {
      this.setState((prevState) => ({
        imageView: prevState.imageView.filter(
          (media) => media.fileName !== selectedImage.fileName
        ),
        PhotoorVideo: prevState.PhotoorVideo.filter(
          (media) => media.name !== selectedImage.fileName
        ),
      }));
    }
    return;
  };

  addAlbum(e) {
    e.preventDefault();
    this.setState({
      showAlbumModal: true,
      isAlbumError: false,
      albumErrors: [],
    });
  }

  closeAlbumModal(e) {
    e.preventDefault();
    this.setState({
      showAlbumModal: false,
      Name: "",
    });
  }

  postButtonClick(buttonValue) {
    this.setState({
      Publish: buttonValue,
    });
  }

  //album form submit
  albumSubmit(e) {
    e.preventDefault();
    let albumData = {
      Name: this.state.Name,
    };
    this.props.addAlbumAction(this.state.loginUserId, albumData);
    this.setState({
      albumAdded: 1,
      albumSubmitClick: 1,
      Name: "",
    });
  }

  //form submit
  onSubmit(e) {
    e.preventDefault();

    if (this.state.Title.length === 0 && !this.state.PhotoorVideo[0]) {
      this.setState(prevState => ({
        isPostError: true,
        postErrors: {
          ...prevState.postErrors,
          Title: 'This field is required',
          PhotoorVideo: 'This field is required'
        }
      }))
      return
    } else if (this.state.Title.length === 0) {
      this.setState(prevState => ({
        isPostError: true,
        postErrors: {
          ...prevState.postErrors,
          Title: 'This field is required',
          PhotoorVideo: null
        }
      }))
      return
    } else if (!this.state.PhotoorVideo[0]) {
      this.setState(prevState => ({
        isPostError: true,
        postErrors: {
          ...prevState.postErrors,
          Title: null,
          PhotoorVideo: 'This field is required'
        }
      }))
      return
    }
      this.setState({
        postSubmitClick: 1,
        ButtonLoader: true,
      });
    let date = "";
    if (this.state.ScheduleDateTime !== "") {
      date = new Date(this.state.ScheduleDateTime);
      let day = date.getDate();
      if (day <= 9) {
        day = "0" + day;
      }
      let month = date.getMonth() + 1;
      if (month <= 9) {
        month = "0" + month;
      }
      let hours = date.getHours();
      if (hours <= 9) {
        hours = "0" + hours;
      }
      let minutes = date.getMinutes();
      if (minutes <= 9) {
        minutes = "0" + minutes;
      }
      date = day + "-" + month + "-" + date.getFullYear() + " " + hours + ":" + minutes;
    }

    let postData = new FormData();

    this.state.PhotoorVideo.forEach((file) => {
      postData.append("PhotoorVideo[]", file);
    });

    postData.append("Title", this.state.Title);
    postData.append("Caption", this.state.Caption);
    postData.append("Tags", this.state.Tags);
    postData.append("is_public", this.state.is_public);

    postData.append('ChooseAlbum', this.state.ChooseAlbum);
    postData.append('Publish', this.state.Publish);
    postData.append('ScheduleDateTime', date);
    this.props.addPostAction(this.state.loginUserId, postData);
  }

  scheduleDateTimeChange(e) {
    this.setState({
      ScheduleDateTime: e._d,
    });
  }

  handleClose() {
    let userName = this.state.userName;
    this.setState({
      modalShow: false,
      errorMessage: "",
    });
    if (this.state.errorMessage !== "Album created successfully!" && !this.state.isPostError) {
      this.props.history.push("/" + userName);      
    }
  }

  show() {
    useState(false);
  }

  render() {
    const {
      Albums,
      Name,
      Title,
      Caption,
      Tags,
      ChooseAlbum,
      showAlbumModal,
      isAlbumError,
      albumErrors,
      imageView,
      isPostError,
      postErrors,
      ButtonLoader,
      ScheduleDateTime,
      modalShow,
      errorMessage,
    } = this.state;

    let settings = {
      infinite: imageView.length >= 4 ? true : false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            infinite: imageView.length >= 4 ? true : false,
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            infinite: imageView.length >= 3 ? true : false,
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
      ],
    };

    // let today = new Date()
    // today = today.getDate()+"-"+(today.getMonth()+1)+"-"+today.getFullYear()
    // let minDate =today+" 00:00"

    const today = new Date();
    let yesterday = today.setDate(today.getDate() - 1);
    const disablePastDt = (current) => {
      return current.isAfter(yesterday);
    };

    return (
      <>
        <MetaTags>
          <title> Posts | DesireMe </title>
          <meta name="description" content="__METADESCRIPTION__" />
          <meta name="title" content="__METATITLE__" />
          <meta property="og:description" content="__METADESCRIPTION__" />
          <meta property="og:title" content="__METATITLE__" />
          <meta property="og:url" content="__OGURL__" />
          <link rel="canonical" href="__OGURL__" />
        </MetaTags>
        <Header />
        <section id="create_post_page">
          {ButtonLoader ? (
            <div id="preloader">
              <div className="preloader_div"></div>
            </div>
          ) : (
            ""
          )}
          <div className="container py-5">
            <div className="ceate_post_content bg-white">
              <div className="row pb-4">
                <div className="createpost_heading createpost_page">
                  <h1 className="font-weight-bold">
                    <span className="pr-3">
                      <PostIcon />
                    </span>
                    Create a post
                  </h1>
                </div>
              </div>

              <form onSubmit={this.onSubmit}>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 createpost-left">
                    <div className="form-group create_post_form">
                      <label htmlFor="photo/video" className="label_text">
                        {" "}
                        Upload your photos or video here{" "}
                        <span className="text-danger">*</span>
                      </label>

                      <div className="pic_upload_box create_post_input">
                        <input
                          type="file"
                          id=""
                          name="PhotoorVideo"
                          onChange={this.imageUpload}
                          multiple
                          accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.gif,.GIF,.mp4,.MP4,.mov,.MOV,.avi,.AVI,.ogg,.OGG,.3gp,.3GP,.3gpp,.3GPP,.flv,.FLV,.mpeg,.MPEG"
                        />
                        <Link to="#">
                          <CameraIcon />
                          <span className="upload-img-text">
                            Upload Photo or Video
                          </span>
                        </Link>
                      </div>

                      <Slider {...settings}>
                        {imageView.length
                          ? imageView.map((media) =>
                              supportedImageExtension.includes(
                                media.extension
                              ) ? (
                                <div>
                                  <img
                                    height={100}
                                    className="createpost_img input_preview_img"
                                    style={{
                                      borderRadius: "10px",
                                      width: "100%",
                                    }}
                                    src={media.url}
                                    alt="img-icon"
                                  />
                                  <span
                                    className="position-absolute post-remove cursor-pointer"
                                    style={{
                                      top: "5px",
                                      right: "10px",
                                      cursor: "pointer",
                                      zIndex: 9999,
                                    }}
                                    onClick={() => this.removeImage(media)}
                                  >
                                    {" "}
                                    x{" "}
                                  </span>
                                </div>
                              ) : supportedVideoExtension.includes(
                                  media.extension
                                ) ? (
                                <div className="create_post_video">
                                  <video
                                    src={media.url || ""}
                                    id={media.url}
                                    className="video_preview"
                                    style={{ width: "100%" }}
                                    controls
                                  />
                                  <span
                                    className="position-absolute cursor-pointer"
                                    style={{
                                      top: "5px",
                                      right: "10px",
                                      cursor: "pointer",
                                      zIndex: 9999,
                                    }}
                                    onClick={() => this.removeImage(media)}
                                  >
                                    x
                                  </span>
                                </div>
                              ) : null
                            )
                          : null}
                      </Slider>
                      <div className="d-flex mt-3 createpost_para">
                        <div
                          className=" pic_upload_box d-flex justify-content-between align-items-center p-1"
                          style={{ width: "53%", height: "auto" }}
                        >
                          <div>
                            <ImageIcon className="icon_image" />
                          </div>
                          <div className="para" style={{ lineHeight: "1rem" }}>
                            <strong>High Resolution Photos &amp; Images</strong>
                            <br /><br />
                            JPG, PNG, GIF
                            <br /><br />
                            <em>Up to 6000px</em>
                          </div>
                        </div>

                        <div
                          className=" pic_upload_box d-flex align-items-center p-1"
                          style={{ width: "45%", height: "auto" }}
                        >
                          <div>
                            <ClapperIcon className="icon_image" />
                          </div>

                          <div className="para" style={{ lineHeight: "1rem" }}>
                            <strong>Videos</strong>
                            <br /><br />
                            MP4, MOV, 3GP, AVI, OGG, MPEG, FLV
                            <br /><br />
                            <em>Up to 500 MB</em>
                          </div>
                        </div>
                      </div>

                      {isPostError && postErrors.PhotoorVideo ? (
                        <div id="alert-box2" className="font-weight-bold mt-1">
                          <span className="pr-2">
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-exclamation-circle"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                            </svg>
                          </span>
                          {postErrors.PhotoorVideo}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12 col-sm-12 createpost-right">
                    <div className="form-group">
                      <label htmlFor="title" className="label_text">
                        Title <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  input_text"
                        id=""
                        placeholder="Enter your Title"
                        name="Title"
                        defaultValue={Title}
                        onChange={this.inputChange}
                      />

                      {isPostError && postErrors.Title ? (
                        <div id="alert-box2" className="font-weight-bold mt-1">
                          <span className="pr-2">
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-exclamation-circle"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                            </svg>
                          </span>
                          {postErrors.Title}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="custom-control custom-checkbox form-group">
                    <input
                        type="checkbox"
                        id="showMedia"
                        className="custom-control-input  input_checkbox"
                        name="showMedia"
                        checked = {this.state.is_public} onChange={this.handleCheckClick}
                      />
                      <label
                        htmlFor="showMedia"
                        className="custom-control-label label_text"
                        style={{fontSize: 13}}
                      >
                        Show this posts media to non-subscribers (free content)
                      </label>
                      
                    </div>

                    <div className="form-group">
                      <label htmlFor="caption" className="label_text">
                        Caption {/*<span className="text-danger">*</span>*/}
                      </label>
                      <textarea
                        className=" form-control"
                        id="caption_textarea"
                        placeholder="Enter your Text Here"
                        name="Caption"
                        onChange={this.inputChange}
                        maxLength="200"
                        value={Caption}
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <label htmlFor="tags" className="label_text">
                        Enter Tags separated by commas,{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control input_text"
                        id=""
                        placeholder="EX: picture, video, girl,"
                        name="Tags"
                        defaultValue={Tags}
                        onChange={this.inputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="album" className="label_text">
                        Add To Album{" "}
                      </label>
                      <div className="d-flex justify-content-between create_new_album">
                        <select
                          className="form-control select_box"
                          id=""
                          style={{ width: "53%" }}
                          defaultValue={ChooseAlbum}
                          name="ChooseAlbum"
                          onChange={this.inputChange}
                        >
                          <option disabled value="0">
                            Choose Your Album
                          </option>
                          {Albums.map((album, key) => {
                            return (
                              <option value={album.id} key={key}>
                                {album.name}
                              </option>
                            );
                          })}
                        </select>
                        <div
                          className="input_text mb-0 input_text--width"
                          style={{ width: "45%" }}
                        >
                          <Link
                            to="#"
                            onClick={(e) => {
                              this.addAlbum(e);
                            }}
                          >
                            <div className="w-100 h-100 text-center justify-content-between d-flex align-items-center">
                              <div>Create New Album </div>
                              <span className="pl-3 pr-3">
                                <i className="fas fa-plus" />
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="tags" className="label_text">
                        Schedule DateTime
                      </label>
                      {/*
                                                <input type="text" className="form-control input_text" id="datetimepicker" name="ScheduleDateTime" defaultValue={ScheduleDateTime} onChange={this.inputChange} min={minDate}/>

                                                */}
                      <Datetime
                        className="form-control input_text date_time"
                        onChange={(e) => {
                          this.scheduleDateTimeChange(e);
                        }}
                        value={ScheduleDateTime}
                        dateFormat="DD-MM-YYYY"
                        isValidDate={disablePastDt}
                      />

                      {isPostError && postErrors.ScheduleDateTime ? (
                        <div id="alert-box2" className="font-weight-bold mt-1">
                          <span className="pr-2">
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-exclamation-circle"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                            </svg>
                          </span>
                          {postErrors.ScheduleDateTime}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="createpost-btns d-flex col-lg-12">
                    {!ButtonLoader ? (
                      <>
                        <button
                          className="btn btn3 mt-3 mt-sm-0"
                          type="submit"
                          name="Publish"
                          onClick={(e) => {
                            this.postButtonClick("now");
                          }}
                        >
                          Publish Now!
                        </button>
                        {/*<button className="btn btn4" type="submit" name="Draft" onClick={(e) => {this.postButtonClick('draft')}}>Save as Draft</button>*/}
                        <button
                          className="btn btn4 mt-3 mt-sm-0"
                          type="submit"
                          name="Schedule"
                          onClick={(e) => {
                            this.postButtonClick("schedule");
                          }}
                        >
                          Schedule Post
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="btn btn3 mt-3 mt-sm-0"
                          type="submit"
                          name="Publish"
                          disabled="disabled"
                        >
                          Publish Now!
                        </button>
                        {/*<button className="btn btn4" type="submit" name="Draft" disabled="disabled">Save as Draft</button>*/}
                        <button
                          className="btn btn4 mt-3 mt-sm-0"
                          type="submit"
                          name="Schedule"
                          disabled="disabled"
                        >
                          Schedule Post
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </form>

              {showAlbumModal ? (
                <div className="popup_modal">
                  <div className="popup_box_post">
                    <form onSubmit={this.albumSubmit}>
                      <div className="form-group">
                        <label htmlFor="firstname" className="label_text">
                          Title <span className="text-danger">*</span>
                        </label>

                        <input
                          type="text"
                          className="form-control input_text"
                          id=""
                          name="Name"
                          placeholder="Enter Album Name"
                          onChange={this.inputChange}
                          defaultValue={Name}
                        />
                        {isAlbumError && albumErrors.Name ? (
                          <div
                            id="alert-box2"
                            className="font-weight-bold mt-1"
                          >
                            <span className="pr-2">
                              <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                                className="bi bi-exclamation-circle"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                />
                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                              </svg>
                            </span>
                            {albumErrors.Name}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="d-flex justify-content-between">
                        <button
                          id="back_btn"
                          className="btn  font-weight-bold"
                          type="button"
                          onClick={(e) => {
                            this.closeAlbumModal(e);
                          }}
                        >
                          CANCEL
                        </button>
                        <button
                          className="btn next font-weight-bold"
                          type="submit"
                        >
                          SUBMIT
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {/*
                        <ToastContainer
                            position="bottom-center"
                            autoClose={5000}
                            hideProgressBar={true}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />*/}
          {modalShow ? (
            <ModalComponent
              isError={isPostError}
              errorMessage={errorMessage}
              show={this.show}
              handleClose={this.handleClose}
            />
          ) : (
            ""
          )}
        </section>
        <Footer />
      </>
    );
  }
}

export const mapStateToProps = (state) => ({
  authenticatedUserData: state.auth.loginSuccessResult,
  getAlbums: state.postData.getUserAlbums,
  addAlbum: state.postData.addAlbum,
  addPost: state.postData.addPost,
});

export const mapDispatchToProps = (dispatch) => ({
  getUserAlbumAction: (values) => dispatch(getUserAlbumAction(values)),
  addAlbumAction: (values, data) => dispatch(addAlbumAction(values, data)),
  addPostAction: (values, data) => dispatch(addPostAction(values, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Post));
