export const BASE_URL = process.env.REACT_APP_BASE_URL
export const ERROR_MESSAGE = process.env.REACT_APP_ERROR_MESSAGE
export const LOGIN_WHISHLIST_ERROR_MESSAGE = process.env.REACT_APP_LOGIN_WHISHLIST_ERROR_MESSAGE
export const API_KEY = process.env.REACT_APP_API_KEY
export const ACCOUNT_NUMBER = process.env.REACT_APP_ACCOUNT_NUMBER
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
export const MERCHANT_URL = process.env.REACT_APP_MERCHANT_URL
export const DEVICE_CHANNEL = process.env.REACT_APP_DEVICE_CHANNEL
export const MESSAGE_CATEGORY = process.env.REACT_APP_MESSAGE_CATEGORY
export const AUTHENTICATION_PURPOSE = process.env.REACT_APP_AUTHENTICATION_PURPOSE
export const CURRENCY = process.env.REACT_APP_CURRENCY
export const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY
export const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER
export const APP_KEY = process.env.REACT_APP_KEY
//export const API_KEY = process.env.REACT_APP_API_KEY
//export const ACCOUNT_NUMBER = process.env.REACT_APP_ACCOUNT_NUMBER
//export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
