import {
  GET_ALL_POST_DATA,
  GET_ALL_POSTS_DATA,
  GET_USER_POST,
  GET_MOST_VIEW_POST_DATA,
  GET_POPULAR_PROFILES,
  GET_POPULAR_POSTS,
  GET_SEARCH_ACTIVITY_DATA,
  GET_POSTS_SEARCH_ACTIVITY_DATA,
  GET_RECENT_POST,
  LIKE_DISLIKE_POST,
  LIKE_DISLIKE_COMMENT,
  ADD_COMMENT,
  GET_USER_ALBUMS,
  ADD_ALBUM,
  ADD_POST,
  UPDATE_POST,
  DELETE_POST,
  VIEW_POST,
  GET_ERRORS,
} from "./types";

import axios from "axios";
import authHeader from "../utils/AuthHeader";
import { BASE_URL } from "../components/Constant";

//page following user feed can see
export const getUserPostAction =
  (userId, loginUserId, start, limit, props) => (dispatch) => {
    axios
      .post(
        BASE_URL +
          "/getUserPost/" +
          userId +
          "/" +
          loginUserId +
          "/" +
          start +
          "/" +
          limit,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        dispatch({
          type: GET_USER_POST,
          payload: response.data,
        });
      })
      .catch((error) => {        
        if (error.response.status == "404") {
          props.history.push("/404");
        }
      });
  };

//if user search some user withou login then call this one
export const getGuestUserPostAction =
  (userId, loginUserId, start, limit, props) => (dispatch) => {
    axios
      .post(
        BASE_URL +
          "/getGuestUserPost/" +
          userId +
          "/" +
          loginUserId +
          "/" +
          start +
          "/" +
          limit,
        {}
      )
      .then((response) => {
        dispatch({
          type: GET_USER_POST,
          payload: response.data,
        });
      })
      .catch((error) => {
        if (error.response.status == "404") {
          props.history.push("/404");
        }
      });
  };

//all feed
export const getAllPostAction = (userId, start, limit) => (dispatch) => {
  axios
    .post(
      BASE_URL + "/getAllPost/" + userId + "/" + start + "/" + limit,
      {},
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      dispatch({
        type: GET_ALL_POST_DATA,
        payload: response.data,
      });
    })
    .catch((error) => {});
};

//all posts
export const getAllPostsAction = (category, orderBy, loginUserId, start, limit) => (dispatch) => {
  axios
    .get(
      BASE_URL + "/getAllPosts/" + category + "/" + orderBy + "/" + loginUserId + "/" + start + "/" + limit,
      {},
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      dispatch({
        type: GET_ALL_POSTS_DATA,
        payload: response.data,
      });
    })
    .catch((error) => { });
};

//all posts search activity
export const getAllPostsSearchAction = (search, category, orderBy, loginUserId, start, limit) => (dispatch) => {
  axios
    .get(
      BASE_URL + "/getAllPostsSearch/" + search + "/" + category + "/" + orderBy + "/" + loginUserId + "/" + start + "/" + limit,
      {},
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      dispatch({
        type: GET_POSTS_SEARCH_ACTIVITY_DATA,
        payload: response.data,
      });
    })
    .catch((error) => { });
};

//most viewd feed
export const getMostViewedPostAction =
  (loginUserId, start, limit) => (dispatch) => {
    axios
      .post(
        BASE_URL + "/mostViewed/" + loginUserId + "/" + start + "/" + limit,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        dispatch({
          type: GET_MOST_VIEW_POST_DATA,
          payload: response.data,
        });
      })
      .catch((error) => {});
  };

//popular post
export const getPopularPostAction =
  (loginUserId, start, limit) => (dispatch) => {
    axios
      .post(
        BASE_URL + "/mostPopular/" + loginUserId + "/" + start + "/" + limit,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        dispatch({
          type: GET_POPULAR_POSTS,
          payload: response.data,
        });
      })
      .catch((error) => {});
  };

//popular profiles
export const getPopularProfileAction =
  (loginUserId, start, limit) => (dispatch) => {
    axios
      .post(
        BASE_URL +
          "/mostPopularProfile/" +
          loginUserId +
          "/" +
          start +
          "/" +
          limit,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        dispatch({
          type: GET_POPULAR_PROFILES,
          payload: response.data,
        });
      })
      .catch((error) => {});
  };

//guest(without login) popular profiles
export const getGuestPopularProfileAction =
  (loginUserId, start, limit) => (dispatch) => {
    axios
      .post(
        BASE_URL +
          "/guestMostPopular/" +
          loginUserId +
          "/" +
          start +
          "/" +
          limit,
        {}
      )
      .then((response) => {
        dispatch({
          type: GET_POPULAR_PROFILES,
          payload: response.data,
        });
      })
      .catch((error) => {});
  };

//serach activity
export const serachActivityAction =
  (searchValue, loginUserId, start, limit) => (dispatch) => {
    axios
      .post(
        BASE_URL +
          "/searchActivity/" +
          searchValue +
          "/" +
          loginUserId +
          "/" +
          start +
          "/" +
          limit,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        dispatch({
          type: GET_SEARCH_ACTIVITY_DATA,
          payload: response.data,
        });
      })
      .catch((error) => {});
  };

//recent uploaded post
export const recentPostAction =
  (loginUserId, start, limit, userName) => (dispatch) => {
    axios
      .post(
        BASE_URL + "/getRecentPost/" + loginUserId + "/" + start + "/" + limit,
        { userName: userName },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        dispatch({
          type: GET_RECENT_POST,
          payload: response.data,
        });
      })
      .catch((error) => {});
  };

//like dislike post
export const likeDislikePostAction = (postId, loginUserId) => (dispatch) => {
  axios
    .post(
      BASE_URL + "/likePost/" + postId + "/" + loginUserId,
      {},
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      dispatch({
        type: LIKE_DISLIKE_POST,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data,
      });
    });
};

//add comment to post
export const addCommentAction =
  (postId, loginUserId, commentData) => (dispatch) => {
    axios
      .post(
        BASE_URL + "/addCommenttoPost/" + postId + "/" + loginUserId,
        commentData,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        dispatch({
          type: ADD_COMMENT,
          payload: response.data,
        });
      })
      .catch((error) => {});
  };

//reply to comment
export const replyToCommentAction =
  (commentId, loginUserId, commentData) => (dispatch) => {
    axios
      .post(
        BASE_URL + "/CommentComment/" + commentId + "/" + loginUserId,
        commentData,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        dispatch({
          type: ADD_COMMENT,
          payload: response.data,
        });
      })
      .catch((error) => {});
  };

// like/dislike comment
export const likeDislikeCommentAction =
  (commentId, loginUserId) => (dispatch) => {
    axios
      .post(
        BASE_URL + "/likeComment/" + commentId + "/" + loginUserId,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        dispatch({
          type: LIKE_DISLIKE_COMMENT,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ERRORS,
          payload: error.response.data,
        });
      });
  };

//get user albums name
export const getUserAlbumAction = (loginUserId) => (dispatch) => {
  axios
    .post(
      BASE_URL + "/getUserAlbum/" + loginUserId,
      {},
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      dispatch({
        type: GET_USER_ALBUMS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data,
      });
    });
};

//add album name
export const addAlbumAction = (loginUserId, albumData) => (dispatch) => {
  axios
    .post(BASE_URL + "/addAlbum/" + loginUserId, albumData, {
      headers: authHeader(),
    })
    .then((response) => {
      dispatch({
        type: ADD_ALBUM,
        payload: response.data,
      });
    })
    .catch((error) => {});
};

export const addPostAction = (loginUserId, postData) => (dispatch) => {
  axios
    .post(BASE_URL + "/addPost/" + loginUserId, postData, {
      headers: authHeader(),
      "content-type": "multipart/form-data",
    })
    .then((response) => {      
      dispatch({
        type: ADD_POST,
        payload: response.data,
      });
    })
    .catch((error) => {});
};

export const updatePostAction = (postId, postData) => (dispatch) => {
  axios
    .post(BASE_URL + "/updatePost/" + postId, postData, {
      headers: authHeader(),
      "content-type": "multipart/form-data",
    })
    .then((response) => {
      dispatch({
        type: UPDATE_POST,
        payload: response.data,
      });
    })
    .catch((error) => {});
};

export const deletePostAction = (postId) => (dispatch) => {
  axios
    .post(
      BASE_URL + "/deletePost/" + postId,
      {},
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      dispatch({
        type: DELETE_POST,
        payload: response.data,
      });
    })
    .catch((error) => {});
};

export const viewPostAction = (postId, userId) => (dispatch) => {
  axios
    .post(
      BASE_URL + "/viewPost/" + postId + "/" + userId,
      {},
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      dispatch({
        type: VIEW_POST,
        payload: response.data,
      });
    })
    .catch((error) => {});
};
