import {
	GET_BLOGS,
	GET_BLOG_DETAIL,
	GET_BLOG_CATEGORIES,
	GET_RECENT_BLOGS,
	GET_SEARCHED_BLOGS,
} from './types';

import axios from 'axios';
import authHeader from '../utils/AuthHeader';
import {BASE_URL} from '../components/Constant';

//page following user feed can see
export const getBlogsAction = (start,limit,category='') => (dispatch) => {
	axios
		.post(BASE_URL+"/getBlogs/"+start+"/"+limit,{'category':category},{
			headers : authHeader(),
		})
		.then(response => {
			dispatch({
				type : GET_BLOGS,
				payload : response.data
			})
		})
		.catch(error => {

		})
}

export const getBlogDetailAction = (blogId,props) => (dispatch) => {
	axios
		.post(BASE_URL+"/getBlogDetail/"+blogId,{},{
			headers : authHeader(),
		})
		.then(response => {
			dispatch({
				type : GET_BLOG_DETAIL,
				payload : response.data
			})
		})
		.catch(error => {
			if(error.response.status=='404'){
				props.history.push('/404')
			}
		})
}

export const getBlogCategoriesAction = (start,limit) => (dispatch) => {
	axios
		.post(BASE_URL+"/getBlogCategories/"+start+"/"+limit,{},{
			headers : authHeader(),
		})
		.then(response => {
			dispatch({
				type : GET_BLOG_CATEGORIES,
				payload : response.data
			})
		})
		.catch(error => {

		})
}

export const getRecentBlogsAction = (start,limit) => (dispatch) => {
	axios
		.post(BASE_URL+"/getRecentBlogs/"+start+"/"+limit,{},{
			headers : authHeader(),
		})
		.then(response => {
			dispatch({
				type : GET_RECENT_BLOGS,
				payload : response.data
			})
		})
		.catch(error => {

		})
}

//serach blog
export const searchBlogAction = (serachValue,start,limit,category) => (dispatch) => {
	axios
		.post(BASE_URL+"/searchBlog/"+serachValue+"/"+start+"/"+limit,{'category':category},{
			headers : authHeader(),
		})
		.then(response => {
			dispatch({
				type : GET_SEARCHED_BLOGS,
				payload : response.data
			})
		})
		.catch(error => {

		})
}
