export const supportedImageExtension = [
    'jpg',
    'JPG',
    'jpeg',
    'JPEG',
    'png',
    'PNG',
    'gif',
    'GIF'
]

export const supportedVideoExtension = [
    'mp4',
    'MP4',
    'mov',
    'MOV',
    'quicktime',
    '3gp',
    '3GP',
    '3gpp',
    '3GPP',
    'avi',
    'AVI',
    'mpeg',
    'MPEG',
    'flv',
    'FLV',
    'ogg',
    'OGG'
]

export const supportedExtension = [...supportedImageExtension, ...supportedVideoExtension]

export const getFileExtension = (file) => {
    return file && file.type ? file.type.split('/').pop() : ''
}