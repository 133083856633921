import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import {getFAQsAction , searchFAQAction} from '../actions/faq';
import MetaTags from "react-meta-tags";
import {ReactComponent as SearchIcon} from '../assets/images/svgs/search.svg'

export class FAQ extends Component {
    constructor(props){
        super(props)

        this.state = {
            faqs : [],
            start : 0,
            limit : 5,
            totalCount : 0,
            Loader : false,
            faqDataLength : 0,
            searchFAQValue : '', 
            searchButtonClick : 0,
            prevStart : 0,
            LoadAllFAQs : false,
        }
        // this.handleScroll = this.handleScroll.bind(this)
        this.inputChange = this.inputChange.bind(this)
        // this.searchedFAQ = this.searchedFAQ.bind(this)
    }

    componentDidMount(){
        this.props.getFAQsAction(this.state.start,this.state.limit)
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        let faqsArray = [];
        this.setState({
            Loader : false,
            searchButtonClick : 0,
        })

        let start = this.state.start
        let faqValue = this.state.searchFAQValue
        if(faqValue !== '' && faqValue !== null && faqValue !== 'null'){
            if(nextProps.searchedFAQs && nextProps.searchedFAQs.data && nextProps.searchedFAQs.data.length > 0){
                let faqs = this.state.faqs

                if(this.state.faqs.length === 0){
                    faqs = nextProps.searchedFAQs.data
                }
                else if(this.state.LoadAllFAQs){
                    faqs.push(...nextProps.searchedFAQs.data)
                }
                // searchFAQDataArray.push(...nextProps.searchedBlogs.data)
                this.setState({
                    faqs : faqs,
                    totalCount : nextProps.searchedFAQs.totalfaqCount,
                    faqDataLength : faqs.length,
                    searchButtonClick : 0,
                    // start : this.state.start,
                })
            }
        }
        else{
            if(nextProps.faqs && nextProps.faqs.data && nextProps.faqs.data.length > 0){
                let faqs = this.state.faqs
                if(this.state.faqs.length === 0){
                    faqs = nextProps.faqs.data
                    //if second time serach previous serached value not become null
                    // this.props.searchFAQAction('0',0,this.state.limit)
                }
                else if(this.state.LoadAllFAQs){
                    faqs.push(...nextProps.faqs.data)
                }

                // blogsArray.push(...nextProps.blogs.data)
                this.setState({
                    faqs : faqs,
                    totalCount : nextProps.faqs.faqCount,
                    faqDataLength : faqs.length,
                    // start :  this.state.start,
                })
            }
            else{
                this.setState({
                    start : 0,
                })
            }
        }
    }

    viewAllFAQs(e){
        e.preventDefault()

        let start = this.state.limit
        let limit = this.state.totalCount - this.state.limit

        this.setState({
            LoadAllFAQs : true,
            Loader : true,
        })
        if(this.state.searchFAQValue !== '' && this.state.searchFAQValue !== null && this.state.searchFAQValue !== 'null'){
            this.props.searchFAQAction(this.state.searchFAQValue,start,limit)
        }
        else{
            this.props.getFAQsAction(start,limit)
        }

    }

    inputChange(e){
        this.setState({
            searchFAQValue : e.target.value,
            start : 0,
            prevStart : 0,
            faqDataLength : 0,
            faqs : [],
            searchButtonClick : 1,
            LoadAllFAQs : false,
        })

        if(e.target.value === ''){
            this.setState({
                faqs : [],
                searchButtonClick : 0,
                start : 0,
                searchFAQValue : '',
            })
            this.props.getFAQsAction(0,this.state.limit)
            // this.props.history.push('/faq')
        }
    }

    searchedFAQ(e){
        e.preventDefault()
        if(this.state.searchButtonClick === 1){
            if(this.state.searchFAQValue !== ''){
                this.setState({
                    faqs : [],
                    start : 0,
                    faqDataLength : 0,
                    searchButtonClick : 1,
                })

                let searchValue = this.state.searchFAQValue
                if(this.state.searchFAQValue.lastIndexOf('?')){
                    let removeQtnmark = this.state.searchFAQValue.lastIndexOf('?');
                    searchValue = this.state.searchFAQValue.slice(0, removeQtnmark) + this.state.searchFAQValue.slice(removeQtnmark).replace('?', '');
                }
                this.props.searchFAQAction(searchValue,0,this.state.limit)
            } 
        }
    }
    render() {
        const {faqs , totalCount, Loader  ,searchFAQValue , LoadAllFAQs , searchButtonClick} = this.state
        const bgImage = process.env.PUBLIC_URL+"/frontend/images/general-header.jpg";
        
        return (
            <>
            <MetaTags>
                <title>Desirer & Content Creator FAQs | DesireMe</title>
                <meta name="description" content="__METADESCRIPTION__" />
                <meta name="title" content="__METATITLE__" />
                <meta property="og:description" content="__METADESCRIPTION__"/>
                <meta property="og:title" content="__METATITLE__"/>
                <meta property="og:url" content="__OGURL__"/>
                <link rel="canonical" href="__OGURL__" />
            </MetaTags>
                <Header />
                    <section id="blog_section">
                        {(Loader && totalCount !== faqs.length) ? 
                            <div id="preloader">
                                <div className="preloader_div">
                                </div>
                            </div>
                            :
                            ""
                        }
                        <div className="blog_img">
                        {/*
                            <img alt="" src={process.env.PUBLIC_URL + '/frontend/images/blog-header.png'} />
                           */}
                            <h1>FAQs</h1>
                        </div>
                        <div className="blog">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="col-lg-7">
                                        {
                                            (faqs.length > 0)
                                            ?
                                                (faqs.map((faqData,faqKey) => {

                                                    return(
                                                        <div className="blog_post" key={faqKey}>
                                                            <h3>{faqData.question}</h3>
                                                            <p>{faqData.answer}</p>
                                                        </div>

                                                    )
                                                }))
                                            :
                                                <div className="d-flex justify-content-center align-items-center" style={{height:"400px"}}>
                                                    {(searchButtonClick === 1 && searchFAQValue !== '')
                                                    ?
                                                        <h4 className="" style={{textAlign:"center"}}>Click on search to get the result</h4>
                                                    :

                                                        (searchFAQValue !== '')
                                                        ?
                                                            <h4 className="" style={{textAlign:"center"}}>No FAQ results found for {searchFAQValue} </h4>
                                                        :
                                                            <h4 className="" style={{textAlign:"center"}}>No FAQs </h4>
                                                    }
                                                </div>
                                        }

                                        
                                        {(totalCount > 5 && !LoadAllFAQs && faqs.length > 0) ?
                                            <>
                                                <Link to="#" className="mx-auto d-flex btn show_btn justify-content-center" onClick={(e) => {this.viewAllFAQs(e)}}>Load More</Link>
                                                <br/>
                                            </>
                                        :
                                            ""
                                        }
                                    </div>

                                    <div className="col-lg-4 ">
                                        <div className="side_post">
                                            <div className="side_post_search mb-3">
                                                <h4 className="mb-3">Search FAQ</h4>
                                                <div className="side_post_box d-flex">
                                                    <input placeholder="Type to Search" onChange={this.inputChange} value={searchFAQValue}/>
                                                    <div className="side_post_search_img">
                                                        <Link to="#" onClick={(e) => {this.searchedFAQ(e)}}><SearchIcon height="1.2em" width="1.2em" /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                <Footer />
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    faqs : state.faqData.getFAQsData,
    searchedFAQs : state.faqData.getSearchedFAQData,
})


export default connect(
    mapStateToProps,
    {getFAQsAction , searchFAQAction}
)(withRouter(FAQ));

