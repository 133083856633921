import React, {Component} from 'react';
import AuxFile from '../../../hoc/AuxFile';

class Cardinput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: '',
            objUrl: null,
            file: null,
            fileElement: null,
            extension : '',
            loader: false
        }
    }

    imageUpload = (e) => {
        e.preventDefault()
        var file = e.target.files[0]
        var filename = file.name
        var extension = filename.substring(filename.lastIndexOf('.') + 1)
        if (file) {
            var image_file = URL.createObjectURL(file)
            this.setState({ objUrl: image_file, fileElement: file,extension:extension })
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = (e) =>
                this.setState({
                    file: reader.result,
                })
        }
    }
    clearImage = (e) => {
        e.target.value = null;
    }

    removeMedia = () => {
        const { file, objUrl,fileElement } = this.state

        if (file && objUrl) {
            this.setState({ file: null, objUrl: null, fileElement: null,extension:'' })
        }

    }

    // handle input change event
    onInputChange = (e) => {
        this.setState({ message: e.target.value });
    }

    sendMessage = async() => {
        if (!this.state.message && !this.state.file) return;
        if(this.state.file){
            await this.setStateAsync({ loader: true });
        }
        this.props.onClicked(this.state);
        this.setState({ ...this.state, message: '', file: null, objUrl: null, fileElement: null });
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
          this.setState(state, resolve)
        });
    }

    componentWillReceiveProps(nextProps){
        this.setState({ loader: false });
    }

    onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            if (this.state.message) {
                this.sendMessage();
                document.getElementById(e.target.id).focus();
            }
        }
    }

    toggleModal = () => {
        if (this.props.currentUser.cardDetails) {
            this.props.showPayModal();
        } else {
            this.props.showPaySetupModal();
        }
    }

    renderPreviewImage = () => {
        const { objUrl,extension } = this.state

        return (
            objUrl &&
            <div className="chat_preview-image">
                <div className="chat_image-cross-icon" onClick={this.removeMedia}>
                    <span>x</span>
                </div>
                {(extension === 'jpg' || extension === 'JPG' || extension === 'jpeg' || extension === 'JPEG' || extension === 'png' || extension === 'PNG' || extension === 'gif' || extension === 'GIF' || extension === 'webp' || extension ==='png') ?
                    <img src={objUrl} alt="preview-img" width={150} height={120} />
                    :
                    (extension === 'mp4' || extension==='MP4')
                        ?
                        <video src={objUrl} className="video_preview " width={150} height={120} controls ></video>
                        :
                        ""
            }
            </div>
        )
    }

    render() {

        return (
            <div className="row p-3 chat_card-input">
                {this.props.smallDevice === "false" ? (
                    <AuxFile>
                        <div className="col-md-10 mt-3 chat_input-area">
                            {this.renderPreviewImage()}

                    <textarea value={this.state.message} onKeyDown={this.onEnterPress} onChange={this.onInputChange} type="text" className="form-control chat-textarea" id="Inputmessage" aria-describedby="emailHelp" placeholder="Enter your message here" autoComplete="off" ></textarea>
                    <div className="image-upload-attachment">
                                {this.state.loader && <div id = "preloader"><div className = "preloader_div"> </div></div>}

                                <label htmlFor="file-input">
                                    <img src={process.env.PUBLIC_URL + '/frontend/images/Path 2975.png'} className="send_icon_image_upload" alt="" />
                                </label>
                                <input id="file-input" type="file" name="files" onClick={this.clearImage} onChange={this.imageUpload} accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.gif,.GIF,.webp,.mp4" />
                            </div>
                            <div className="send_icon" onClick={this.toggleModal}>
                                <img src={process.env.PUBLIC_URL + '/frontend/images/£.png'} className="send_icon_image" alt="" />
                            </div>
                        </div>
                        <div className="col-md-2 mt-3">
                            <button disabled={!this.state.message && !this.state.file} onClick={this.sendMessage} type="button" className="btn send_primary_message">Send <img src={process.env.PUBLIC_URL + '/frontend/images/Path 2974.png'} alt="" /></button>
                        </div>
                    </AuxFile>
                ) : (
                    <AuxFile>
                        <div className="col-md-12 mt-3 chat_input-area">
                            {this.renderPreviewImage()}


            <textarea value={this.state.message} onKeyDown={this.onEnterPress} onChange={this.onInputChange} type="text" className="form-control chat-textarea" id="InputmessageSmall" aria-describedby="emailHelp" placeholder="Enter your message here" autoComplete="off" ></textarea>
            <div className="btn send_primary_message_mobile">
            {this.state.loader && <div id = "preloader"><div className = "preloader_div"> </div></div>}
                                <img src={process.env.PUBLIC_URL + '/frontend/images/Path 2974.png'} onClick={this.sendMessage} className="send_message_mobile" alt="" />
                            </div>
                        </div>
                        <div className="col-md-12 mt-3 mb-5">
                            <div className="image-upload-attachment">
                                <label htmlFor="file-input-small">
                                    <img src={process.env.PUBLIC_URL + '/frontend/images/Path 2975.png'} className="send_icon_image_upload_mobile" alt="" />
                                </label>
                                <input id="file-input-small" type="file" onClick={this.clearImage} name="files-small" onChange={this.imageUpload} accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.gif,.GIF,.webp,.mp4" />
                            </div>
                            <div className="send_icon" onClick={this.toggleModal}>
                                <img src={process.env.PUBLIC_URL + '/frontend/images/£.png'} className="send_icon_image_mobile" alt="" />
                            </div>
                        </div>
                    </AuxFile>
                )}

            </div>

        )
    }
}
export default Cardinput;
