import React, { Component, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import RegisterSlider from "./RegisterSlider";
import {
  registerAction,
  verifyEmailAction,
  updateProfileAction,
  userIdVerifyAction,
  userPaymentDetailsAction,
  getCountries,
  emptyError,
  getStatesAction,
  getSubscriptionRangeAction,
  loginAction,
} from "../../actions/auth";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import ModalComponent from "../ModalComponent";
import { ERROR_MESSAGE } from "../Constant";
import MetaTags from "react-meta-tags";
import { ReactComponent as CameraIcon } from "../../assets/images/svgs/camera.svg";
import { ReactComponent as InformationIcon } from "../../assets/images/svgs/information.svg";
import { ReactComponent as ErrorinfoIcon } from "../../assets/images/svgs/errorinfo.svg";
import fnGetDecrypted from "../../utils/DecryptUtils";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //first step input fields
      Role: "",
      Forename: "",
      Surname: "",
      Email: "",
      Username: "",
      DisplayName: "",
      PhoneNumber: "",
      Password: "",
      ConfirmPassword: "",
      Category: "",
      TwoFactor: 1,
      AgreeTerms: 0,
      YearsOld: 0,
      errors: [],
      isError: false,
      TwoFactorChecked: true,
      AgreeTermsChecked: false,
      YearsOldChecked: false,
      LeftSideTabNumber: 1, //which tab shown active
      TabClickBoolean: 0, //when click on left side tab it goes on componentwillreceiveProps and then goes in condition and show toaster on slider click, so put condition when this is true not go in conition - this is true when click on slider,
      SubmitClickBoolean: 0, //this is set 1 when click on submit button (do this because for toaster issue like reducer not updating so i call emptyError inside onsubmit so its response come and error is null so go inside success and then if error then go in error method so both toaster display so when first call emptyerror inside submit flag is 0 and for second method it is 1)
      PersonalDetailsError: false, //if this is true that means 1 step completed,
      SlideTab: 0, //set this because if there is error and click on left side tab move on to next form due to emptyError function so set this to 1 if this is 1 then not move to next form

      //second step input fields
      PhotoId: "",
      PhotoIdView: "",
      PhotowithId: "",
      PhotowithIdView: "",
      VerifyIdError: false, //if this is false then not go in 3 step

      //third step input fields
      ProfilePic: "",
      ProfilePicView: "",
      ProfileVideo: "",
      ProfileVideoView: "",
      ProfileBanner: "",
      ProfileBannerView: "",
      Location: "",
      SubscriptionPrice: "",
      TwitterURL: "",
      AmazonURL: "",
      Bio: "",
      Tags: "",
      ProfileError: false, //if this is false then not go in 4 step
      minPrice: "",
      maxPrice: "",

      //fourth step input fields
      CountriesArray: [],
      Country: "United Kingdom",
      CountryCode: "gb",
      StatesArray: [],
      State: "",
      StateCode: "",
      AccountName: "",
      SortCode: "",
      SortCodeDisabled: true,
      AccountNumber: "",
      CardHolderName: "",
      cardNumber: "",
      cvv: "",
      expiryDate: "",
      ZipCode: "",
      PaymentError: false, // if this is true that means all steps are completed
      ButtonLoader: false, //when click on submit button disable

      //user id get from email verify API
      UserId: 0,
      errorMessage: "",
      modalShow: false,
      paysafeError: false,
      paysaferesponse: "",
    };

    // localStorage.removeItem('roleName');
    this.onSubmit = this.onSubmit.bind(this); //on form submit
    this.inputChange = this.inputChange.bind(this); //when input chnage
    this.TabsClick = this.TabsClick.bind(this); //on left side tab changes
    this.imageUpload = this.imageUpload.bind(this); //call function on image upload
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.show = this.show.bind(this);
  }

  componentDidMount() {
    let step = parseInt(window.location.href.split("step")[1]);
    if (
      (localStorage.getItem("emailVerifyTime") &&
        localStorage.getItem("emailVerifyTime").length > 6) ||
      (this.props.userId !== 0 &&
        (step == 1 ||
          step == 2 ||
          (step == 4 && this.props.RoleName === "Desirer") ||
          (step === 3 && this.props.VerifyIdError) ||
          (step === 4 && this.props.ProfileError)) &&
        this.props.SlideTab === 0 &&
        !this.props.ButtonLoader)
    )
      this.TabsClick(step);
    else {
      localStorage.roleName === "Desirer"
        ? this.props.history.push("/follow/step1")
        : this.props.history.push("/profile/step1");
    }
    localStorage.removeItem("searchedBlog");

    let user_id = fnGetDecrypted(localStorage.getItem("email_verify_user_id"));
    // let user_id = 38;
    let is_verified = localStorage.getItem("verified"); //if set then only 2 step displayed because coming from mail else on reload 1 step display

    this.props.getCountries();
    this.props.emptyError();
    this.props.getSubscriptionRangeAction();
    // localStorage.getItem('user_id');
    // localStorage.removeItem('emailVerifyTime')

    let RoleName = "";
    if (
      localStorage.getItem("roleName") !== null &&
      localStorage.getItem("roleName") !== "null" &&
      localStorage.getItem("roleName") !== "" &&
      this.state.Role !== localStorage.getItem("roleName") &&
      localStorage.getItem("roleName") !== undefined
    ) {
      RoleName = localStorage.getItem("roleName");
    }
    if (
      localStorage.getItem("email_verify_user_id") !== "null" &&
      localStorage.getItem("email_verify_user_id") !== null &&
      localStorage.getItem("email_verify_user_id") !== ""
    ) {
      var tabNum = 1;
      if (is_verified !== null && RoleName === "ContentCreator") {
        tabNum = 2;
        let stepfromlogin = parseInt(localStorage.getItem("step"));
        if (
          stepfromlogin != tabNum &&
          stepfromlogin != 5 &&
          !isNaN(stepfromlogin)
        ) {
          tabNum = stepfromlogin;
        }
      } else if (is_verified !== null && RoleName === "Desirer") {
        tabNum = 4;
      }
      this.setState({
        UserId: user_id,
        LeftSideTabNumber: tabNum, //if user id come in url then go to second step
        PersonalDetailsError: true,
        Role: RoleName,
      });
      localStorage.removeItem("verified");
      this.props.verifyEmailAction(user_id);
    } else {
      this.setState({
        UserId: 0,
      });
    }
    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //min max subscription price range
    if (!nextProps.userRegisterData.getSubscriptionPriceRange.isError) {
      let minPrice =
        nextProps.userRegisterData.getSubscriptionPriceRange.min_value;
      let maxPrice =
        nextProps.userRegisterData.getSubscriptionPriceRange.max_value;
      this.setState({
        minPrice: minPrice,
        maxPrice: maxPrice,
      });
    }

    this.setState({
      isError: false,
    });

    if (
      (this.state.CountriesArray === undefined ||
        this.state.CountriesArray.length === 0) &&
      nextProps.userRegisterData.countriesData &&
      nextProps.userRegisterData.countriesData.list &&
      nextProps.userRegisterData.countriesData.list.length > 0
    ) {
      this.setState({
        CountriesArray: nextProps.userRegisterData.countriesData.list,
      });
    }

    if (
      (this.state.StatesArray === undefined ||
        this.state.StatesArray.length === 0) &&
      nextProps.userRegisterData.statesData &&
      nextProps.userRegisterData.statesData.list &&
      nextProps.userRegisterData.statesData.list.length > 0
    ) {
      this.setState({
        StatesArray: nextProps.userRegisterData.statesData.list,
      });
    }

    //when clicking on header footer create profile

    if (
      localStorage.getItem("roleName") !== null &&
      localStorage.getItem("roleName") !== "null" &&
      localStorage.getItem("roleName") !== "" &&
      this.state.Role !== localStorage.getItem("roleName") &&
      localStorage.getItem("roleName") !== undefined
    ) {
      this.setState({
        Role: localStorage.getItem("roleName"),
        Forename: "",
        Surname: "",
        Email: "",
        Username: "",
        DisplayName: "",
        PhoneNumber: "",
        Password: "",
        ConfirmPassword: "",
        Category: "",
        TwoFactor: 1,
        AgreeTerms: 0,
        YearsOld: 0,
        TwoFactorChecked: true,
        AgreeTermsChecked: false,
        YearsOldChecked: false,
        ProfilePic: "",
      });
    } else {
      if (this.state.Role === "") {
        this.setState({
          Role: "ContentCreator",
        });
      }
    }
    //if step1 is success
    //if error is not coming in success result && step 1 is active && not click on tab && click on submit button then only calls this method
    if (
      nextProps.userRegisterData.registerSuccessResult &&
      nextProps.userRegisterData.registerSuccessResult !== "" &&
      !nextProps.userRegisterData.registerSuccessResult.isError &&
      this.state.LeftSideTabNumber === 1 &&
      this.state.TabClickBoolean === 0 &&
      this.state.SubmitClickBoolean === 1 &&
      !nextProps.errors.errors.isError
    ) {
      var TabNum = 1;
      if (this.state.UserId !== 0 && this.state.Role === "ContentCreator") {
        TabNum = 2;
      } else if (this.state.UserId !== 0 && this.state.Role === "Desirer") {
        TabNum = 4;
      }
      let errorMessage = "";
      let modalShow = false;
      if (
        this.state.UserId !== 0 &&
        this.state.UserId !== null &&
        this.state.UserId !== 0
      ) {
        // toast.success('Registered!');//
        errorMessage = "Registered!";
        modalShow = true;
        this.props.history.push("/profile/step2");
      } else {
        // toast.success('Thank you for your registration to be a Desired Content Creator, we have now emailed you a link to verify your email address. You will need to click this in order to proceed with your registration');//

        // errorMessage = 'Thank you for your registration to be a Desired Content Creator, we have now emailed you a link to verify your email address. You will need to click this in order to proceed with your registration'
        errorMessage = nextProps.userRegisterData.registerSuccessResult.message;
        modalShow = true;
          // if(window.location.pathname !== "/profile/success" )this.props.history.push("/profile/success")
        // return

        const state = { 'step': 1 }
        const title = window.document.title
        const url = 'success'
        if(window.location.pathname !== "/profile/success" || window.location.pathname !== "/follow/success") window.history.pushState(state, title, url);
      }
      
      this.setState({
        LeftSideTabNumber: TabNum,
        SlideTab: 0,
        ButtonLoader: false,
        errorMessage: errorMessage,
        modalShow: modalShow,
        ProfilePicView: "",
      });
    }

    //if step2 is success
    else if (
      nextProps.userRegisterData.idVerificaionSuccessResult &&
      nextProps.userRegisterData.idVerificaionSuccessResult !== "" &&
      !nextProps.userRegisterData.idVerificaionSuccessResult.isError &&
      this.state.LeftSideTabNumber === 2 &&
      this.state.TabClickBoolean === 0 &&
      this.state.SubmitClickBoolean === 1 &&
      !nextProps.errors.errors.isError &&
      this.state.Role === "ContentCreator"
    ) {
      this.setState({
        VerifyIdError: true,
        LeftSideTabNumber: 3,
        SlideTab: 0,
        ButtonLoader: false,
        errorMessage:
          "Thank you, we'll now review your ID, usually within in the next 24 hours before putting your profile live on Desire me. Meanwhile please continue to setup your profile.",
        modalShow: true,
        PhotoIdView: "",
        PhotowithIdView: "",
      });
      this.props.history.push("/profile/step3");
      // toast.success('Identity Uploaded!');//
    }

    //if step3 is success
    else if (
      nextProps.userRegisterData.updateProfileSuccess &&
      nextProps.userRegisterData.updateProfileSuccess !== "" &&
      !nextProps.userRegisterData.updateProfileSuccess.isError &&
      this.state.LeftSideTabNumber === 3 &&
      this.state.TabClickBoolean === 0 &&
      this.state.SubmitClickBoolean === 1 &&
      !nextProps.errors.errors.isError &&
      this.state.Role === "ContentCreator"
    ) {
      this.setState({
        ProfileError: true,
        LeftSideTabNumber: 4,
        SlideTab: 0,
        ButtonLoader: false,
        errorMessage:
          "Profile information saved, please continue your sign up.",
        modalShow: true,
        ProfilePicView: "",
        ProfileBannerView: "",
        ProfileVideoView: "",
      });
      this.props.history.push("/profile/step4");
      // toast.success('Profile Updated!');//
    }

    //if setp4 is success
    else if (
      nextProps.userRegisterData.paymentCardDetails &&
      nextProps.userRegisterData.paymentCardDetails !== "" &&
      !nextProps.userRegisterData.paymentCardDetails.isError &&
      this.state.LeftSideTabNumber === 4 &&
      this.state.TabClickBoolean === 0 &&
      this.state.SubmitClickBoolean === 1 &&
      !nextProps.errors.errors.isError &&
      !nextProps.userRegisterData.paymentCardDetails.paysafeError
    ) {
      localStorage.removeItem("email_verify_user_id");
      localStorage.removeItem("roleName");
      localStorage.removeItem("emailVerifyTime");

      // var loginData={
      //     // email : nextProps.userRegisterData.paymentCardDetails.email,
      //     // password : nextProps.userRegisterData.paymentCardDetails.password,
      //     email : 'aakashi.thinktanker@gmail.com',
      //     password : '12345678',
      // }

      // this.props.loginAction(loginData)

      this.setState({
        PaymentError: true,
        SlideTab: 0,
        ButtonLoader: false,
        errorMessage:
          "Registration successful ♥ Welcome to Desire me!<br/>Redirecting you to login in 5 seconds....",
        modalShow: true,
      });
      //this.props.history.push('/registration-successful')
      window.location.hash = "registration-successful";

      // toast.success('Successfully Rgistered!');
      // var that = this
      setTimeout(function () {
        window.location.href = "/login"; //put href to null the localstorage
      }, 2000);
    } else if (nextProps.userRegisterData.paymentCardDetails.paysafeError) {
      this.setState({
        errors: [],
        isError: true,
        errorMessage:
          nextProps.userRegisterData.paymentCardDetails.paysaferesponse,
        modalShow: true,
        ButtonLoader: false,
      });
    } else {
      //if error comes and not click on left side tab
      if (nextProps.errors.errors.isError && this.state.TabClickBoolean === 0) {
        let errorMessage = ERROR_MESSAGE;
        if (nextProps.errors.errors.isEmailExist) {
          errorMessage = nextProps.errors.errors.error.Email[0];
        }
        this.setState({
          errors: nextProps.errors.errors.error,
          isError: nextProps.errors.errors.isError,
          SubmitClickBoolean: 0,
          SlideTab: 1,
          ButtonLoader: false,
          errorMessage: errorMessage,
          modalShow: true,
        });
        // toast.error('Error!');
      }
    }

    // let user_id = atob(localStorage.getItem('email_verify_user_id'));
    // save values coming from verify email response
    if (
      nextProps.userRegisterData.getRegisterUserData &&
      nextProps.userRegisterData.getRegisterUserData !== ""
    ) {
      var userData = nextProps.userRegisterData.getRegisterUserData;
      let twoFactorChecked = true;
      if (userData.twoFactor === "No") {
        twoFactorChecked = false;
      }

      let termChecked = false;
      if (userData.AgreeTerms === 1) {
        termChecked = true;
      } else {
        userData.AgreeTerms = 0;
      }

      var yearOldChecked = false;
      if (userData.YearsOld === 1) {
        yearOldChecked = true;
      }

      var personalDetailsError = false;
      if (
        localStorage.getItem("email_verify_user_id") !== "null" &&
        localStorage.getItem("email_verify_user_id") !== null &&
        localStorage.getItem("email_verify_user_id") !== ""
      ) {
        personalDetailsError = true;

        this.setState({
          //step1
          Role: userData.Role,
          Forename: userData.Forename,
          Surname: userData.Surname,
          Email: userData.Email,
          Username: userData.Username,
          DisplayName: userData.DisplayName,
          Category: userData.Category,
          PhoneNumber: userData.PhoneNumber,
          TwoFactor: userData.twoFactor,
          AgreeTerms: userData.AgreeTerms,
          YearsOld: userData.YearsOld,
          TwoFactorChecked: twoFactorChecked,
          AgreeTermsChecked: termChecked,
          YearsOldChecked: yearOldChecked,
          PersonalDetailsError: personalDetailsError,
        });
      }

      var verifyIdError = false;
      if (userData.PhotoId !== "" && userData.PhotowithId !== "") {
        verifyIdError = true;

        let PhotoId = userData.PhotoId;
        if (this.state.PhotoIdView !== "") {
          PhotoId = this.state.PhotoId;
        }

        let PhotowithId = userData.PhotowithId;
        if (this.state.PhotowithIdView !== "") {
          PhotowithId = this.state.PhotowithId;
        }

        this.setState({
          //step2

          VerifyIdError: verifyIdError,
          PhotoId: PhotoId,
          PhotowithId: PhotowithId,
        });
      }

      var profileError = false;
      if (
        userData.ProfilePic !== "" &&
        (userData.SubscriptionPrice !== "" ||
          userData.SubscriptionPrice !== null ||
          userData.SubscriptionPrice !== "null")
      ) {
        if (verifyIdError) {
          profileError = true;
        }

        let ProfilePic = userData.ProfilePic;
        if (this.state.ProfilePicView !== "") {
          ProfilePic = this.state.ProfilePic;
        }

        let ProfileVideo = userData.ProfileVideo;
        if (this.state.ProfileVideoView !== "") {
          ProfileVideo = this.state.ProfileVideo;
        }

        let ProfileBanner = userData.ProfileBanner;
        if (this.state.ProfileBannerView !== "") {
          ProfileBanner = this.state.ProfileBanner;
        }
        this.setState({
          //step3

          ProfileError: profileError,
          ProfilePic: ProfilePic,
          ProfileVideo: ProfileVideo,
          ProfileBanner: ProfileBanner,
          Location: userData.Location,
          SubscriptionPrice: userData.SubscriptionPrice,
          TwitterURL: userData.TwitterURL,
          AmazonURL: userData.AmazonURL,
          Bio: userData.Bio,
          Tags: userData.Tags,
        });
      }

      var payemtError = false;
      if (
        userData.Country !== "" &&
        userData.State !== "" &&
        userData.AccountName !== "" &&
        userData.CardHolderName !== "" &&
        userData.AccountNumber !== "" &&
        userData.cardNumber != "" &&
        userData.cardExpMonth !== "" &&
        userData.cardExpYear !== "" &&
        userData.SortCode !== "" &&
        userData.ZipCode !== "" &&
        userData.Country !== null &&
        userData.State !== null &&
        userData.AccountName !== null &&
        userData.CardHolderName !== null &&
        userData.AccountNumber !== null &&
        userData.cardNumber !== null &&
        userData.cardExpMonth !== null &&
        userData.cardExpYear !== null &&
        userData.SortCode !== null &&
        userData.ZipCode !== null
      ) {
        payemtError = true;

        this.setState({
          //step4
          // Country : userData.Country,
          // State : userData.State,
          // AccountName : userData.AccountName,
          // AccountNumber : userData.AccountNumber,
          // expiryDate : month+" ,"+userData.cardExpYear,
          // cardNumber : userData.cardNumber,
          // SortCode : userData.SortCode,
          PaymentError: payemtError,
        });
      }

      // const monthNames = ["January", "February", "March", "April", "May", "June",
      //                     "July", "August", "September", "October", "November", "December"
      //                    ];
      // let month = monthNames[userData.cardExpMonth];
    }

    // save values coming from verify email response for step1
    if (
      nextProps.userRegisterData.registerSuccessResult &&
      nextProps.userRegisterData.registerSuccessResult !== ""
    ) {
      var registerData = nextProps.userRegisterData.registerSuccessResult.data;

      let twoFactorChecked = true;
      if (registerData.twoFactor === "No") {
        twoFactorChecked = false;
      }

      let termChecked = false;
      if (registerData.AgreeTerms === 1) {
        termChecked = true;
      } else {
        registerData.AgreeTerms = "";
      }

      yearOldChecked = false;
      if (registerData.YearsOld === 1) {
        yearOldChecked = true;
      }

      personalDetailsError = false;
      if (
        localStorage.getItem("email_verify_user_id") !== "null" &&
        localStorage.getItem("email_verify_user_id") !== null &&
        localStorage.getItem("email_verify_user_id") !== ""
      ) {
        personalDetailsError = true;
      }

      // //profilepic n location for desirer in step 1
      // let profilePic = '';
      // let location = '';
      // if(this.state.roleName === 'Desirer'){
      //     profile
      // }

      this.setState({
        //step1
        Role: registerData.Role,
        Forename: registerData.Forename,
        Surname: registerData.Surname,
        Email: registerData.Email,
        Username: registerData.Username,
        DisplayName: registerData.DisplayName,
        Category: registerData.Category,
        PhoneNumber: registerData.PhoneNumber,
        TwoFactor: registerData.twoFactor,
        AgreeTerms: registerData.AgreeTerms,
        YearsOld: registerData.YearsOld,
        TwoFactorChecked: twoFactorChecked,
        AgreeTermsChecked: termChecked,
        YearsOldChecked: yearOldChecked,
        PersonalDetailsError: personalDetailsError,
        ProfilePic: registerData.ProfilePic,
        Location: registerData.Location,
      });
    }

    // save values coming from verify email response for step2 (this do bcz after saving data for step2 and click on next and then click on again 2 num of slider then emailVerify Api is not hit so PhotoId is not coming so data is coming in identityVerify api response)
    if (
      nextProps.userRegisterData.idVerificaionSuccessResult &&
      nextProps.userRegisterData.idVerificaionSuccessResult !== "" &&
      this.state.Role === "ContentCreator"
    ) {
      var userVerifyData =
        nextProps.userRegisterData.idVerificaionSuccessResult.data;

      verifyIdError = false;
      if (userVerifyData.PhotoId !== "" && userVerifyData.PhotowithId !== "") {
        verifyIdError = true;
      }

      this.setState({
        //step2

        VerifyIdError: verifyIdError,
        PhotoId: userVerifyData.PhotoId,
        PhotowithId: userVerifyData.PhotowithId,
      });
    }

    // save values coming from verify email response for step3
    if (
      nextProps.userRegisterData.updateProfileSuccess &&
      nextProps.userRegisterData.updateProfileSuccess !== "" &&
      this.state.Role === "ContentCreator"
    ) {
      var profileData = nextProps.userRegisterData.updateProfileSuccess.data;

      profileError = false;
      if (
        profileData.ProfilePic !== "" &&
        (userData.SubscriptionPrice !== "" || userData.Subscription !== null)
      ) {
        profileError = true;
      }

      this.setState({
        //step3

        ProfileError: profileError,
        ProfilePic: profileData.ProfilePic,
        ProfileVideo: profileData.ProfileVideo,
        ProfileBanner: profileData.ProfileBanner,
        Location: profileData.Location,
        SubscriptionPrice: profileData.SubscriptionPrice,
        TwitterURL: profileData.TwitterURL,
        AmazonURL: profileData.AmazonURL,
        Bio: profileData.Bio,
        Tags: profileData.Tags,
      });
    }
  }

  //on imput change save value in state
  inputChange(e) {
    if (e.target.name === "Username") {
      e.target.value = e.target.value.replace(/\W/g, "");
    }
    var TwoFactorChecked = this.state.TwoFactorChecked;
    if (e.target.name === "TwoFactor") {
      TwoFactorChecked = !this.state.TwoFactorChecked;
      e.target.value = 0;
      if (TwoFactorChecked) {
        e.target.value = 1;
      }
    }

    //if agrreterms checked then send value yes else no
    var AgreeTermsChecked = this.state.AgreeTermsChecked;
    if (e.target.name === "AgreeTerms") {
      AgreeTermsChecked = !this.state.AgreeTermsChecked;
      e.target.value = 0;
      if (AgreeTermsChecked) {
        e.target.value = 1;
      }
    }

    //if agrreterms checked then send value 1 else 0
    var YearsOldChecked = this.state.YearsOldChecked;
    if (e.target.name === "YearsOld") {
      YearsOldChecked = !this.state.YearsOldChecked;
      e.target.value = 0;
      if (YearsOldChecked) {
        e.target.value = 1;
      }
    }

    if (e.target.name === "Country") {
      var index = e.target.selectedIndex;
      var countryOptionElement = e.target.childNodes[index];
      var countryOption = countryOptionElement.getAttribute("id");
      var SortCodeDisabled = false;
      if (countryOption === "gb") {
        SortCodeDisabled = !SortCodeDisabled;
      }
      this.setState({
        StatesArray: [],
        State: "",
        CountryCode: countryOption,
        SortCodeDisabled: SortCodeDisabled,
      });
      this.props.getStatesAction(e.target.value);
    }

    if (
      e.target.name === "State" &&
      this.state.StatesArray.length !== 0 &&
      this.state.CountryCode !== "gb" &&
      this.state.CountryCode !== "GB"
    ) {
      var index = e.target.selectedIndex;
      var stateOptionElement = e.target.childNodes[index];
      var stateOption = stateOptionElement.getAttribute("id");
      this.setState({
        StateCode: stateOption,
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
      TwoFactorChecked: TwoFactorChecked,
      AgreeTermsChecked: AgreeTermsChecked, //set changed agrre terms value for next change
      YearsOldChecked: YearsOldChecked, //set changed agrre terms value for next change
    });
  }

  imageUpload(e) {
    var file = e.target.files[0];
    if (file) {
      // var image_file = URL.createObjectURL(file)
      // this.setState({[e.target.name+'View']: image_file})
      var field_name = e.target.name;
      this.setState({
        [field_name]: file,
      });
      let reader = new FileReader();
      // let demo = ''
      reader.onload = (e) =>
        this.setState({
          [field_name + "View"]: reader.result,
        });
      reader.readAsDataURL(file);
    }
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({
      TabClickBoolean: 0,
      SubmitClickBoolean: 0,
      ButtonLoader: true,
    });

    this.props.emptyError();

    // let TwoFactor = 0;
    // if(this.state.TwoFactor === 'Yes'){
    //     TwoFactor = 1;
    // }

    if (this.state.LeftSideTabNumber === 1) {
      let userPersonalDetails = new FormData();
      userPersonalDetails.append("Role", this.state.Role);
      userPersonalDetails.append("Forename", this.state.Forename);
      userPersonalDetails.append("Surname", this.state.Surname);
      userPersonalDetails.append("Username", this.state.Username);
      userPersonalDetails.append("Email", this.state.Email);
      userPersonalDetails.append("DisplayName", this.state.DisplayName);
      userPersonalDetails.append("Password", this.state.Password);
      userPersonalDetails.append("ConfirmPassword", this.state.ConfirmPassword);
      userPersonalDetails.append("Category", this.state.Category);
      userPersonalDetails.append("PhoneNumber", this.state.PhoneNumber);
      userPersonalDetails.append("TwoFactor", parseInt(this.state.TwoFactor));
      userPersonalDetails.append("AgreeTerms", parseInt(this.state.AgreeTerms));
      userPersonalDetails.append("YearsOld", parseInt(this.state.YearsOld));
      userPersonalDetails.append("UserId", this.state.UserId);

      let ProfilePicFlag = 0;
      let ProfilePic = this.state.ProfilePic;
      if (ProfilePic !== "" && ProfilePic !== null && ProfilePic !== "null") {
        if (ProfilePic.name === undefined) {
          ProfilePic = "";
          ProfilePicFlag = 1;
        } else {
          userPersonalDetails.append("ProfilePic", ProfilePic);
        }
      } else {
        ProfilePic = "";
      }
      userPersonalDetails.append("ProfilePicFlag", ProfilePicFlag);
      userPersonalDetails.append("Location", this.state.Location);

      // var userPersonalDetails = {

      //     //first step input fields
      //     Role : this.state.Role,
      //     Forename : this.state.Forename,
      //     Surname : this.state.Surname,
      //     Username : this.state.Username,
      //     Email : this.state.Email,
      //     DisplayName : this.state.DisplayName,
      //     Password : this.state.Password,
      //     ConfirmPassword : this.state.ConfirmPassword,
      //     Category : this.state.Category,
      //     // Category : 'Female',
      //     PhoneNumber : this.state.PhoneNumber,
      //     TwoFactor : parseInt(this.state.TwoFactor),
      //     AgreeTerms : parseInt(this.state.AgreeTerms),
      //     YearsOld : parseInt(this.state.YearsOld),
      //     UserId : this.state.UserId,
      //     ProfilePic : this.state.ProfilePic, //for desirer
      //     Location : this.state.Location //for desirer
      // }
      this.setState({
        SubmitClickBoolean: 1,
      });

      this.props.registerAction(userPersonalDetails);
      
      
    } else if (
      this.state.LeftSideTabNumber === 2 &&
      this.state.Role === "ContentCreator"
    ) {
      let PhotoIdFlag = 0;
      let PhotoId = this.state.PhotoId;
      if (PhotoId !== "" && PhotoId !== null && PhotoId !== "null") {
        if (PhotoId.name === undefined) {
          PhotoIdFlag = 1;
          PhotoId = "";
        }
      }

      let PhotowithIdFlag = 0;
      let PhotowithId = this.state.PhotowithId;
      if (
        PhotowithId !== "" &&
        PhotowithId !== null &&
        PhotowithId !== "null"
      ) {
        if (PhotowithId.name === undefined) {
          PhotowithIdFlag = 1;
          PhotowithId = "";
        }
      }

      let userIdVerificationDetails = new FormData();
      userIdVerificationDetails.append("Forename", this.state.Forename);
      userIdVerificationDetails.append("Surname", this.state.Surname);
      userIdVerificationDetails.append("PhotoId", PhotoId);
      userIdVerificationDetails.append("PhotoIdFlag", PhotoIdFlag);
      userIdVerificationDetails.append("PhotowithId", PhotowithId);
      userIdVerificationDetails.append("PhotowithIdFlag", PhotowithIdFlag);

      // var userIdVerificationDetails = {

      //     //third step input fields

      //     Forename : this.state.Forename,
      //     Surname : this.state.Surname,
      //     PhotoId : this.state.PhotoId,
      //     PhotowithId : this.state.PhotowithId
      // }
      this.setState({
        SubmitClickBoolean: 1,
      });

      this.props.userIdVerifyAction(
        userIdVerificationDetails,
        this.state.UserId
      );
    } else if (
      this.state.LeftSideTabNumber === 3 &&
      this.state.Role === "ContentCreator"
    ) {
      let ProfilePicFlag = 0;
      let ProfilePic = this.state.ProfilePic;
      if (ProfilePic !== "" && ProfilePic !== null && ProfilePic !== "null") {
        if (ProfilePic.name === undefined) {
          ProfilePicFlag = 1;
          ProfilePic = "";
        }
      }

      let ProfileVideoFlag = 0;
      let ProfileVideo = this.state.ProfileVideo;
      if (
        ProfileVideo !== "" &&
        ProfileVideo !== null &&
        ProfileVideo !== "null"
      ) {
        if (ProfileVideo.name === undefined) {
          ProfileVideoFlag = 1;
          ProfileVideo = "";
        }
      }

      let ProfileBannerFlag = 0;
      let ProfileBanner = this.state.ProfileBanner;
      if (
        ProfileBanner !== "" &&
        ProfileBanner !== null &&
        ProfileBanner !== "null"
      ) {
        if (ProfileBanner.name === undefined) {
          ProfileBannerFlag = 1;
          ProfileBanner = "";
        }
      }

      let userProfileDetails = new FormData();
      userProfileDetails.append("ProfilePic", ProfilePic);
      userProfileDetails.append("ProfilePicFlag", ProfilePicFlag);
      userProfileDetails.append("ProfileVideo", ProfileVideo);
      userProfileDetails.append("ProfileVideoFlag", ProfileVideoFlag);
      userProfileDetails.append("ProfileBanner", ProfileBanner);
      userProfileDetails.append("ProfileBannerFlag", ProfileBannerFlag);
      userProfileDetails.append("Location", this.state.Location);
      userProfileDetails.append(
        "SubscriptionPrice",
        this.state.SubscriptionPrice
      );
      userProfileDetails.append("TwitterURL", this.state.TwitterURL);
      userProfileDetails.append("AmazonURL", this.state.AmazonURL);
      userProfileDetails.append("Bio", this.state.Bio);
      userProfileDetails.append("Tags", this.state.Tags);

      // var userProfileDetails = {

      //     //third step input fields

      //     ProfilePic : this.state.ProfilePic,
      //     ProfileVideo : this.state.ProfileVideo,
      //     ProfileBanner : this.state.ProfileBanner,
      //     Location : this.state.Location,
      //     SubscriptionPrice : this.state.SubscriptionPrice,
      //     TwitterURL : this.state.TwitterURL,
      //     AmazonURL : this.state.AmazonURL,
      //     Bio : this.state.Bio,
      //     Tags : this.state.Tags,
      // }
      this.setState({
        SubmitClickBoolean: 1,
      });
      this.props.updateProfileAction(userProfileDetails, this.state.UserId);
    } else if (this.state.LeftSideTabNumber === 4) {
      let expiryDate = "";
      let month = "";
      let year = "";
      if (this.state.expiryDate !== "") {
        let date = new Date(this.state.expiryDate);
        month = date.getMonth() + 1;
        year = date.getFullYear();
      }
      var userPaymentDetails = {
        //third step input fields

        Country: this.state.Country,
        CountryCode: this.state.CountryCode,
        State: this.state.State,
        StateCode: this.state.StateCode,
        AccountName: this.state.AccountName,
        SortCode: this.state.SortCode,
        ZipCode: this.state.ZipCode,
        AccountNumber: this.state.AccountNumber,
        cardNumber: this.state.cardNumber,
        cvv: this.state.cvv,
        CardHolderName: this.state.CardHolderName,
        cardExpMonth: month,
        cardExpYear: year,
      };
      this.setState({
        SubmitClickBoolean: 1,
      });
      this.props.userPaymentDetailsAction(
        userPaymentDetails,
        this.state.UserId
      );
    }
  }

  backStep = () => {
    let step = parseInt(window.location.href.split("step")[1]);
    this.props.history.push("/profile/step" + (step - 1));
  };

  TabsClick(TabNum) {
    this.props.history.push("/profile/step" + TabNum);
    localStorage.getItem("roleName") === "Desirer"
      ? TabNum === 1
        ? this.props.history.push("/follow/step1")
        : this.props.history.push("/follow/step2")
      : this.props.history.push("/profile/step" + TabNum);
    this.setState({
      LeftSideTabNumber: TabNum,
      TabClickBoolean: 1,
      SubmitClickBoolean: 1,
    });
  }

  handleDateChange(e) {
    this.setState({
      expiryDate: e,
    });
  }

  handleClose() {
    this.setState({
      modalShow: false,
      errorMessage: "",
      paysafeError: false,
      paysaferesponse: "",
    });
  }

  show() {
    useState(false);
  }

  render() {
    const {
      isError,
      errors,
      Role,
      UserId,
      LeftSideTabNumber,
      Forename,
      Surname,
      Email,
      Username,
      DisplayName,
      PhoneNumber,
      Password,
      ConfirmPassword,
      Category,
      TwoFactorChecked,
      AgreeTermsChecked,
      YearsOldChecked,
      PersonalDetailsError,
      PhotoId,
      PhotoIdView,
      PhotowithId,
      PhotowithIdView,
      VerifyIdError,
      ProfilePic,
      ProfilePicView,
      ProfileVideo,
      ProfileVideoView,
      ProfileBanner,
      ProfileBannerView,
      Location,
      TwitterURL,
      AmazonURL,
      Bio,
      ProfileError,
      CountriesArray,
      StatesArray,
      AccountName,
      AccountNumber,
      cardNumber,
      CardHolderName,
      expiryDate,
      SortCode,
      ZipCode,
      SortCodeDisabled,
      Country,
      State,
      PaymentError,
      ButtonLoader,
      SlideTab,
      SubscriptionPrice,
      minPrice,
      maxPrice,
      Tags,
      errorMessage,
      modalShow,
      CountryCode,
      cvv,
    } = this.state;

    //locals torage that is set in emailverify js is destroy after 1 hr if set
    if (localStorage.emailVerifyTime) {
      let emailVerifyTime = localStorage.getItem("emailVerifyTime");
      // Check for email expired time
      const currentTime = new Date();
      let timeDiff = (currentTime.getTime() - emailVerifyTime) / 1000;
      timeDiff /= 60;
      timeDiff = Math.abs(Math.round(timeDiff));

      if (timeDiff > 30) {
        // Redirect to login
        localStorage.clear();
        this.props.history.push("/profile/step1");
        window.location.reload();

        this.setState({
          errorMessage: "Timeout!",
          modalShow: true,
          isError: true,
        });
        // toast.warning('Timeout!')
      }
    }

    return (
      <>
        <MetaTags>
          <title>Create Your Profile - Register Now | DesireMe</title>
          <meta property="og:type" content="website" />
          <meta property="og:url" content="__OGURL__" />
          <link rel="canonical" href="__OGURL__" />
          <meta
            property="og:image"
            content="https://desire.me/images/ogimage.png"
          />
          <meta property="og:site_name" content="Desire Me" />
          <meta name="description" content="__METADESCRIPTION__" />
          <meta name="title" content="__METATITLE__" />
          <meta property="og:description" content="__METADESCRIPTION__" />
          <meta property="og:title" content="__METATITLE__" />
        </MetaTags>
        <Header />
        {ButtonLoader ? (
          <div id="preloader">
            <div className="preloader_div"></div>
          </div>
        ) : (
          ""
        )}
        <section id="register_section">
          <div className="container-fluid register_heading">
            <h1 className="register_head_text pt-5 pb-5">
              {Role === "ContentCreator"
                ? "Create Your Profile"
                : "Start Following"}
            </h1>
          </div>
          <div className="container register_content d-lg-flex mt-5">
            <div className="reg_left_content">
              <RegisterSlider
                tabsclick={this.TabsClick}
                userId={UserId}
                TabNum={LeftSideTabNumber}
                VerifyIdError={VerifyIdError}
                ProfileError={ProfileError}
                SlideTab={SlideTab}
                PaymentError={PaymentError}
                PersonalDetailsError={PersonalDetailsError}
                ButtonLoader={ButtonLoader}
                RoleName={Role}
              />
            </div>
            {LeftSideTabNumber === 1 ? (
              <div className="reg_right_content">
                <div className="register_form">
                  <h3 className="pb-3">Get Started with Desire Me</h3>
                  <p className="pb-3">
                    {Role === "ContentCreator" ? (
                      <>
                        {" "}
                        Be DESIRED and start making money as a content creator
                        on Desire Me! Please start by entering your details,
                        it’s important this information is correct so we can
                        ensure you can withdraw your well earned funds promptly.
                        All your personal information is kept private apart from
                        your Display Name and which category you register in.
                      </>
                    ) : (
                      <>
                        Start DESIRING and follow your desired profiles on
                        Desire Me! Please start by entering your details to
                        register your account, all your personal information is
                        kept private apart from your Username and Display Name
                        which is visible by other users.
                      </>
                    )}
                  </p>

                  <form onSubmit={this.onSubmit}>
                    <div className="form-group">
                      <label htmlFor="firstname" className="label_text ">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control input_text"
                        id="FormControlInput1"
                        placeholder="Enter your Name"
                        name="Forename"
                        onChange={this.inputChange}
                        value={Forename}
                      />

                      {isError && errors.Forename ? (
                        <div id="alert-box2" className="font-weight-bold mt-1">
                          <span className="pr-2">
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-exclamation-circle"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                            </svg>
                          </span>
                          {errors.Forename}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="lastname" className="label_text">
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control input_text"
                        id="FormControlInput2"
                        placeholder="Your Last Name"
                        name="Surname"
                        onChange={this.inputChange}
                        value={Surname}
                      />

                      {isError && errors.Surname ? (
                        <div id="alert-box2" className="font-weight-bold mt-1">
                          <span className="pr-2">
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-exclamation-circle"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                            </svg>
                          </span>
                          {errors.Surname}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="emailaddress" className="label_text">
                        Email Address <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control input_text"
                        id="FormControlInput3"
                        placeholder="Your email Address"
                        name="Email"
                        onChange={this.inputChange}
                        value={Email}
                        disabled={UserId !== 0 ? true : false}
                      />

                      {isError && errors.Email ? (
                        <div id="alert-box2" className="font-weight-bold mt-1">
                          <span className="pr-2">
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-exclamation-circle"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                            </svg>
                          </span>
                          {errors.Email}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="username" className="label_text">
                        User Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control input_text"
                        id="FormControlInput4"
                        placeholder="Your User Name"
                        name="Username"
                        onChange={this.inputChange}
                        value={Username}
                      />
                      <div id="alert-box3" className="mt-3 usernamealert">
                        <span>
                          <InformationIcon />
                        </span>
                        {Role === "ContentCreator" ? (
                          <>
                            Your username is how your account with Desire Me is
                            identified, you will be able to access your profile
                            directly on desire.me/username, so it can only
                            contain letters and numbers.
                          </>
                        ) : (
                          <>
                            Your username is how your account with Desire Me is
                            identified, e.g. @username, it may only contain
                            letters and numbers.
                          </>
                        )}
                      </div>
                      {isError && errors.Username ? (
                        <div id="alert-box2" className="font-weight-bold mt-1">
                          <span className="pr-2">
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-exclamation-circle"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                            </svg>
                          </span>
                          {errors.Username}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="displayname" className="label_text">
                        Display Name{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control input_text"
                        id="FormControlInput5"
                        placeholder="Your Display Name"
                        name="DisplayName"
                        onChange={this.inputChange}
                        value={DisplayName}
                        minlength="3"
                        maxlength="26"
                      />

                      <div id="alert-box3" className="mt-3 align-items-center">
                        <span>
                          <InformationIcon />
                        </span>
                        {Role === "ContentCreator" ? (
                          <>
                            This name will be displayed on your profile and
                            should be between 3 &amp; 26 characters
                          </>
                        ) : (
                          <>
                            This name will be displayed when interacting with
                            other users and should be between 3 &amp; 26
                            characters
                          </>
                        )}
                      </div>
                    </div>

                    {Role === "ContentCreator" ? (
                      <div className="form-group">
                        <label htmlFor="phonenumber" className="label_text">
                          Phone Number <span></span>
                        </label>
                        <input
                          type="number"
                          className="form-control input_text"
                          id="FormControlInput8"
                          placeholder="Your Phone Number"
                          name="PhoneNumber"
                          onChange={this.inputChange}
                          value={PhoneNumber}
                        />

                        {isError && errors.PhoneNumber ? (
                          <div
                            id="alert-box2"
                            className="font-weight-bold mt-1"
                          >
                            <span className="pr-2">
                              <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                                className="bi bi-exclamation-circle"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                />
                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                              </svg>
                            </span>
                            {errors.PhoneNumber}
                          </div>
                        ) : (
                          ""
                        )}

                        <div id="alert-box3" className="mt-3">
                          <span>
                            <InformationIcon />
                          </span>
                          This is optional, but used if we ever need to contact
                          you regarding the funds we are holding for you, it
                          also provides another way to regain access to your
                          account if you lose your email access
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {UserId === 0 ? (
                      <div>
                        <div className="form-group">
                          <label htmlFor="password" className="label_text">
                            Password <span className="text-danger">*</span>
                          </label>
                          <input
                            type="password"
                            className="form-control input_text"
                            id="FormControlInput6"
                            placeholder="Enter Password"
                            name="Password"
                            onChange={this.inputChange}
                            value={Password}
                          />

                          {isError && errors.Password ? (
                            <div
                              id="alert-box2"
                              className="font-weight-bold mt-1"
                            >
                              <span className="pr-2">
                                <ErrorinfoIcon />
                              </span>
                              {errors.Password}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="password" className="label_text">
                            Confirm Your Password{" "}
                          </label>
                          <input
                            type="password"
                            className="form-control input_text"
                            id="FormControlInput7"
                            placeholder="Enter Confirm Password"
                            name="ConfirmPassword"
                            onChange={this.inputChange}
                            value={ConfirmPassword}
                          />

                          {isError && errors.ConfirmPassword ? (
                            <div
                              id="alert-box2"
                              className="font-weight-bold mt-1"
                            >
                              <span className="pr-2">
                                <ErrorinfoIcon />
                              </span>
                              {errors.ConfirmPassword}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {Role === "ContentCreator" ? (
                      <div className="form-group">
                        <label
                          htmlFor="formControlSelect1"
                          className="label_text"
                        >
                          Category <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control select_box"
                          id="formControlSelect1"
                          name="Category"
                          onChange={this.inputChange}
                          value={Category}
                        >
                          {/* <div className="option_box"> */}
                          <option disabled value="">
                            Please select Your Category
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Trans">Trans</option>
                          <option value="Couples">Couples</option>
                          {/* </div>    */}
                        </select>

                        {isError && errors.Category ? (
                          <div
                            id="alert-box2"
                            className="font-weight-bold mt-1"
                          >
                            <span className="pr-2">
                              <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                                className="bi bi-exclamation-circle"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                />
                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                              </svg>
                            </span>
                            {errors.Category}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    {Role === "Desirer" ? (
                      <>
                        <div className="form-group">
                          <label htmlFor="profilephoto" className="label_text">
                            Add Profile Photo{" "}
                            <span className="text-danger"></span>
                          </label>

                          <div className=" pic_upload_box">
                            <input
                              type="file"
                              id=""
                              name="ProfilePic"
                              onChange={this.imageUpload}
                              accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.gif,.GIF,.webp"
                            />
                            {ProfilePic === "" ? (
                              <Link to="#">
                                <CameraIcon />
                                <span>Upload Image</span>
                              </Link>
                            ) : ProfilePicView !== "" ? (
                              <div className="img_preview d-flex">
                                <img alt="" src={ProfilePicView} />
                                <span>Upload Image</span>
                              </div>
                            ) : (
                              <div className="img_preview d-flex">
                                <img alt="" src={ProfilePic} />
                                <span>Upload Image</span>
                              </div>
                            )}
                          </div>
                          <div
                            className="mt-3 px-1 align-items-center custom_alert alertbox"
                            style={{ lineHeight: "20px" }}
                          >
                            <span>
                              <InformationIcon />
                            </span>
                            Square crop photos will work best for your profile
                            photo, landscape photos will work best for your
                            cover photo. But please remember no nudity is
                            permitted in these photos, save the good stuff for
                            your paying followers in your posts!
                          </div>
                          {isError && errors.ProfilePic ? (
                            <div
                              id="alert-box2"
                              className="font-weight-bold mt-1"
                            >
                              <span className="pr-2">
                                <svg
                                  width="1em"
                                  height="1em"
                                  viewBox="0 0 16 16"
                                  className="bi bi-exclamation-circle"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                  />
                                  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                </svg>
                              </span>
                              {errors.ProfilePic}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="location" className="label_text">
                            Location{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control input_text"
                            id=""
                            name="Location"
                            placeholder="Enter your Location"
                            onChange={this.inputChange}
                            value={Location}
                          ></input>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {/*
                                            <div className="form-group">
                                                <label htmlFor="formControlSelect2" className="label_text">
                                                    Enable Two-factor authentication{" "}
                                                </label>
                                                <select className="form-control select_box" id="formControlSelect2" name="TwoFactor" onChange={this.inputChange} value={TwoFactor}>
                                                    <option disabled value="">
                                                        Select Two Factor
                                                    </option>
                                                    <option value="Yes" selected="selected">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                                <div id="alert-box" className="font-weight-bold mt-3 two-factor">
                                                    <span className="pr-2">
                                                        <img alt="" src={process.env.PUBLIC_URL+'/frontend/images/message.png'} style={{width: 19, height: 19}}/>
                                                    </span>
                                                    By enabling two-factor authentication, this increases your account security and you will be required to verify any logins from any new devices or browsers, simply by entering a code that we'll email to you.
                                                </div>
                                               
                                                {(isError && errors.TwoFactor) ?
                                                    <div id="alert-box2" className="font-weight-bold mt-1">
                                                        <span className="pr-2">
                                                            <svg
                                                                width="1em"
                                                                height="1em"
                                                                viewBox="0 0 16 16"
                                                                className="bi bi-exclamation-circle"
                                                                fill="currentColor"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                  fillRule="evenodd"
                                                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                                />
                                                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                            </svg>
                                                        </span>
                                                        {errors.TwoFactor}
                                                    </div>
                                                    :
                                                    ""
                                                }
                                            </div>

                                            <div className="check_box">
                                            <p className="check_text">
                                                <input type="checked"></input>
                                            </p>
                                            </div>   */}

                    <div className="form-group">
                      <div className="radios">
                        <label className="control control--checkbox">
                          Enable Two-factor authentication
                          <input
                            type="checkbox"
                            id="radio_0"
                            name="TwoFactor"
                            onClick={this.inputChange}
                            checked={TwoFactorChecked}
                          />
                          <div className="control__indicator"></div>
                        </label>

                        <div id="alert-box3" className="mt-3 two-factor">
                          <span>
                            <InformationIcon />
                          </span>
                          By enabling two-factor authentication, this increases
                          your account security and you will be required to
                          verify any logins from any new devices or browsers,
                          simply by entering a code that we'll email to you.
                        </div>

                        {isError && errors.TwoFactor ? (
                          <div
                            id="alert-box2"
                            className="font-weight-bold mt-1"
                          >
                            <span className="pr-2">
                              <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                                className="bi bi-exclamation-circle"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                />
                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                              </svg>
                            </span>
                            {errors.TwoFactor}
                          </div>
                        ) : (
                          ""
                        )}

                        <br />

                        <label className="control control--checkbox">
                          Please confirm you agree with our{" "}
                          <Link
                            to="/terms-conditions"
                            target="_blank"
                            className="doclinks"
                          >
                            Terms
                          </Link>{" "}
                          and{" "}
                          <Link
                            className="doclinks"
                            to="/privacy-policy"
                            target="_blank"
                          >
                            Privacy Policy
                          </Link>
                          <input
                            type="checkbox"
                            id="radio_0"
                            name="AgreeTerms"
                            onClick={this.inputChange}
                            checked={AgreeTermsChecked}
                          />
                          <div className="control__indicator"></div>
                        </label>

                        {isError && errors.AgreeTerms ? (
                          <div
                            id="alert-box2"
                            className="font-weight-bold mt-1"
                          >
                            <span className="pr-2">
                              <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                                className="bi bi-exclamation-circle"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                />
                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                              </svg>
                            </span>
                            {errors.AgreeTerms}
                          </div>
                        ) : (
                          ""
                        )}

                        <label className="control control--checkbox">
                          I confirm that I am at least 18 years of age or older
                          <input
                            type="checkbox"
                            id="radio_1"
                            name="YearsOld"
                            onClick={this.inputChange}
                            checked={YearsOldChecked}
                          />
                          <div className="control__indicator"></div>
                        </label>

                        {isError && errors.YearsOld ? (
                          <div
                            id="alert-box2"
                            className="font-weight-bold mt-1"
                          >
                            <span className="pr-2">
                              <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                                className="bi bi-exclamation-circle"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                />
                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                              </svg>
                            </span>
                            {errors.YearsOld}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div
                      className="register_btns d-flex jutify-content-between pb-4 pt-5"
                      style={{ justifyContent: "space-between" }}
                    >
                      {/*<button className="prev" type="button">Previous</button>*/}
                      {!ButtonLoader ? (
                        <button className="next" type="submit">
                          Next
                        </button>
                      ) : (
                        <button
                          className="next"
                          type="button"
                          disabled="disabled"
                        >
                          Processing.....
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            ) : LeftSideTabNumber === 2 && Role === "ContentCreator" ? (
              <div className="reg_right_content">
                <div className="identity_form pt-5">
                  <h3 className="pb-3"> VERIFY YOUR IDENTITY</h3>
                  <p className="pb-3">
                    {" "}
                    Before you can be Desired with us, we need to verify that
                    your at least 18 years old and that you're legally allowed
                    to add content on our website. Your ID will be private and
                    kept securely, we will manually check it before your profile
                    goes public on our website. Please ensure the name on your
                    ID matches that on your newly created account, to avoid
                    delaying your verification process.
                  </p>
                  <p className="pt-3">
                    Documents allowed:
                    <ul className="pl-4 ">
                      <li> Passport</li>
                      <li> Driving Licence</li>
                    </ul>
                    <form onSubmit={this.onSubmit}>
                      <div className="form-group pt-3">
                        <label htmlFor="photoid" className="label_text">
                          Photo or Scan of your ID Document{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className=" pic_upload_box">
                          <input
                            type="file"
                            id=""
                            onChange={this.imageUpload}
                            name="PhotoId"
                            accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.webp"
                          />
                          {PhotoId === "" ? (
                            <Link to="#">
                              <svg
                                width="3rem"
                                height="3rem"
                                viewBox="0 0 16 16"
                                className="bi bi-camera-fill"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                <path
                                  fillRule="evenodd"
                                  d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"
                                />{" "}
                              </svg>
                              <span>Upload Image</span>
                            </Link>
                          ) : PhotoIdView !== "" ? (
                            <div className="img_preview d-flex">
                              <img alt="" src={PhotoIdView} />
                              <span>Upload Image</span>
                            </div>
                          ) : (
                            <div className="img_preview d-flex">
                              <img alt="" src={PhotoId} />
                              <span>Upload Image</span>
                            </div>
                          )}
                        </div>

                        {isError && errors.PhotoId ? (
                          <div
                            id="alert-box2"
                            className="font-weight-bold mt-1"
                          >
                            <span className="pr-2">
                              <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                                className="bi bi-exclamation-circle"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                />
                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                              </svg>
                            </span>
                            {errors.PhotoId}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="form-group pt-4">
                        <label htmlFor="photoId" className="label_text">
                          Photo of you holding your ID Document{" "}
                          <span className="text-danger">*</span>
                        </label>

                        <div className=" pic_upload_box">
                          <input
                            type="file"
                            id=""
                            onChange={this.imageUpload}
                            name="PhotowithId"
                            accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.webp"
                          />
                          {PhotowithId === "" ? (
                            <Link to="#">
                              <svg
                                width="3rem"
                                height="3rem"
                                viewBox="0 0 16 16"
                                className="bi bi-camera-fill"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                <path
                                  fillRule="evenodd"
                                  d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"
                                />{" "}
                              </svg>
                              <span>Upload Image</span>
                            </Link>
                          ) : PhotowithIdView !== "" ? (
                            <div className="img_preview d-flex">
                              <img alt="" src={PhotowithIdView} />
                              <span>Upload Image</span>
                            </div>
                          ) : (
                            <div className="img_preview d-flex">
                              <img alt="" src={PhotowithId} />
                              <span>Upload Image</span>
                            </div>
                          )}
                        </div>
                        {isError && errors.PhotowithId ? (
                          <div
                            id="alert-box2"
                            className="font-weight-bold mt-1"
                          >
                            <span className="pr-2">
                              <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                                className="bi bi-exclamation-circle"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                />
                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                              </svg>
                            </span>
                            {errors.PhotowithId}
                          </div>
                        ) : (
                          ""
                        )}
                        <div
                          id="alert-box3"
                          className="mt-3 px-1  custom_alert"
                          style={{ lineHeight: "20px" }}
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="37.639"
                              height="37.639"
                              viewBox="0 0 37.639 37.639"
                            >
                              <g
                                id="Group_341"
                                data-name="Group 341"
                                transform="translate(-768 -348)"
                              >
                                <path
                                  id="Path_2945"
                                  data-name="Path 2945"
                                  d="M786.82,348a18.82,18.82,0,1,0,18.82,18.82A18.809,18.809,0,0,0,786.82,348Zm0,34.7A15.879,15.879,0,1,1,802.7,366.82,15.87,15.87,0,0,1,786.82,382.7Zm0,0"
                                  fill="#1c1c1c"
                                />
                                <path
                                  id="Path_2946"
                                  data-name="Path 2946"
                                  d="M946.47,444.656a1.47,1.47,0,0,0-1.47,1.47v9.468a1.47,1.47,0,1,0,2.941,0v-9.468a1.47,1.47,0,0,0-1.47-1.47Zm0,0"
                                  transform="translate(-159.651 -87.182)"
                                  fill="#1c1c1c"
                                />
                                <path
                                  id="Path_2947"
                                  data-name="Path 2947"
                                  d="M943.72,591.606a1.985,1.985,0,1,1-1.985-1.985,1.985,1.985,0,0,1,1.985,1.985Zm0,0"
                                  transform="translate(-154.915 -217.938)"
                                  fill="#1c1c1c"
                                />
                              </g>
                            </svg>
                          </span>
                          Please ensure that your face and your ID are clearly
                          visible in the same photo.
                        </div>
                      </div>

                      <div
                        className="register_btns d-flex jutify-content-between pt-5 pb-4"
                        style={{ justifyContent: "space-between" }}
                      >
                        {!ButtonLoader ? (
                          <>
                            <button
                              className="prev"
                              type="button"
                              onClick={() => this.TabsClick(1)}
                            >
                              Previous
                            </button>
                            <button className="next">Next</button>
                          </>
                        ) : (
                          <>
                            <button
                              className="prev"
                              type="button"
                              disabled="disabled"
                            >
                              Previous
                            </button>
                            <button className="next" disabled="disabled">
                              Processing..
                            </button>
                          </>
                        )}
                      </div>
                    </form>
                  </p>
                </div>
              </div>
            ) : LeftSideTabNumber === 3 && Role === "ContentCreator" ? (
              <div className="reg_right_content">
                <div className="profileconfirm_form pt-4">
                  <h3 className="pt-3 pb-3">Profile Details</h3>

                  <form onSubmit={this.onSubmit}>
                    <div className="form-group">
                      <label htmlFor="profilephoto" className="label_text">
                        Add Profile Photo <span className="text-danger">*</span>
                      </label>

                      <div className=" pic_upload_box">
                        <input
                          type="file"
                          id=""
                          name="ProfilePic"
                          onChange={this.imageUpload}
                          accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.gif,.GIF,.webp"
                        />
                        {ProfilePic === "" ? (
                          <Link to="#">
                            <svg
                              width="3rem"
                              height="3rem"
                              viewBox="0 0 16 16"
                              className="bi bi-camera-fill"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                              <path
                                fillRule="evenodd"
                                d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"
                              />{" "}
                            </svg>
                            <span>Upload Image</span>
                          </Link>
                        ) : ProfilePicView !== "" ? (
                          <div className="img_preview d-flex">
                            <img alt="" src={ProfilePicView} />
                            <span>Upload Image</span>
                          </div>
                        ) : (
                          <div className="img_preview d-flex">
                            <img alt="" src={ProfilePic} />
                            <span>Upload Image</span>
                          </div>
                        )}
                      </div>
                      <div
                        className="mt-3 px-1 align-items-center custom_alert alertbox"
                        style={{ lineHeight: "20px" }}
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="37.639"
                            height="37.639"
                            viewBox="0 0 37.639 37.639"
                          >
                            <g
                              id="Group_341"
                              data-name="Group 341"
                              transform="translate(-768 -348)"
                            >
                              <path
                                id="Path_2945"
                                data-name="Path 2945"
                                d="M786.82,348a18.82,18.82,0,1,0,18.82,18.82A18.809,18.809,0,0,0,786.82,348Zm0,34.7A15.879,15.879,0,1,1,802.7,366.82,15.87,15.87,0,0,1,786.82,382.7Zm0,0"
                                fill="#1c1c1c"
                              />
                              <path
                                id="Path_2946"
                                data-name="Path 2946"
                                d="M946.47,444.656a1.47,1.47,0,0,0-1.47,1.47v9.468a1.47,1.47,0,1,0,2.941,0v-9.468a1.47,1.47,0,0,0-1.47-1.47Zm0,0"
                                transform="translate(-159.651 -87.182)"
                                fill="#1c1c1c"
                              />
                              <path
                                id="Path_2947"
                                data-name="Path 2947"
                                d="M943.72,591.606a1.985,1.985,0,1,1-1.985-1.985,1.985,1.985,0,0,1,1.985,1.985Zm0,0"
                                transform="translate(-154.915 -217.938)"
                                fill="#1c1c1c"
                              />
                            </g>
                          </svg>
                        </span>
                        Square crop photos will work best for your profile
                        photo, landscape photos will work best for your cover
                        photo. But please remember no nudity is permitted in
                        these photos, save the good stuff for your paying
                        followers in your posts!
                      </div>
                      {isError && errors.ProfilePic ? (
                        <div id="alert-box2" className="font-weight-bold mt-1">
                          <span className="pr-2">
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-exclamation-circle"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                            </svg>
                          </span>
                          {errors.ProfilePic}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="profilevideo" className="label_text">
                        Add Profile Video{" "}
                      </label>
                      <div className=" pic_upload_box">
                        <input
                          type="file"
                          id=""
                          onChange={this.imageUpload}
                          name="ProfileVideo"
                          accept=".gif,.GIF"
                        />
                        {ProfileVideo === "" && ProfileVideoView === "" ? (
                          <Link to="#">
                            <svg
                              width="3rem"
                              height="3rem"
                              viewBox="0 0 16 16"
                              className="bi bi-camera-fill"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                              <path
                                fillRule="evenodd"
                                d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"
                              />{" "}
                            </svg>
                            <span>Upload Image</span>
                          </Link>
                        ) : ProfileVideoView !== "" ? (
                          <div className="img_preview d-flex">
                            <img alt="" src={ProfileVideoView} />
                            <span>Upload Image</span>
                          </div>
                        ) : (
                          <div className="img_preview d-flex">
                            <img alt="" src={ProfileVideo} />
                            <span>Upload Image</span>
                          </div>
                        )}
                      </div>
                      {isError && errors.ProfileVideo ? (
                        <div id="alert-box2" className="font-weight-bold mt-1">
                          <span className="pr-2">
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-exclamation-circle"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                            </svg>
                          </span>
                          Your profile video must be one of the following file
                          types: gif (animated).
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="profilebanner" className="label_text">
                        Add Profile Banner{" "}
                      </label>

                      <div className=" pic_upload_box">
                        <input
                          type="file"
                          id=""
                          onChange={this.imageUpload}
                          name="ProfileBanner"
                          accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.webp"
                        />
                        {ProfileBanner === "" && ProfileBannerView === "" ? (
                          <Link to="#">
                            <svg
                              width="3rem"
                              height="3rem"
                              viewBox="0 0 16 16"
                              className="bi bi-camera-fill"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                              <path
                                fillRule="evenodd"
                                d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"
                              />{" "}
                            </svg>
                            <span>Upload Image</span>
                          </Link>
                        ) : ProfileBannerView !== "" ? (
                          <div className="img_preview d-flex">
                            <img alt="" src={ProfileBannerView} />
                            <span>Upload Image</span>
                          </div>
                        ) : (
                          <div className="img_preview d-flex">
                            <img alt="" src={ProfileBanner} />
                            <span>Upload Image</span>
                          </div>
                        )}
                      </div>
                      {isError && errors.ProfileBanner ? (
                        <div id="alert-box2" className="font-weight-bold mt-1">
                          <span className="pr-2">
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-exclamation-circle"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                            </svg>
                          </span>
                          {errors.ProfileBanner}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="priceplan" className="label_text">
                        Subscription price{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="basic-addon1"
                            style={{
                              borderTopLeftRadius: "20px",
                              borderBottomLeftRadius: "20px",
                              fontSize: "small",
                              borderColor: "#00A99D",
                            }}
                          >
                            £
                          </span>
                        </div>

                        <input
                          type="number"
                          className="form-control input_text"
                          id=""
                          name="SubscriptionPrice"
                          placeholder={
                            "minimum " +
                            minPrice +
                            (maxPrice !== "0" &&
                            maxPrice !== "" &&
                            maxPrice !== "null" &&
                            maxPrice !== null
                              ? " maximum " + maxPrice
                              : "")
                          }
                          onChange={this.inputChange}
                          defaultValue={SubscriptionPrice}
                          step="any"
                        ></input>
                      </div>

                      <div
                        id="alert-box3"
                        className="mt-3 px-1 custom_alert"
                        style={{ lineHeight: "20px" }}
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="37.639"
                            height="37.639"
                            viewBox="0 0 37.639 37.639"
                          >
                            <g
                              id="Group_341"
                              data-name="Group 341"
                              transform="translate(-768 -348)"
                            >
                              <path
                                id="Path_2945"
                                data-name="Path 2945"
                                d="M786.82,348a18.82,18.82,0,1,0,18.82,18.82A18.809,18.809,0,0,0,786.82,348Zm0,34.7A15.879,15.879,0,1,1,802.7,366.82,15.87,15.87,0,0,1,786.82,382.7Zm0,0"
                                fill="#1c1c1c"
                              />
                              <path
                                id="Path_2946"
                                data-name="Path 2946"
                                d="M946.47,444.656a1.47,1.47,0,0,0-1.47,1.47v9.468a1.47,1.47,0,1,0,2.941,0v-9.468a1.47,1.47,0,0,0-1.47-1.47Zm0,0"
                                transform="translate(-159.651 -87.182)"
                                fill="#1c1c1c"
                              />
                              <path
                                id="Path_2947"
                                data-name="Path 2947"
                                d="M943.72,591.606a1.985,1.985,0,1,1-1.985-1.985,1.985,1.985,0,0,1,1.985,1.985Zm0,0"
                                transform="translate(-154.915 -217.938)"
                                fill="#1c1c1c"
                              />
                            </g>
                          </svg>
                        </span>
                        This is the price (in £-GBP) that your desirers
                        (followers) will pay to follow you and access your
                        content.
                      </div>
                      {isError && errors.SubscriptionPrice ? (
                        <div id="alert-box2" className="font-weight-bold mt-1">
                          <span className="pr-2">
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-exclamation-circle"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                            </svg>
                          </span>
                          {errors.SubscriptionPrice}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="location" className="label_text">
                        Location{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control input_text"
                        id=""
                        name="Location"
                        placeholder="Enter your Location"
                        onChange={this.inputChange}
                        defaultValue={Location}
                      ></input>
                    </div>

                    <div className="form-group">
                      <label htmlFor="twitter" className="label_text">
                        Twitter Profile URL{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control input_text"
                        id=""
                        name="TwitterURL"
                        placeholder="Enter your twitter url"
                        onChange={this.inputChange}
                        defaultValue={TwitterURL}
                      ></input>
                    </div>
                    {isError && errors.TwitterURL ? (
                      <div
                        id="alert-box6"
                        className="font-weight-bold mt-1 alertbox-validation"
                      >
                        <span className="pr-2">
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 16 16"
                            className="bi bi-exclamation-circle"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            />
                            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                          </svg>
                        </span>
                        The Twitter URL is invalid.
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="form-group">
                      <label htmlFor="amzon" className="label_text">
                        Amazon Wishlist URL{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control input_text"
                        id=""
                        name="AmazonURL"
                        placeholder="Enter your amazon url"
                        onChange={this.inputChange}
                        defaultValue={AmazonURL}
                      ></input>
                    </div>
                    {isError && errors.AmazonURL ? (
                      <div className="font-weight-bold mt-1 alertbox-validation">
                        <span className="pr-2">
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 16 16"
                            className="bi bi-exclamation-circle"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            />
                            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                          </svg>
                        </span>
                        The Amazon URL is invalid.
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="form-group">
                      <label htmlFor="bio" className="label_text">
                        Bio{" "}
                      </label>
                      <input
                        type="textarea"
                        className="form-control input_text "
                        id=""
                        name="Bio"
                        placeholder="Enter your Bio"
                        onChange={this.inputChange}
                        defaultValue={Bio}
                        minLength="20"
                        maxlength="200"
                      ></input>
                    </div>
                    <div
                      id="alert-box9"
                      className="mt-3 align-items-center alertbox "
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="37.639"
                          height="37.639"
                          viewBox="0 0 37.639 37.639"
                        >
                          <g
                            id="Group_341"
                            data-name="Group 341"
                            transform="translate(-768 -348)"
                          >
                            <path
                              id="Path_2945"
                              data-name="Path 2945"
                              d="M786.82,348a18.82,18.82,0,1,0,18.82,18.82A18.809,18.809,0,0,0,786.82,348Zm0,34.7A15.879,15.879,0,1,1,802.7,366.82,15.87,15.87,0,0,1,786.82,382.7Zm0,0"
                              fill="#1c1c1c"
                            ></path>
                            <path
                              id="Path_2946"
                              data-name="Path 2946"
                              d="M946.47,444.656a1.47,1.47,0,0,0-1.47,1.47v9.468a1.47,1.47,0,1,0,2.941,0v-9.468a1.47,1.47,0,0,0-1.47-1.47Zm0,0"
                              transform="translate(-159.651 -87.182)"
                              fill="#1c1c1c"
                            ></path>
                            <path
                              id="Path_2947"
                              data-name="Path 2947"
                              d="M943.72,591.606a1.985,1.985,0,1,1-1.985-1.985,1.985,1.985,0,0,1,1.985,1.985Zm0,0"
                              transform="translate(-154.915 -217.938)"
                              fill="#1c1c1c"
                            ></path>
                          </g>
                        </svg>
                      </span>
                      Your bio should be between 20 and 200 characters.
                    </div>

                    {isError && errors.Bio ? (
                      <div className="font-weight-bold mt-1 alertbox-validation">
                        <span className="pr-2">
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 16 16"
                            className="bi bi-exclamation-circle"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            />
                            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                          </svg>
                        </span>
                        {errors.Bio}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="form-group">
                      <label htmlFor="tags" className="label_text">
                        Tags{" "}
                      </label>
                      <input
                        type="textarea"
                        className="form-control input_text "
                        id=""
                        name="Tags"
                        placeholder="Enter Tags"
                        onChange={this.inputChange}
                        defaultValue={Tags}
                      ></input>
                    </div>

                    <div
                      className="register_btns d-flex jutify-content-between pt-5 pb-4"
                      style={{ justifyContent: "space-between" }}
                    >
                      {!ButtonLoader ? (
                        <>
                          <button
                            className="prev"
                            type="button"
                            onClick={() => this.TabsClick(2)}
                          >
                            Previous
                          </button>
                          <button className="next" type="submit">
                            Next
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="prev"
                            type="button"
                            disabled="disabled"
                          >
                            Previous
                          </button>
                          <button className="next" disabled="disabled">
                            Processing..
                          </button>
                        </>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            ) : LeftSideTabNumber === 4 ? (
              <div className="reg_right_content">
                <div className="payment_form pt-4">
                  <h3 className="pt-3 pb-3">Payment Details</h3>
                  <p className="pb-3">
                    {" "}
                    {Role === "ContentCreator" ? (
                      <>
                        Please enter your bank account details so we can pay
                        your well earned commissions! If you only have a non-UK
                        bank account please skip this step and contact us later
                        to discuss an alternative withdrawal method.
                      </>
                    ) : (
                      <>
                        <p>
                          Please enter your payment card details so you can
                          start following on Desire Me, we’ll securely save
                          these details through our processing partner Paysafe,
                          to allow you to quickly and easily follow new users in
                          the future without having to re-enter these details.{" "}
                        </p>
                        <p>
                          These payment details will also be used to
                          automatically process the renewals for whom you are
                          following on Desire Me, which you can cancel at
                          anytime.
                        </p>
                      </>
                    )}
                  </p>

                  <form onSubmit={this.onSubmit}>
                    {Role !== "ContentCreator" ? (
                      <>
                        <div className="form-group">
                          <label
                            htmlFor="formControlSelect1"
                            className="label_text"
                          >
                            Billing Country{" "}
                          </label>
                          <select
                            className="form-control select_box"
                            id="formControlSelect1"
                            name="Country"
                            onChange={this.inputChange}
                            value={Country}
                          >
                            {CountriesArray
                              ? CountriesArray.map(function (country) {
                                  return (
                                    <option
                                      key={country.id}
                                      value={country.name}
                                      id={country.code}
                                    >
                                      {country.name}
                                    </option>
                                  );
                                })
                              : ""}
                          </select>
                          {isError && errors.Country ? (
                            <div
                              id="alert-box2"
                              className="font-weight-bold mt-1"
                            >
                              <span className="pr-2">
                                <svg
                                  width="1em"
                                  height="1em"
                                  viewBox="0 0 16 16"
                                  className="bi bi-exclamation-circle"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                  />
                                  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                </svg>
                              </span>
                              {errors.Country}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="form-group">
                          <label
                            htmlFor="formControlSelect1"
                            className="label_text"
                          >
                            County/Region/State{" "}
                          </label>
                          {StatesArray.length === 0 ||
                          CountryCode === "gb" ||
                          CountryCode === "GB" ? (
                            <input
                              type="text"
                              className="form-control input_text"
                              id=""
                              name="State"
                              placeholder="Enter State"
                              onChange={this.inputChange}
                              defaultValue={State}
                            ></input>
                          ) : (
                            <select
                              className="form-control select_box"
                              id="formControlSelect2"
                              name="State"
                              onChange={this.inputChange}
                              value={State}
                            >
                              <option selected disabled value="">
                                Please select Your State
                              </option>

                              {StatesArray
                                ? StatesArray.map(function (state) {
                                    return (
                                      <option
                                        key={state.id}
                                        value={state.name}
                                        id={state.state_code}
                                      >
                                        {state.name}
                                      </option>
                                    );
                                  })
                                : ""}
                            </select>
                          )}
                          {isError && errors.State ? (
                            <div
                              id="alert-box2"
                              className="font-weight-bold mt-1"
                            >
                              <span className="pr-2">
                                <svg
                                  width="1em"
                                  height="1em"
                                  viewBox="0 0 16 16"
                                  className="bi bi-exclamation-circle"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                  />
                                  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                </svg>
                              </span>
                              {errors.State}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="ccv" className="label_text">
                            Postcode / Zipcode{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control input_text"
                            id="card_number"
                            placeholder="Enter Your ZipCode"
                            name="ZipCode"
                            onChange={this.inputChange}
                            defaultValue={ZipCode}
                          />
                          {isError && errors.ZipCode ? (
                            <div
                              id="alert-box2"
                              className="font-weight-bold mt-1"
                            >
                              <span className="pr-2">
                                <svg
                                  width="1em"
                                  height="1em"
                                  viewBox="0 0 16 16"
                                  className="bi bi-exclamation-circle"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                  />
                                  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                </svg>
                              </span>
                              {errors.ZipCode}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="form-group">
                          <label
                            htmlFor="cardholdername"
                            className="label_text"
                          >
                            Card Holder Name{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control input_text"
                            id="card_holder"
                            name="CardHolderName"
                            placeholder="Enter Card Holder Full Name"
                            onChange={this.inputChange}
                            defaultValue={CardHolderName}
                          />
                          {isError && errors.CardHolderName ? (
                            <div
                              id="alert-box2"
                              className="font-weight-bold mt-1"
                            >
                              <span className="pr-2">
                                <svg
                                  width="1em"
                                  height="1em"
                                  viewBox="0 0 16 16"
                                  className="bi bi-exclamation-circle"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                  />
                                  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                </svg>
                              </span>
                              {errors.CardHolderName}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="cardnumber" className="label_text">
                            Card Number{" "}
                          </label>
                          <input
                            type="number"
                            className="form-control input_text"
                            id="card_number"
                            placeholder="Enter Your Card Number"
                            name="cardNumber"
                            onChange={this.inputChange}
                            defaultValue={cardNumber}
                          />

                          {isError && errors.cardNumber ? (
                            <div
                              id="alert-box2"
                              className="font-weight-bold mt-1"
                            >
                              <span className="pr-2">
                                <svg
                                  width="1em"
                                  height="1em"
                                  viewBox="0 0 16 16"
                                  className="bi bi-exclamation-circle"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                  />
                                  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                </svg>
                              </span>
                              {errors.cardNumber}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {Role === "ContentCreator" ? (
                      <>
                        <div className="form-group">
                          <label
                            htmlFor="cardholdername"
                            className="label_text"
                          >
                            Account Holder Name{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control input_text"
                            id="card_holder"
                            name="AccountName"
                            placeholder="Enter Account Holder  Full Name"
                            onChange={this.inputChange}
                            defaultValue={AccountName}
                          />
                          {isError && errors.AccountName ? (
                            <div
                              id="alert-box2"
                              className="font-weight-bold mt-1"
                            >
                              <span className="pr-2">
                                <svg
                                  width="1em"
                                  height="1em"
                                  viewBox="0 0 16 16"
                                  className="bi bi-exclamation-circle"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                  />
                                  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                </svg>
                              </span>
                              {errors.AccountName}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="cardnumber" className="label_text">
                            Account Number{" "}
                          </label>
                          <input
                            type="number"
                            className="form-control input_text"
                            id="card_number"
                            placeholder="Enter Your Account Number"
                            name="AccountNumber"
                            onChange={this.inputChange}
                            defaultValue={AccountNumber}
                          />

                          {isError && errors.AccountNumber ? (
                            <div
                              id="alert-box2"
                              className="font-weight-bold mt-1"
                            >
                              <span className="pr-2">
                                <svg
                                  width="1em"
                                  height="1em"
                                  viewBox="0 0 16 16"
                                  className="bi bi-exclamation-circle"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                  />
                                  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                </svg>
                              </span>
                              {errors.AccountNumber}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {Role !== "ContentCreator" ? (
                      <>
                        <div className="form-group">
                          <label htmlFor="expdate" className="label_text">
                            Expiration Date{" "}
                          </label>
                          <br />

                          <DatePicker
                            className="datePicker input_text"
                            selected={expiryDate}
                            onChange={this.handleDateChange}
                            dateFormat="MM/yy"
                            showMonthYearPicker
                            minDate={new Date()}
                          />

                          {isError &&
                          (errors.cardExpMonth || errors.cardExpYear) ? (
                            <div
                              id="alert-box2"
                              className="font-weight-bold mt-1"
                            >
                              <span className="pr-2">
                                <svg
                                  width="1em"
                                  height="1em"
                                  viewBox="0 0 16 16"
                                  className="bi bi-exclamation-circle"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                  />
                                  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                </svg>
                              </span>
                              {errors.cardExpYear}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="form-group w-lg-50 w-sm-100">
                          <label className="label_text">CVV</label>
                          {/*
                  				<input type="month" className="form-control input_text" id="" name="expiryDate" placeholder="Enter Your Expiration Date" defaultValue={expiryDate} onChange={this.inputChange}/>
                            */}
                          <input
                            type="text"
                            className="form-control input_text"
                            id="cvv"
                            placeholder="Enter Your CVV"
                            name="cvv"
                            onChange={this.inputChange}
                            defaultValue={cvv}
                          />

                          {isError && errors.cvv ? (
                            <div
                              id="alert-box2"
                              className="font-weight-bold mt-1"
                            >
                              <span className="pr-2">
                                <svg
                                  width="1em"
                                  height="1em"
                                  viewBox="0 0 16 16"
                                  className="bi bi-exclamation-circle"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                  />
                                  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                </svg>
                              </span>
                              {errors.cvv}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        {/*
                                                    <div className="form-group">
                                                        <label htmlFor="ccv" className="label_text">
                                                            SortCode{" "}
                                                        </label>
                                                        <input type="text" className="form-control input_text" id="card_number" placeholder="Enter Your SortCode" name="SortCode" onChange={this.inputChange} defaultValue={SortCode} disabled={SortCodeDisabled}/>
                                                        {(isError && errors.SortCode) ?
                                                            <div id="alert-box2" className="font-weight-bold mt-1">
                                                                <span className="pr-2">
                                                                    <svg
                                                                        width="1em"
                                                                        height="1em"
                                                                        viewBox="0 0 16 16"
                                                                        className="bi bi-exclamation-circle"
                                                                        fill="currentColor"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                          fillRule="evenodd"
                                                                          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                                        />
                                                                        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                                    </svg>
                                                                </span>
                                                                {errors.SortCode}
                                                            </div>
                                                            :
                                                            ""
                                                        }
                                                    </div>
                                                    */}
                      </>
                    ) : (
                      <div className="form-group">
                        <label htmlFor="ccv" className="label_text">
                          SortCode{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control input_text"
                          id="card_number"
                          placeholder="Enter Your SortCode"
                          name="SortCode"
                          onChange={this.inputChange}
                          defaultValue={SortCode}
                        />
                        {isError && errors.SortCode ? (
                          <div
                            id="alert-box2"
                            className="font-weight-bold mt-1"
                          >
                            <span className="pr-2">
                              <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                                className="bi bi-exclamation-circle"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                />
                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                              </svg>
                            </span>
                            {errors.SortCode}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}

                    <div
                      className="register_btns d-flex jutify-content-between pt-5 pb-4"
                      style={{ justifyContent: "space-between" }}
                    >
                      {!ButtonLoader ? (
                        <>
                          {Role === "ContentCreator" ? (
                            <button
                              className="prev"
                              type="button"
                              onClick={() => this.TabsClick(3)}
                            >
                              Previous
                            </button>
                          ) : (
                            <button
                              className="prev"
                              type="button"
                              onClick={() => this.TabsClick(1)}
                            >
                              Previous
                            </button>
                          )}
                          <button className="next" type="submit">
                            Next
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="prev"
                            type="button"
                            disabled="disabled"
                          >
                            Previous
                          </button>
                          <button className="next" disabled="disabled">
                            Processing..
                          </button>
                        </>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              ""
            )}
            {/*
                            <ToastContainer
                                position="bottom-center"
                                autoClose={5000}
                                hideProgressBar={true}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />*/}
            {modalShow ? (
              <ModalComponent
                isError={isError}
                errorMessage={errorMessage}
                show={this.show}
                handleClose={this.handleClose}
              />
            ) : (
              ""
            )}
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

Profile.propTypes = {
  registerAction: PropTypes.func.isRequired,
  verifyEmailAction: PropTypes.func.isRequired,
  updateProfileAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userRegisterData: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  registerAction,
  verifyEmailAction,
  updateProfileAction,
  userIdVerifyAction,
  userPaymentDetailsAction,
  getCountries,
  emptyError,
  getStatesAction,
  getSubscriptionRangeAction,
  loginAction,
})(withRouter(Profile));
