import React, { Component } from "react";
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import MetaTags from "react-meta-tags";

// helper components
import { Button } from "./helpers/Button";

class AboutUs extends Component {
    
    render() {

        const { history } = this.props

        return (
            <>
                <MetaTags>
                    <title>About Us | DesireMe</title>
                    <meta name="description" content="__METADESCRIPTION__" />
                    <meta name="title" content="__METATITLE__" />
                    <meta property="og:description" content="__METADESCRIPTION__" />
                    <meta property="og:title" content="__METATITLE__" />
                    <meta property="og:url" content="__OGURL__" />
                </MetaTags>
                <Header />

                <section id="create_post_page" className="about_page">

                    <div className="about_header_image">
                        <h1>About Us</h1>
                    </div>

                    <div className="container py-5" id="">
                        <div className="ceate_post_content bg-white text-center text-lg-left">
                            <div className="row pb-4">
                                <div className="createpost_heading pl-0 pl-lg-5 mx-auto mx-lg-0">
                                    <h4 className="font-weight-bold">About Us</h4>

                                    <div className="about-content">
                                        <p>
                                            {"DesireMe is an adult content subscription site catering for both creators (the desired) and users (the desirers). It's an opportunity for everyone to safely monetise online sex work, either as a performer or viewer. Performers can upstream material and viewers can stream or buy videos."}
                                        </p>

                                        <p>
                                            {"Akin to Facebook and other similar global platforms, DesireMe is a portal where an audience turns to your material, watching safely from their homes. And if you've been interested in growing a fresh stream of income, our site is perfect. If you need to know more, reach out to DesireMe and learn how to become a desired model creating unique adult content for an audience happy to pay to see you do all those things that make them hot and bothered!"}
                                        </p>

                                        <p className="description-link">
                                            <Button onClick={() => history.push('/blog/Top-8-Things-to-Keep-in-Mind-When-You-Sign-Up-to-Desire.me')}>
                                                Learn how
                                            </Button>

                                        </p>

                                        <p>
                                            {"We're a site configured to optimise creator engagement and customer satisfaction. If you're already in the field, expand your reach. Partnering with DesireMe connects you with an exclusive audience that's already looking for you. Create and upload your content, set a price and wait for those recurring payments. Right now, interactive subscription sites are becoming more popular than conventional porn. DesireMe can help you take your career to the next level, providing you with an built-in audience. Contact our friendly team to learn more."}
                                        </p>

                                        <p className="description-link">
                                            <Button onClick={() => history.push('/contact-us')}>Contact our team</Button>
                                        </p>

                                        <p>
                                            {"Here are just some reasons why DesireMe is, well, a desirable option."}
                                        </p>

                                        <ul className="list-items">
                                            <li>
                                                {"Interactive adult subscription websites are already generating over a billion in revenue annually, can you afford to not be a part of this?"}
                                            </li>
                                            <li>
                                                {"Adult content is the focus of 15% of the world's internet uses daily, that’s a lot of clicks."}
                                            </li>
                                            <li>
                                                {"Amateur model platforms have fast become one of the hottest outlets for adults of all ages."}
                                            </li>
                                        </ul>

                                        <p>
                                            {"DesireMe keeps it simple. Create a profile, add content and start earning. Earn extra by being tipped by your favourite viewers! End up one of DesireMe's Rising Stars or Superstars, which are automated categorises used to promote the content of our most active contributors. Get started today!"}
                                        </p>

                                        <p className="description-link">
                                            <Button onClick={() => history.push('/profile/step1')}>Get started today!</Button>
                                        </p>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <Footer />
            </>
        );
    }
}

export const mapStateToProps = (state) => ({

})


export default connect(
    mapStateToProps,
    {}
)(withRouter(AboutUs));
