import React, { Component } from 'react';
import AuxFile from '../../../hoc/AuxFile';
import { connect } from 'react-redux';
import { toggleUserActiveStatus } from '../../../actions/chat';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayName: '',
            ProfilePic: '',
            isActive: true
        }
    }
    componentDidMount() {
        this.setState({
            displayName: this.props.authenticatedUserData.Username,
            ProfilePic: this.props.authenticatedUserData.ProfilePic,
            userId: this.props.authenticatedUserData.userId,
            isActive: true
        });
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.getUserData.usersData && nextProps.getUserData.usersData !== '') {
            this.setState({
                ...this.state,
                ProfilePic: nextProps.getUserData.usersData.ProfilePic,
                displayName: nextProps.getUserData.usersData.Username,
                userId: this.props.authenticatedUserData.userId
            })
        }
        //updated profile response
        if (nextProps.getUserData.updatedProfileData && nextProps.getUserData.updatedProfileData && nextProps.getUserData.updatedProfileData.data !== '') {
            this.setState({
                ...this.state,
                ProfilePic: nextProps.getUserData.updatedProfileData.data.ProfilePic,
                displayName: nextProps.getUserData.updatedProfileData.data.Username,
                userId: this.props.authenticatedUserData.userId
            })
        }
    }
    toggleActive = () => {
        this.props.toggleUserActiveStatus(this.state.userId,{online_status: this.state.isActive ? 0 : 1 });
        this.setState({
            ...this.state,
            isActive: !this.state.isActive
        })
    }
    render() {
        const { displayName, ProfilePic, isActive } = this.state;
        return (
            <AuxFile>
                {this.props.isWeb === "true" ? (
                    <AuxFile>
                        <img src={ProfilePic} className="message_img" alt="" />
                        <p className="message_img_text">{displayName} </p>
                        <label className="switch message_toggle mt-1">
                            <input type="checkbox" onChange={this.toggleActive} checked={isActive} />
                            <span className="slider round message_toggle_circle"></span>
                        </label>
                    </AuxFile>
                ) : (<AuxFile>
                    <div className="col-md-1 set_column">
                        <img src={ProfilePic} className="message_img_sandy" alt="" />
                    </div>
                    <div className="col-md-3 special_messages_bojan set_column">
                        <p className="message_img_text">{displayName} </p>
                        <label className="switch message_toggle_bojan">
                            <input type="checkbox" onChange={this.toggleActive} checked={isActive} className="message_toggle_img" />
                            <span className="slider round message_toggle_circle"></span>
                        </label>
                    </div>
                </AuxFile>)}
            </AuxFile>
        )
    }
}

const mapStateToProps = (state) => ({
    authenticatedUserData: state.auth.loginSuccessResult,
    getUserData: state.userAccountData,
})
export default connect(mapStateToProps,{toggleUserActiveStatus})(Profile);
