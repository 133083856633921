import {
    SAVE_CONTACT_FORM_DATA,
} from "../actions/types"

const initialState = {
    saveContatFormData : "",
}

export default function(state = initialState, action) {
    switch (action.type) {
        case SAVE_CONTACT_FORM_DATA:
            return {
                ...state,
                saveContatFormData: action.payload
            }
        default:
            return state
    }
}
