import React, { Component, useState } from "react";
import {connect} from 'react-redux';
import {withRouter , Link} from 'react-router-dom';
import {forgotPasswordAction} from '../../actions/auth'
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Modal from "react-bootstrap/Modal";
import ModalComponent from "../ModalComponent";
import {ERROR_MESSAGE} from '../Constant';
import MetaTags from "react-meta-tags";

export class ForgotPassword extends Component {
    constructor(props){
        super(props);
        this.state = {
            email : '',
            errors : [],
            isError : false,
            ButtonLoader : false,
            errorMessage : '',
            modalShow : false,
        }

        this.onSubmit = this.onSubmit.bind(this)
        this.inputChange = this.inputChange.bind(this)
        this.handleClose = this.handleClose.bind(this);
        this.show = this.show.bind(this);
    }

     UNSAFE_componentWillReceiveProps(nextProps){
        this.setState({
            ButtonLoader : false
        })
        if(nextProps.userRegisterData.forgotPassword && nextProps.userRegisterData.forgotPassword !== ''){
            this.setState({
                    isError : false,
                    errorMessage : nextProps.userRegisterData.forgotPassword.message,
                    modalShow : true,
                })
                // toast.success(nextProps.userRegisterData.forgotPassword.message);
                // setTimeout(function(){
                //     this.props.history.push('/login')
                // },5000);
        }
        else{
            // toast.error('Error/!');
            if(nextProps.errors.errors.errors){
                this.setState({
                    errors : nextProps.errors.errors.errors,
                    isError : true,
                    errorMessage : ERROR_MESSAGE,
                    modalShow : true,
                })
            }
        }

    }

    //on imput change save value in state
    inputChange(e){
        this.setState({
            email : e.target.value
        })
    }

    onSubmit(e){
        e.preventDefault()
        this.setState({
            ButtonLoader : true
        })
        var emailData = {
            email : this.state.email
        }
        this.props.forgotPasswordAction(emailData)
    }

    handleClose() {
        this.setState({
            modalShow : false,
            errorMessage : '',
        })
    }

    show() {
        useState(false);
    }

    render() {
        const {isError , errors , ButtonLoader, modalShow , errorMessage} = this.state
        return (
            <>
                <MetaTags>
                    <meta property="og:title" content="Desire Me"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content="__OGURL__"/>
                    <link rel="canonical" href="__OGURL__" />
                    <meta property="og:image" content="https://desire.me/images/ogimage.png"/>
                    <meta property="og:site_name" content="Desire Me"/>
                    <meta property="og:description" content="__METADESCRIPTION__"/>
                    <meta property="og:title" content="__METATITLE__"/>
                    <meta
                        name="description"
                        content="__METADESCRIPTION__"
                    />
                    <meta name="title" content="__METATITLE__"/>
                </MetaTags>
                <Header />
                    {ButtonLoader ? 
                        <div id="preloader">
                            <div className="preloader_div">
                            </div>
                        </div>
                        :
                        ""
                    }
                    <section id="forgetpwd">
                        <div className="container p-5">
                            <div className="forget_box shadow rounded w-50 mx-auto p-4">
                                <h3 className="text-center font-weight-bold">
                                  Forgot Your Password
                                </h3>

                            <p className="text-center pt-3">Please enter the email address you signed up with, so we can send you an email to reset your password.</p>

                            <form onSubmit={this.onSubmit}>
                            {/*
                                <ToastContainer
                                    position="bottom-center"
                                    autoClose={5000}
                                    hideProgressBar={true}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                />*/}
                                <div className="form-group">
                                    <label for="username" className="label_text">
                                      Email{" "}<span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control input_text"
                                      id=""
                                      name="email"  onChange={this.inputChange}
                                      placeholder="Enter Your Email"
                                    />
                                    {(isError && errors.email) ? 
                                        <div id="alert-box2" className="font-weight-bold mt-1">
                                            <span className="pr-2">
                                              <svg
                                                width="1em"
                                                height="1em"
                                                viewBox="0 0 16 16"
                                                className="bi bi-exclamation-circle"
                                                fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                />
                                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                              </svg>
                                            </span>
                                            {errors.email}
                                        </div>
                                        :
                                        ""
                                    }
                                </div>

                                {!ButtonLoader ?
                                    <div className="d-flex justify-content-between flex-md-row">
                                        <Link className="text-white" to="/login"><button id="back_btn" className="btn font-weight-bold " type="button">BACK</button></Link>
                                        <button id="forgot_submit" className="btn next font-weight-bold" type="submit">SUBMIT</button>  
                                    </div>
                                :
                                    <div className="d-flex justify-content-between">
                                        <Link className="text-white" to="/"><button id="back_btn" className="btn font-weight-bold" type="button" disabled="disabled">BACK</button></Link>
                                        <button id="forgot_submit" className="btn next font-weight-bold" type="button" disabled="disabled">Processing..</button> 
                                    </div>
                                } 
                            </form>
                          </div>
                        </div>

                        {(modalShow) ?
                            <ModalComponent isError={isError} errorMessage={errorMessage} show={this.show} handleClose={this.handleClose}/>
                        :
                            ""
                        }
                    </section>
                <Footer />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userRegisterData : state.auth,
    errors : state.errors
})

export default connect(
    mapStateToProps,
    {forgotPasswordAction}
)(withRouter(ForgotPassword));
