import React from 'react'


// available className
     // 1. primary (default)

export const Button = ({ type, children, className, onClick }) => {

    return (
        <button type={type} className={`button ${className}`} onClick={onClick}>
            {children}
        </button>
    )
}

Button.defaultProps = {
    type: 'button',
    className: 'primary',
    onClick: () => null
}