import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

class Footer extends Component {


	redirectToProfile(roleName){
		localStorage.setItem('roleName',roleName)
	}

	render() {
		return (
			<footer>
				
					<section id="footer_section">
						<div className="container">
							<div className="row footer_content d-flex pt-4 justify-content-center">
								<div className="col-3 footer_img d-flex align-items-center">
									<Link to="/"><img alt="" src={process.env.PUBLIC_URL + '/frontend/images/logo footer.png'} className="mr-2 image-fluid" /></Link>
								</div>
								<div className="col-lg-3 footer_list">
									<div className="footer_list_div">
										<h3>Navigation</h3>
										<ul className="">
											<li className="mb-2"><Link to="/about-us">About us</Link></li>
											<li className="mb-2"><Link to="/contact-us">Contact us</Link></li>
											<li className="mb-2"><Link to="/blogs">Blog </Link></li>
											{(!this.props.authenticatedUserData.userId)
												?
												<li className="mb-2"><Link to="/login">Login</Link></li>
												:
												""
											}
										</ul>
									</div>
								</div>
								<div className="col-lg-3 social_media">
									<div className="social_media_div">
										<h3>Social media</h3>
										<ul className="d-flex mt-4">
											<li className="mb-2"><a href="https://www.facebook.com/desiremeonline/" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
											<li className="mb-2"><a href="https://www.instagram.com/desiremeonline/" target="_blank"><i className="fab fa-instagram"></i></a></li>
											<li className="mb-2"><a href="https://twitter.com/desiremeonline" target="_blank"><i className="fab fa-twitter"></i></a></li>
										</ul>
									</div>
								</div>

								{(!this.props.authenticatedUserData.userId)
								?
								<div className="col-lg-3 footer_sign_up">
									<div className="sign_Up_heading mb-4">
										<h3>Sign Up</h3>
									</div>
									<div className="sign_up text-center">
										<Link to="/profile/step1" className="btn sign_up_btn d-inline-block" onClick={() => this.redirectToProfile('ContentCreator')}>Create Your Profile</Link><br/>
										<small className="text-white d-inline-block">or</small><br/>
										<Link to="/follow/step1" className="sign_up_following_btn d-inline-block" onClick={() => this.redirectToProfile('Desirer')}>Start Following</Link>
									</div>
								</div>

								:
									""
								}
							</div>
						</div>
					</section>
					<div>
						<div className="container">
							<div className="row">
								<div className=" col-12 copyright d-lg-flex d-inline-block justify-content-center py-2 text-center">
									<div className="copy_right_div_1 order-lg-1 order-2 py-lg-0 py-2">
										<Link to="#"><small>© {(new Date().getFullYear())} DesireMe All rights reserved.</small></Link>
									</div>
									<div className="copy_right_div_2 ml-auto order-lg-2 order-1 py-lg-0 py-2">
										<Link to="/terms-conditions" className="px-lg-3 ml-auto"><small >Terms of Services</small></Link>
										<Link to="/privacy-policy"><small>Privacy Policy</small></Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				
				<div className="opacity_class"></div>
			</footer>
		)
	}
}


export const mapStateToProps = (state) => ({
    authenticatedUserData : state.auth.loginSuccessResult,
})


export default connect(
	mapStateToProps 
)(withRouter(Footer));
