import React from 'react'

const NotificationMobile = () => {
    return (
        <div class="notification__section">
            <div className="title d-flex align-items-center">
                <div className="left">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.857" height="18.122"><path d="M7.929 18.122a2.265 2.265 0 002.264-2.265H5.665a2.265 2.265 0 002.264 2.265zm7.624-5.3c-.684-.735-1.963-1.84-1.963-5.461A5.589 5.589 0 009.061 1.87v-.737a1.132 1.132 0 10-2.264 0v.737a5.589 5.589 0 00-4.529 5.492c0 3.621-1.28 4.726-1.963 5.461a1.106 1.106 0 00-.3.768 1.134 1.134 0 001.136 1.133h13.58a1.133 1.133 0 001.136-1.133 1.105 1.105 0 00-.305-.768z" fill="#f2ffff"/></svg>
                </div>
                <h5 className="right">Notifications</h5>
            </div>
            <div className="notify__msg">
                <h6 className="heading">Today</h6>
                <li className="notify__li d-flex align-items-center">
                    <p className="svg__tag">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21.457" height="17.472"><path data-name="Path 2994" d="M20.821 2.949A6.4 6.4 0 0013.897.166a7.071 7.071 0 00-2.412 1.083 5.631 5.631 0 00-.756.549 5.624 5.624 0 00-.756-.549A7.071 7.071 0 007.561.166 6.4 6.4 0 00.637 2.949c-1.2 2.167-.53 4.681.591 6.729a15.27 15.27 0 003.412 4.124 19.976 19.976 0 001.846 1.424q.585.4 1.2.76.733.431 1.5.8a14.618 14.618 0 011.546.682 14.619 14.619 0 011.546-.682q.765-.373 1.5-.8.611-.36 1.2-.76a19.967 19.967 0 001.846-1.424 15.27 15.27 0 003.412-4.124c1.116-2.048 1.785-4.562.585-6.729z" fill="#edffff"/></svg></p>
                    <div className="msg__text">
                        <p>You have a new desirer!</p>
                        <p><b>Hiren Patel</b></p>
                    </div>
                </li>
                <li className="notify__li d-flex align-items-center">
                    <p className="svg__tag grey__bg">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22.08" height="20.513"><path data-name="Path 2995" d="M19.872 0H2.208A2.208 2.208 0 000 2.208v12.11a2.208 2.208 0 002.208 2.208H8.15l2.89 3.987 2.891-3.987h5.941a2.208 2.208 0 002.208-2.208V2.208A2.208 2.208 0 0019.872 0z" fill="#fff"/></svg>
                    </p>
                    <div className="msg__text">
                        <p><b>Hiren Patel</b></p>
                        <p>Has a new post, click to view</p>
                    </div>
                </li>
                
            </div>

        </div>
    )
}

export default NotificationMobile
