import React, { Component, useState } from "react";
import { connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {updateNotificationSettingAction, emptyError} from '../../actions/accountSetting'
import Modal from "react-bootstrap/Modal";
import ModalComponent from "../ModalComponent";
import {ERROR_MESSAGE} from '../Constant';

export class Notifications extends Component {
    constructor(props){
        super(props)

        this.state = {
            post_email : 0,
            postEmailChecked : false,
            subscribe_email : 0,
            subscribeEmailChecked : false,
            post_app : 0,
            postAppChecked : false,
            subscribe_app : 0,
            subscribeAppChecked :  false,
            errorMessage : '',
            modalShow : false,
            ButtonLoader : false,
            updateNotificationClick : 0,
            submitButtonClick : 0,
            Role : '',
        }

        this.inputChange = this.inputChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.handleClose = this.handleClose.bind(this);
        this.show = this.show.bind(this);
    }

    componentDidMount(){
        let role = this.props.authenticatedUserData.Role;

        this.setState({
            Role : role
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        this.setState({
            ButtonLoader : false,
        })

        if(nextProps.getUserData.usersData !== ''){
            let userData =  nextProps.getUserData.usersData;
            
            let postEmailChecked = false;
            if(userData.post_email === 1){
                postEmailChecked = true;
            }

            let subscribeEmailChecked = false;
            if(userData.subscribe_email === 1){
                subscribeEmailChecked = true;
            }

            let postAppChecked = false;
            if(userData.post_app === 1){
                postAppChecked = true;
            }

            let subscribeAppChecked = false;
            if(userData.subscribe_app === 1){
                subscribeAppChecked = true;
            }
            else{
                userData.AgreeTerms = 0
            }
            this.setState({
                post_email : userData.post_email,
                subscribe_email : userData.subscribe_email,
                post_app : userData.post_app,
                subscribe_app : userData.subscribe_app,
                postEmailChecked : postEmailChecked,
                subscribeEmailChecked : subscribeEmailChecked,
                postAppChecked : postAppChecked,
                subscribeAppChecked : subscribeAppChecked,
            })
        }

        if(nextProps.getUserData.notificationsettingUpdateData && nextProps.getUserData.notificationsettingUpdateData.data  && nextProps.getUserData.notificationsettingUpdateData.data !== ''){
            

            let notificationsettingUpdatedData =  nextProps.getUserData.notificationsettingUpdateData.data;
    
            let postEmailChecked = false;
            if(notificationsettingUpdatedData.post_email === 1){
                postEmailChecked = true;
            }

            let subscribeEmailChecked = false;
            if(notificationsettingUpdatedData.subscribe_email === 1){
                subscribeEmailChecked = true;
            }

            let postAppChecked = false;
            if(notificationsettingUpdatedData.post_app === 1){
                postAppChecked = true;
            }

            let subscribeAppChecked = false;
            if(notificationsettingUpdatedData.subscribe_app === 1){
                subscribeAppChecked = true;
            }
            this.setState({
                
                post_email : notificationsettingUpdatedData.post_email,
                subscribe_email : notificationsettingUpdatedData.subscribe_email,
                post_app : notificationsettingUpdatedData.post_app,
                subscribe_app : notificationsettingUpdatedData.subscribe_app,
                postEmailChecked : postEmailChecked,
                subscribeEmailChecked : subscribeEmailChecked,
                postAppChecked : postAppChecked,
                subscribeAppChecked : subscribeAppChecked,
            })
        }

        if(nextProps.TabName === 'Notification'){
            // if(nextProps.getUserData.updatedPaymentData && nextProps.getUserData.updatedPaymentData.data !== '' && !nextProps.errors.errors.isError){
            if(this.state.updateNotificationClick === 1){
                if(nextProps.getUserData.notificationsettingUpdateData && nextProps.getUserData.notificationsettingUpdateData.data !== '' && !nextProps.getUserData.notificationsettingUpdateData.isError){
                    let errorMessage = ''
                    let modalShow = false
                    if(this.state.submitButtonClick === 1 && (this.state.AccountName !== null || this.state.AccountNumber !== null || this.state.SortCode !== null)){
                        // toast.success('Updated!')
                        errorMessage = 'Notification Setting Updated.'
                        modalShow = true
                    }

                    this.setState({
                        errors : [],
                        isError : false,
                        submitButtonClick : 0,
                        errorMessage : errorMessage,
                        modalShow : modalShow,
                    })
                }
                else if(nextProps.getUserData.notificationsettingUpdateData.isError){
                    this.setState({
                        errors : nextProps.getUserData.notificationsettingUpdateData.error,
                        isError : nextProps.getUserData.notificationsettingUpdateData.isError,
                        errorMessage : ERROR_MESSAGE,
                        modalShow : true,
                    })
                    // toast.error('Error!')
                }
            }
        }
        else{
            this.setState({
                updateNotificationClick : 0,
                isError : false,
                errors : [],
            })
        }
    }

    //on imput change save value in state
    inputChange(e){

        var postEmailChecked = this.state.postEmailChecked
        if(e.target.name === 'post_email'){
            postEmailChecked = !this.state.postEmailChecked
            e.target.value = 0;
            if(postEmailChecked){
                e.target.value = 1;
            }
        }

        var subscribeEmailChecked = this.state.subscribeEmailChecked
        if(e.target.name === 'subscribe_email'){
            subscribeEmailChecked = !this.state.subscribeEmailChecked
            e.target.value = 0;
            if(subscribeEmailChecked){
                e.target.value = 1;
            }
        }

        var postAppChecked = this.state.postAppChecked
        if(e.target.name === 'post_app'){
            postAppChecked = !this.state.postAppChecked
            e.target.value = 0;
            if(postAppChecked){
                e.target.value = 1;
            }
        }

        var subscribeAppChecked = this.state.subscribeAppChecked
        if(e.target.name === 'subscribe_app'){
            subscribeAppChecked = !this.state.subscribeAppChecked
            e.target.value = 0;
            if(subscribeAppChecked){
                e.target.value = 1;
            }
        }

        this.setState({
            [e.target.name] : parseInt(e.target.value),
            postEmailChecked : postEmailChecked,
            subscribeEmailChecked : subscribeEmailChecked,
            postAppChecked : postAppChecked,
            subscribeAppChecked : subscribeAppChecked,
        })
    }

    handleClose() {
        this.setState({
            modalShow : false,
            errorMessage : '',
        })
    }

    show() {
        useState(false);
    }

    onSubmit(e){
        e.preventDefault();
        this.setState({
            ButtonLoader : true,
            updateNotificationClick : 1,
            submitButtonClick : 1,
        })
        //if there is error so error reducer length is not null so everytime its go inside error so if there is error after submit we clean the error reducer so it goes inside success
        if(this.state.isError){
            this.props.emptyError()    
        }
        let notificationSettingData = {
            post_email : this.state.post_email,
            subscribe_email : this.state.subscribe_email,
            post_app : this.state.post_app,
            subscribe_app : this.state.subscribe_app,
        }
        let user_id = parseInt(this.props.authenticatedUserData.userId)
        this.props.updateNotificationSettingAction(notificationSettingData,user_id)
    }

    render() {
        const {ButtonLoader , errors , isError, modalShow , errorMessage , postEmailChecked , subscribeEmailChecked , postAppChecked , subscribeAppChecked, Role} = this.state
        return (
            <div className="container p-lg-5 p-sm-0 p-2 cardsetting_container ">
                {ButtonLoader ? 
                    <div id="preloader">
                        <div className="preloader_div">
                        </div>
                    </div>
                    :
                    ""
                }
                
                <div className="col-lg-12 col-md-9 col-sm-12">
                    <form onSubmit={this.onSubmit}>

                        <div className="form-group">
                            <div className="radios">

                                <label className="control control--checkbox">Email me when one of my desired users adds a new post
                                    <input type="checkbox" id="radio_0" name="post_email" onClick={this.inputChange} checked={postEmailChecked}/>
                                    <div className="control__indicator"></div>
                                </label>
                               
                                {(isError && errors.post_email) ?
                                    <div id="alert-box2" className="font-weight-bold mt-1">
                                        <span className="pr-2">
                                            <svg
                                                width="1em"
                                                height="1em"
                                                viewBox="0 0 16 16"
                                                className="bi bi-exclamation-circle"
                                                fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                />
                                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                            </svg>
                                        </span>
                                        {errors.post_email}
                                    </div>
                                    :
                                    ""
                                }

                                {
                                    (Role !== 'Desirer') ?
                                        <label className="control control--checkbox">Email me when a new desirer subscribes to follow me
                                            <input type="checkbox" id="radio_0" name="subscribe_email" onClick={this.inputChange} checked={subscribeEmailChecked}/>
                                            <div className="control__indicator"></div>
                                        </label>
                                    :
                                        ""
                                }
                                {
                                    (isError && errors.subscribe_email && Role !== 'Desirer') ?
                                        <div id="alert-box2" className="font-weight-bold mt-1">
                                            <span className="pr-2">
                                                <svg
                                                    width="1em"
                                                    height="1em"
                                                    viewBox="0 0 16 16"
                                                    className="bi bi-exclamation-circle"
                                                    fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                      fillRule="evenodd"
                                                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                    />
                                                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                </svg>
                                            </span>
                                            {errors.subscribe_email}
                                        </div>
                                    :
                                        ""
                                }

                                <label className="control control--checkbox">Notify me when one of my desired users adds a new post
                                    <input type="checkbox" id="radio_1" name="post_app" onClick={this.inputChange} checked={postAppChecked}/>
                                    <div className="control__indicator"></div>
                                </label>
                               
                                {(isError && errors.post_app) ?
                                    <div id="alert-box2" className="font-weight-bold mt-1">
                                        <span className="pr-2">
                                            <svg
                                                width="1em"
                                                height="1em"
                                                viewBox="0 0 16 16"
                                                className="bi bi-exclamation-circle"
                                                fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                />
                                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                            </svg>
                                        </span>
                                        {errors.post_app}
                                    </div>
                                    :
                                    ""
                                }


                                {(Role !== 'Desirer')?
                                    <label className="control control--checkbox">Notify me when a new desirer subscribes to follow me
                                        <input type="checkbox" id="radio_1" name="subscribe_app" onClick={this.inputChange} checked={subscribeAppChecked}/>
                                        <div className="control__indicator"></div>
                                    </label>
                                    :
                                    ""
                                }
                               
                                {
                                    (isError && errors.subscribe_app && Role !== 'Desirer') ?
                                        <div id="alert-box2" className="font-weight-bold mt-1">
                                            <span className="pr-2">
                                                <svg
                                                    width="1em"
                                                    height="1em"
                                                    viewBox="0 0 16 16"
                                                    className="bi bi-exclamation-circle"
                                                    fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                      fillRule="evenodd"
                                                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                    />
                                                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                </svg>
                                            </span>
                                            {errors.subscribe_app}
                                        </div>
                                    :
                                    ""
                                }
                            </div>
                        </div>
                        {!ButtonLoader ?
                            <button className="btn " id="submit_btn">Submit</button>
                        :
                            <button className="btn next font-weight-bold" type="button" disabled="disabled">Processing..</button> 
                        }
                    </form>
                </div>




                {(modalShow) ?
                    <ModalComponent isError={isError} errorMessage={errorMessage} show={this.show} handleClose={this.handleClose}/>
                :
                    ""
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    getUserData : state.userAccountData,
    authenticatedUserData : state.auth.loginSuccessResult,
})


export default connect(
    mapStateToProps,
    {updateNotificationSettingAction, emptyError}
)(withRouter(Notifications));
