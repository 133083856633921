import {
	GET_FAQS,
	GET_SEARCHED_FAQS,
} from './types';

import axios from 'axios';
import {BASE_URL} from '../components/Constant';

//get FAQ
export const getFAQsAction = (start,limit) => (dispatch) => {
	axios
		.get(BASE_URL+"/faq/"+start+"/"+limit)
		.then(response => {
			dispatch({
				type : GET_FAQS,
				payload : response.data
			})
		})
		.catch(error => {

		})
}

//serach FAQ
export const searchFAQAction = (serachValue,start,limit) => (dispatch) => {
	axios
		.get(BASE_URL+"/searchFaq/"+serachValue+"/"+start+"/"+limit)
		.then(response => {
			dispatch({
				type : GET_SEARCHED_FAQS,
				payload : response.data
			})
		})
		.catch(error => {

		})
}
