//auth
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const GET_USER_REGISTER_DATA = "GET_USER_REGISTER_DATA";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const FORGOT_PASSWOD_SUCCESS = "FORGOT_PASSWOD_SUCCESS";
export const RESET_PASSWOD_SUCCESS = "RESET_PASSWOD_SUCCESS";
export const USER_ID_VERIFICATION_SUCCESS = "USER_ID_VERIFICATION_SUCCESS";
export const PASSWORD_CHANGE_SUCCESS = "PASSWORD_CHANGE_SUCCESS";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const PAYMENT_UPDATE_SUCCESS = "PAYMENT_UPDATE_SUCCESS";
export const GET_SUBSCRIPTION_PRICE_RANGE = "GET_SUBSCRIPTION_PRICE_RANGE";
//update profile
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
//account setting
export const REMOVE_CARD = "REMOVE_CARD";
export const CLOSE_ACCOUNT = "CLOSE_ACCOUNT";
export const GET_ACCOUNT_BALANCE = "GET_ACCOUNT_BALANCE";
export const WITHDRAW_BALANCE = "WITHDRAW_BALANCE";
export const NOTIFICATION_SETTING_UPDATE_SUCCESS = "NOTIFICATION_SETTING_UPDATE_SUCCESS";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const NOTIFICATION_UPDATE = "NOTIFICATION_UPDATE";
//users
export const GET_USERS_DATA = "GET_USERS_DATA";
//countries data
export const COUNTRIES_DATA = "COUNTRIES_DATA";
//states data
export const STATES_DATA = "STATES_DATA";

export const TWO_FACTOR_AUTH_SUCCESS = "TWO_FACTOR_AUTH_SUCCESS"

//error
export const GET_ERRORS = "GET_ERRORS";
export const CATEGORY_WISE_USERS_DATA = "CATEGORY_WISE_USERS_DATA";
export const GET_USER_BY_SEARCH = "GET_USER_BY_SEARCH";

//post
export const GET_ALL_POST_DATA = "GET_ALL_POST_DATA";
export const GET_ALL_POSTS_DATA = "GET_ALL_POSTS_DATA";
export const GET_POSTS_SEARCH_ACTIVITY_DATA = "GET_POSTS_SEARCH_ACTIVITY_DATA";
export const GET_MOST_VIEW_POST_DATA = "GET_MOST_VIEW_POST_DATA";
export const GET_POPULAR_POSTS = "GET_POPULAR_POSTS";
export const GET_POPULAR_PROFILES = "GET_POPULAR_PROFILES";
export const GET_RECENT_POST = "GET_RECENT_POST";
export const GET_SEARCH_ACTIVITY_DATA = "GET_SEARCH_ACTIVITY_DATA";
export const GET_USER_POST = "GET_USER_POST";
export const LIKE_DISLIKE_POST = "LIKE_DISLIKE_POST";
export const LIKE_DISLIKE_COMMENT = "LIKE_DISLIKE_COMMENT";
export const ADD_COMMENT = "ADD_COMMENT";

//albums
export const GET_USER_ALBUMS = "GET_USER_ALBUMS";
export const ADD_ALBUM = "ADD_ALBUM";

//create post
export const ADD_POST = "ADD_POST";
export const UPDATE_POST = "UPDATE_POST";
export const DELETE_POST = "DELETE_POST";
export const VIEW_POST = "VIEW_POST";

//after payment following
export const FOLLOWING_CONTENT = "FOLLOWING_CONTENT";

//unfollow user
export const UNFOLLOW = "UNFOLLOW";

//blogs
export const GET_BLOGS = "GET_BLOGS";
export const GET_BLOG_DETAIL = "GET_BLOG_DETAIL";
export const GET_BLOG_CATEGORIES = "GET_BLOG_CATEGORIES";
export const GET_RECENT_BLOGS = "GET_RECENT_BLOGS";
export const GET_SEARCHED_BLOGS = "GET_SEARCHED_BLOGS";


//faqs
export const GET_FAQS = "GET_FAQS"; 
export const GET_SEARCHED_FAQS = "GET_SEARCHED_FAQS"; 

//whishlist
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const GET_WISHLIST_DATA = "GET_WISHLIST_DATA";
export const REMOVE_WISHLIST_DATA = "REMOVE_WISHLIST_DATA";


//contact us
export const SAVE_CONTACT_FORM_DATA = "SAVE_CONTACT_FORM_DATA";

//chat
export const GET_USERS_BY_USER_ID = "GET_USERS_BY_USER_ID";
export const UPDATE_USER_ACTIVE_STATUS = "UPDATE_USER_ACTIVE_STATUS"
export const GET_MESSAGE_BY_SENDERID = "GET_MESSAGE_BY_SENDERID"
export const SEND_MESSAGE_BY_ID = "SEND_MESSAGE_BY_ID"
export const DELETE_CHAT = "DELETE_CHAT"
export const SEND_TIP = "SEND_TIP"
