import {
    CATEGORY_WISE_USERS_DATA,
    GET_USER_BY_SEARCH,
    FOLLOWING_CONTENT,
    ADD_TO_WISHLIST,
    GET_WISHLIST_DATA,
    REMOVE_WISHLIST_DATA, UNFOLLOW,
} from "../actions/types"

const initialState = {
    categoryWiseUserData : "",
    searchedUser : "",
    followingContent : "",
    addToWishList : "",
    getWishListData : "",
    removeWishListData : "",
    unfollow : "",
}

export default function(state = initialState, action) {
    switch (action.type) {
        case CATEGORY_WISE_USERS_DATA:
            return {
                ...state,
                categoryWiseUserData: action.payload
            }
        case GET_USER_BY_SEARCH:
            return {
                ...state,
                searchedUser: action.payload
            }
        case FOLLOWING_CONTENT:
            return {
                ...state,
                followingContent: action.payload
            }
        case ADD_TO_WISHLIST:
            return {
                ...state,
                addToWishList: action.payload
            }
        case GET_WISHLIST_DATA:
            return {
                ...state,
                getWishListData: action.payload
            }
        case REMOVE_WISHLIST_DATA:
            return {
                ...state,
                removeWishListData: action.payload
            }
        case UNFOLLOW:
            return {
                ...state,
                unfollow: action.payload
            }
        default:
            return state
    }
}
