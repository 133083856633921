import React from 'react';
import Chatlist from './Chatlist';
const slides = (props) => {
    return (
        // <div className="mySlides fade"></div>
        <div className="mySlides">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 createpost-right">
                    <div className="panel panel-white border-top-green mt-3">
                        <div className="panel-body chat">
                            <div className="row chat-wrapper">
                                <div className="col-md-12">
                                    <div>
                                        <div className="slimScrollDiv" style={{ position: "relative", overflow: "hidden", width: "auto" }}>
                                            <Chatlist {...props}/>
                                            <div className="slimScrollBar" style={{ width: "7px", position: "absolute", top: "0px", opacity: "0.4", display: "none", borderRadius: "7px", zIndex: 99, right: "1px", height: "478.639px", background: "rgb(0,0,0)" }} ></div>
                                            <div className="slimScrollRail" style={{ width: "7px", height: "100%", position: "absolute", top: "0px", display: "none", borderRadius: "7px", opacity: "0.2", zIndex: 90, right: "1px", background: "rgb(51, 51, 51)" }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default slides;