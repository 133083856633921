import {
    GET_FAQS,
    GET_SEARCHED_FAQS,
} from "../actions/types"

const initialState = {
    getFAQsData : "",
    getSearchedFAQData : "",
}

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_FAQS:
            return {
                ...state,
                getFAQsData: action.payload
            }
        case GET_SEARCHED_FAQS:
            return {
                ...state,
                getSearchedFAQData : action.payload
            }
        default:
            return state
    }
}
