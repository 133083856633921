import React, { Component } from "react";
import { Link } from "react-router-dom";

class RegisterSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      TabNum: this.props.TabNum,
    };

    this.tabsClick = this.tabsClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      TabNum: nextProps.TabNum,
    });
  }

  tabsClick(TabNum) {
    //if user_id !== '' and if click on tab1 or tab 2 then go inside but if click on tab3 and 2 form is not fill then not go inside and if there is no error in current

    if (
      this.props.userId !== 0 &&
      (TabNum === 1 ||
        TabNum === 2 ||
        (TabNum === 4 && this.props.RoleName === "Desirer") ||
        (TabNum === 3 && this.props.VerifyIdError) ||
        (TabNum === 4 && this.props.ProfileError)) &&
      this.props.SlideTab === 0 &&
      !this.props.ButtonLoader
    ) {
      this.setState({
        TabNum: TabNum,
      });
      this.props.tabsclick(TabNum); // this props coming from Profile Js as props
    }
  }

  render() {
    return (
      <div className="register-slider">
        <div className="register-slider_small_div d-flex">
          <div
            className={
              "register-slider_small_div_1 d-none " +
              (this.state.TabNum === 1 ? "small_slider_active" : "")
            }
          >
            <p className="mb-0 add-cursor" onClick={() => this.tabsClick(1)}>
              Step 1
            </p>
            <small className={"add-cursor"} onClick={() => this.tabsClick(1)}>
              Personal Details
            </small>
          </div>
          <div
            className={
              "register-slider_small_div_2 d-none " +
              (this.state.TabNum === 2 ? "small_slider_active" : "")
            }
          >
            <p className="mb-0 add-cursor" onClick={() => this.tabsClick(2)}>
              Step 2
            </p>
            <small className={"add-cursor"} onClick={() => this.tabsClick(2)}>
              Identification
            </small>
          </div>
          <div
            className={
              "register-slider_small_div_3 d-none " +
              (this.state.TabNum === 3 ? "small_slider_active" : "")
            }
          >
            <p className="mb-0 add-cursor" onClick={() => this.tabsClick(3)}>
              Step 3
            </p>
            <small className={"add-cursor"} onClick={() => this.tabsClick(3)}>
              Profile
            </small>
          </div>
          <div
            className={
              "register-slider_small_div_4 d-none " +
              (this.state.TabNum === 4 ? "small_slider_active" : "")
            }
          >
            <p className="mb-0 add-cursor" onClick={() => this.tabsClick(4)}>
              Step 4
            </p>
            <small className={"add-cursor"} onClick={() => this.tabsClick(4)}>
              Payment
            </small>
          </div>
        </div>
        <ul className="mx-4 px-3 step__tab">
          <li className="d-flex  align-items-center">
            <div
              className={
                "slider-link slider-link-active step-1 text-center " +
                (this.state.TabNum === 1 ||
                this.props.PersonalDetailsError ||
                this.props.VerifyIdError ||
                this.props.ProfileError ||
                this.props.PaymentError
                  ? "slider-link-active"
                  : "")
              }
            >
              <Link to="#" onClick={() => this.tabsClick(1)}>
                1
              </Link>
            </div>
            <div
              className={
                "slider-text d-lg-inline-block d-lg-block d-none" +
                (this.state.TabNum === 1 ? " slider-text-active" : "")
              }
            >
              <p className="mb-1 add-cursor" onClick={() => this.tabsClick(1)}>
                Step 1
              </p>
              <small className={"add-cursor"} onClick={() => this.tabsClick(1)}>
                Personal Details
              </small>
            </div>
          </li>

          {this.props.RoleName === "ContentCreator" ? (
            <>
              <li className="d-flex align-items-center">
                <div
                  className={
                    "slider-link step-2 " +
                    (this.state.TabNum === 2 ||
                    this.props.VerifyIdError ||
                    this.props.ProfileError ||
                    this.props.PaymentError
                      ? "slider-link-active"
                      : "")
                  }
                >
                  <Link to="#" onClick={() => this.tabsClick(2)}>
                    2
                  </Link>
                </div>
                <div
                  className={
                    "slider-text d-lg-inline-block d-lg-block d-none" +
                    (this.state.TabNum === 2 ? " slider-text-active" : "")
                  }
                >
                  <p
                    className="mb-1 add-cursor"
                    onClick={() => this.tabsClick(2)}
                  >
                    Step 2
                  </p>
                  <small
                    className={"add-cursor"}
                    onClick={() => this.tabsClick(2)}
                  >
                    Identification
                  </small>
                </div>
              </li>
              <li className="d-flex align-items-center">
                <div
                  className={
                    "slider-link step-3 " +
                    (this.state.TabNum === 3 ||
                    this.props.ProfileError ||
                    this.props.PaymentError
                      ? "slider-link-active"
                      : "")
                  }
                >
                  <Link to="#" onClick={() => this.tabsClick(3)}>
                    3
                  </Link>
                </div>
                <div
                  className={
                    "slider-text d-lg-inline-block d-lg-block d-none" +
                    (this.state.TabNum === 3 ? " slider-text-active" : "")
                  }
                >
                  <p
                    className="mb-1 add-cursor"
                    onClick={() => this.tabsClick(3)}
                  >
                    Step 3
                  </p>
                  <small
                    className={"add-cursor"}
                    onClick={() => this.tabsClick(3)}
                  >
                    Profile
                  </small>
                </div>
              </li>
            </>
          ) : (
            ""
          )}
          <li className="d-flex align-items-center">
            <div
              className={
                "slider-link step-4 " +
                (this.state.TabNum === 4 || this.props.PaymentError
                  ? "slider-link-active"
                  : "")
              }
            >
              <Link to="#" onClick={() => this.tabsClick(4)}>
                {this.props.RoleName === "ContentCreator" ? 4 : 2}
              </Link>
            </div>
            <div
              className={
                "slider-text d-lg-inline-block d-lg-block d-none" +
                (this.state.TabNum === 4 ? " slider-text-active" : "")
              }
            >
              <p className="mb-1 add-cursor" onClick={() => this.tabsClick(4)}>
                Step {this.props.RoleName === "ContentCreator" ? 4 : 2}
              </p>
              <small className={"add-cursor"} onClick={() => this.tabsClick(4)}>
                Payment
              </small>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default RegisterSlider;
