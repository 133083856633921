import React, { Component, useState } from 'react'
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getAccountBalanceAction , withdrawBalanceAction} from '../../actions/accountSetting'
import {logoutAction} from '../../actions/auth';
// import {toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Modal from "react-bootstrap/Modal";
import ModalComponent from "../ModalComponent";
import {ERROR_MESSAGE} from '../Constant';

export class WithdrawFund extends Component {
    constructor(props){
        super(props)
        this.state = {
            user_id : '',
            accountBalance : 0,
            withdrawButtonClick : 0,
            isError : false,
            errorMessage : '',
            modalShow : false,
        }

        this.withdrawFund = this.withdrawFund.bind(this)
        this.handleClose = this.handleClose.bind(this);
        this.show = this.show.bind(this);
    }

    componentDidMount(){
        let user_id = this.props.authenticatedUserData.userId;

        this.setState({
            user_id : user_id
        })
        this.props.getAccountBalanceAction(user_id)
    }

    withdrawFund(){
        this.setState({
            withdrawButtonClick : 1,
        })
        this.props.withdrawBalanceAction(this.state.user_id, this.state.accountBalance)
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.TabName === 'Withdraw Fund'){
            
            if(nextProps.accountBalance && nextProps.accountBalance.balance){
                this.setState({
                    accountBalance : nextProps.accountBalance.balance,
                })
            }

            if(!nextProps.withdrawBalance.isError && this.state.withdrawButtonClick === 1){
                this.setState({
                    withdrawButtonClick : 0,
                    isError : false,
                    errorMessage : nextProps.withdrawBalance.message,
                    modalShow : true,
                })
                // toast.success(nextProps.withdrawBalance.message);
            }
            else if(this.state.withdrawButtonClick === 1){
                this.setState({
                    withdrawButtonClick : 0,
                    isError : true,
                    errorMessage : nextProps.withdrawBalance.message,
                    modalShow : true,
                })
                // toast.error(nextProps.withdrawBalance.message)
            }
        }
    }

    handleClose() {
        this.setState({
            modalShow : false,
            errorMessage : '',
        })
    }

    show() {
        useState(false);
    }

    render() {
        const {accountBalance , isError, modalShow , errorMessage} = this.state

        return (
            <div className="container p-5 closeacc_container">
                <div>
                  <h3 className="pb-3" >Withdraw Balance</h3>
                    <p>Account Balance : <strong>{'£'+accountBalance}</strong></p>
                    <button className="btn btn-info mt-3 font-weight-bold" onClick={this.withdrawFund}>Request Balance Withdrawal</button>
                </div>
                {(modalShow) ?
                    <ModalComponent isError={isError} errorMessage={errorMessage} show={this.show} handleClose={this.handleClose}/>
                :
                    ""
                }
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    accountBalance : state.userAccountData.getAccountBalance,
    withdrawBalance : state.userAccountData.withdrawBalance,
    authenticatedUserData : state.auth.loginSuccessResult
})

export default connect(
    mapStateToProps,
    {getAccountBalanceAction,withdrawBalanceAction , logoutAction}
)(withRouter(WithdrawFund));
