import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";

class ShowChatImageModal extends Component {
    render() {
        return (
            <div className="modal__div">
                <Modal size="lg" animation={false} show={this.props.show} onHide={this.props.handleClose} centered dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" className="custom__modal">
                    <Modal.Header closeButton onClick={this.props.handleClose} style={{ border: "none" }} />
                    <Modal.Body>
                        <div className="modal__body col-12">
                            <div className="col-12" style={{ textAlign: "center" }}>
                                {this.props.displayType === "image" ? <img className="chat-dialog-content" src={this.props.file} alt="" /> : <video className="chat-dialog-content" src={this.props.file} controls ></video>}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default ShowChatImageModal;
