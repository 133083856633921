import React from 'react';
import Chatlistelement from './Chatlistelements';

const ChatList = (props) => {
    return (
        // <div className="chat-list-wrapper" style={{ overflowY: "auto", width: "auto", height: "550px" }}></div>
        <div className="chat-list-wrapper" style={{ overflowY: "auto", width: "auto"}}>
            <ul className="chat-list">
                <Chatlistelement {...props}/>
            </ul>
        </div>
    )
} 
export default ChatList;