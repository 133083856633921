import React,{Component} from 'react';

class EmailVerify extends Component{


	componentDidMount(){
		//this component will call  from email and after storing id it redirects user to profile page
		let user_id = this.props.match.params.userId;
		localStorage.setItem('email_verify_user_id',user_id); 
		let roleName = this.props.match.params.role;
		localStorage.setItem('roleName',roleName)
		localStorage.setItem('verified','verified');//set to check wether coming from mail
		let emailVerifyTime = new Date();
		let time = emailVerifyTime.getTime()
		localStorage.setItem('emailVerifyTime',time);
		roleName === 'Desirer' ? this.props.history.push('/follow/step2') :
		this.props.history.push('/profile/step2')
	}

	render(){
		return(
            <div id="preloader">
                <div className="preloader_div">
                </div>
            </div>
		)
	}
}

export default EmailVerify
