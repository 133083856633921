import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import {getBlogDetailAction , getBlogCategoriesAction,getRecentBlogsAction} from '../../actions/blog';
import MetaTags from "react-meta-tags";

export class SingleBlog extends Component {
    constructor(props){
        super(props)

        this.state = {
            blogDetails : [],
            blogCategories : [],
            recentBlogs : [],
            Loader : false,
            searchBlogValue : '',
        }
        this.inputChange = this.inputChange.bind(this)
        this.filterBlog = this.filterBlog.bind(this)
        this.replaceSlugPara = this.replaceSlugPara.bind(this)
    }

    componentDidMount(){
       // let blogId = this.props.match.params.blogId;
        let slug = this.props.match.params.slug;
        //blogId = atob(blogId)

        if(localStorage.getItem('singleblog')=='' || localStorage.getItem('singleblog')==undefined || localStorage.getItem('singleblog')=='undefined'){
            var replaceSlug = this.replaceSlugPara(slug)
            localStorage.setItem('singleblog',replaceSlug)
        }
        // blogId = 1;
        this.props.getBlogDetailAction(slug,this.props)
        this.props.getBlogCategoriesAction(0,4)
        this.props.getRecentBlogsAction(0,3)
    }
    replaceSlugPara(slug){

        const regex = /-/gi;

        return slug.replace(regex, ' ');
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.blogDetails && nextProps.blogDetails.data && nextProps.blogDetails.data.length > 0){
            this.setState({
                blogDetails : nextProps.blogDetails.data[0],
                Loader : false,
            })
        }

        if(nextProps.blogCategories && nextProps.blogCategories.data && nextProps.blogCategories.data.length > 0){
            this.setState({
                blogCategories : nextProps.blogCategories.data,
            })
        }

        if(nextProps.recentBlogs && nextProps.recentBlogs.data && nextProps.recentBlogs.data.length > 0){
            this.setState({
                recentBlogs : nextProps.recentBlogs.data,
            })
        }
    }

    getSingleBlogData(e,blogId,slug){
        //e.preventDefault()
        this.setState({
            Loader : true,
        })
        window.scrollTo(0,0)
        this.props.getBlogDetailAction(slug,this.props)
        blogId = btoa(blogId)
        this.props.history.push('/blog/'+slug)

    }

    inputChange(e){
        this.setState({
            searchBlogValue : e.target.value,
        })
    }

    searchedBlog(e){
        e.preventDefault()
        // localStorage.setItem('searchedBlog',this.state.searchBlogValue)
        // let searchBlogValue = this.state.searchBlogValue
        setTimeout(function(){
            // window.location.href = '/blogs'
            this.props.history.push('/blogs?value='+this.state.searchBlogValue)
        }.bind(this),500)
    }
    filterBlog(e,blogCategory){
        localStorage.removeItem('searchedBlog')
        this.props.history.push('/blogs?category='+blogCategory)
    }

    render() {
        const {blogDetails , blogCategories , recentBlogs , Loader , searchBlogValue} = this.state
        return (
            <>
                <MetaTags>
                <title>{ this.replaceSlugPara(this.props.match.params.slug) }  | DesireMe</title>
                <meta name="description" content={blogDetails.meta=='' || blogDetails.meta==null ? blogDetails.title : blogDetails.meta} />
                <meta property="og:title" content="__METATITLE__"/>
                    <meta name="description" content="__METADESCRIPTION__" />
                    <meta name="title" content="__METATITLE__" />
                    <meta property="og:description" content="__METADESCRIPTION__"/>
                    <meta property="og:title" content="__METATITLE__"/>
                    <meta property="og:url" content="__OGURL__"/>
                    <link rel="canonical" href="__OGURL__" />
                </MetaTags>
                <Header />
                    {Loader ? 
                        <div id="preloader">
                            <div className="preloader_div">
                            </div>
                        </div>
                        :
                        ""
                    }
                    <section id="singleblog_section">
                        <div className="blog_img">
                            <img alt="" src={process.env.PUBLIC_URL + '/frontend/images/article-header.png'} />
                            <div className="singleblog_img_content mb-2">
                                <h1>{blogDetails.title}</h1>
                                <div className="d-flex justify-content-center">
                                    <div className="singleblog_date mr-lg-5">
                                        <svg id="Group_175" data-name="Group 175" xmlns="http://www.w3.org/2000/svg" width="18.931" height="18.93" viewBox="0 0 18.931 18.93">
                                            <path id="Path_2908" data-name="Path 2908" d="M914.217,574.337l2.034-2.034a1.161,1.161,0,1,0-1.642-1.642l-.392.392-.392-.392a1.161,1.161,0,0,0-1.642,1.642Zm0,0" transform="translate(-904.752 -559.36)" fill="#1c1c1c"/>
                                            <path id="Path_2909" data-name="Path 2909" d="M768,509.179H786.93V497.625H768Zm6.646-8.414a12.077,12.077,0,0,0,4.032-.665,2.271,2.271,0,0,1,1.605,3.876l-2.819,2.819-2.819-2.819a2.273,2.273,0,0,1,0-3.211Zm0,0" transform="translate(-768 -490.249)" fill="#1c1c1c"/>
                                            <path id="Path_2910" data-name="Path 2910" d="M784.463,350.387v.642a1.855,1.855,0,0,1-1.853,1.853v-1.109a.744.744,0,0,0,.744-.743v-.642h-1.487v-.534a.743.743,0,1,1,1.487,0v.534h1.109v-.534a1.853,1.853,0,0,0-3.706,0v.534h-1.44v.642a1.855,1.855,0,0,1-1.853,1.853v-1.109a.744.744,0,0,0,.743-.743v-.642h-1.487v-.534a.743.743,0,1,1,1.487,0v.534h1.109v-.534a1.853,1.853,0,0,0-3.705,0v.534h-1.44v.642a1.855,1.855,0,0,1-1.852,1.853v-1.109a.744.744,0,0,0,.743-.743v-.642h-1.487v-.534a.744.744,0,0,1,1.487,0v.534h1.109v-.534a1.853,1.853,0,0,0-3.705,0v.534H768v3.88H786.93v-3.88Zm0,0" transform="translate(-768 -348)" fill="#1c1c1c"/>
                                        </svg>
                                        <span className="ml-lg-2 ml-2">{blogDetails.created}</span>
                                    </div>
                                    <div className="singleblog_category">
                                        <svg id="Group_174" data-name="Group 174" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="28.189" height="17.24" viewBox="0 0 28.189 17.24">
                                            <defs>
                                                <clipPath id="clip-path">
                                                <rect id="Rectangle_217" data-name="Rectangle 217" width="28.189" height="17.24" fill="#1c1c1c"/>
                                                </clipPath>
                                            </defs>
                                            <g id="Group_166" data-name="Group 166" transform="translate(0 0)">
                                                <g id="Group_165" data-name="Group 165" transform="translate(0 0)" clip-path="url(#clip-path)">
                                                <path id="Path_2907" data-name="Path 2907" d="M822.1,445.054c-2.942-1.271-5.777-6.054-8.631-6.054s-3.533,1.358-5.434,1.359S805.453,439,802.6,439s-5.9,5.123-8.539,6.16c-.2.078.1.356.627.956,2.442,2.759,3.91,10.1,13.34,10.1,9.692,0,11.458-8.067,13.624-10.474.389-.433.577-.63.448-.686Zm-8.285,1.887c-.946.367-2.843,2.584-5.744,2.584-3.135,0-4.722-2.1-5.786-2.584a.056.056,0,0,1,0-.09,7.364,7.364,0,0,1,3.079-1.162c1.266,0,1.735.342,2.579.342s1.58-.342,2.845-.342a7.369,7.369,0,0,1,3.079,1.162c.026.012.007.067-.052.09Zm0,0" transform="translate(-793.999 -439)" fill="#1c1c1c"/>
                                                </g>
                                            </g>
                                        </svg>
                                        <span className="ml-2">{blogDetails.category}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container my-4">
                            <div className="row justify-content-between">
                                <div className="col-lg-7">
                                    <div className="single_blog_img mb-5">
                                        <img alt="" src={blogDetails.image} className="img-fluid" />
                                    </div>
                                    <div className="single_blog_para" dangerouslySetInnerHTML={{__html: blogDetails.content}}>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="side_post">
                                        <div className="side_post_search mb-3">
                                            <h4 className="mb-3">Search Blog</h4>
                                            <div className="side_post_box d-flex">
                                                <input placeholder="Type to Search" onChange={this.inputChange} value={searchBlogValue}/>
                                                <div className="side_post_search_img">
                                                    <Link to="#" onClick={(e) => {this.searchedBlog(e)}}><img alt="" src={process.env.PUBLIC_URL + '/frontend/images/search-solid.png'} /></Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="side_category mb-3">
                                            <h4 className="mb-3">Categories</h4>
                                            <div className="side_category_box">
                                                <p className="mb-2 categoryfilterdata" onClick={(e) => {this.filterBlog(e,'')}}>All</p>
                                                {blogCategories.map((categoryData,key) => {
                                                    return(
                                                        <p className="mb-2 categoryfilterdata" onClick={(e) => {this.filterBlog(e,categoryData.category)}}>{categoryData.category}</p>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className="side_recent my-4">
                                            <h4 className="mb-3">Recent Posts</h4>
                                            <div className="side_recent_box">
                                                {
                                                    recentBlogs.map((recentBlogData,key) => {

                                                        return(
                                                            <div className="side_recent_content d-flex mb-4">
                                                                <Link to={'/blog/'+recentBlogData.slug} onClick={(e) => {this.getSingleBlogData(e,recentBlogData.id,recentBlogData.slug)}}><img alt="" src={recentBlogData.image} className="img-fluid" /></Link>
                                                                <div className="side_recent_para">
                                                                <Link to={'/blog/'+recentBlogData.slug} onClick={(e) => {this.getSingleBlogData(e,recentBlogData.id,recentBlogData.slug)}}>
                                                                    <p className="mb-1">{recentBlogData.title} </p>
                                                                    </Link>
                                                                    <small>{recentBlogData.created}</small>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                <Footer />
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    blogDetails : state.blogData.getBlogDetails,
    blogCategories : state.blogData.getBlogCategories,
    recentBlogs : state.blogData.getRecentBlogs,
})


export default connect(
    mapStateToProps,
    {getBlogDetailAction , getBlogCategoriesAction,getRecentBlogsAction}
)(withRouter(SingleBlog));
