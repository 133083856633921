import React, {Component, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Badge} from "reactstrap";
import {
  addCommentAction,
  deletePostAction,
  getGuestPopularProfileAction,
  getGuestUserPostAction,
  getPopularProfileAction,
  getUserAlbumAction,
  getUserPostAction,
  likeDislikeCommentAction,
  likeDislikePostAction,
  recentPostAction,
  replyToCommentAction,
  updatePostAction,
  viewPostAction,
} from "../../actions/post";
import {getUserDetails} from "../../actions/accountSetting";
import {
  addToWishListAction,
  followContentAction,
  removeFromWishListAction,
  unfollowContentAction,
} from "../../actions/homePage";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Modal from "react-bootstrap/Modal";
import ModalComponent from "../ModalComponent";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import LoginModalComponent from "../LoginModalComponent";
import {
  ACCOUNT_NUMBER,
  API_KEY,
  AUTHENTICATION_PURPOSE,
  BASE_URL,
  CURRENCY,
  DEVICE_CHANNEL,
  ENVIRONMENT,
  MERCHANT_URL,
  MESSAGE_CATEGORY,
} from "../Constant";
import MetaTags from "react-meta-tags";
import axios from "axios";
import authHeader from "../../utils/AuthHeader";
import {ReactComponent as PostIcon} from "../../assets/images/svgs/post.svg";
import {ReactComponent as CameraIcon} from "../../assets/images/svgs/camera.svg";
import {ReactComponent as ClapperIcon} from "../../assets/images/svgs/clapper.svg";
import {ReactComponent as ImageIcon} from "../../assets/images/svgs/image.svg";
import {getFileExtension, supportedImageExtension, supportedVideoExtension,} from "../../utils/mediaUtils";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {ReactComponent as ArrowrightIcon} from "../../assets/images/svgs/arrow-right.svg";
import {ReactComponent as ArrowleftIcon} from "../../assets/images/svgs/arrow-left.svg";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import VideoPlayer from "../common/videojs";
import RenderModalSlider from "./../common/RenderModalSlider";
import { dateFilter, isBlankNull } from "../../utils/appUtils";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowrightIcon className="arrow_img_custom" onClick={onclick} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowleftIcon className="arrow_img_custom" onClick={onclick} />
    </div>
  );
}

export class FollowingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: 0,
      limit: 1,
      postData: [],
      postDataArray: [],
      userId: "",
      Loader: true,
      totalPostCount: 0,
      totalPostImageCount: 0,
      totalPostVideoCount: 0,
      login_user_id: "",
      popularProfiles: [],
      postSubmitLoader: false,
      userInfo: [],
      postFiles: [],
      addedFiles: [],
      postDataLength: 0, //this is set to stop call API if all data comes,
      showLess: true, //for description showing less or more
      recentPostData: [],
      commentModalShow: false, //to show comment box on profile or add comment click
      singlePostData: [], //single post comment data
      singlePostDataIndex: "", //single post index number
      Comment: "",
      commentIndex: "", //parent comment index when click on reply,
      lastCommentId: 0,
      encryptedUserName: "",
      isError: false,
      editPostModalShow: false,
      nav1: null,
      settingOptionShow: false,
      postIdForsettingOption: "", //setting option box open for this post id only
      postIndex: "", //when delete post set postIndex for if postIndex is 0 then call getUserPost if there is another post,
      editPostIndex: "",
      updateFlag: 0, //set replace array element during update if this is true only
      PhotoorVideo: [], //if new image upload during update then show changed image
      imageView: "",
      isPostError: false,
      postErrors: [],
      Albums: [],
      Publish: "",
      selectedIndex: [],
      selectedSlide: 0,
      // fingerPrintId : '',
      loginWishListAlert: false,
      paymentConfirmationModal: false,
      contentWriterId: 0,
      paymentError: false,
      cardDetails: 0,
      followerList: [],
      wishList: [],
      // wishListCount : 0,
      subscribeOrDesiredLoader: false,
      subscribeClick: 0, //for paymentconfirmation modal which is true in follow content props
      postButtonShow: 0, //when click on reply to comment if postbuttonshow is 0 then only show post bcz in reply we need name and if not write anything then it shows name only
      replyTo: 0,
      // showLessCaption : true,//for caption showing less or more
      paymentErrorMessage: "",
      paysaferesponse: "",
      loadMoreButton: true,
      subscribeConfirmationModal: false,
      unsubscribeConfirmationModal: false,
      unfollowConfirmationModal: false,
      unfollowId: 0,
      subscriptionPrice: 0,
      subscriptionPlan: 0,
      viewPostId: 0,
      selectedImageId: [],
      captionClickPostId: 0,
      PaysafeToken: "",
      postType: "Post",
      commentPostId: "", //in which post comment is going to write
      id_verified: 3,
      showunfollowsuccess: false,
      cvv: "",
      cvvError: false,
      cvvErrorMessage: "",
      modalShow: false,
      is_public: false,
      replytousername:''
    };

    this.handleScrollAlertHide = this.handleScrollAlertHide.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.descriptionReadMore = this.descriptionReadMore.bind(this);
    this.descriptionReadLess = this.descriptionReadLess.bind(this);
    this.postLikeCountChanges = this.postLikeCountChanges.bind(this);
    this.commentLikeCountChanges = this.commentLikeCountChanges.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.closeUpdatemessagePopup = this.closeUpdatemessagePopup.bind(this);
    this.show = this.show.bind(this); //reaact modal by default function for showing modal
    this.commentSubmit = this.commentSubmit.bind(this);
    // this.inputChange = this.inputChange.bind(this)
    this.editModalOpen = this.editModalOpen.bind(this);
    this.editModalClose = this.editModalClose.bind(this);
    this.bodyClick = this.bodyClick.bind(this);
    this.imageUpload = this.imageUpload.bind(this);
    this.postInputChange = this.postInputChange.bind(this);
    this.handleCheckClick = this.handleCheckClick.bind(this);
    this.postSubmit = this.postSubmit.bind(this);
    this.paymentConfirmationModalOpen =
      this.paymentConfirmationModalOpen.bind(this);
    this.paymentConfirmationModalClose =
      this.paymentConfirmationModalClose.bind(this);
    this.subscribeConfirmationModalOpen =
      this.subscribeConfirmationModalOpen.bind(this);
    this.subscribeConfirmationModalClose =
      this.subscribeConfirmationModalClose.bind(this);
    this.wishListSubscribeHandleClose =
      this.wishListSubscribeHandleClose.bind(this);
    this.wishListSubscribeshow = this.wishListSubscribeshow.bind(this);
    this.gotoupdatecard = this.gotoupdatecard.bind(this);
    this.gotocontactsupport = this.gotocontactsupport.bind(this);
    this.unsubscribeConfirmationModalOpen =
      this.unsubscribeConfirmationModalOpen.bind(this);
    this.unsubscribeConfirmationModalClose =
      this.unsubscribeConfirmationModalClose.bind(this);
    this.unfollowConfirmationModalClose =
      this.unfollowConfirmationModalClose.bind(this);
    this.unfollowModalOpen = this.unfollowModalOpen.bind(this);
    this.openModal = this.openModal.bind(this);
    this.showAuthErrorPaysafe = this.showAuthErrorPaysafe.bind(this);
    this.showgetAuthorizationerror = this.showgetAuthorizationerror.bind(this);
    this.inputChangeCVV = this.inputChangeCVV.bind(this);
    this.getSettings = this.getSettings.bind(this);
  }

  componentDidMount() {
    localStorage.removeItem("searchedBlog");

    this.setState({
      start: 0,
      nav1: this.slider1,
      postData: [],
    });
    //searchUser Id
    // let searchUserName = this.props.location.search;
    // const params = new URLSearchParams(searchUserName);
    let user_name = this.props.match.params.profileName;
    localStorage.setItem("userprofileName", user_name);

    if (user_name !== "") {
      // user_name = atob(user_name)
      let login_user_id = this.props.authenticatedUserData.userId;
      if (login_user_id === undefined) {
        login_user_id = 0;
      }
      // let searchUserName = atob(this.props.match.params.searchUserName);
      this.setState({
        userName: user_name,
        login_user_id: login_user_id,
        encryptedUserName: this.props.match.params.profileName,
      });
      if (login_user_id === 0) {
        this.props.getGuestUserPostAction(
          user_name,
          login_user_id,
          this.state.start,
          this.state.limit,
          this.props
        );
        this.props.getGuestPopularProfileAction(login_user_id, 0, 4);
        window.addEventListener("scroll", this.handleScroll);
      } else {
        this.props.getUserPostAction(
          user_name,
          login_user_id,
          this.state.start,
          this.state.limit,
          this.props
        );
        this.props.recentPostAction(login_user_id, 0, 4, user_name);
        this.props.getPopularProfileAction(login_user_id, 0, 4);
        this.props.getUserAlbumAction(login_user_id);
        this.props.getUserDetails(login_user_id);
        // if(window.innerWidth > 991){
        window.addEventListener("scroll", this.handleScroll);
        // }
        //bcz when come from show profile click it goes down to end due to that go in handlescroll function and issue occured so put below function
      }
      window.scrollTo(0, 0);
      window.addEventListener("scroll", this.handleScrollAlertHide);
    } else {
      this.props.history.push("/");
    }
    window.addEventListener("click", this.bodyClick);
  }

  renderFiles(url, id) {
    let videoJsOptions = {
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: true,
      sources: [
        {
          src: url,
          type: "video/mp4",
        },
      ],
    };

    if (document.querySelector("#postvideos" + id)) {
      let player = videojs(document.querySelector("#postvideos" + id));
      player.src({
        src: url,
        type: "video/mp4" /*video type*/,
      });
    }

    return <VideoPlayer {...videoJsOptions} id={"postvideos" + id} />;
  }

  displayPostFiles(data) {
    if (this.state.postFiles[0] && data.media[0]) {
      let file = this.state.postFiles.find((x) => x.id === data.id);
      if (file && file.type === "image") {
        return <img className="img-fluid" src={file.url} alt="img" />;
      } else if (file && file.type === "video") {
        return this.renderFiles(file.url, data.id);
      } else {
        return data.media[0].type === "image" ? (
          <img className="img-fluid" src={data.media[0].medium} alt="img" />
        ) : (
          this.renderFiles(data.media[0].original, data.id)
        );
      }
    } else {
      return data.media[0] ? (
        data.media[0].type === "image" ? (
          <img className="img-fluid" src={data.media[0].original} alt="img" />
        ) : (
          this.renderFiles(data.media[0].original, data.id)
        )
      ) : null;
    }
  }

  viewUserPost(e, profileName) {
    //e.preventDefault()
    let searchUserName = profileName;
    localStorage.setItem("userprofileName", profileName);
    localStorage.setItem("searchUserName", searchUserName);
    this.props.history.push("/" + searchUserName);
  }

  descriptionReadMore(e) {
    e.preventDefault();
    this.setState({
      showLess: false,
    });
  }

  descriptionReadLess(e) {
    e.preventDefault();
    this.setState({
      showLess: true,
    });
  }

  inputChangeCVV(e) {
    this.state.cvv = e.target.value;
  }

  redirectToProfile(roleName) {
    localStorage.setItem("roleName", roleName);
  }

  handleScrollAlertHide() {
    if (window.scrollY <= 100) {
      this.setState({
        loginWishListAlert: false,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    window.addEventListener("scroll", this.handleScroll);
    //get album name of user
    if (
      nextProps.getAlbums &&
      nextProps.getAlbums.data &&
      nextProps.getAlbums.data.length > 0
    ) {
      this.setState({
        Albums: nextProps.getAlbums.data,
      });
    }
    // let searchUserName = nextProps.location.search;
    // const params = new URLSearchParams(searchUserName);
    let userName = this.props.match.params.profileName;
    if (
      localStorage.getItem("searchUserName") !== null &&
      localStorage.getItem("searchUserName") !== "null" &&
      localStorage.getItem("searchUserName") !== ""
    ) {
      userName = localStorage.getItem("searchUserName");
    }

    if (userName !== this.state.userName) {
      this.setState({
        userName: userName,
        Loader: true,
        postData: [],
        userInfo: [],
        start: 0,
        encryptedUserName: localStorage.getItem("searchUserName"),
      });
      window.scrollTo(0, 0);

      if (this.state.login_user_id === 0) {
        this.props.getGuestUserPostAction(
          userName,
          this.state.login_user_id,
          0,
          this.state.limit,
          this.props
        );
        window.addEventListener("scroll", this.handleScroll);
      } else {
        this.props.getUserPostAction(
          userName,
          this.state.login_user_id,
          0,
          this.state.limit,
          this.props
        );
        this.props.recentPostAction(this.state.login_user_id, 0, 4, userName);
        this.props.getPopularProfileAction(this.state.login_user_id, 0, 4);
        this.props.getUserAlbumAction(this.state.login_user_id);
        this.props.getUserDetails(this.state.login_user_id);
        // if(window.innerWidth > 991){
        window.addEventListener("scroll", this.handleScroll);
      }
    } else {
      localStorage.removeItem("searchUserName");
      this.setState({
        Loader: false,
      });
    }
    let postDataArray = [];
    if (
      this.state.postData.length !== 0 &&
      this.state.start !== 0 &&
      (localStorage.getItem("searchUserName") === null ||
        localStorage.getItem("searchUserName") === "null" ||
        localStorage.getItem("searchUserName") === "")
    ) {
      postDataArray.push(...this.state.postData);
    }

    // post data
    if (
      nextProps.userPostData &&
      nextProps.userPostData.data &&
      nextProps.userPostData.data.length > 0 &&
      (localStorage.getItem("searchUserName") === null ||
        localStorage.getItem("searchUserName") === "null" ||
        localStorage.getItem("searchUserName") === "")
    ) {
      let id = nextProps.userPostData.data[0].id;
      if (
        !postDataArray.some((o) => o.id === nextProps.userPostData.data[0].id)
      ) {
        postDataArray.push(...nextProps.userPostData.data);
      }

      if (
        this.state.viewPostId !== nextProps.userPostData.data[0].id &&
        (this.state.followerList.includes(parseInt(this.state.userId)) ||
          this.state.login_user_id === parseInt(this.state.userId))
      ) {
        this.props.viewPostAction(
          nextProps.userPostData.data[0].id,
          this.state.login_user_id
        );
        this.setState({
          viewPostId: nextProps.userPostData.data[0].id,
        });
      }

      let start = this.state.start;
      if (this.state.start === 0) {
        start = 0;
      } else {
        start = this.state.start;
      }
      if (this.state.editPostIndex !== "" && this.state.updateFlag === 1) {
        postDataArray = [...this.state.postData];
        let prevMediaExtension = postDataArray[this.state.editPostIndex]
          .media[0]
          ? postDataArray[this.state.editPostIndex].media[0].medium
              .split("?")
              .shift()
              .split(".")
              .pop()
          : "";
        // postDataArray.splice(this.state.editPostIndex,1)
        postDataArray[this.state.editPostIndex] = {
          ...nextProps.userPostData.data[0],
        };
        let videoCount = this.state.totalPostVideoCount;
        let imageCount = this.state.totalPostImageCount;
        if (
          (nextProps.userPostData.data[0].media[0] &&
            nextProps.userPostData.data[0].media[0].medium
              .split("?")
              .shift()
              .split(".")
              .pop() === "mp4" &&
            prevMediaExtension !== "mp4") ||
          (nextProps.userPostData.data[0].media[0] &&
            nextProps.userPostData.data[0].media[0].medium
              .split("?")
              .shift()
              .split(".")
              .pop() === "MP4" &&
            prevMediaExtension !== "MP4")
        ) {
          videoCount = this.state.totalPostVideoCount + 1;
        } else if (
          prevMediaExtension !== "jpg" ||
          prevMediaExtension !== "JPG" ||
          prevMediaExtension !== "jpeg" ||
          prevMediaExtension !== "JPEG" ||
          prevMediaExtension !== "png" ||
          prevMediaExtension !== "PNG" ||
          prevMediaExtension !== "gif" ||
          prevMediaExtension !== "GIF" ||
          prevMediaExtension !== "webp"
        ) {
          imageCount = this.state.totalPostImageCount + 1;
        }
        this.setState({
          editPostModalShow: false,
          Loader: false,
          singlePostData: [],
          settingOptionShow: false,
          postIdForsettingOption: "",
          editPostIndex: "",
          PhotoorVideo: [],
          totalPostImageCount: imageCount,
          totalPostVideoCount: videoCount,
          updateFlag: 2,
        });
      }

      // let totalPostCount = nextProps.userPostData.count
      // if(parseInt(this.state.userId) !== parseInt(this.state.login_user_id)){
      //     totalPostCount = nextProps.userPostData.publishCount
      // }
      let loadMoreButton = false;
      if (nextProps.userPostData.count > 1 && this.state.start == 0) {
        loadMoreButton = true;
      }

      this.setState({
        postData: postDataArray,
        totalPostCount: nextProps.userPostData.count,
        totalPostImageCount: nextProps.userPostData.imageCount,
        totalPostVideoCount: nextProps.userPostData.videoCount,
        postDataLength: postDataArray.length,
        lastCommentId: nextProps.userPostData.lastCommentId + 1,
        start: start,
        loadMoreButton: loadMoreButton,
        // id_verified : nextProps.userPostData.userData.id_verified,
      });
    } else {
      //if serach again n again then if no data postData should be null
      this.setState({
        totalPostCount: 0 ,
        postData: [],
        userInfo: [],
        start: 0,
      });
    }

    //userData is always come if userPostData not come
    if (nextProps.userPostData && nextProps.userPostData.userData) {
      this.setState({
        userId: nextProps.userPostData.userData.userId,
        userInfo: nextProps.userPostData.userData,
        totalPostImageCount: nextProps.userPostData.imageCount,
        totalPostVideoCount: nextProps.userPostData.videoCount,
        id_verified: nextProps.userPostData.userData.id_verified,
      });
    }

    //popular profile data
    if (
      nextProps.popularProfilesData &&
      nextProps.popularProfilesData.data &&
      nextProps.popularProfilesData.data.length > 0
    ) {
      this.setState({
        popularProfiles: nextProps.popularProfilesData.data,
      });
    }

    //recent post data
    if (
      nextProps.recentPost &&
      nextProps.recentPost.data &&
      nextProps.recentPost.data.length > 0
    ) {
      this.setState({
        recentPostData: nextProps.recentPost.data,
      });
    }

    //delete post
    if (
      nextProps.deletePost &&
      nextProps.deletePost.message &&
      nextProps.deletePost.message !== ""
    ) {
      let start = this.state.start;
      if (this.state.postIndex !== "") {
        if (this.state.postIndex === 0) {
          start = 0;
        } else {
          start = this.state.start - 1;
        }
      }

      //if first post and there should be more than one post then only go inside
      if (this.state.postIndex === 0 && this.state.totalPostCount !== 1) {
        this.props.getUserPostAction(
          this.state.userName,
          this.state.login_user_id,
          start,
          this.state.limit,
          this.props
        );
      } else {
        //if post index not null means come after deleting post
        if (this.state.postIndex !== "") {
          let array = [...this.state.postData];
          array.splice(this.state.postIndex, 1);
          this.setState({
            postData: array,
          });
        }
      }

      let videoCount = this.state.totalPostVideoCount;
      let imageCount = this.state.totalPostImageCount;

      this.setState({
        postIndex: "",
        Loader: false,
        totalPostImageCount: imageCount,
        totalPostVideoCount: videoCount,
        start: start,
      });
    }

    //update post - if error come
    if (
      this.state.editPostModalShow &&
      nextProps.updatePost &&
      nextProps.updatePost.isError
    ) {
      // if (
      //   nextProps.updatePost.error.hasOwnProperty("PhotoorVideo.0") ||
      //   nextProps.updatePost.error.hasOwnProperty("PhotoorVideo.1") ||
      //   nextProps.updatePost.error.hasOwnProperty("PhotoorVideo.2")
      // ) {
      //   this.setState({
      //     PhotoorVideo: [],
      //     addedFiles: [],
      //     imageView: []
      //   });
      // }
      this.setState({
        postSubmitLoader:false,
        isPostError: true,
        postErrors: nextProps.updatePost.error,
      });
    } else if (
      nextProps.updatePost &&
      nextProps.updatePost.data &&
      this.state.editPostIndex !== ""
    ) {
      // let start = this.state.start
      // if(this.state.editPostIndex === 0){
      //     start = 0
      // }

      // if(this.state.start > this.state.postData.length - 1){
      //     start = this.state.postData.length - 1
      // }

      this.setState({
        editPostModalShow: false,
        modalShow: true,
        successMessage: nextProps.updatePost.message,
        postSubmitLoader: false,
      });

      postDataArray[this.state.editPostIndex].media =
        nextProps.updatePost.data.media;
      if (this.state.editPostIndex !== "" && this.state.updateFlag === 0) {
        this.setState({
          updateFlag: 1,
          imageView: "",
          editPostModalShow: false,
        });
        //put editPostIndex instead of start bcz we need to fetch updated data of editPostIndex

        this.props.getUserPostAction(
          this.state.userName,
          this.state.login_user_id,
          this.state.editPostIndex,
          this.state.limit,
          this.props
        );
      }
    }

    //following or not

    if (
      nextProps.getUserData &&
      nextProps.getUserData.usersData &&
      nextProps.getUserData.usersData.length !== ""
    ) {
      let subscribeOrDesiredLoader = this.state.subscribeOrDesiredLoader;
      if (this.state.subscribeOrDesiredLoader) {
        subscribeOrDesiredLoader = false;
      }
      this.setState({
        cardDetails: nextProps.getUserData.usersData.cardDetails,
        followerList: nextProps.getUserData.usersData.followerList,
        wishList: nextProps.getUserData.usersData.wishList,
        subscribeOrDesiredLoader: subscribeOrDesiredLoader,
        paymentError: false,
        PaysafeToken: nextProps.getUserData.usersData.PaysafeToken,
      });
    }

    if (nextProps.followingContent && this.state.contentWriterId !== 0) {
      // localStorage.removeItem('fingerPrintId')
      let contentWriterId = this.state.contentWriterId;

      let paymentConfirmationModal = false;
      let paymentError = false;
      let paymentErrorMessage = "";
      let paysaferesponse = "";
      if (this.state.subscribeClick === 1) {
        paymentConfirmationModal = true;
      }
      if (nextProps.followingContent.isError) {
        paymentError = true;
        paymentErrorMessage = nextProps.followingContent.error;
        paysaferesponse = nextProps.followingContent.paysafe;
      }
      this.setState({
        contentWriterId: 0,
        paymentConfirmationModal: paymentConfirmationModal,
        subscribeOrDesiredLoader: false,
        paymentError: paymentError,
        paymentErrorMessage: paymentErrorMessage,
        paysaferesponse: paysaferesponse,
      });
      setTimeout(
        function () {
          let paymentConfirmationModal = false;
          if (
            nextProps.followingContent.isError &&
            this.state.subscribeClick === 1
          ) {
            paymentConfirmationModal = true;
          }
          if (
            !nextProps.followingContent.isError &&
            this.state.subscribeClick === 1
          ) {
            contentWriterId = btoa(contentWriterId);
            this.props.getUserDetails(this.state.login_user_id); //for updating cardDetails
          }
          this.setState({
            paymentConfirmationModal: paymentConfirmationModal,
            subscribeClick: 0,
          });
        }.bind(this),
        800
      );
    }

    if (
      nextProps.addToWishList &&
      this.state.contentWriterId !== 0 &&
      this.state.subscribeClick === 0
    ) {
      this.setState({
        contentWriterId: 0,
        // wishListCount : this.state.wishListCount + 1,
      });
      if (!nextProps.addToWishList.isError) {
        // this.setState({
        //     wishListCount : 1,
        // })
        this.setState({
          loginWishListAlert: true,
        });
        this.props.getUserDetails(this.state.login_user_id);
        // window.scrollTo(0, 0);
      }
    }
    if (nextProps.unfollow && this.state.unfollowId !== 0) {
      if (!nextProps.unfollow.isError) {
        this.setState({
          unfollowConfirmationModal: true,
          unfollowId: 0,
          followerList: nextProps.unfollow.followerList,
        });
      }
    }
  }

  //when writing comment
  inputChange(e, postId) {
    // e.preventDefault()
    let postButtonShow = 0;
    let Comment = e.target.value;
    let replytousername = this.state.replytousername
    if (this.state.Comment.includes("@") && this.state.commentIndex !== "") {
      if (
        e.target.value ===
        "@" +
          replytousername +
          " ,"
      ) {
        postButtonShow = 1;
      }
      //if @name erase from middle or anyother way n then if not match then remove it
      if (!e.target.value.includes("@" + replytousername + " ,")) {
        Comment = this.state.Comment.replace(
            "@" +
            replytousername +
            " ,",
          ""
        );
      }
    }
    let replyTo = this.state.replyTo;
    if (Comment === "") {
      replyTo = 0;
    }

    this.setState({
      Comment: Comment,
      postButtonShow: postButtonShow,
      isError: false,
      replyTo: replyTo,
      commentPostId: postId,
    });
  }

  //when reply to any comment
  replyToComment(e, commentIndex, postId,username) {
    e.preventDefault();
    let userName = "@" + username + " ,";
    this.setState({
      Comment: userName,
      commentIndex: parseInt(commentIndex),
      postButtonShow: 1,
      replyTo: 1,
      commentPostId: postId,
      replytousername:username
    });
  }

  //react modal hide show functions start
  addComment(e, index, posttype) {
    // objDiv.scrollTop = objDiv.scrollHeight;
    e.preventDefault();
    // let authenticatedUserData = JSON.parse(
    //   decodeURIComponent(localStorage.getItem("user_data"))
    // );
    // let expires_at = new Date(authenticatedUserData.expires_at);
    // expires_at = expires_at.getTime();
    // let currentTime = new Date();
    // currentTime = currentTime.getTime();
    // if (
    //   authenticatedUserData &&
    //   expires_at < currentTime &&
    //   authenticatedUserData.remember_me === "No"
    // ) {
    //   this.props.logoutAction();
    //   // Redirect to login
    //   window.location.href = "/login";
    // } else {
    let singlePostData = this.state.postData[index];
    if (posttype === "recentPost") {
      singlePostData = this.state.recentPostData[index];
    }

    this.setState({
      commentModalShow: true,
      singlePostData: singlePostData,
      singlePostDataIndex: index,
      Comment: "",
      postType: posttype,
    });

    //chat box scroll to bottom
    setTimeout(function () {
      var chatBoxDiv = document.getElementById("chat_box");
      chatBoxDiv.scrollTo(0, chatBoxDiv.scrollHeight);
    }, 100);

    if (this.state.selectedIndex.length > 0) {
      let selected = this.state.selectedIndex.find(
        (x) => x.id === this.state.postData[index].id
      );
      if (selected) {
        this.setState({ selectedSlide: selected.index });
      } else {
        this.setState({ selectedSlide: 0 });
      }
    }
  }

  closeUpdatemessagePopup() {
    this.setState({ modalShow: false });
    window.location.reload();
  }

  handleClose() {
    this.setState({
      commentModalShow: false,
      singlePostData: [],
      postButtonShow: 0,
      replyTo: 0,
      commentPostId: "",
      postType: "Post",
    });
  }

  show() {
    useState(false);
  }

  //react modal hide show functions end

  //when click on post button of comment
  commentSubmit(e) {
    e.preventDefault();
    if (localStorage.user_data) {
      let authenticatedUserData = JSON.parse(
        decodeURIComponent(localStorage.getItem("user_data"))
      );

      let expires_at = new Date(authenticatedUserData.expires_at);
      expires_at = expires_at.getTime();
      let currentTime = new Date();
      currentTime = currentTime.getTime();
      if (
        authenticatedUserData &&
        expires_at < currentTime &&
        authenticatedUserData.remember_me === "No"
      ) {
        this.props.logoutAction();
        // Redirect to login
        window.location.href = "/login";
      } else {
        let isError = false;
        let commentPostId = "";
        if (this.state.Comment === "") {
          isError = true;
          commentPostId = parseInt(e.target.getAttribute("postid"));
        }

        this.setState({
          isError: isError,
          commentPostId: commentPostId,
        });
        if (!isError) {
          let authenticatedUserData = this.props.authenticatedUserData;
          let authUserProfilePic = authenticatedUserData.ProfilePic;
          let authUserdisplayName = authenticatedUserData.DisplayName;
          let index = parseInt(e.target.id); //post index number

          let commentUserData = {};
          let replyCommentUserData = {};
          let getCommentId = 0;
          let splitComment = "";
          //comment created date
          const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          var today = new Date();
          let commentCreatedDate =
            today.getDate() +
            " " +
            monthNames[today.getMonth()] +
            " " +
            today.getFullYear();

          if (this.state.replyTo === 1) {
            splitComment = this.state.Comment.substring(
              this.state.Comment.indexOf(",") + 1,
              this.state.Comment.length
            );

            replyCommentUserData = {
              comment: splitComment,
              profile: authUserProfilePic,
              displayName:authUserdisplayName
            };
            //@Aaki Modi coming in comment when click on reply to so remove this name from comment
            this.state.singlePostData.commentUsers[
              this.state.commentIndex
            ].commentUsers.push(replyCommentUserData);

            getCommentId =
              this.state.singlePostData.commentUsers[this.state.commentIndex]
                .id;
          } else {
            commentUserData = {
              id: this.state.lastCommentId,
              comment: this.state.Comment,
              profile: authUserProfilePic,
              commentUsers: [],
              likes: 0,
              likeByMe: 0,
              commentDate: commentCreatedDate,
              displayName:authUserdisplayName
            };
            this.setState({
              lastCommentId: this.state.lastCommentId + 1,
            });

            //when comment without opening pop up
            if (this.state.singlePostData.length !== 0) {
              //push comemnt data to singlePost commentUsers param
              this.state.singlePostData.commentUsers.push(commentUserData);
            } else {
              if (this.state.postType === "Post") {
                this.state.postData[index].commentUsers.push(commentUserData);
              } else {
                this.state.recentPostData[index].commentUsers.push(
                  commentUserData
                );
              }
            }
          }
          if (this.state.singlePostData.length !== 0) {
            this.state.singlePostData = {
              ...this.state.singlePostData,
              totalComments: this.state.singlePostData.totalComments + 1,
            };
          }

          if (this.state.postType === "Post") {
            this.state.postData[index] = {
              ...this.state.postData[index],
              totalComments: this.state.postData[index].totalComments + 1,
            };
            this.setState({
              ...this.state.singlePostData, //for single post update in modal
              ...this.state.postData[index], //for post update
            });
          } else {
            this.state.recentPostData[index] = {
              ...this.state.recentPostData[index],
              totalComments: this.state.recentPostData[index].totalComments + 1,
            };
            this.setState({
              ...this.state.singlePostData, //for single post update in modal
              ...this.state.recentPostData[index], //for recent post update
            });
          }

          let Comment = this.state.Comment;
          if (splitComment !== "") {
            Comment = splitComment;
          }
          let commentData = {
            Comment: Comment,
          };
          if (this.state.replyTo === 1) {
            this.props.replyToCommentAction(
              getCommentId,
              this.state.login_user_id,
              commentData
            );
          } else {
            if (this.state.postType === "Post") {
              this.props.addCommentAction(
                this.state.postData[index].id,
                this.state.login_user_id,
                commentData
              );
            } else {
              this.props.addCommentAction(
                this.state.recentPostData[index].id,
                this.state.login_user_id,
                commentData
              );
            }
          }
          this.setState({
            Comment: "",
            commentIndex: "",
            replyTo: 0,
          });
        }
      }
    }
  }

  postLikeCountChanges(e, index) {
    e.preventDefault();
    if (localStorage.user_data) {
      let authenticatedUserData = JSON.parse(
        decodeURIComponent(localStorage.getItem("user_data"))
      );

      let expires_at = new Date(authenticatedUserData.expires_at);
      expires_at = expires_at.getTime();
      let currentTime = new Date();
      currentTime = currentTime.getTime();
      if (
        authenticatedUserData &&
        expires_at < currentTime &&
        authenticatedUserData.remember_me === "No"
      ) {
        this.props.logoutAction();
        // Redirect to login
        window.location.href = "/login";
      } else {
        index = parseInt(index);
        let likedByMe = 0;
        let likes = this.state.postData[index].likes;
        if (this.state.postType === "recentPost") {
          likes = this.state.recentPostData[index].likes;
        }

        if (
          this.state.postType === "Post" &&
          this.state.postData[index].likedByMe === 1
        ) {
          likedByMe = 0;
          likes = likes - 1;
        } else if (
          this.state.postType === "recentPost" &&
          this.state.recentPostData[index].likedByMe === 1
        ) {
          likedByMe = 0;
          likes = likes - 1;
        } else {
          likedByMe = 1;
          likes = likes + 1;
        }

        if (this.state.singlePostDataIndex !== "") {
          this.state.singlePostData = {
            ...this.state.singlePostData,
            likes: likes,
            likedByMe: likedByMe,
          };
          this.setState({
            ...this.state.singlePostData,
          });
        }

        if (this.state.postType === "Post") {
          this.state.postData[index] = {
            ...this.state.postData[index],
            likes: likes,
            likedByMe: likedByMe,
          };
          this.setState({
            ...this.state.postData[index],
          });
          this.props.likeDislikePostAction(
            this.state.postData[index].id,
            this.state.userId
          );
        } else {
          this.state.recentPostData[index] = {
            ...this.state.recentPostData[index],
            likes: likes,
            likedByMe: likedByMe,
          };
          this.setState({
            ...this.state.recentPostData[index],
          });
          this.props.likeDislikePostAction(
            this.state.recentPostData[index].id,
            this.state.userId
          );
        }
      }
    } else {
      this.setState({
        loginWishListAlert: true,
      });
    }
  }

  //comment like count
  commentLikeCountChanges(e, commentIndex) {
    e.preventDefault();
    if (localStorage.user_data) {
      let authenticatedUserData = JSON.parse(
        decodeURIComponent(localStorage.getItem("user_data"))
      );

      let expires_at = new Date(authenticatedUserData.expires_at);
      expires_at = expires_at.getTime();
      let currentTime = new Date();
      currentTime = currentTime.getTime();
      if (
        authenticatedUserData &&
        expires_at < currentTime &&
        authenticatedUserData.remember_me === "No"
      ) {
        this.props.logoutAction();
        // Redirect to login
        window.location.href = "/login";
      } else {
        commentIndex = parseInt(commentIndex);
        let likedByMe = 0;

        let likes = this.state.singlePostData.commentUsers[commentIndex].likes;

        if (
          this.state.singlePostData.commentUsers[commentIndex].likeByMe === 1
        ) {
          likedByMe = 0;
          likes = likes - 1;
        } else {
          likedByMe = 1;
          likes = likes + 1;
        }

        this.state.singlePostData.commentUsers[commentIndex] = {
          ...this.state.singlePostData.commentUsers[commentIndex],
          likes: likes,
          likeByMe: likedByMe,
        };
        this.setState({
          ...this.state.singlePostData,
        });
        this.props.likeDislikeCommentAction(
          this.state.singlePostData.commentUsers[commentIndex].id,
          this.state.userId
        );
      }
    }
  }

  handleScroll(e) {
    e.preventDefault();

    if (window.innerWidth > 991) {
      const windowHeight =
        "innerHeight" in window
          ? window.innerHeight
          : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      const windowBottom = windowHeight + window.pageYOffset;
      // let userId = atob(user_id)
      if (Math.ceil(windowBottom) >= docHeight) {
        //do this bcz when i serach some user from following page and then scroll and then go to home page and again click on user profile then prev data comes due to first come in this funtion so if state user is matched with URL user then only go inside
        let user_name = this.props.match.params.profileName;
        if (
          this.state.postData.length > 0 &&
          this.state.encryptedUserName === user_name
        ) {
          let start = this.state.start + 1;
          this.setState({
            start: start,
            Loader: true,
            settingOptionShow: false,
          });
          if (
            this.state.totalPostCount !== this.state.postDataLength &&
            start === this.state.start &&
            this.state.totalPostCount >= this.state.start
          ) {
            if (this.state.login_user_id !== 0) {
              this.props.getUserPostAction(
                this.state.userName,
                this.state.login_user_id,
                start,
                this.state.limit,
                this.props
              );
            } else {
              this.props.getGuestUserPostAction(
                this.state.userName,
                this.state.login_user_id,
                start,
                this.state.limit
              );
            }
          } else {
            this.setState({
              start: start,
              Loader: true,
              settingOptionShow: false,
            });
            if (
              this.state.totalPostCount !== this.state.postDataLength &&
              start === this.state.start &&
              this.state.totalPostCount >= this.state.start
            ) {
              this.props.getUserPostAction(
                this.state.userName,
                this.state.login_user_id,
                start,
                this.state.limit
              );
            } else {
              this.setState({
                Loader: false,
              });
            }
          }
        }
      }
    }
  }

  //for mobile view load more button
  loadAllPost(e) {
    e.preventDefault();
    // if (window.location.href.includes('search')) {
    this.setState({
      start: 1,
      Loader: true,
      loadMoreButton: false,
    });
    let limit = this.state.totalPostCount - 1;
    if (this.state.login_user_id !== 0) {
      this.props.getUserPostAction(
        this.state.userName,
        this.state.login_user_id,
        1,
        limit,
        this.props
      );
    } else {
      this.props.getGuestUserPostAction(
        this.state.userName,
        this.state.login_user_id,
        1,
        limit
      );
    }

    // }
  }

  alertClose(e) {
    e.preventDefault();
    this.setState({
      loginWishListAlert: false,
    });
  }

  paymentConfirmationModalClose() {
    this.setState({
      paymentConfirmationModal: false,
      paymentError: false,
      subscribeClick: 0,
    });
  }

  gotoupdatecard() {
    this.setState({
      paymentConfirmationModal: false,
      paymentError: false,
      subscribeClick: 0,
    });
    let TabName = btoa("CardsTab");
    localStorage.setItem("cardsTab", TabName);
    setTimeout(
      function () {
        this.props.history.push("/account");
      }.bind(this),
      200
    );
  }

  gotocontactsupport() {
    this.setState({
      paymentConfirmationModal: false,
      paymentError: false,
      subscribeClick: 0,
    });
    setTimeout(
      function () {
        this.props.history.push("/contact-us");
      }.bind(this),
      200
    );
  }

  paymentConfirmationModalOpen() {
    useState(false);
  }

  subscribeConfirmationModal(
    e,
    contentWriterId,
    subscriptionPrice,
    subscriptionPlan,
    displayName
  ) {
    e.preventDefault();
    if (this.state.login_user_id !== "" && this.state.login_user_id !== 0) {
      this.setState({
        subscribeClick: 1,
        displayName: displayName,
        cvv: "",
        cvvError: false,
        subscriptionPrice: subscriptionPrice,
      });
      if (this.state.cardDetails === 1) {
        this.setState({
          subscribeConfirmationModal: true,
          contentWriterId: contentWriterId,
          subscriptionPrice: subscriptionPrice,
          subscriptionPlan: subscriptionPlan,
        });
      } else {
        this.setState({
          subscribeOrDesiredLoader: false,
          paymentError: true,
        });
        let TabName = btoa("CardsTab");
        localStorage.setItem("cardsTab", TabName);
        setTimeout(
          function () {
            this.props.history.push("/account");
          }.bind(this),
          200
        );
      }
    }
    //if not login
    else {
      this.setState({
        loginWishListAlert: true,
      });
    }
  }

  unsubscribeConfirmationModal(e, contentWriterId, displayName) {
    e.preventDefault();
    this.setState({
      unsubscribeConfirmationModal: true,
      contentWriterId: contentWriterId,
      displayName: displayName,
    });
  }

  subscribeConfirmationModalOpen() {
    useState(false);
  }

  unsubscribeConfirmationModalOpen() {
    useState(false);
  }

  unfollowModalOpen() {
    useState(false);
  }

  unfollowConfirmationModalClose() {
    this.setState({
      unfollowConfirmationModal: false,
    });
  }

  unsubscribeConfirmationModalClose() {
    this.setState({
      unsubscribeConfirmationModal: false,
    });
  }

  subscribeConfirmationModalClose() {
    this.setState({
      subscribeConfirmationModal: false,
      contentWriterId: 0,
      subscriptionPrice: 0,
      subscribeClick: 0,
    });
  }

  unfollow(e, userId) {
    e.preventDefault();
    this.setState({
      subscribeOrDesiredLoader: true,
      unsubscribeConfirmationModal: false,
      loginWishListAlert: false,
      showunfollowsuccess: true,
      unfollowId: userId,
    });
    this.props.unfollowContentAction(this.state.login_user_id, userId);
  }

  /**
   * card type not supported error message
   */
  openModal() {
    this.setState({
      contentWriterId: 0,
      userName: "",
      paymentConfirmationModal: true,
      Loader: false,
      paymentError: "Error Code 5050 : Card type is not supported",
      paymentErrorMessage: "Error Code 5050 : Card type is not supported",
      paysaferesponse:
        "Merchant Account is not configured to support this card type",
    });
  }

  /**
   *
   * authentication credentials are invalid error
   */

  showAuthErrorPaysafe() {
    this.setState({
      contentWriterId: 0,
      userName: "",
      paymentConfirmationModal: true,
      Loader: false,
      paymentError: "Error Code 5270 : Unauthorized access",
      paymentErrorMessage: "Error Code 5270 : Unauthorized access",
      paysaferesponse:
        "The credentials do not have permission to access the requested data",
    });
  }

  /**
   * get authorization error modal
   *
   */
  showgetAuthorizationerror() {
    this.setState({
      contentWriterId: 0,
      userName: "",
      paymentConfirmationModal: true,
      Loader: false,
      paymentError: "Error Code 5279 : Invalid credentials",
      paymentErrorMessage: "Error Code 5279 : Invalid credentials",
      paysaferesponse: "The authentication credentials are invalid.",
    });
  }

  subscribe(e) {
    e.preventDefault();
    let contentWriterId = this.state.contentWriterId;
    let subscriptionPrice = this.state.subscriptionPrice;
    //let subscriptionPrice = 1
    let subscriptionPlan = this.state.subscriptionPlan;
    var token = this.props.getUserData.usersData.PaysafeToken;
    let firstSixDigit = this.props.getUserData.usersData.FirstSixDigit;
    let merchantRefNum = this.props.getUserData.usersData.merchantRefnum;
    let cvv = this.state.cvv;

    let length = cvv.length;
    if (length == 0) {
      this.setState({
        cvvError: true,
        cvvErrorMessage: "CVV field is required",
      });
    } else if (length < 3 || length > 4) {
      this.setState({
        cvvError: true,
        cvvErrorMessage:
          "CVV length must be greater than 2 character and less than 4 character",
      });
    }
    if (length == 3 || length == 4) {
      window.state = this.state;
      window.props = this.props;
      window.this = this;
      //if login
      if (this.state.login_user_id !== "" && this.state.login_user_id !== 0) {
        this.setState({
          subscribeOrDesiredLoader: true,
          subscribeConfirmationModal: false,
          loginWishListAlert: false,
        });
        if (this.state.cardDetails === 1) {
          this.setState({
            contentWriterId: contentWriterId,
          });
          let paymentData = {
            amount: subscriptionPrice,
            paysafe_token: this.state.PaysafeToken,
          };
          let subscriptionPlan = 1;
          window.paysafe.threedsecure.start(
            API_KEY,
            {
              environment: ENVIRONMENT,
              accountId: ACCOUNT_NUMBER,
              card: {
                cardBin: firstSixDigit,
              },
            },
            function (deviceFingerprintingId, error) {
              if (error) {
                window.this.openModal();
              } else {
                var data = {
                  amount: subscriptionPrice * 100,
                  currency: CURRENCY,
                  currencyCode: CURRENCY,
                  merchantRefNum: merchantRefNum,
                  merchantUrl: MERCHANT_URL,
                  card: { paymentToken: token },
                  deviceChannel: DEVICE_CHANNEL,
                  messageCategory: MESSAGE_CATEGORY,
                  authenticationPurpose: AUTHENTICATION_PURPOSE,
                  deviceFingerprintingId: deviceFingerprintingId,
                };
                axios
                  .post(
                    BASE_URL + "/authorization",
                    { data },
                    {
                      headers: authHeader(),
                    }
                  )
                  .then((response) => {
                    if (response.data.status != "COMPLETED") {
                      var responsePayload = response.data.sdkChallengePayload;

                      window.paysafe.threedsecure.challenge(
                        API_KEY,
                        {
                          environment: ENVIRONMENT,
                          sdkChallengePayload: responsePayload,
                        },
                        function (authenticationID, error) {
                          if (error) {
                          } else {
                            var data = {
                              authenticationID: authenticationID,
                            };
                            axios
                              .post(
                                BASE_URL + "/getauthorization",
                                { data },
                                {
                                  headers: authHeader(),
                                }
                              )
                              .then((getAuthorization) => {
                                /*if(getAuthorization.error!=''){
                                                                alert('heeeeeeee')
                                                                window.this.showgetAuthorizationerror()
                                                            } else{*/
                                var paymentData = {
                                  amount: subscriptionPrice,
                                  paysafe_token: token,
                                  cvv: cvv,
                                  data: getAuthorization,
                                };
                                window.props.followContentAction(
                                  window.state.login_user_id,
                                  contentWriterId,
                                  subscriptionPlan,
                                  paymentData
                                );

                                //}
                              });
                          }
                        }
                      );
                    } else {
                      //return false
                      var data = {
                        authenticationID: response.data.id,
                      };
                      axios
                        .post(
                          BASE_URL + "/getauthorization",
                          { data },
                          {
                            headers: authHeader(),
                          }
                        )
                        .then((getAuthorization) => {
                          var paymentData = {
                            amount: subscriptionPrice,
                            paysafe_token: token,
                            cvv: cvv,
                            data: getAuthorization,
                          };
                          window.props.followContentAction(
                            window.state.login_user_id,
                            contentWriterId,
                            subscriptionPlan,
                            paymentData
                          );
                        });
                    }
                  });
              }
            }
          );
        }
      }
    }
  }

  addToWishList(e, contentWriterId) {
    e.preventDefault();
    localStorage.removeItem("wishListClick");
    //if login
    if (this.state.login_user_id !== "" && this.state.login_user_id !== 0) {
      this.setState({
        subscribeOrDesiredLoader: true,
        contentWriterId: contentWriterId,
        paymentConfirmationModal: false,
      });
      this.props.addToWishListAction(this.state.login_user_id, contentWriterId);
    }
    //if not login
    else {
      this.setState({
        loginWishListAlert: true,
      });
    }
  }

  // setRef = (ref) => {
  //   inputRefs.push(ref);
  // };

  changeImage(mediaId, id, media, index) {
    let sI = this.state.selectedIndex.filter(function (sI) {
      return sI.id !== id;
    });

    sI.push({ id: id, index: index });
    this.setState(
      {
        selectedImageId: this.state.selectedImageId.filter(function (media) {
          return media.split(":")[0] !== id.toString();
        }),
      },
      () => {
        this.setState({
          selectedImageId: [...this.state.selectedImageId, mediaId],
          selectedIndex: sI,
        });
      }
    );

    let imageObj = { id: id, url: media.original, type: media.type };
    let updateImages = [];
    let flag = 0;
    if (this.state.postFiles.length > 0) {
      updateImages = this.state.postFiles.map(function (obj) {
        // return obj.id === imageObj.id ? imageObj :  obj;
        if (obj.id === imageObj.id) {
          flag = 1;
          return imageObj;
        } else {
          return obj;
        }
      });

      if (flag === 0) {
        this.setState({
          postFiles: updateImages.concat(imageObj),
        });
      } else {
        this.setState({
          postFiles: updateImages,
        });
      }
    } else {
      this.setState({
        postFiles: [...this.state.postFiles, imageObj],
      });
    }
  }

  removeFromDesiredList(e, userId) {
    e.preventDefault();

    // this.state.wishList.splice(userId,1)
    const index = this.state.wishList.indexOf(userId);
    if (index > -1) {
      this.state.wishList.splice(index, 1);
    }
    this.setState({
      // Loader : true,
      wishList: this.state.wishList,
    });
    this.props.removeFromWishListAction(this.state.login_user_id, userId);
  }

  //edit delete box open on click
  settingOption(e, postId) {
    e.preventDefault();

    let settingOptionShow = true;
    if (postId === this.state.postIdForsettingOption) {
      settingOptionShow = false;
    }
    this.setState({
      settingOptionShow: settingOptionShow,
      postIdForsettingOption: parseInt(postId),
    });
  }

  deletePost(e, postId, postIndex) {
    e.preventDefault();
    this.setState({
      Loader: true,
    });
    this.props.deletePostAction(postId);
    this.setState({
      postIndex: postIndex,
      settingOptionShow: false,
    });
  }

  updatePost(e, postIndex) {
    e.preventDefault();
    this.setState({
      editPostModalShow: true,
      singlePostData: this.state.postData[postIndex],
      editPostIndex: postIndex,
      updateFlag: 0,
      PhotoorVideo: this.state.postData[postIndex].media,
      existing_images: this.state.postData[postIndex].media,
      imageView: this.state.postData[postIndex].media,
    });
  }

  showReadyUpload(addedfiles) {
    Array.from(addedfiles).forEach((file) => {
      let reader = new FileReader();
      reader.onload = (e) =>
        this.setState((prevState) => ({
          imageView: [
            ...prevState.imageView,
            {
              url: reader.result,
              extension: getFileExtension(file),
              fileName: file.name,
            },
          ],
        }));
      reader.readAsDataURL(file);
    });
  }

  uniqnewFiles(arr) {
    const result = arr.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.name === thing.name)
    );
    this.showReadyUpload(result);
  }

  imageUpload(e) {
    e.preventDefault();
    let files = e.target.files;

    const { PhotoorVideo } = this.state;

    const allNames = PhotoorVideo.map((file) => file.name);

    if (PhotoorVideo.length + files.length > 50) {
      return; // TODO::error to display for max length
    }
    
    const updatedFilesArray = Array.from(files).filter(
      (file) => !allNames.includes(file.name)
    );
    const updatedFiles = [];
    Array.from(updatedFilesArray).forEach((file) => {
      if (supportedImageExtension.includes(getFileExtension(file)) || supportedVideoExtension.includes(getFileExtension(file))) {
        updatedFiles.push(file)
      }
    });

    this.setState(
      (prevState) => ({
        PhotoorVideo: [...prevState.PhotoorVideo, ...updatedFiles],
        addedFiles: [...prevState.addedFiles, ...updatedFiles],
      }),
      () => {
        this.uniqnewFiles(updatedFiles);
      }
    );
    return;
    // }

    // const isVideoMedia = Array.from(files).every((file) =>
    //   supportedVideoExtension.includes(getFileExtension(file))
    // );
    // console.log(isVideoMedia, "isVideoMedia");

    // if (files.length && isVideoMedia) {
    // //   if (files.length > 1) {
    // //     return; // TODO::error to display for max length
    // //   }

    // //   if (PhotoorVideo.length && !isUploadedFilesHasVideo) {
    // //
    // //     return; // error
    // //   }

    // const video = files[0];

    // //   if (allNames.includes(video.name)) {
    // //     return;
    // //   }

    // //   console.log(video, "video");

    //  // this.setState({ PhotoorVideo: [video] });

    //   let reader = new FileReader();
    //   reader.onload = (e) =>
    //     this.setState({
    //       imageView: [
    //         {
    //           url: reader.result,
    //           extension: getFileExtension(video),
    //           fileName: video.name,
    //         },
    //       ],
    //     });
    //   reader.readAsDataURL(video);
    // }
  }

  handleCheckClick(e) {
    this.setState((prevState) => ({
      //   is_public: !prevState.is_public

      singlePostData: {
        ...prevState.singlePostData,
        is_public: !prevState.singlePostData.is_public,
      },
    }));
    // }));
  }

  postInputChange(e) {
    this.state.singlePostData = {
      ...this.state.singlePostData,
      [e.target.name]: e.target.value,
    };
  }

  scheduleDateTimeChange(e) {
    this.state.singlePostData = {
      ...this.state.singlePostData,
      schedule_at: e._d,
    };
  }

  bodyClick(e) {
    //except svg and path click if click anywhere close the settingBox
    if (
      e.target.id === "settingBoxSVG" ||
      e.target.id === "settingBoxPath" ||
      e.target.classList.contains("vjs-icon-placeholder")
    ) {
    } else {
      this.setState({
        settingOptionShow: false,
        postIdForsettingOption: "",
      });
    }
  }

  editModalClose() {
    this.setState({
      editPostModalShow: false,
      singlePostData: [],
      settingOptionShow: false,
      postIdForsettingOption: "",
      editPostIndex: "",
      PhotoorVideo: [],
      updateFlag: 0,
      Loader: false,
    });
  }

  editModalOpen() {
    useState(false);
  }

  postButtonClick(buttonValue) {
    this.setState({
      Publish: buttonValue,
    });
  }

  postSubmit(e) {
    e.preventDefault();

    if (this.state.singlePostData.title.length === 0 && !this.state.imageView[0]) {
      this.setState((prevState) => ({
        isPostError: true,
        postErrors: {
          ...prevState.postErrors,
          Title: "This field is required",
          PhotoorVideo: "This field is required",
        },
      }));
      return;
    } else if (this.state.singlePostData.title.length === 0) {
      this.setState((prevState) => ({
        isPostError: true,
        postErrors: {
          ...prevState.postErrors,
          Title: "This field is required",
          PhotoorVideo: null,
        },
      }));
      return;
    } else if (!this.state.imageView[0]) {
      this.setState((prevState) => ({
        isPostError: true,
        postErrors: {
          ...prevState.postErrors,
          Title: null,
          PhotoorVideo: "This field is required",
        },
      }));
      return;
    }

    this.setState({
      postSubmitLoader: true,
    });
    let date = "";
    if (this.state.singlePostData.schedule_at !== "") {
      // let dateTime = new Date(this.state.singlePostData.schedule_at)
      date = new Date(this.state.singlePostData.schedule_at);
      let day = date.getDate();
      if (day <= 9) {
        day = "0" + day;
      }
      let month = date.getMonth() + 1;
      if (month <= 9) {
        month = "0" + month;
      }
      let hours = date.getHours();
      if (hours <= 9) {
        hours = "0" + hours;
      }
      let minutes = date.getMinutes();
      if (minutes <= 9) {
        minutes = "0" + minutes;
      }
      date = day + "-" + month + "-" + date.getFullYear() + " " + hours + ":" + minutes;


      let postData = new FormData();
      this.state.PhotoorVideo.forEach((file) => {
        postData.append("PhotoorVideo[]", file);
      });

      postData.append(
        "existing_images",
        this.state.existing_images.map((image) => image.original)
      );
      postData.append("Title", this.state.singlePostData.title);
      postData.append("Caption", this.state.singlePostData.caption);
      postData.append("Tags", this.state.singlePostData.tags);
      postData.append("ChooseAlbum", this.state.singlePostData.add_to_album);
      postData.append("Publish", this.state.Publish);
      postData.append("ScheduleDateTime", date);
      postData.append("is_public", this.state.singlePostData.is_public);

      this.props.updatePostAction(this.state.singlePostData.id, postData);
    }
  }

  captionReadMore(e, postId) {
    e.preventDefault();
    this.setState({
      // showLessCaption : false,
      captionClickPostId: postId,
    });
  }

  removeImage = (selectedImage) => {
    if (selectedImage && selectedImage.fileName && selectedImage.url) {
      this.setState((prevState) => ({
        PhotoorVideo: prevState.PhotoorVideo.filter(
          (media) => media.name !== selectedImage.fileName
        ),
        imageView: prevState.imageView.filter(
          (media) => media.fileName !== selectedImage.fileName
        ),
        addedFiles: prevState.addedFiles.filter(
          (media) => media.filename !== selectedImage.fileName
        ),
      }));
    } else {
      this.setState((prevState) => ({
        PhotoorVideo: prevState.PhotoorVideo.filter(
          (media) => media.thumbnail !== selectedImage.thumbnail
        ),

        imageView: prevState.imageView.filter(
          (media) => media.thumbnail !== selectedImage.thumbnail
        ),

        existing_images: prevState.existing_images.filter(
          (media) => media.thumbnail !== selectedImage.thumbnail
        ),
      }));
    }
    return;
  };

  captionReadLess(e) {
    e.preventDefault();
    this.setState({
      // showLessCaption : true,
      captionClickPostId: 0,
    });
  }

  wishListSubscribeHandleClose() {
    this.setState({
      loginWishListAlert: false,
    });
  }

  wishListSubscribeshow() {
    useState(false);
  }

  getSettings(data_length) {
    return {
      infinite: data_length >= 4 ? true : false,
      speed: 500,
      slidesToShow: 4,
      ref: (slider) => (this.slider1 = slider),
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            infinite: data_length >= 3 ? true : false,
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            infinite: data_length >= 3 ? true : false,
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            infinite: data_length >= 2 ? true : false,
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };
  }

  render() {
    const {
      postData,
      postSubmitLoader,
      Loader,
      totalPostCount,
      popularProfiles,
      userInfo,
      showLess,
      recentPostData,
      login_user_id,
      commentModalShow,
      singlePostData,
      singlePostDataIndex,
      Comment,
      isError,
      editPostModalShow,
      settingOptionShow,
      postIdForsettingOption,
      totalPostImageCount,
      totalPostVideoCount,
      PhotoorVideo,
      isPostError,
      postErrors,
      Albums,
      loginWishListAlert,
      paymentConfirmationModal,
      paymentError,
      userId,
      followerList,
      wishList,
      subscribeOrDesiredLoader,
      postButtonShow,
      paymentErrorMessage,
      paysaferesponse,
      loadMoreButton,
      subscribeConfirmationModal,
      unsubscribeConfirmationModal,
      unfollowModalOpen,
      unfollowConfirmationModalClose,
      unfollowConfirmationModal,
      captionClickPostId,
      commentPostId,
      id_verified,
      imageView,
      cvv,
      cvvError,
      cvvErrorMessage,
    } = this.state;

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    let postCreatedDate = "";
    let extension = "";
    let media = "";
    var today = new Date();
    let scheduleDefaultDateTime = "";
    if (Object.keys(singlePostData).length > 0 && singlePostData.created) {
      if (!isNaN(singlePostData.created)) {
        today.setHours(today.getHours() - singlePostData.created);
      } else if (isNaN(singlePostData.created)) {
        postCreatedDate = singlePostData.created;
      }

      if (
        !isNaN(singlePostData.created) ||
        (isNaN(singlePostData.created) &&
          singlePostData.created.includes("min"))
      ) {
        // let getPostCreatedDate = today.toLocaleString();
        let getPostCreatedDate = new Date();
        postCreatedDate =
          getPostCreatedDate.getDate() +
          " " +
          monthNames[getPostCreatedDate.getMonth()] +
          " " +
          getPostCreatedDate.getFullYear();
      }

      //chat box div scroll to bottom
      var chatBoxDiv = document.getElementById("chat_box");
      if (chatBoxDiv !== null) {
        setTimeout(function () {
          chatBoxDiv.scrollTo(0, chatBoxDiv.scrollHeight);
        }, 100);
      }

      if (PhotoorVideo === [] && singlePostData.media) {
        extension = singlePostData.media[0].medium
          .split("?")
          .shift()
          .split(".")
          .pop();
        media = singlePostData.media;
      } else {
        // let filename = PhotoorVideo.name
        // extension = filename.substring(filename.lastIndexOf('.') + 1)
        // media = PhotoorVideo.name
      }

      if (singlePostData.schedule_at) {
        // let splitScheduleDateTime = singlePostData.schedule_at.split(' ');
        scheduleDefaultDateTime = new Date(singlePostData.schedule_at);
        // let date = scheduleDefaultDate.getDate()
        // if(date <= 9){
        //     date = "0"+date
        // }
        // scheduleDefaultDateTime = scheduleDefaultDate.getFullYear()+"-"+(scheduleDefaultDate.getMonth()+1)+"-"+date+"T"+splitScheduleDateTime[1]
      }
    }
    let bannerImage =
      process.env.PUBLIC_URL + "/frontend/images/blog-header.jpg";
    if (
      userInfo.banner &&
      userInfo.banner !== null &&
      userInfo.banner !== "" &&
      userInfo.banner !== "null"
    ) {
      bannerImage = userInfo.banner;
    }
    let profileImage = process.env.PUBLIC_URL + "/images/default-avatar.png";
    if (
      userInfo.profile &&
      userInfo.profile !== null &&
      userInfo.profile !== "" &&
      userInfo.profile !== "null"
    ) {
      profileImage = userInfo.profile;
    }

    // today = today.getFullYear()+"-"+(today.getMonth()+1)+"-"+today.getDate()
    // let minDate =today+"T00:00"
    let yesterday = today.setDate(today.getDate() - 1);
    const disablePastDt = (current) => {
      return current.isAfter(yesterday);
    };

    return (
      <>
        <MetaTags>
          <title>
            {userInfo.display_name ? userInfo.display_name : userInfo.username}{" "}
            | DesireMe
          </title>
          <meta name="title" content="__METATITLE__" />
          <meta name="description" content="__METADESCRIPTION__" />
          <meta property="og:description" content="__METADESCRIPTION__" />
          <meta property="og:title" content="__METATITLE__" />
          <meta property="og:url" content="__OGURL__" />
          <link rel="canonical" href="__OGURL__" />
        </MetaTags>
        <Header />
        {Loader && totalPostCount !== postData.length ? (
          <div id="preloader">
            <div className="preloader_div"></div>
          </div>
        ) : (
          ""
        )}

        {subscribeOrDesiredLoader ? (
          <div id="preloader">
            <div className="preloader_div"></div>
          </div>
        ) : (
          ""
        )}

        {this.state.modalShow ? (
          <ModalComponent
            errorMessage={this.state.successMessage}
            show={this.show}
            handleClose={this.closeUpdatemessagePopup}
          />
        ) : (
          ""
        )}

        {commentModalShow ? (
          <section id="profileonclick">
            <div>
              <Modal
                size="xl"
                show={this.show}
                onHide={this.handleClose}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                className="custom-modal-content"
              >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body style={{ width: "100%" }}>
                  <div className="container d-flex p-0">
                    <div
                      className="pic_view d-flex align-items-center justify-content-center bg-dark"
                      style={{ width: "65%" }}
                    >
                      {singlePostData.media[0] && (
                        <RenderModalSlider
                          media={singlePostData.media}
                          selectedSlide={this.state.selectedSlide}
                        />
                      )}
                    </div>

                    <div className="chat_box bg-white" style={{ width: "35%" }}>
                      <div className=" d-flex p-2 pl-3">
                        <div>
                          <img
                            src={isBlankNull(userInfo.profile) ? "/frontend/images/profile-image.png" : userInfo.profile }
                            className="icon_img" alt="img" />
                        </div>

                        <div className=" pl-3 d-flex flex-column">
                          <p className="font-weight-bold m-0">
                            {userInfo.display_name}
                          </p>

                          <small className="font-weight-thin sub-title">
                          {singlePostData.created && dateFilter(singlePostData.created)}
                          </small>
                        </div>
                      </div>

                      <hr />

                      <div className="icon_count d-flex">
                        <div className="pl-3 ">
                          {singlePostData.likedByMe === 0 ? (
                            <>
                              <Link
                                to="#"
                                onClick={(e) => {
                                  this.postLikeCountChanges(
                                    e,
                                    singlePostDataIndex
                                  );
                                }}
                                className={
                                  parseInt(login_user_id) !== 0
                                    ? ""
                                    : "remove-pointer"
                                }
                              >
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/frontend/images/like.png"
                                  }
                                  alt="img"
                                  className="red_like_img1"
                                />
                              </Link>
                              <Badge
                                variant="secondary"
                                className="bg-transparent text-body align-self-center"
                              >
                                {singlePostData.likes}
                              </Badge>
                            </>
                          ) : (
                            <>
                              <Link
                                to="#"
                                onClick={(e) => {
                                  this.postLikeCountChanges(
                                    e,
                                    singlePostDataIndex
                                  );
                                }}
                                className={
                                  parseInt(login_user_id) !== 0
                                    ? ""
                                    : "remove-pointer"
                                }
                              >
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/frontend/images/heart_likes.webp"
                                  }
                                  alt="img"
                                  className="red_like_img1"
                                />
                              </Link>
                              <Badge
                                variant="secondary"
                                className="bg-transparent text-body align-self-center"
                              >
                                {singlePostData.likes}
                              </Badge>
                            </>
                          )}
                        </div>

                        <div className="pl-1">
                          <span>
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-chat-square"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M14 1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h2.5a2 2 0 0 1 1.6.8L8 14.333 9.9 11.8a2 2 0 0 1 1.6-.8H14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                              />
                            </svg>
                          </span>
                          <Badge color="white">
                            {singlePostData.totalComments}
                          </Badge>
                        </div>
                      </div>
                      <hr />

                      <div className="chats_box" id="chat_box">
                        {singlePostData.commentUsers.map(
                          (parentComment, parentCommentKey) => {
                            return (
                              <>
                                <div className="recevied_chat d-flex mx-auto mt-2">
                                  <div className="pl-2  ">
                                    <img
                                      src={isBlankNull(parentComment.profile) ? process.env.PUBLIC_URL +
                                        "/images/default-avatar.png" : parentComment.profile}
                                      className="icon_img" alt="img" />
                                  </div>
                                  <div className=" pl-3 d-flex flex-column">
                                    <small className="font-weight-bold m-0">
                                      {parentComment.displayName}
                                    </small>
                                    <small>{parentComment.comment}</small>
                                    <small
                                      className="font-weight-thin"
                                      style={{ fontSize: 10 }}
                                    >
                                      {parentComment.commentDate}{" "}
                                      {parseInt(login_user_id) !== 0 && (
                                        <Link
                                          to="#"
                                          onClick={(e) =>
                                            this.replyToComment(
                                              e,
                                              parentCommentKey,
                                              singlePostData.id,
                                              parentComment.displayName
                                            )
                                          }
                                        >
                                          <span
                                            className="font-weight-bolder pl-3"
                                            style={{ color: "#00A99D" }}
                                          >
                                            {" "}
                                            Reply{" "}
                                          </span>
                                        </Link>
                                      )}
                                    </small>
                                  </div>
                                  <div className="ml-auto d-flex ">
                                    {parentComment.likeByMe === 0 ? (
                                      <>
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            this.commentLikeCountChanges(
                                              e,
                                              parentCommentKey
                                            );
                                          }}
                                        >
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/frontend/images/like.png"
                                            }
                                            alt="img"
                                            className="red_like_img1"
                                          />
                                        </Link>
                                        <Badge
                                          variant="secondary"
                                          className="bg-transparent text-body align-self-center"
                                        >
                                          {parentComment.likes}
                                        </Badge>
                                      </>
                                    ) : (
                                      <>
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            this.commentLikeCountChanges(
                                              e,
                                              parentCommentKey
                                            );
                                          }}
                                        >
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/frontend/images/heart_likes.webp"
                                            }
                                            alt="img"
                                            className="red_like_img"
                                          />
                                        </Link>
                                        <Badge
                                          variant="secondary"
                                          className="bg-transparent text-body align-self-center"
                                        >
                                          {parentComment.likes}
                                        </Badge>
                                      </>
                                    )}
                                  </div>
                                </div>

                                {parentComment.commentUsers.map(
                                  (subComment, subCommentKey) => (
                                    <div className="sent_chat d-flex mt-2">
                                      <div className="pl-1  ">
                                        <img
                                          src={isBlankNull(subComment.profile) ? "/frontend/images/username.png" : subComment.profile}
                                          className="icon_img" alt="img" />
                                      </div>
                                      <div className=" pl-3 d-flex flex-column">
                                        <small className="font-weight-bold m-0">
                                          {subComment.displayName}
                                        </small>
                                        <small>{subComment.comment}</small>
                                      </div>
                                    </div>
                                  )
                                )}
                              </>
                            );
                          }
                        )}
                      </div>
                      {singlePostData.totalComments == 0 ? <hr /> : ""}

                      {parseInt(login_user_id) !== 0 && (
                        <div className="input_box">
                          <form
                            onSubmit={this.commentSubmit}
                            id={singlePostDataIndex}
                          >
                            <div className="form-group d-flex justify-content-between">
                              <textarea
                                for="commnet"
                                name="Comment"
                                className="borderd-0 w-100"
                                placeholder="Type Something"
                                onChange={(e) => {
                                  this.inputChange(e, singlePostData.id);
                                }}
                                value={
                                  commentPostId === singlePostData.id
                                    ? Comment
                                    : ""
                                }
                              />
                              {Comment !== "" && postButtonShow === 0 ? (
                                <button className="btn pt-0 pb-0" type="submit">
                                  post
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </section>
        ) : (
          ""
        )}

        {id_verified === 1 ||
        parseInt(userId) === parseInt(login_user_id) ||
        id_verified === 3 ? (
          <section id="follow_page">
            <div className="container follow_page_container pt-4">
              <div
                className="follow_page_box row"
                style={{
                  backgroundImage: "url(" + bannerImage + ")",
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                {userInfo.superStar === 1 ? (
                  <div className="superstar_box">
                    <p className="font-weight-bold">
                      <span className="superstar_text">
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL +
                            "/frontend/images/superstar.png"
                          }
                        />
                      </span>
                      SuperStar
                    </p>
                  </div>
                ) : userInfo.risingStar === 1 ? (
                  <div className="superstar_box">
                    <p className="font-weight-bold">
                      <span className="superstar_text">
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL +
                            "/frontend/images/rising star.png"
                          }
                        />
                      </span>
                      Rising stars
                    </p>
                  </div>
                ) : (
                  ""
                )}

                {login_user_id !== parseInt(userId) ? (
                  !followerList.includes(parseInt(userId)) &&
                  !wishList.includes(parseInt(userId)) ? (
                    <div className="add_list_btn">
                      <button
                        className="btn"
                        onClick={(e) => {
                          this.addToWishList(e, userId);
                        }}
                      >
                        Add To My Desired List
                      </button>
                    </div>
                  ) : !followerList.includes(parseInt(userId)) &&
                    wishList.includes(parseInt(userId)) ? (
                    <div className="add_list_btn">
                      <button
                        className="btn"
                        onClick={(e) => {
                          this.removeFromDesiredList(e, userId);
                        }}
                      >
                        Undesire
                      </button>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                <div
                  className="profile_img_box"
                  style={{
                    backgroundImage: "url(" + profileImage + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>

                <div className="user_info_box font-weight-bold d-flex justify-content-between align-items-center">
                  <div className="user_name_box pt-1">
                    <h1 className="font-weight-bold">
                      {userInfo.display_name
                        ? userInfo.display_name
                        : userInfo.username}
                    </h1>
                  </div>
                  <div className="user_location">
                    {userInfo.username ? (
                      <div className="user_location_info">
                        <span>
                          <img
                            alt=""
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/frontend/images/username.png"
                            }
                          />
                        </span>
                        <Link to="#" style={{ cursor: "default" }}>
                          {" "}
                          {"@" + userInfo.username}
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}

                    {userInfo.state !== null || userInfo.country !== null ? (
                      <div className="user_location_info">
                        <span className="user_location-map-icon">
                          <img
                            alt=""
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/frontend/images/location.png"
                            }
                          />
                        </span>
                        <Link to="#">
                          {" "}
                          {(userInfo.location !== null && userInfo.location !== "" &&
                            userInfo.location !== 'undefined' &&
                            userInfo.location !== "null") ?
                              userInfo.location
                              :
                              (userInfo.state &&
                              userInfo.state !== "" &&
                              userInfo.state !== null &&
                              userInfo.state !== "null"
                                  ? userInfo.state + ","
                                  : "") +
                              " " +
                              (userInfo.country &&
                              userInfo.country !== "" &&
                              userInfo.country !== null &&
                              userInfo.country !== "null"
                                  ? userInfo.country
                                  : "")
                          }
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="user_video_icon bg-white ">
                    <div className="user_icons p-2 text-center">
                      <Link
                        to="#"
                        className="pr-4"
                        style={{ cursor: "default" }}
                      >
                        <span>
                          {/*<img alt="" src={  process.env.PUBLIC_URL + "/frontend/images/images.png" } />*/}
                          <svg
                            id="Group_132"
                            data-name="Group 132"
                            xmlns="http://www.w3.org/2000/svg"
                            width="22.524"
                            height="16.088"
                            viewBox="0 0 22.524 16.088"
                          >
                            <path
                              id="Path_16"
                              data-name="Path 16"
                              d="M130.621,365.929h-17.7a2.413,2.413,0,0,0-2.413,2.413V379.6a2.413,2.413,0,0,0,2.413,2.413h17.7a2.413,2.413,0,0,0,2.413-2.413V368.342A2.413,2.413,0,0,0,130.621,365.929Zm-17.7,1.609h17.7a.8.8,0,0,1,.8.8v6.907l-5.062-5.062a.8.8,0,0,0-1.138,0l-6.671,6.671-2.649-2.649a.8.8,0,0,0-1.137,0l-2.649,2.649v-8.516A.8.8,0,0,1,112.924,367.538Z"
                              transform="translate(-110.511 -365.929)"
                              fill="#1c1c1c"
                            />
                            <ellipse
                              id="Ellipse_1"
                              data-name="Ellipse 1"
                              cx="2.413"
                              cy="2.413"
                              rx="2.413"
                              ry="2.413"
                              transform="translate(4.827 3.218)"
                              fill="#1c1c1c"
                            />
                          </svg>
                        </span>{" "}
                        {totalPostImageCount}
                      </Link>

                      <Link to="#" style={{ cursor: "default" }}>
                        <span>
                          {/*
                                                        <img alt="" src={ process.env.PUBLIC_URL + "/frontend/images/videos.png" } />
                                                        */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23.655"
                            height="15.206"
                            viewBox="0 0 23.655 15.206"
                          >
                            <path
                              id="Path_2608"
                              data-name="Path 2608"
                              d="M40.321,367.744a.845.845,0,0,0-.822-.037l-5.536,2.768v-2.012a2.534,2.534,0,0,0-2.534-2.534H19.6a2.534,2.534,0,0,0-2.534,2.534V378.6a2.534,2.534,0,0,0,2.534,2.535H31.429a2.534,2.534,0,0,0,2.534-2.535v-2.012l5.536,2.773a.845.845,0,0,0,1.223-.76V368.463A.846.846,0,0,0,40.321,367.744Zm-14.806,9.167a3.379,3.379,0,1,1,3.379-3.379A3.379,3.379,0,0,1,25.515,376.911Z"
                              transform="translate(-17.067 -365.929)"
                              fill="#1c1c1c"
                            />
                          </svg>
                        </span>{" "}
                        {totalPostVideoCount}
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="social_links">
                  <ul>
                    {/*
                                            <li>
                                                <Link to="/">
                                                    <img
                                                      src={
                                                        process.env.PUBLIC_URL +
                                                        "/frontend/images/facebook-black.png"
                                                      }
                                                      alt="facebook"
                                                      className=""
                                                    />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/">
                                                    <img
                                                      src={
                                                        process.env.PUBLIC_URL +
                                                        "/frontend/images/instagram-black.png"
                                                      }
                                                      alt="instagram"
                                                      className=""
                                                    />
                                                </Link>
                                            </li>
                                        */}
                    {userInfo.twitter_url !== null &&
                    userInfo.twitter_url !== undefined ? (
                      <li>
                        <a href={userInfo.twitter_url} target="_blank">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/frontend/images/twitter-black.png"
                            }
                            alt="twitter"
                            className=""
                          />
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {userInfo.amazon_url !== null &&
                    userInfo.amazon_url !== undefined ? (
                      <li>
                        <a
                          href={
                            userInfo.amazon_url !== null
                              ? userInfo.amazon_url
                              : "#"
                          }
                          target="_blank"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/frontend/images/amazon.png"
                            }
                            alt="amazon"
                            className=""
                          />
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>

              <div className="row d-flex follow_done_row">
                <div className="description_box w-50 bg-white">
                  <h5>Description</h5>
                  <span className="d-flex flex-wrap">
                    {userInfo.bio && userInfo.bio !== "null" ? (
                      showLess ? (
                        <>
                          <p>
                            {userInfo.bio.split(" ").length > 25
                              ? userInfo.bio.split(" ").slice(0, 25).join(" ") +
                                " ...."
                              : userInfo.bio}
                          </p>
                          {userInfo.bio.split(" ").length > 25 ? (
                            <Link
                              to="#"
                              style={{ paddingLeft: "10px" }}
                              onClick={this.descriptionReadMore}
                            >
                              <small style={{ color: "#cecece" }}>
                                Read More
                              </small>
                            </Link>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <>
                          <p>{userInfo.bio}</p>
                          <Link
                            to="#"
                            style={{ paddingLeft: "10px" }}
                            onClick={this.descriptionReadLess}
                          >
                            <small style={{ color: "#cecece" }}>
                              Read Less
                            </small>
                          </Link>
                        </>
                      )
                    ) : (
                      <p>Description Not Added</p>
                    )}
                  </span>
                </div>
                {login_user_id !== parseInt(userId) ? (
                  !followerList.includes(parseInt(userId)) ? (
                    <div className="follow_done_box w-50 p-4 d-flex justify-content-between">
                      <p className="font-weight-bold pl-4 ">
                        Become a Desirer,
                        <br />
                        follow{" "}
                        {userInfo.display_name
                          ? userInfo.display_name
                          : ""}{" "}
                        today!
                      </p>
                      <div className="btns_group d-flex flex-column">
                        <button
                          className="btn btn1 mb-2"
                          onClick={(e) => {
                            this.subscribeConfirmationModal(
                              e,
                              userId,
                              userInfo.SubscriptionPriceWithVAT,
                              1,
                              userInfo.display_name
                            );
                          }}
                        >
                          Start Following £{userInfo.subscription_price}/month
                        </button>
                        {/*<button className="btn btn2 mb-2" onClick={(e)=>{this.subscribeConfirmationModal(e,userId,userInfo.subscription_price,2)}}>3 months for 10% off</button>
                                                        <button className="btn btn2" onClick={(e)=>{this.subscribeConfirmationModal(e,userId,userInfo.subscription_price,3)}}>6 months for 20% off</button>
*/}
                      </div>
                    </div>
                  ) : (
                    <div className="follow_done_box w-50 p-5 d-flex flex-column flex-lg-row">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/frontend/images/you are following.png"
                        }
                        alt="follow"
                        className="pr-3"
                      />
                      <span>
                        <p> You are Following</p>
                        <h5>
                          {userInfo.display_name ? userInfo.display_name : ""}
                        </h5>
                      </span>
                      <button
                        className="btn unfollowbtn"
                        onClick={(e) => {
                          this.unsubscribeConfirmationModal(
                            e,
                            userId,
                            userInfo.display_name
                          );
                        }}
                      >
                        Unfollow
                      </button>
                    </div>
                  )
                ) : (
                  <div className="follow_done_box w-50 p-5 d-flex">
                    <h5>
                      {userInfo.display_name ? userInfo.display_name : ""}
                    </h5>
                  </div>
                )}
              </div>

              <div className="row follow_activity d-flex ">
                <div className="recent_box">
                  <div className="p-4">
                    <h6 className="font-weight-bold">
                      {" "}
                      <span>
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL +
                            "/frontend/images/recently uploaded.png"
                          }
                          className="pr-3"
                        />
                      </span>
                      Recently Uploaded
                    </h6>
                    <hr className="follow_hr" />

                    <div className="recent_photos wrapper ">
                      {recentPostData.map((data, key) => {
                        if (
                          followerList.includes(parseInt(userId)) ||
                          parseInt(login_user_id) ===
                            parseInt(userInfo.userId) ||
                          data.is_public
                        ) {
                          if (data.media[0] && data.media[0].type === "image") {
                            return (
                              <Link
                                className="mx-auto"
                                to="#"
                                onClick={(e) => {
                                  this.addComment(e, key, "recentPost");
                                }}
                              >
                                <img
                                  src={data.media[0].thumbnail}
                                  alt="img"
                                  className="p-2 img-fluid"
                                  key={key}
                                />
                              </Link>
                            );
                          } else if (
                            data.media[0] &&
                            data.media[0].type === "video"
                          ) {
                            return (
                              <Link
                                className="mx-auto"
                                to="#"
                                onClick={(e) => {
                                  this.addComment(e, key, "recentPost");
                                }}
                              >
                                <video
                                  src={data.media[0].original}
                                  width="110"
                                  height="110"
                                  key={key}
                                ></video>
                              </Link>
                            );
                          }
                        }
                      })}
                    </div>
                  </div>
                </div>
                {editPostModalShow ? (
                  <div>
                    <Modal
                      size="xl"
                      show={this.editModalOpen}
                      onHide={this.editModalClose}
                      dialogClassName="modal-90w"
                      aria-labelledby="example-custom-modal-styling-title"
                      className="custom-modal-content"
                    >
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body style={{ width: "100%" }}>
                        <div id="create_post_page">
                          <div className="container p-0">
                            <div className="ceate_post_content px-md-0 py-md-3 bg-white">
                              <div className="row pb-4">
                                <div className="createpost_heading ">
                                  <h4 className="font-weight-bold">
                                    <span className="pr-3">
                                      <PostIcon />
                                    </span>{" "}
                                    Update a post
                                  </h4>
                                </div>
                              </div>
                              {postSubmitLoader ? (
                                <div id="preloader">
                                  <div className="preloader_div"></div>
                                </div>
                              ) : (
                                ""
                              )}

                              <form onSubmit={this.postSubmit}>
                                <div className="row">
                                  <div className="col-lg-6 col-md-12 col-sm-12 createpost-left">
                                    <div className="form-group update_post">
                                      <label for="photo/video">
                                        {" "}
                                        Upload Photo or Video{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <div className=" pic_upload_box create_post_input">
                                        <input
                                          type="file"
                                          id=""
                                          multiple
                                          name="PhotoorVideo"
                                          onChange={this.imageUpload}
                                          accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.gif,.GIF,.webp,.mp4,.MP4, .mov, .MOV"
                                        />
                                        <Link to="#">
                                          <CameraIcon />
                                          <span>Upload Photo or Video</span>
                                        </Link>
                                      </div>
                                      <Slider
                                        {...this.getSettings(imageView.length)}
                                      >
                                        {imageView.length
                                          ? imageView.map((media) =>
                                              supportedImageExtension.includes(
                                                media.extension
                                              ) || media.type === "image" ? (
                                                <div>
                                                  <img
                                                    height={100}
                                                    className="update_post_image input_preview_img"
                                                    style={{
                                                      borderRadius: "10px",
                                                      width: "100%",
                                                    }}
                                                    src={
                                                      media.url ||
                                                      media.thumbnail
                                                    }
                                                    alt="img-icon"
                                                  />
                                                  <span
                                                    className="position-absolute cursor-pointer"
                                                    style={{
                                                      top: "5px",
                                                      right: "10px",
                                                      cursor: "pointer",
                                                      zIndex: 9999,
                                                    }}
                                                    onClick={() =>
                                                      this.removeImage(media)
                                                    }
                                                  >
                                                    {" "}
                                                    x{" "}
                                                  </span>
                                                </div>
                                              ) : supportedVideoExtension.includes(
                                                  media.extension
                                                ) || media.type === "video" ? (
                                                <div className="update_post_video">
                                                  <video
                                                    src={
                                                      media.url ||
                                                      media.original
                                                    }
                                                    id={media.url}
                                                    className="video_preview"
                                                    style={{ width: 100 }}
                                                    controls
                                                  />
                                                  <span
                                                    className="position-absolute cursor-pointer"
                                                    style={{
                                                      top: "5px",
                                                      right: "10px",
                                                      cursor: "pointer",
                                                      zIndex: "9999",
                                                    }}
                                                    onClick={() =>
                                                      this.removeImage(media)
                                                    }
                                                  >
                                                    {" "}
                                                    x{" "}
                                                  </span>
                                                </div>
                                              ) : null
                                            )
                                          : null}
                                      </Slider>
                                      <div className="d-flex mt-3 justify-content-between createpost_para">
                                        <div
                                          className=" pic_upload_box d-flex justify-content-between align-items-center p-1"
                                          style={{ width: "53%" }}
                                        >
                                          <div>
                                            <ImageIcon />
                                          </div>
                                          <div className="para">
                                            {" "}
                                            High Resolution Image
                                            <br />
                                            PNG, JPG, GIF+
                                            <br /> Up to 6000px
                                          </div>
                                        </div>

                                        <div
                                          className="pic_upload_box  d-flex justify-content-between  align-items-center p-1"
                                          style={{ width: "45%" }}
                                        >
                                          <div>
                                            <ClapperIcon />
                                          </div>

                                          <div className="para">
                                            {" "}
                                            Videos
                                            <br />
                                            MP4, MOV
                                            <br />
                                            Up to 40 minutes
                                          </div>
                                        </div>
                                      </div>

                                      {isPostError &&
                                      postErrors.PhotoorVideo ? (
                                        <div
                                          id="alert-box2"
                                          className="font-weight-bold mt-1"
                                        >
                                          <span className="pr-2">
                                            <svg
                                              width="1em"
                                              height="1em"
                                              viewBox="0 0 16 16"
                                              className="bi bi-exclamation-circle"
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                              />
                                              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                            </svg>
                                          </span>
                                          {postErrors.PhotoorVideo}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-12 col-sm-12 createpost-right">
                                    <div className="form-group">
                                      <label for="title" className="label_text">
                                        Title{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control  input_text"
                                        id=""
                                        placeholder="Enter your Title"
                                        name="title"
                                        defaultValue={singlePostData.title}
                                        onChange={this.postInputChange}
                                      />
                                      {isPostError && postErrors.Title ? (
                                        <div
                                          id="alert-box2"
                                          className="font-weight-bold mt-1"
                                        >
                                          <span className="pr-2">
                                            <svg
                                              width="1em"
                                              height="1em"
                                              viewBox="0 0 16 16"
                                              className="bi bi-exclamation-circle"
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                              />
                                              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                            </svg>
                                          </span>
                                          {postErrors.Title}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="custom-control custom-checkbox form-group">
                                      <input
                                        type="checkbox"
                                        id="showMedia"
                                        className="custom-control-input   input_checkbox"
                                        name="showMedia"
                                        value={singlePostData.is_public}
                                        checked={singlePostData.is_public}
                                        onChange={this.handleCheckClick}
                                      />
                                      <label
                                        htmlFor="showMedia"
                                        className="custom-control-label label_text"
                                        style={{ fontSize: 13 }}
                                      >
                                        Show this posts media to non-subscribers
                                        (free content)
                                      </label>
                                    </div>
                                    <div className="form-group"></div>
                                    <div className="form-group">
                                      <label
                                        for="caption"
                                        className="label_text"
                                      >
                                        Caption{" "}
                                      </label>
                                      <textarea
                                        className="form-control"
                                        id="caption_textarea"
                                        placeholder="Enter your Text Here"
                                        name="caption"
                                        onChange={this.postInputChange}
                                        maxlength="200"
                                      >
                                        {singlePostData.caption == null ||
                                        singlePostData.caption == "null"
                                          ? ""
                                          : singlePostData.caption}
                                      </textarea>
                                    </div>
                                    <div className="form-group">
                                      <label for="tags" className="label_text">
                                        Enter Tags separated by commas,{" "}
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control  input_text"
                                        id=""
                                        placeholder="EX: picture, video, girl,"
                                        name="tags"
                                        defaultValue={
                                          singlePostData.tags == null ||
                                          singlePostData.tags == "null" ||
                                          singlePostData.tags == "undefined" ||
                                          singlePostData.tags == undefined
                                            ? ""
                                            : singlePostData.tags
                                        }
                                        onChange={this.postInputChange}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label for="album" className="label_text">
                                        Choose Album{" "}
                                      </label>
                                      <div className="d-flex justify-content-between">
                                        <select
                                          className="form-control   select_box"
                                          id=""
                                          style={{ width: "53% " }}
                                          defaultValue={
                                            singlePostData.add_to_album
                                          }
                                          name="add_to_album"
                                          onChange={this.postInputChange}
                                        >
                                          <option selected disabled value="0">
                                            Choose Your Album
                                          </option>
                                          {Albums.map((album, key) => {
                                            return (
                                              <option
                                                value={album.id}
                                                key={key}
                                              >
                                                {album.name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <label for="tags" className="label_text">
                                        Schedule DateTime
                                      </label>
                                      {/*
                                                                                <input type="text" className="form-control input_text" id="datetimepicker" name="schedule_at" defaultValue={singlePostData.schedule_at} onChange={this.postInputChange} min={minDate}/>
                                                                                */}

                                      <Datetime
                                        className="form-control input_text date_time"
                                        onChange={(e) => {
                                          this.scheduleDateTimeChange(e);
                                        }}
                                        value={scheduleDefaultDateTime}
                                        dateFormat="DD-MM-YYYY"
                                        isValidDate={disablePastDt}
                                      />

                                      {isPostError &&
                                      postErrors.ScheduleDateTime ? (
                                        <div
                                          id="alert-box2"
                                          className="font-weight-bold mt-1"
                                        >
                                          <span className="pr-2">
                                            <svg
                                              width="1em"
                                              height="1em"
                                              viewBox="0 0 16 16"
                                              className="bi bi-exclamation-circle"
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                              />
                                              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                            </svg>
                                          </span>
                                          {postErrors.ScheduleDateTime}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="createpost-btns d-flex col-lg-12">
                                    {!Loader ? (
                                      <>
                                        <button
                                          className="btn btn3"
                                          type="submit"
                                          name="Publish"
                                          onClick={(e) => {
                                            this.postButtonClick("now");
                                          }}
                                        >
                                          Publish Now!
                                        </button>
                                        <button
                                          className="btn btn4 mt-sm-0"
                                          type="submit"
                                          name="Schedule"
                                          onClick={(e) => {
                                            this.postButtonClick("schedule");
                                          }}
                                        >
                                          Schedule Post
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <button
                                          className="btn btn3"
                                          type="submit"
                                          name="Publish"
                                          disabled="disabled"
                                        >
                                          Publish Now!
                                        </button>
                                        <button
                                          className="btn btn4 mt-3 mt-sm-0"
                                          type="submit"
                                          name="Schedule"
                                          disabled="disabled"
                                        >
                                          Schedule Post
                                        </button>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                ) : (
                  ""
                )}

                <div className="feed_box">
                  <div className="p-4">
                    <h6 className="font-weight-bold">
                      {" "}
                      <span>
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL +
                            "/frontend/images/feed-profile.png"
                          }
                          className="pr-3"
                        />
                      </span>
                      Feed
                    </h6>
                    <hr className="follow_hr" />
                  </div>
                  {totalPostCount > 0 ? (
                    postData.map((data, key) => (
                      <div className="feed_post_card " key={key}>
                        <div className="p-3">
                          <div className="row flex-nowrap">
                            <div className="pl-3">
                              <img
                                alt=""
                                className="icon_img"
                                src={
                                  userInfo.profile !== null &&
                                  userInfo.profile !== "null" &&
                                  userInfo.profile !== ""
                                    ? userInfo.profile
                                    : process.env.PUBLIC_URL +
                                      "/images/default-avatar.png"
                                }
                              />
                            </div>
                            <div className=" pl-2 pr-2 d-flex flex-column feed_card_head">
                              <p className="font-weight-bold p-0">
                                {userInfo.display_name
                                  ? userInfo.display_name
                                  : userInfo.username}
                              </p>
                              <p
                                className="p-0 add_text"
                                style={{ color: "#6B6B6B", fontSize: 10 }}
                              >
                                added new media
                              </p>
                              <small
                                className=""
                                style={{ color: "#6B6B6B", fontSize: 10 }}
                              >
                                {data.created && dateFilter(data.created)}
                              </small>
                            </div>
                            <div className="pl-2 added_text">
                              <small style={{ color: "#6B6B6B" }}>
                                added new media
                              </small>
                            </div>

                            {parseInt(userInfo.userId) ===
                            parseInt(login_user_id) ? (
                              <div className="moredots mr-3 ml-auto">
                                <Link
                                  to="#"
                                  onClick={(e) =>
                                    this.settingOption(e, data.id)
                                  }
                                >
                                  <svg
                                    width="1.3em"
                                    height="1.3em"
                                    viewBox="0 0 16 16"
                                    className="bi bi-three-dots"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    id="settingBoxSVG"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                                      id="settingBoxPath"
                                    />
                                  </svg>
                                </Link>
                                {settingOptionShow &&
                                postIdForsettingOption === data.id ? (
                                  <div className="moredots__select">
                                    <ul>
                                      <li>
                                        <Link
                                          to="#"
                                          onClick={(e) =>
                                            this.updatePost(e, key)
                                          }
                                        >
                                          Edit
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to="#"
                                          onClick={(e) =>
                                            this.deletePost(e, data.id, key)
                                          }
                                        >
                                          Delete
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="row p-2 pt-2 flex-md-column flex-wrap">                            
                            <p
                              className={
                                data.title.concat(" " + data.caption).split(" ")
                                  .length > 17
                                  ? "font-weight-bold text-left posting_text pl-lg-3  d-flex flex-wrap post-title-caption"
                                  : "font-weight-bold text-left posting_text pl-lg-3 post-title-caption"
                              }
                            >
                              {data.title.concat(" " + data.caption).split(" ")
                                .length > 17 ? (
                                captionClickPostId !== data.id ? (
                                  <>
                                    <p>
                                      {data.title
                                        .concat(" " + data.caption)
                                        .split(" ").length > 17
                                        ? data.title
                                            .split(" ")
                                            .slice(0, 17)
                                            .join(" ") + " ...."
                                        : data.title}
                                      <Link
                                        to="#"
                                        style={{ paddingLeft: "10px" }}
                                        onClick={(e) => {
                                          this.captionReadMore(e, data.id);
                                        }}
                                      >
                                        <small style={{ color: "#cecece" }}>
                                          Read More
                                        </small>
                                      </Link>
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <p className="title">{data.title}</p>
                                    <p
                                      className="caption"
                                      style={{ color: "#6B6B6B" }}
                                    >
                                      {data.caption == null
                                        ? " "
                                        : data.caption}
                                    </p>
                                    <Link
                                      to="#"
                                      style={{
                                        color: "#cecece !important",
                                        paddingLeft: "10px",
                                      }}
                                      onClick={(e) => {
                                        this.captionReadLess(e);
                                      }}
                                    >
                                      <small style={{ color: "#cecece" }}>
                                        Read Less
                                      </small>
                                    </Link>
                                  </>
                                )
                              ) : (
                                  <>
                                    <p className="title">{data.title}</p>
                                    <p className="caption" style={{ color: "#6B6B6B" }}>
                                      {data.caption == null || data.caption == "null" ? " " : data.caption}
                                    </p>
                                  </>
                              )}
                            </p>
                            {data.is_public ||
                            followerList.includes(parseInt(userId)) ||
                            parseInt(login_user_id) ===
                              parseInt(userInfo.userId) ? (
                              <div className="mx-auto" style={{ width: 380 }}>
                                <Link
                                  to="#"
                                  className="mx-auto"
                                  onClick={(e) => {
                                    this.addComment(e, key);
                                  }}
                                >
                                  {this.displayPostFiles(data)}
                                </Link>
                              </div>
                            ) : (
                              <div className="p-3">
                                  <div className="text-center pb-2rem">
                                    <img src={process.env.PUBLIC_URL + "frontend/images/locked-icon.svg"} alt="locked-icon-img" />
                                  </div>
                                <p>This post is only available to desirers</p>
                                <div className="btns_group d-flex flex-column">
                                  {" "}
                                  <button
                                    className="btn btn1 mb-2"
                                    style={{
                                      background:
                                        "transparent linear-gradient(172deg, #00FFFF 0%, #00A99D 100%) 0% 0% no-repeat padding-box",
                                    }}
                                    onClick={(e) => {
                                      this.subscribeConfirmationModal(
                                        e,
                                        userId,
                                        userInfo.SubscriptionPriceWithVAT,
                                        1,
                                        userInfo.display_name
                                      );
                                    }}
                                  >
                                    Follow {userInfo.display_name} now
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>

                          {(data.is_public ||
                            followerList.includes(parseInt(userId)) ||
                            parseInt(login_user_id) ===
                              parseInt(userInfo.userId)) &&
                            data.media.length > 1 && (
                              <Slider {...this.getSettings(data.media.length)}>
                                {data.media.map((entry, index) => {
                                  let mediaId = entry.thumbnail
                                    ? data.id + ":" + entry.thumbnail
                                    : data.id + ":" + entry.original;
                                  return entry.type === "image" ? (
                                    <div>
                                      <img
                                        height={100}
                                        id={mediaId}
                                        className={`${
                                          this.state.selectedImageId.includes(
                                            mediaId
                                          )
                                            ? "active"
                                            : ""
                                        } createpost_img input_preview_img`}
                                        style={{
                                          borderRadius: "10px",
                                          width: "100%",
                                        }}
                                        src={entry.thumbnail}
                                        alt="img-icon"
                                        onClick={(e) =>
                                          this.changeImage(
                                            mediaId,
                                            data.id,
                                            entry,
                                            index
                                          )
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className={`${
                                        this.state.selectedImageId.includes(
                                          mediaId
                                        )
                                          ? "active"
                                          : ""
                                      } post_slider`}
                                    >
                                      <video
                                        src={entry.original}
                                        className={`video_preview`}
                                        style={{ width: 100 }}
                                        onClick={(e) =>
                                          this.changeImage(
                                            mediaId,
                                            data.id,
                                            entry,
                                            index
                                          )
                                        }
                                      />
                                    </div>
                                  );
                                })}
                              </Slider>
                            )}

                          {(data.is_public ||
                            followerList.includes(parseInt(userId)) ||
                            parseInt(login_user_id) ===
                              parseInt(userInfo.userId)) && (
                            <div className="row pt-2 d-flex justify-content-between align-items-center">
                              <div className="icon_count d-flex">
                                <div className="pl-3 ">
                                  <span>
                                    {data.likedByMe === 0 ? (
                                      <>
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            this.postLikeCountChanges(e, key);
                                          }}
                                          className={
                                            parseInt(login_user_id) !== 0
                                              ? ""
                                              : "remove-pointer"
                                          }
                                        >
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/frontend/images/like.png"
                                            }
                                            alt="img"
                                            index={key}
                                          />
                                        </Link>
                                        <Badge
                                          variant="secondary"
                                          className="bg-transparent text-body align-self-center red_like_img"
                                        >
                                          {data.likes}
                                        </Badge>
                                      </>
                                    ) : (
                                      <>
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            this.postLikeCountChanges(e, key);
                                          }}
                                          className={
                                            parseInt(login_user_id) !== 0
                                              ? ""
                                              : "remove-pointer"
                                          }
                                        >
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/frontend/images/heart_likes.webp"
                                            }
                                            alt="img"
                                            className="red_like_img"
                                            index={key}
                                          />
                                        </Link>
                                        <Badge
                                          variant="secondary"
                                          className="bg-transparent text-body align-self-center"
                                        >
                                          {data.likes}
                                        </Badge>
                                      </>
                                    )}
                                  </span>
                                </div>
                                <div
                                  className="pl-3"
                                  onClick={(e) => {
                                    this.addComment(e, key);
                                  }}
                                >
                                  <span>
                                    <svg
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 16 16"
                                      className="bi bi-chat-square"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M14 1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h2.5a2 2 0 0 1 1.6.8L8 14.333 9.9 11.8a2 2 0 0 1 1.6-.8H14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                                      />
                                    </svg>
                                  </span>
                                  <Badge
                                    color="white"
                                    style={{ cursor: "pointer" }}
                                  >
                                    {data.totalComments}
                                  </Badge>
                                </div>
                              </div>

                              {((data.is_public &&
                                parseInt(login_user_id) !== 0) ||
                                followerList.includes(parseInt(userId)) ||
                                parseInt(login_user_id) ===
                                  parseInt(userInfo.userId)) && (
                                <div className="text-center pr-4">
                                  <Link
                                    to="#"
                                    onClick={(e) => {
                                      this.addComment(e, key, "Post");
                                    }}
                                  >
                                    <p
                                      style={{
                                        color: "#00A99D",
                                        fontSize: 12,
                                        margin: 0,
                                      }}
                                    >
                                      Add a Comment
                                    </p>
                                  </Link>
                                </div>
                              )}
                            </div>
                          )}
                          <hr />
                          {((data.is_public && parseInt(login_user_id) !== 0) ||
                            followerList.includes(parseInt(userId)) ||
                            parseInt(login_user_id) ===
                              parseInt(userInfo.userId)) && (
                            <div className="row pt-1 d-flex feed_card_input_btn">
                              <div className="pl-3">
                                <img
                                  alt=""
                                  src={
                                    this.props.authenticatedUserData
                                      .ProfilePic !== null
                                      ? this.props.authenticatedUserData
                                          .ProfilePicThumbnail
                                      : process.env.PUBLIC_URL +
                                        "/images/default-avatar.png"
                                  }
                                  className="icon_img"
                                />
                              </div>

                              <div className="pl-2 ">
                                <form
                                  onSubmit={this.commentSubmit}
                                  id={key}
                                  postid={data.id}
                                >
                                  <div className="d-flex form-group search_profile_error">
                                    <input
                                      type="text"
                                      className={
                                        "form-control input_text " +
                                        (isError && commentPostId === data.id
                                          ? "border-danger"
                                          : "")
                                      }
                                      id=""
                                      name="Comment"
                                      onChange={(e) => {
                                        this.inputChange(e, data.id);
                                      }}
                                      value={
                                        commentPostId === data.id ? Comment : ""
                                      }
                                      placeholder="Type Your Comment"
                                    />
                                    {isError && commentPostId === data.id ? (
                                      <button
                                        className="ml-2 mr-3"
                                        id="post_btn"
                                        type="button"
                                        disabled="disabled"
                                      >
                                        Post
                                      </button>
                                    ) : (
                                      <button
                                        className="ml-2 mr-3"
                                        id="post_btn"
                                        type="submit"
                                      >
                                        Post
                                      </button>
                                    )}
                                  </div>
                                </form>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="d-flex justify-content-center align-items-center">
                      <h4 className="pt-5" style={{ textAlign: "center" }}>
                        No Posts
                      </h4>
                    </div>
                  )}

                  {loadMoreButton &&
                    totalPostCount !== 0 &&
                    totalPostCount > 1 && (
                      <Link
                        to="#"
                        className="mx-auto d-flex d-block d-lg-none btn show_btn justify-content-center"
                        onClick={(e) => {
                          this.loadAllPost(e);
                        }}
                      >
                        Load More
                      </Link>
                    )}
                </div>
                <div className="popular_box">
                  <div className="p-4 popular_content ">
                    <h6 className="font-weight-bold">
                      {" "}
                      <span>
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL +
                            "/frontend/images/popular profile.png"
                          }
                          className="pr-3"
                        />
                      </span>
                      Popular Profiles
                    </h6>
                    <hr className="follow_hr" />
                    <div className="profile_box">
                      {popularProfiles.map((data, key) => (
                        <div
                          className="profile_name_box d-flex justify-content-start p-2 pl-3 mt-3"
                          key={key}
                        >
                          <div>
                            <img
                              alt=""
                              src={
                                data.ProfileVideo !== "" &&
                                data.ProfileVideo !== null &&
                                data.ProfileVideo !== "null"
                                  ? data.ProfileVideo
                                  : data.ProfilePic !== "" &&
                                    data.ProfilePic !== null &&
                                    data.ProfilePic !== "null"
                                  ? data.ProfilePic
                                  : process.env.PUBLIC_URL +
                                    "/images/default-avatar.png"
                              }
                            />
                          </div>
                          <div className=" pl-3 d-flex flex-column text-truncate">
                            <p className="font-weight-bold m-0 text-truncate">
                              {data.DisplayName !== "" &&
                              data.DisplayName !== null &&
                              data.DisplayName !== "null"
                                ? data.DisplayName
                                : data.Username}
                            </p>
                            <Link
                              to={"/" + data.Username}
                              onClick={(e) => {
                                this.viewUserPost(e, data.Username);
                              }}
                            >
                              {" "}
                              <small className="view_text" id={data.id}>
                                View Profile
                              </small>
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {subscribeConfirmationModal ? (
                <div className="modal__div">
                  <Modal
                    size="lg"
                    show={this.subscribeConfirmationModalOpen}
                    onHide={this.subscribeConfirmationModalClose}
                    centered
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    className="custom__modal"
                  >
                    <Modal.Header
                      closeButton
                      style={{ border: "none" }}
                    ></Modal.Header>
                    <Modal.Body style={{ width: "100%" }}>
                      <div className="modal__body">
                        <div className="modal__text px-3 px-md-5 pb-5 pb-md-5 text-center">
                          <p className="modalheading">
                            Desire{" "}
                            {userInfo.display_name ? userInfo.display_name : ""}
                          </p>
                          <p className="modalparagraph">{userInfo.message}</p>

                          <div className="form-group" id="subscription-modal">
                            <label htmlFor="cvv" className="cvv-popup-number">
                              <b>Enter CVV </b>
                              <b>
                                ({this.props.getUserData.usersData.cardType}{" "}
                                ****{" "}
                                {this.props.getUserData.usersData.cardLastDigit}
                                ){" "}
                              </b>
                              <a
                                href="javascript:void(0)"
                                onClick={this.gotoupdatecard}
                              >
                                <u>
                                  <b className="update-card-cvv">Update Card</b>
                                </u>
                              </a>
                            </label>
                            <input
                              type="number"
                              className="form-control input_text desire-cvv-update"
                              id="cvv"
                              placeholder="Enter Your CVV"
                              name="cvv"
                              onChange={this.inputChangeCVV}
                              defaultValue={cvv}
                            />
                            &nbsp;&nbsp;&nbsp;
                            <button
                              className="followbtn btn btn__continue mr-0 mb-3 mb-md-0 mr-md-3"
                              type="button"
                              onClick={(e) => {
                                this.subscribe(e);
                              }}
                            >
                              Desire{" "}
                              {this.state.displayName
                                ? this.state.displayName
                                : ""}
                            </button>
                            {this.state.cvvError ? (
                              <div
                                id="alert-box2"
                                className="font-weight-bold mt-1"
                              >
                                <span className="pr-2">
                                  <svg
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 16 16"
                                    className="bi bi-exclamation-circle"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                    />
                                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                  </svg>
                                </span>
                                {this.state.cvvErrorMessage}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="button__group d-flex flex-column flex-md-row align-itmes-center justify-content-center">
                            <button
                              className="btn btn__black ml-0 ml-md-3"
                              type="button"
                              onClick={this.subscribeConfirmationModalClose}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              ) : (
                ""
              )}

              {unsubscribeConfirmationModal ? (
                <div className="modal__div">
                  <Modal
                    size="lg"
                    show={this.unsubscribeConfirmationModalOpen}
                    onHide={this.unsubscribeConfirmationModalClose}
                    centered
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    className="custom__modal"
                  >
                    <Modal.Header
                      closeButton
                      style={{ border: "none" }}
                    ></Modal.Header>
                    <Modal.Body style={{ width: "100%" }}>
                      <div className="modal__body">
                        <div className="modal__text px-3 px-md-5 pb-5 pb-md-5 text-center">
                          <p className="modalheading">
                            Undesire{" "}
                            {userInfo.display_name ? userInfo.display_name : ""}
                          </p>
                          <p className="undesiremessage">
                            You are about to unfollow{" "}
                            {userInfo.display_name ? userInfo.display_name : ""}
                            , you will lose access to their content and you will
                            not see any future posts or updates from{" "}
                            {userInfo.display_name ? userInfo.display_name : ""}
                            .
                          </p>
                          <p className="modalparagraph">
                            As a result of this action your card will not be
                            charged any further renewal payments on this
                            subscription going forwards until you subscribe
                            again.
                          </p>
                          <div className="button__group d-flex flex-column flex-md-row align-itmes-center justify-content-center">
                            <button
                              className="followbtn btn btn__continue mr-0 mb-3 mb-md-0 mr-md-3"
                              type="button"
                              onClick={(e) => {
                                this.unfollow(e, userId);
                              }}
                            >
                              Undesire{" "}
                              {userInfo.display_name
                                ? userInfo.display_name
                                : ""}
                            </button>
                            <p className="mb-0 my-auto d-none d-md-block">OR</p>
                            <button
                              className="btn btn__black ml-0 ml-md-3"
                              type="button"
                              onClick={this.unsubscribeConfirmationModalClose}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              ) : (
                ""
              )}

              {loginWishListAlert ? (
                <LoginModalComponent
                  loginUserId={login_user_id}
                  show={this.wishListSubscribeshow}
                  handleClose={this.wishListSubscribeHandleClose}
                />
              ) : (
                ""
              )}

              {paymentConfirmationModal ? (
                <div className="modal__div">
                  <Modal
                    size="lg"
                    centered
                    show={this.paymentConfirmationModalOpen}
                    onHide={this.paymentConfirmationModalClose}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    className="custom__modal"
                  >
                    <Modal.Header
                      closeButton
                      style={{ border: "none" }}
                    ></Modal.Header>
                    <Modal.Body style={{ width: "100%" }}>
                      <div className="modal__body">
                        <div className="modal__text px-3 px-md-5 pb-5 pb-md-5 text-center">
                          {!paymentError ? (
                            <>
                              <div className="pb-4">
                                <img
                                  width="65px"
                                  height="65px"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/frontend/images/icon-tick.svg"
                                  }
                                />
                              </div>
                              <p className="modalheading">
                                Successfully followed{" "}
                                {this.state.displayName
                                  ? this.state.displayName
                                  : ""}
                              </p>
                              <p className="modalparagraph">
                                Reloading the profile now to show{" "}
                                {this.state.displayName
                                  ? this.state.displayName
                                  : ""}
                                's content, please wait…
                              </p>
                            </>
                          ) : (
                            <>
                              <div className="pb-4">
                                <img
                                  width="65px"
                                  height="65px"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/frontend/images/icon-cross.svg"
                                  }
                                />
                              </div>
                              <p className="modalheading">
                                We're sorry but your payment failed
                              </p>
                              <p className="paysaferesponse">
                                {paysaferesponse}
                              </p>
                              <p className="pb-4 modalparagraph">
                                {paymentErrorMessage}
                              </p>
                              <div className="button__group d-flex flex-column flex-md-row align-itmes-center justify-content-center">
                                <button
                                  className="btn btn__continue btn_updatecard mr-0 mb-3 mb-md-0 mr-md-3"
                                  onClick={this.gotoupdatecard}
                                >
                                  Update Card
                                </button>
                                <p className="mb-0 my-auto d-none d-md-block">
                                  OR
                                </p>
                                <button
                                  className="btn btn__continue btn_contactsupport ml-0 ml-md-3"
                                  onClick={this.gotocontactsupport}
                                >
                                  Contact Support
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              ) : (
                ""
              )}
            </div>

            {unfollowConfirmationModal ? (
              <div className="modal__div">
                <Modal
                  size="lg"
                  centered
                  show={this.unfollowModalOpen}
                  onHide={this.unfollowConfirmationModalClose}
                  dialogClassName="modal-90w"
                  aria-labelledby="example-custom-modal-styling-title"
                  className="custom__modal"
                >
                  <Modal.Header
                    closeButton
                    style={{ border: "none" }}
                  ></Modal.Header>
                  <Modal.Body style={{ width: "100%" }}>
                    <div className="modal__body">
                      <div className="modal__text px-3 px-md-5 pb-5 pb-md-5 text-center">
                        <div className="pb-4">
                          <img
                            width="65px"
                            height="65px"
                            src={
                              process.env.PUBLIC_URL +
                              "/frontend/images/icon-tick.svg"
                            }
                          />
                        </div>
                        <p>
                          User has been succesfully removed from your follow
                          list.
                        </p>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            ) : (
              ""
            )}
          </section>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "400px" }}
          >
            <h4 className="" style={{ textAlign: "center" }}>
              No Profile Found{" "}
            </h4>
          </div>
        )}
        <Footer />
      </>
    );
  }
}

export const mapStateToProps = (state) => ({
  userPostData: state.postData.getUserPost,
  popularProfilesData: state.postData.getPopularProfiles,
  recentPost: state.postData.getRecentPostData,
  authenticatedUserData: state.auth.loginSuccessResult,
  deletePost: state.postData.deletePost,
  getAlbums: state.postData.getUserAlbums,
  updatePost: state.postData.updatePost,
  getUserData: state.userAccountData,
  followingContent: state.homePageData.followingContent,
  addToWishList: state.homePageData.addToWishList,
  unfollow: state.homePageData.unfollow,
});

export default connect(mapStateToProps, {
  getUserPostAction,
  getPopularProfileAction,
  recentPostAction,
  likeDislikePostAction,
  getGuestUserPostAction,
  getGuestPopularProfileAction,
  addCommentAction,
  replyToCommentAction,
  likeDislikeCommentAction,
  deletePostAction,
  getUserAlbumAction,
  updatePostAction,
  getUserDetails,
  followContentAction,
  addToWishListAction,
  viewPostAction,
  removeFromWishListAction,
  unfollowContentAction,
})(withRouter(FollowingPage));
