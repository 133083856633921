import React, { useState } from "react";
import Slider from "react-slick";
//import VideoPlayer from "../common/videojs"
import videojs from "video.js";
import VREPlayer from "videojs-react-enhanced";
import "video.js/dist/video-js.css";

import { ReactComponent as ArrowrightIcon } from "../../assets/images/svgs/arrow-right.svg";
import { ReactComponent as ArrowleftIcon } from "../../assets/images/svgs/arrow-left.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowrightIcon className="arrow_img_custom" onClick={onclick} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowleftIcon className="arrow_img_custom" onClick={onclick} />
    </div>
  );
}

const RenderSlider = (props) => {
  const sliderRef = React.useRef(null);
  React.useEffect(() => {    
    const track = sliderRef.current.innerSlider.list.querySelector('.slick-track');
    const focusSlider = setTimeout(() => {
      const slide = track.querySelector('.slick-slide');
      slide.focus();
    }, 0);
    return () => clearTimeout(focusSlider);
  }, []);

  const renderFeed = (url)=>{       
    let videojsOptions = {
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: true,
      sources: [{
        src: url,
        type: 'video/mp4'
      }]
    }    
    
    return <VREPlayer
             id = {url}
             height={100}
             width={300}
             videojsOptions={videojsOptions}/> 
  }
  const settings_dialog = {
    infinite: true,
    accessibility: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    initialSlide: props.selectedSlide
  
  };

  return (
    <Slider {...settings_dialog} ref={sliderRef}>
      {props.media.map((media, key) => {
        return media.type === "image" ? (
          <div key={key}>
            <img
              src={media.original}              
              alt="img"
              height={100}
              className="createpost_img input_preview_img"
              style={{
                borderRadius: "10px",
                width: "100%",
              }}
            />
          </div>
        ) : (
          <div key={key}>
            {renderFeed(media.original)}
          </div>
        );
      })}
    </Slider>
  );
};

export default RenderSlider;
