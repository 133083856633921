import React, { Component, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import {
  getUserByCategoryAction,
  getUserByNameAction,
  followContentAction,
  addToWishListAction,
} from "../../actions/homePage";
import { getUserDetails } from "../../actions/accountSetting";
import Modal from "react-bootstrap/Modal";
import LoginModalComponent from "../LoginModalComponent";
import MetaTags from "react-meta-tags";
import axios from "axios";
import {
  BASE_URL,
  API_KEY,
  ACCOUNT_NUMBER,
  ENVIRONMENT,
  MERCHANT_URL,
  DEVICE_CHANNEL,
  MESSAGE_CATEGORY,
  AUTHENTICATION_PURPOSE,
  CURRENCY,
} from "../Constant";
import authHeader from "../../utils/AuthHeader";
import { ReactComponent as RisingstarIcon } from "../../assets/images/svgs/risingstar.svg";
import { ReactComponent as StarIcon } from "../../assets/images/svgs/star.svg";
import InfiniteScroll from "react-infinite-scroll-component";
//import InfiniteScroll from "react-infinite-scroller"

export class ProfileListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryProfileName: "",
      orderBy: (localStorage.getItem('category')) ? localStorage.getItem('category') : "Popular",
      start: 0,
      limit: 20,
      Loader: true,
      userData: [],
      totalCount: 0,
      // pageCount : 0,
      // pageNumber : 1,
      isCategory: 0,
      // fingerPrintId : '',
      loginUserId: "",
      loginWishListAlert: false,
      paymentConfirmationModal: false,
      contentWriterId: 0,
      userName: "",
      paymentError: false,
      cardDetails: 0,
      followerList: [],
      wishList: [],
      // wishListCount : 0,
      subscribeClick: 0, //for paymentconfirmation modal which is true in follow content props
      paymentErrorMessage: "",
      paysaferesponse: "",
      subscribeConfirmationModal: false,
      subscriptionPrice: 0,
      PaysafeToken: "",
      LoadAllProfiles: false,
      categoryClick: 0,
      displayName: "",
      subscriptionMessage: "",
      cvv: "",
      cvvError: false,
      cvvErrorMessage: "",
      page: 1,
    };

    // this.categoryClick = this.categoryClick.bind(this)
    // this.handleScroll = this.handleScroll.bind(this)
    this.paymentConfirmationModalOpen =
      this.paymentConfirmationModalOpen.bind(this);
    this.paymentConfirmationModalClose =
      this.paymentConfirmationModalClose.bind(this);
    this.subscribeConfirmationModalOpen =
      this.subscribeConfirmationModalOpen.bind(this);
    this.subscribeConfirmationModalClose =
      this.subscribeConfirmationModalClose.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.show = this.show.bind(this);
    this.gotoupdatecard = this.gotoupdatecard.bind(this);
    this.gotocontactsupport = this.gotocontactsupport.bind(this);
    this.openModal = this.openModal.bind(this);
    this.showAuthErrorPaysafe = this.showAuthErrorPaysafe.bind(this);
    this.showgetAuthorizationerror = this.showgetAuthorizationerror.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.viewAllProfiles = this.viewAllProfiles.bind(this);
  }

  viewUserPost(e, profileName) {
    e.preventDefault();
    let searchUserName = profileName;
    localStorage.setItem("userprofileName", profileName);
    this.props.history.push("/" + searchUserName);
  }

  componentDidMount() {
    localStorage.removeItem("searchedBlog");

    // let category = decodeURIComponent(localStorage.getItem('category'))
    let user_id = "";
    if (this.props.authenticatedUserData.userId) {
      user_id = this.props.authenticatedUserData.userId;
      this.props.getUserDetails(user_id);
    }

    let loginUserId = user_id;
    if (loginUserId === "" || loginUserId === undefined) {
      loginUserId = 0;
    }

    let searchedCategoryProfileName = this.props.location.search; //search by category or profile name
    const params = new URLSearchParams(searchedCategoryProfileName);
    let category = params.get("category");
    let profileName = params.get("name");
    let categoryProfileName = "";
    let isCategory = 0;
    if (category !== null && category !== "null" && category !== "") {
      categoryProfileName =
        category.charAt(0).toUpperCase() + category.slice(1);
      isCategory = 1;
      this.props.getUserByCategoryAction(
        categoryProfileName,
        this.state.orderBy,
        loginUserId,
        this.state.start,
        this.state.limit
      );
    } else if (
      profileName !== null ||
      profileName !== "null" ||
      profileName !== ""
    ) {
      categoryProfileName = atob(profileName);
      this.props.getUserByNameAction(
        categoryProfileName,
        loginUserId,
        this.state.start,
        this.state.limit
      );
    }
    

    this.setState({
      categoryProfileName: categoryProfileName,
      orderBy: this.state.orderBy,
      isCategory: isCategory,
      loginUserId: user_id,
      totalCount: 0,
      // categoryProfileName : category
    });
    window.addEventListener("scroll", this.viewAllProfiles);
    window.scrollTo(0, 0);
    // localStorage.removeItem('category')
  }

  redirectToProfile(roleName) {
    localStorage.setItem("roleName", roleName);
  }

  inputChange(e) {
    this.state.cvv = e.target.value;
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.categoryClick === 1) {
      this.setState({
        categoryClick: 0,
      });
      let category = this.state.categoryProfileName;
      if (category !== "") {
        category = category.charAt(0).toLowerCase() + category.slice(1);
      }
      this.props.history.push("/profiles?category=" + category);
    }

    // let pageCount = Math.ceil(nextProps.userData.categoryWiseUserData.userCount / this.state.limit);
    if (
      nextProps.userData.categoryWiseUserData &&
      nextProps.userData.categoryWiseUserData.data &&
      nextProps.userData.categoryWiseUserData.data.length !== 0 &&
      this.state.isCategory === 1
    ) {
      let userData =
        this.state.userData === undefined ? [] : this.state.userData;
      let LoadAllProfiles = false;
      if (userData.length === 0) {
        userData = nextProps.userData.categoryWiseUserData.data;
      } else if (this.state.LoadAllProfiles) {
        // LoadAllProfiles = true
        userData.push(...nextProps.userData.categoryWiseUserData.data);
      }

      this.setState({
        userData: userData,
        totalCount: nextProps.userData.categoryWiseUserData.userCount,
        // pageCount : pageCount,
        isCategory: 1,
        Loader: false,
        // LoadAllProfiles : LoadAllProfiles,
      });
    } else if (
      nextProps.userData.searchedUser &&
      nextProps.userData.searchedUser.data &&
      nextProps.userData.searchedUser.data.length !== 0 &&
      this.state.isCategory === 0
    ) {
      let userData = this.state.userData;
      if (this.state.userData.length === 0) {
        userData = nextProps.userData.searchedUser.data;
      } else if (this.state.LoadAllProfiles) {
        userData.push(...nextProps.userData.searchedUser.data);
      }

      this.setState({
        userData: userData,
        totalCount: nextProps.userData.searchedUser.userCount,
        // pageCount : pageCount,
        isCategory: 0,
        Loader: false,
      });
    }

    if (
      nextProps.getUserData &&
      nextProps.getUserData.usersData &&
      nextProps.getUserData.usersData.length !== ""
    ) {
      let Loader = this.state.Loader;
      if (this.state.Loader) {
        Loader = false;
      }

      let userData =
        this.state.userData === undefined ? [] : this.state.userData;

      if (userData.length === 0) {
        userData = nextProps.userData.categoryWiseUserData.data;
      }
      // else if(this.state.LoadAllProfiles){
      //     userData.push(...nextProps.userData.categoryWiseUserData.data)
      // }

      this.setState({
        cardDetails: nextProps.getUserData.usersData.cardDetails,
        followerList: nextProps.getUserData.usersData.followerList,
        wishList: nextProps.getUserData.usersData.wishList,
        Loader: Loader,
        PaysafeToken: nextProps.getUserData.usersData.PaysafeToken,
        userData: userData,
      });
    } else {
      this.setState({
        Loader: false,
      });
    }

    if (nextProps.followingContent && this.state.contentWriterId !== 0) {
      let contentWriterId = this.state.contentWriterId;
      let userName = this.state.userName;
      // localStorage.removeItem('fingerPrintId')
      let paymentConfirmationModal = false;
      let paymentError = false;
      let paymentErrorMessage = "";
      let paysaferesponse = "";
      if (this.state.subscribeClick === 1) {
        paymentConfirmationModal = true;
      }
      if (nextProps.followingContent.isError) {
        paymentError = true;
        paymentErrorMessage = nextProps.followingContent.error;
        paysaferesponse = nextProps.followingContent.paysafe;
      }
      this.setState({
        contentWriterId: 0,
        userName: "",
        paymentConfirmationModal: paymentConfirmationModal,
        Loader: false,
        paymentError: paymentError,
        paymentErrorMessage: paymentErrorMessage,
        paysaferesponse: paysaferesponse,
      });
      setTimeout(
        function () {
          let paymentConfirmationModal = false;
          if (
            !nextProps.followingContent.isError &&
            this.state.subscribeClick === 1
          ) {
            // contentWriterId = btoa(contentWriterId)
            this.props.history.push("/" + userName);
          }
          if (
            nextProps.followingContent.isError &&
            this.state.subscribeClick === 1
          ) {
            paymentConfirmationModal = true;
          }
          this.setState({
            paymentConfirmationModal: paymentConfirmationModal,
            subscribeClick: 0,
          });
        }.bind(this),
        800
      );
    }

    if (
      nextProps.addToWishList &&
      this.state.contentWriterId !== 0 &&
      this.state.subscribeClick === 0
    ) {
      this.setState({
        contentWriterId: 0,
        userName: "",
        // wishListCount : this.state.wishListCount + 1,
      });
      if (!nextProps.addToWishList.isError) {
        // this.setState({
        //     wishListCount : 1,
        // })

        this.setState({
          loginWishListAlert: true,
        });
        this.props.getUserDetails(this.state.loginUserId);
        // window.scrollTo(0, 0);
      }
    }
  }

  viewAllProfiles = (e) => {

    e.preventDefault();
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;

    if (window.scrollY <= 100 && this.state.loginWishListAlert) {
      this.setState({
        loginWishListAlert: false,
      });
    }

    if (windowBottom >= docHeight) {
      let limit = 20;

      let user_id = this.state.loginUserId;
      if (this.state.loginUserId === "") {
        user_id = 0;
      }

      this.setState({
        LoadAllProfiles: true,
        Loader: true,
        categoryClick: 0,
      });

      if (window.location.href.indexOf("name") > -1){
        var url = new URL(window.location.href);
        var c = url.searchParams.get("name");
        let pname=atob(c)
        this.setState(
          {
            page: this.state.page + 1,
          },
          () => {
            this.props.getUserByNameAction(
              pname,
              user_id,
              this.state.limit * (this.state.page - 1),
              limit
            );
          }
        );
      }
      else{
        this.setState(
          {
            page: this.state.page + 1,
          },
          () => {
            setTimeout(() => {
              this.props.getUserByCategoryAction(
                this.state.categoryProfileName,
                this.state.orderBy,
                user_id,
                this.state.limit * (this.state.page - 1),
                limit
              );
            }, 1500);
          }
        );
      }
    }
  };
  risingstar(e) {
    var item = document.getElementById("left-tabs-example-tab-Risingstar");
    item.click();
  }
  superstar(e) {
    var item = document.getElementById("left-tabs-example-tab-Superstars");
    item.click();
  }

  orderByClick(orderByVal) {
    this.setState({
      orderBy: orderByVal
    }, () => {
      document.getElementById("left-tabs-example-tab-" + this.state.categoryProfileName).click();
    });
  }

  categoryClick(e) {
    e.preventDefault();
    if (e.target.text !== undefined) {
      this.setState({
        categoryProfileName: e.target.text,
        orderBy: this.state.orderBy,
        userData: [],
        LoadAllProfiles: false,
        totalCount: 0,
        Loader: true,
        page: 1,
        categoryClick: 1, //for changing URL
      });
      let user_id = this.state.loginUserId;
      if (this.state.loginUserId === "") {
        user_id = 0;
      }
      this.props.getUserByCategoryAction(
        encodeURIComponent(e.target.text),
        this.state.orderBy,
        user_id,
        0,
        this.state.limit
      );
    }
    // else{
    //     this.setState({
    //         totalCount : 0,
    //         LoadAllProfiles : true,
    //         categoryProfileName : e.target.text,
    //     })
    //     let category = e.target.text
    //     if(category !== ''){
    //         category = category.charAt(0).toLowerCase() + category.slice(1)
    //     }
    //     this.props.history.push('/profiles?category='+category)
    // }
    // setTimeout(function(){
    //     this.props.history.push('/profiles?category='+category)
    // }.bind(this),500)
  }

  alertClose(e) {
    e.preventDefault();
    this.setState({
      loginWishListAlert: false,
    });
  }

  paymentConfirmationModalClose() {
    this.setState({
      paymentConfirmationModal: false,
      paymentError: false,
      subscribeClick: 0,
    });
  }
  gotoupdatecard() {
    this.setState({
      paymentConfirmationModal: false,
      paymentError: false,
      subscribeClick: 0,
    });
    let TabName = btoa("CardsTab");
    localStorage.setItem("cardsTab", TabName);
    setTimeout(
      function () {
        this.props.history.push("/account");
      }.bind(this),
      200
    );
  }
  gotocontactsupport() {
    this.setState({
      paymentConfirmationModal: false,
      paymentError: false,
      subscribeClick: 0,
    });
    setTimeout(
      function () {
        this.props.history.push("/contact-us");
      }.bind(this),
      200
    );
  }

  paymentConfirmationModalOpen() {
    useState(false);
  }

  subscribeConfirmationModal(
    e,
    contentWriterId,
    userName,
    subscriptionPrice,
    displayName,
    subscriptionMessage
  ) {
    e.preventDefault();
    if (
      this.state.loginUserId !== "" &&
      this.state.loginUserId !== null &&
      this.state.loginUserId !== "null"
    ) {
      this.setState({
        subscribeClick: 1,
        displayName: displayName,
        cvv: "",
        cvvError: false,
      });
      if (this.state.cardDetails === 1) {
        this.setState({
          subscribeConfirmationModal: true,
          contentWriterId: contentWriterId,
          userName: userName,
          displayName: displayName,
          subscriptionPrice: subscriptionPrice,
          subscriptionMessage: subscriptionMessage,
        });
      } else {
        this.setState({
          Loader: false,
          paymentError: true,
        });
        let TabName = btoa("CardsTab");
        localStorage.setItem("cardsTab", TabName);
        setTimeout(
          function () {
            this.props.history.push("/account");
          }.bind(this),
          200
        );
      }
    }
    //if not login
    else {
      this.setState({
        loginWishListAlert: true,
      });
    }
  }

  subscribeConfirmationModalOpen() {
    useState(false);
  }

  subscribeConfirmationModalClose() {
    this.setState({
      subscribeConfirmationModal: false,
      contentWriterId: 0,
      userName: "",
      subscriptionPrice: 0,
      subscribeClick: 0,
    });
  }

  /**
   * card type not supported error message
   */
  openModal() {
    this.setState({
      contentWriterId: 0,
      userName: "",
      paymentConfirmationModal: true,
      Loader: false,
      paymentError: "Error Code 5050 : Card type is not supported",
      paymentErrorMessage: "Error Code 5050 : Card type is not supported",
      paysaferesponse:
        "Merchant Account is not configured to support this card type",
    });
  }

  /**
   *
   * authentication credentials are invalid error
   */

  showAuthErrorPaysafe() {
    this.setState({
      contentWriterId: 0,
      userName: "",
      paymentConfirmationModal: true,
      Loader: false,
      paymentError: "Error Code 5270 : Unauthorized access",
      paymentErrorMessage: "Error Code 5270 : Unauthorized access",
      paysaferesponse:
        "The credentials do not have permission to access the requested data",
    });
  }
  /**
   * get authorization error modal
   *
   */
  showgetAuthorizationerror() {
    this.setState({
      contentWriterId: 0,
      userName: "",
      paymentConfirmationModal: true,
      Loader: false,
      paymentError: "Error Code 5279 : Invalid credentials",
      paymentErrorMessage: "Error Code 5279 : Invalid credentials",
      paysaferesponse: "The authentication credentials are invalid.",
    });
  }

  subscribe(e) {
    e.preventDefault();

    let contentWriterId = this.state.contentWriterId;
    let subscriptionPrice = this.state.subscriptionPrice;
    var token = this.props.getUserData.usersData.PaysafeToken;
    let firstSixDigit = this.props.getUserData.usersData.FirstSixDigit;
    let merchantRefNum = this.props.getUserData.usersData.merchantRefnum;

    let cvv = this.state.cvv;

    let length = cvv.length;
    if (length == 0) {
      this.setState({
        cvvError: true,
        cvvErrorMessage: "CVV field is required",
      });
    } else if (length < 3 || length > 4) {
      this.setState({
        cvvError: true,
        cvvErrorMessage:
          "CVV length must be greater than 2 character and less than 4 character",
      });
    }

    if (length == 3 || length == 4) {
      window.state = this.state;
      window.props = this.props;
      window.this = this;

      if (
        this.state.loginUserId !== "" &&
        this.state.loginUserId !== null &&
        this.state.loginUserId !== "null"
      ) {
        this.setState({
          Loader: true,
          subscribeConfirmationModal: false,
          loginWishListAlert: false,
        });
        if (this.state.cardDetails === 1) {
          this.setState({
            contentWriterId: contentWriterId,
          });
          let paymentData = {
            amount: subscriptionPrice,
            paysafe_token: this.state.PaysafeToken,
          };
          let subscriptionPlan = 1;
          window.paysafe.threedsecure.start(
            API_KEY,
            {
              environment: ENVIRONMENT,
              accountId: ACCOUNT_NUMBER,
              card: {
                cardBin: firstSixDigit,
              },
            },
            function (deviceFingerprintingId, error) {
              if (error) {
                window.this.openModal();
              } else {
                var data = {
                  amount: subscriptionPrice * 100,
                  currency: CURRENCY,
                  currencyCode: CURRENCY,
                  merchantRefNum: merchantRefNum,
                  merchantUrl: MERCHANT_URL,
                  card: { paymentToken: token },
                  deviceChannel: DEVICE_CHANNEL,
                  messageCategory: MESSAGE_CATEGORY,
                  authenticationPurpose: AUTHENTICATION_PURPOSE,
                  deviceFingerprintingId: deviceFingerprintingId,
                };
                axios
                  .post(
                    BASE_URL + "/authorization",
                    { data },
                    {
                      headers: authHeader(),
                    }
                  )
                  .then((response) => {
                    if (response.data.status != "COMPLETED") {
                      var responsePayload = response.data.sdkChallengePayload;

                      window.paysafe.threedsecure.challenge(
                        API_KEY,
                        {
                          environment: ENVIRONMENT,
                          sdkChallengePayload: responsePayload,
                        },
                        function (authenticationID, error) {
                          if (error) {
                          } else {
                            var data = {
                              authenticationID: authenticationID,
                            };
                            axios
                              .post(
                                BASE_URL + "/getauthorization",
                                { data },
                                {
                                  headers: authHeader(),
                                }
                              )
                              .then((getAuthorization) => {
                                /*if(getAuthorization.error!=''){
                                                            alert('heeeeeeee')
                                                            window.this.showgetAuthorizationerror()
                                                        } else{*/
                                var paymentData = {
                                  amount: subscriptionPrice,
                                  paysafe_token: token,
                                  cvv: cvv,
                                  data: getAuthorization,
                                };
                                window.props.followContentAction(
                                  window.state.loginUserId,
                                  contentWriterId,
                                  subscriptionPlan,
                                  paymentData
                                );

                                //}
                              });
                          }
                        }
                      );
                    } else {
                      var data = {
                        authenticationID: response.data.id,
                      };
                      axios
                        .post(
                          BASE_URL + "/getauthorization",
                          { data },
                          {
                            headers: authHeader(),
                          }
                        )
                        .then((getAuthorization) => {
                          var paymentData = {
                            amount: subscriptionPrice,
                            paysafe_token: token,
                            data: getAuthorization,
                            cvv: cvv,
                          };
                          window.props.followContentAction(
                            window.state.loginUserId,
                            contentWriterId,
                            subscriptionPlan,
                            paymentData
                          );
                        });
                    }
                  });
              }
            }
          );
        }
      }
    }
  }

  addToWishList(e, contentWriterId) {
    e.preventDefault();
    localStorage.removeItem("wishListClick");
    //if login
    if (this.state.loginUserId !== "") {
      this.setState({
        Loader: true,
        contentWriterId: contentWriterId,
        paymentConfirmationModal: false,
      });
      this.props.addToWishListAction(this.state.loginUserId, contentWriterId);
    }
    //if not login
    else {
      this.setState({
        loginWishListAlert: true,
      });
    }
  }

  handleClose() {
    this.setState({
      loginWishListAlert: false,
    });
  }

  show() {
    useState(false);
  }

  render() {
    const {
      userData,
      totalCount,
      isCategory,
      loginWishListAlert,
      Loader,
      paymentConfirmationModal,
      paymentError,
      loginUserId,
      followerList,
      wishList,
      paymentErrorMessage,
      paysaferesponse,
      subscribeConfirmationModal,
      LoadAllProfiles,
      categoryProfileName,
      orderBy,
      cvv,
      cvvError,
      cvvErrorMessage,
    } = this.state;

    const profileImage = process.env.PUBLIC_URL + "/images/default-avatar.png";
    var metadescription = "Content Creator profiles.";

    return (
      <>
        {Loader ? (
          <div id="preloader">
            <div className="preloader_div"></div>
          </div>
        ) : (
          ""
        )}
        <MetaTags>
          <title>Browse Profiles | DesireMe</title>
          <meta property="og:type" content="website" />
          <meta property="og:url" content="__OGURL__" />
          <link rel="canonical" href="__OGURL__" />
          <meta
            property="og:image"
            content="https://desire.me/images/ogimage.png"
          />
          <meta property="og:site_name" content="Desire Me" />
          <meta name="description" content="__METADESCRIPTION__" />
          <meta name="title" content="__METATITLE__" />
          <meta property="og:description" content="__METADESCRIPTION__" />
          <meta property="og:title" content="__METATITLE__" />
        </MetaTags>
        <Header />
        <section id="ProfileCardPage">
          <div className="container mt-5">
            <div className="show_profile_heading mb-4">
              <div className="d-flex align-items-center mb-2">
                {/*<img src={process.env.PUBLIC_URL + '/frontend/images/heart.png'} alt="search" className="show-profile-pic" />*/}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="46.447"
                  height="41.323"
                  viewBox="0 0 46.447 41.323"
                >
                  <g
                    id="Group_68"
                    data-name="Group 68"
                    transform="translate(-755 -358)"
                  >
                    <path
                      id="Path_627"
                      data-name="Path 627"
                      d="M797.762,362.044a12.49,12.49,0,0,0-9.29-4.044,11.685,11.685,0,0,0-7.3,2.52,14.909,14.909,0,0,0-2.95,3.081,14.928,14.928,0,0,0-2.951-3.081,11.681,11.681,0,0,0-7.3-2.52,12.49,12.49,0,0,0-9.291,4.044A14.517,14.517,0,0,0,755,371.959c0,3.928,1.464,7.524,4.607,11.316,2.811,3.393,6.853,6.836,11.532,10.824,1.6,1.362,3.409,2.905,5.289,4.55a2.727,2.727,0,0,0,3.591,0c1.88-1.644,3.693-3.189,5.291-4.551,4.679-3.987,8.72-7.43,11.531-10.823,3.143-3.792,4.607-7.388,4.607-11.316a14.517,14.517,0,0,0-3.685-9.914Zm0,0"
                      fill="#1c1c1c"
                    />
                  </g>
                </svg>
                {isCategory === 1 ? (
                  <h1 className="mb-0 ml-3 cls-bp-title">Browse Profiles</h1>
                ) : (
                  <h1 className="mb-0 ml-3 cls-sp-title">Searched Profiles</h1>
                )}
              </div>
            </div>
            {isCategory === 1 ? (
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={categoryProfileName}
              >
                <Row>
                  <Col sm={12}>
                    <Nav
                      variant="pills"
                      className="align-items-center mt-3 justify-content-center justify-content-lg-around"
                    >
                      <Nav.Item
                        className="pl-md-0"
                        onClick={(e) => {
                          this.categoryClick(e);
                        }}
                      >
                        <Nav.Link
                          eventKey="All"
                          className={
                            "p-0 " +
                            (categoryProfileName === "All"
                              ? "show_profile_link_active"
                              : "")
                          }
                        >
                          All
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={(e) => {
                          this.categoryClick(e);
                        }}
                      >
                        <Nav.Link
                          eventKey="Male"
                          className={
                            "p-0 " +
                            (categoryProfileName === "Male"
                              ? "show_profile_link_active"
                              : "")
                          }
                        >
                          Male
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={(e) => {
                          this.categoryClick(e);
                        }}
                      >
                        <Nav.Link
                          eventKey="Female"
                          className={
                            "p-0 " +
                            (categoryProfileName === "Female"
                              ? "show_profile_link_active"
                              : "")
                          }
                        >
                          Female
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={(e) => {
                          this.categoryClick(e);
                        }}
                      >
                        <Nav.Link
                          eventKey="Couples"
                          className={
                            "p-0 " +
                            (categoryProfileName === "Couples"
                              ? "show_profile_link_active"
                              : "")
                          }
                        >
                          Couples
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={(e) => {
                          this.categoryClick(e);
                        }}
                      >
                        <Nav.Link
                          eventKey="Trans"
                          className={
                            "p-0 " +
                            (categoryProfileName === "Trans"
                              ? "show_profile_link_active"
                              : "")
                          }
                        >
                          Trans
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={(e) => {
                          this.categoryClick(e);
                        }}
                        className={"hidenav"}
                      >
                        <Nav.Link
                          eventKey="Online Now"
                          className={
                            "p-0 " +
                            (categoryProfileName === "Online Now"
                              ? "show_profile_link_active"
                              : "")
                          }
                        >
                          Online Now
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={(e) => {
                          this.categoryClick(e);
                        }}
                        className={"hidenav"}
                      >
                        <Nav.Link
                          eventKey="Risingstar"
                          className={
                            "p-0 " +
                            (categoryProfileName === "Risingstar"
                              ? "show_profile_link_active"
                              : "")
                          }
                        >
                          Risingstar
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={(e) => {
                          this.categoryClick(e);
                        }}
                        className={"hidenav"}
                      >
                        <Nav.Link
                          eventKey="Superstars"
                          className={
                            "p-0 " +
                            (categoryProfileName === "Superstars"
                              ? "show_profile_link_active"
                              : "")
                          }
                        >
                          Superstars
                        </Nav.Link>
                      </Nav.Item>
                      <div className="show_profile_mai_main_box">
                        <Nav.Item
                          className="risin_btn_main_btn"
                          onClick={this.risingstar}
                        >
                          <Nav.Link className="btn nav_item_btn_white nav_item_link_btn nav_item_link">
                            <RisingstarIcon className={"mr-2 risingstar"} />{" "}
                            Rising star
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          className="superstar_btn_main_btn"
                          onClick={this.superstar}
                        >
                          <Nav.Link className="btn nav_item_link_btn nav_item_link_btn_hover nav_item_link">
                            <StarIcon
                              height={"1.5em"}
                              width={"1.5em"}
                              className={"mr-2 superstar"}
                            />
                            Superstars
                          </Nav.Link>
                        </Nav.Item>
                      </div>
                      <div className="form-group nav-item m-0">
                          <select
                            className="form-control select_box"
                            id="formControlSelect1"
                            name="orderBY"
                            onChange={(e) => { this.orderByClick(e.target.value); }}
                            value={this.state.orderBy}
                          >
                            <option disabled value="">Sort By :</option>
                            <option value="Popular">Popular (most followers)</option>
                            <option value="Recently">Recently Updated</option>
                            <option value="Most">Most Content</option>
                          </select>
                        </div>
                    </Nav>
                  </Col>
                  <Col sm={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey={categoryProfileName} className="mt-5">
                        <div className="row ">
                          <div className="card-deck mb-5 col-12">
                            <div className="row row_show_profile px-0 justify-content-center">
                              {totalCount > 0 ? (
                                userData.map((data, key) => (
                                  <div
                                    className="card border-none col-md-4 col-lg-4 px-0 mr-0"
                                    key={key}
                                  >
                                    <Link
                                      to={
                                        "/" + data.Username
                                      } /*onClick={(e) => {this.viewUserPost(e,data.Username)}}*/
                                    >
                                      <div
                                        className="card_show_div"
                                        style={{
                                          backgroundImage:
                                            data.ProfileVideo !== ""
                                              ? "url(" + data.ProfileVideo + ")"
                                              : data.ProfilePic !== ""
                                              ? "url(" + data.ProfilePic + ")"
                                              : "url(" + profileImage + ")",
                                        }}
                                      >
                                        {data.superStar === 1 ? (
                                          <button className="btn superstar_btn">
                                            <StarIcon
                                              height={"1.3em"}
                                              width={"1.3em"}
                                              className={"mr-2 superstar"}
                                            />
                                            Superstars
                                          </button>
                                        ) : data.risingStar === 1 ? (
                                          <button className="btn risingstar_btn">
                                            <RisingstarIcon
                                              height={"1.3em"}
                                              width={"1.3em"}
                                              className={"mr-2 superstar"}
                                            />
                                            Rising stars
                                          </button>
                                        ) : (
                                          ""
                                        )}
                                        <div className="show_img">
                                          <Link
                                            to={
                                              "/" + data.Username
                                            } /*onClick={(e)=>{this.viewUserPost(e,data.Username)}}*/
                                          >
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/frontend/images/show.png"
                                              }
                                              className=""
                                              alt=""
                                            />
                                          </Link>
                                        </div>
                                      </div>
                                    </Link>
                                    <div className="card-body">
                                      <div className="d-flex justify-content-between card_heading">
                                        <Link
                                          className={"profile-url"}
                                          to={
                                            "/" + data.Username
                                          } /*onClick={(e)=>{this.viewUserPost(e,data.Username)}}*/
                                        >
                                          {data.DisplayName !== "" &&
                                          data.DisplayName !== null &&
                                          data.DisplayName !== "null"
                                            ? data.DisplayName
                                            : data.Username}
                                        </Link>
                                        <Link
                                          className={"profile-url"}
                                          to={
                                            "/" + data.Username
                                          } /*onClick={(e)=>{this.viewUserPost(e,data.Username)}}*/
                                        >
                                          {data.Username !== ""
                                            ? "@" + data.Username
                                            : ""}
                                        </Link>
                                      </div>
                                      <div
                                        className={
                                          "d-flex justify-content-between align-items-center card_desired_list " +
                                          (followerList.includes(data.id) ||
                                          loginUserId === data.id
                                            ? "card_desired_list_remove"
                                            : "")
                                        }
                                      >
                                        <div className="d-flex">
                                          <Link
                                            to={
                                              "/" + data.Username
                                            } /*onClick={(e)=>{this.viewUserPost(e,data.Username)}}*/
                                          >
                                            {/*<img src={process.env.PUBLIC_URL + '/frontend/images/videos.png'} className="mr-2"  alt=""/>*/}
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="23.655"
                                              height="15.206"
                                              viewBox="0 0 23.655 15.206"
                                              className="mr-2"
                                            >
                                              <path
                                                id="Path_2608"
                                                data-name="Path 2608"
                                                d="M40.321,367.744a.845.845,0,0,0-.822-.037l-5.536,2.768v-2.012a2.534,2.534,0,0,0-2.534-2.534H19.6a2.534,2.534,0,0,0-2.534,2.534V378.6a2.534,2.534,0,0,0,2.534,2.535H31.429a2.534,2.534,0,0,0,2.534-2.535v-2.012l5.536,2.773a.845.845,0,0,0,1.223-.76V368.463A.846.846,0,0,0,40.321,367.744Zm-14.806,9.167a3.379,3.379,0,1,1,3.379-3.379A3.379,3.379,0,0,1,25.515,376.911Z"
                                                transform="translate(-17.067 -365.929)"
                                                fill="#1c1c1c"
                                              />
                                            </svg>
                                          </Link>
                                          <Badge
                                            variant="secondary"
                                            className="bg-transparent text-body align-self-center"
                                          >
                                            {data.videoCount}
                                          </Badge>
                                        </div>
                                        {loginUserId !== data.id ? (
                                          !followerList.includes(data.id) &&
                                          !wishList.includes(data.id) ? (
                                            <Link
                                              to="#"
                                              className="desired_list_btn"
                                              onClick={(e) => {
                                                this.addToWishList(e, data.id);
                                              }}
                                            >
                                              add to my desired list
                                            </Link>
                                          ) : !followerList.includes(
                                              data.id
                                            ) ? (
                                            <Link
                                              to="#"
                                              style={{ cursor: "unset" }}
                                              onClick={(e) => {
                                                e.preventDefault();
                                              }}
                                              className="desired_list_btn"
                                            >
                                              Desired
                                            </Link>
                                          ) : (
                                            ""
                                          )
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div
                                        className={
                                          "d-flex justify-content-between card_subscribe mt-2 " +
                                          (followerList.includes(data.id) ||
                                          loginUserId === data.id
                                            ? "card_subscribe_remove"
                                            : "")
                                        }
                                      >
                                        <div className="d-flex">
                                          <Link
                                            to={
                                              "/" + data.Username
                                            } /*onClick={(e)=>{this.viewUserPost(e,data.Username)}}*/
                                          >
                                            {/*<img src={process.env.PUBLIC_URL + '/frontend/images/images.png'} className="mr-2" alt="" />*/}
                                            <svg
                                              id="Group_132"
                                              data-name="Group 132"
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="22.524"
                                              height="16.088"
                                              viewBox="0 0 22.524 16.088"
                                              className="mr-2"
                                            >
                                              <path
                                                id="Path_16"
                                                data-name="Path 16"
                                                d="M130.621,365.929h-17.7a2.413,2.413,0,0,0-2.413,2.413V379.6a2.413,2.413,0,0,0,2.413,2.413h17.7a2.413,2.413,0,0,0,2.413-2.413V368.342A2.413,2.413,0,0,0,130.621,365.929Zm-17.7,1.609h17.7a.8.8,0,0,1,.8.8v6.907l-5.062-5.062a.8.8,0,0,0-1.138,0l-6.671,6.671-2.649-2.649a.8.8,0,0,0-1.137,0l-2.649,2.649v-8.516A.8.8,0,0,1,112.924,367.538Z"
                                                transform="translate(-110.511 -365.929)"
                                                fill="#1c1c1c"
                                              />
                                              <ellipse
                                                id="Ellipse_1"
                                                data-name="Ellipse 1"
                                                cx="2.413"
                                                cy="2.413"
                                                rx="2.413"
                                                ry="2.413"
                                                transform="translate(4.827 3.218)"
                                                fill="#1c1c1c"
                                              />
                                            </svg>
                                          </Link>
                                          <Badge
                                            variant="secondary"
                                            className="bg-transparent text-body align-self-center"
                                          >
                                            {data.imageCount}
                                          </Badge>
                                        </div>
                                        {loginUserId !== data.id ? (
                                          !followerList.includes(data.id) ? (
                                            <Link
                                              to="#"
                                              onClick={(e) => {
                                                this.subscribeConfirmationModal(
                                                  e,
                                                  data.id,
                                                  data.Username,
                                                  data.SubscriptionPriceWithVAT,
                                                  data.DisplayName,
                                                  data.message
                                                );
                                              }}
                                              className="subscribe_btn"
                                            >
                                              subscribe
                                            </Link>
                                          ) : (
                                            ""
                                          )
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div
                                  className="d-flex justify-content-center align-items-center"
                                  style={{ height: "400px" }}
                                >
                                  <h4
                                    className=""
                                    style={{ textAlign: "center" }}
                                  >
                                    No Profiles
                                  </h4>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            ) : (
              <div className="row ">
                <div className="card-deck mb-5 col-12">
                  <div className="row row_show_profile px-0 justify-content-center">
                    {totalCount > 0 ? (
                      userData.map((data, key) => (
                        <div
                          className="card border-none col-md-3 col-lg-4 px-0 mr-0"
                          key={key}
                        >
                          <Link
                            to={
                              "/" + data.Username
                            } /*onClick={(e) => {this.viewUserPost(e,data.Username)}}*/
                          >
                            <div
                              className="card_show_div"
                              style={{
                                backgroundImage:
                                  data.ProfileVideo !== ""
                                    ? "url(" + data.ProfileVideo + ")"
                                    : data.ProfilePic !== ""
                                      ? "url(" + data.ProfilePic + ")"
                                      : "url(" + profileImage + ")",
                              }}
                            >
                              {data.superStar === 1 ? (
                                <button className="btn superstar_btn">
                                  <img
                                    alt=""
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/frontend/images/star.png"
                                    }
                                    className="mr-2"
                                  />
                                  Superstars
                                </button>
                              ) : data.risingStar === 1 ? (
                                <button className="btn risingstar_btn">
                                  <img
                                    alt=""
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/frontend/images/rising star.png"
                                    }
                                    className="mr-2"
                                  />
                                  Rising stars
                                </button>
                              ) : (
                                ""
                              )}
                              <div className="show_img">
                                <Link
                                  to={
                                    "/" + data.Username
                                  } /*onClick={(e)=>{this.viewUserPost(e,data.Username)}}*/
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/frontend/images/show.png"
                                    }
                                    className=""
                                    alt=""
                                  />
                                </Link>
                              </div>
                            </div>
                          </Link>
                          <div className="card-body">
                            <div className="d-flex justify-content-between card_heading">
                              <p>
                                {data.DisplayName !== "" &&
                                  data.DisplayName !== null &&
                                  data.DisplayName !== "null"
                                  ? data.DisplayName
                                  : ""}
                              </p>
                              <p>
                                {data.Username !== ""
                                  ? "@ " + data.Username
                                  : ""}
                              </p>
                            </div>
                            <div
                              className={
                                "d-flex justify-content-between align-items-center card_desired_list " +
                                (followerList.includes(data.id) ||
                                  loginUserId === data.id
                                  ? "card_desired_list_remove"
                                  : "")
                              }
                            >
                              <div className="d-flex">
                                <Link
                                  to={
                                    "/" + data.Username
                                  } /*onClick={(e)=>{this.viewUserPost(e,data.Username)}}*/
                                >
                                  {/*<img src={process.env.PUBLIC_URL + '/frontend/images/videos.png'} className="mr-2"  alt=""/>*/}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="23.655"
                                    height="15.206"
                                    viewBox="0 0 23.655 15.206"
                                    className="mr-2"
                                  >
                                    <path
                                      id="Path_2608"
                                      data-name="Path 2608"
                                      d="M40.321,367.744a.845.845,0,0,0-.822-.037l-5.536,2.768v-2.012a2.534,2.534,0,0,0-2.534-2.534H19.6a2.534,2.534,0,0,0-2.534,2.534V378.6a2.534,2.534,0,0,0,2.534,2.535H31.429a2.534,2.534,0,0,0,2.534-2.535v-2.012l5.536,2.773a.845.845,0,0,0,1.223-.76V368.463A.846.846,0,0,0,40.321,367.744Zm-14.806,9.167a3.379,3.379,0,1,1,3.379-3.379A3.379,3.379,0,0,1,25.515,376.911Z"
                                      transform="translate(-17.067 -365.929)"
                                      fill="#1c1c1c"
                                    />
                                  </svg>
                                </Link>
                                <Badge
                                  variant="secondary"
                                  className="bg-transparent text-body align-self-center"
                                >
                                  {data.videoCount}
                                </Badge>
                              </div>
                              {loginUserId !== data.id ? (
                                !followerList.includes(data.id) &&
                                  !wishList.includes(data.id) ? (
                                  <Link
                                    to="#"
                                    className="desired_list_btn"
                                    onClick={(e) => {
                                      this.addToWishList(e, data.id);
                                    }}
                                  >
                                    add to my desired list
                                  </Link>
                                ) : !followerList.includes(data.id) ? (
                                  <Link
                                    to="#"
                                    style={{ cursor: "unset" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                    }}
                                    className="desired_list_btn"
                                  >
                                    Desired
                                  </Link>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className={
                                "d-flex justify-content-between card_subscribe mt-2 " +
                                (followerList.includes(data.id) ||
                                  loginUserId === data.id
                                  ? "card_subscribe_remove"
                                  : "")
                              }
                            >
                              <div className="d-flex">
                                <Link
                                  to={
                                    "/" + data.Username
                                  } /*onClick={(e)=>{this.viewUserPost(e,data.Username)}}*/
                                >
                                  {/*<img src={process.env.PUBLIC_URL + '/frontend/images/images.png'} className="mr-2" alt="" />*/}
                                  <svg
                                    id="Group_132"
                                    data-name="Group 132"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22.524"
                                    height="16.088"
                                    viewBox="0 0 22.524 16.088"
                                    className="mr-2"
                                  >
                                    <path
                                      id="Path_16"
                                      data-name="Path 16"
                                      d="M130.621,365.929h-17.7a2.413,2.413,0,0,0-2.413,2.413V379.6a2.413,2.413,0,0,0,2.413,2.413h17.7a2.413,2.413,0,0,0,2.413-2.413V368.342A2.413,2.413,0,0,0,130.621,365.929Zm-17.7,1.609h17.7a.8.8,0,0,1,.8.8v6.907l-5.062-5.062a.8.8,0,0,0-1.138,0l-6.671,6.671-2.649-2.649a.8.8,0,0,0-1.137,0l-2.649,2.649v-8.516A.8.8,0,0,1,112.924,367.538Z"
                                      transform="translate(-110.511 -365.929)"
                                      fill="#1c1c1c"
                                    />
                                    <ellipse
                                      id="Ellipse_1"
                                      data-name="Ellipse 1"
                                      cx="2.413"
                                      cy="2.413"
                                      rx="2.413"
                                      ry="2.413"
                                      transform="translate(4.827 3.218)"
                                      fill="#1c1c1c"
                                    />
                                  </svg>
                                </Link>
                                <Badge
                                  variant="secondary"
                                  className="bg-transparent text-body align-self-center"
                                >
                                  {data.imageCount}
                                </Badge>
                              </div>
                              {loginUserId !== data.id ? (
                                !followerList.includes(data.id) ? (
                                  <Link
                                    to="#"
                                    onClick={(e) => {
                                      this.subscribeConfirmationModal(
                                        e,
                                        data.id,
                                        data.Username,
                                        data.SubscriptionPriceWithVAT,
                                        data.DisplayName,
                                        data.message
                                      );
                                    }}
                                    className="subscribe_btn"
                                  >
                                    subscribe
                                  </Link>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "400px" }}
                      >
                        <h4 className="" style={{ textAlign: "center" }}>
                          No Profiles
                        </h4>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>

        {subscribeConfirmationModal ? (
          <div className="modal__div">
            <Modal
              size="lg"
              show={this.subscribeConfirmationModalOpen}
              onHide={this.subscribeConfirmationModalClose}
              centered
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              className="custom__modal"
            >
              <Modal.Header
                closeButton
                style={{ border: "none" }}
              ></Modal.Header>
              <Modal.Body style={{ width: "100%" }}>
                <div className="modal__body">
                  <div className="modal__text px-3 px-md-5 pb-5 pb-md-5 text-center">
                    <p className="modalheading">
                      Desire{" "}
                      {this.state.displayName ? this.state.displayName : ""}
                    </p>
                    <p className="modalparagraph">
                      {this.state.subscriptionMessage}
                    </p>

                    <div className="form-group" id="subscription-modal">
                      <label htmlFor="cvv" className="cvv-popup-number">
                        <b>Enter CVV</b>
                        <b>
                          {" "}
                          ({this.props.getUserData.usersData.cardType} ****{" "}
                          {this.props.getUserData.usersData.cardLastDigit}){" "}
                        </b>
                        <a
                          href="javascript:void(0)"
                          onClick={this.gotoupdatecard}
                        >
                          <u>
                            <b className="update-card-cvv">Update Card</b>
                          </u>
                        </a>
                      </label>
                      <input
                        type="number"
                        className="form-control input_text desire-cvv-update"
                        id="cvv"
                        placeholder="Enter Your CVV"
                        name="cvv"
                        onChange={this.inputChange}
                        defaultValue={cvv}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <button
                        className="followbtn btn btn__continue mr-0 mb-3 mb-md-0 mr-md-3"
                        type="button"
                        onClick={(e) => {
                          this.subscribe(e);
                        }}
                      >
                        Desire{" "}
                        {this.state.displayName ? this.state.displayName : ""}
                      </button>
                      {this.state.cvvError ? (
                        <div id="alert-box2" className="font-weight-bold mt-1">
                          <span className="pr-2">
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-exclamation-circle"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                            </svg>
                          </span>
                          {this.state.cvvErrorMessage}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="button__group d-flex flex-column flex-md-row align-itmes-center justify-content-center">
                      <button
                        className="btn btn__black ml-0 ml-md-3"
                        type="button"
                        onClick={this.subscribeConfirmationModalClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        ) : (
          ""
        )}

        {loginWishListAlert ? (
          <LoginModalComponent
            loginUserId={loginUserId}
            show={this.show}
            handleClose={this.handleClose}
          />
        ) : (
          ""
        )}

        {paymentConfirmationModal ? (
          <div className="modal__div">
            <Modal
              size="lg"
              centered
              show={this.paymentConfirmationModalOpen}
              onHide={this.paymentConfirmationModalClose}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              className="custom__modal"
            >
              <Modal.Header
                closeButton
                style={{ border: "none" }}
              ></Modal.Header>
              <Modal.Body style={{ width: "100%" }}>
                <div className="modal__body">
                  <div className="modal__text px-3 px-md-5 pb-5 pb-md-5 text-center">
                    {!paymentError ? (
                      <>
                        <div className="pb-4">
                          <img
                            width="65px"
                            height="65px"
                            src={
                              process.env.PUBLIC_URL +
                              "/frontend/images/icon-tick.svg"
                            }
                          />
                        </div>
                        <p className="modalheading">
                          Successfully followed{" "}
                          {this.state.displayName ? this.state.displayName : ""}
                        </p>
                        <p className="modalparagraph">
                          Reloading the profile now to show{" "}
                          {this.state.displayName ? this.state.displayName : ""}
                          's content, please wait…
                        </p>
                      </>
                    ) : (
                      <>
                        <div className="pb-4">
                          <img
                            width="65px"
                            height="65px"
                            src={
                              process.env.PUBLIC_URL +
                              "/frontend/images/icon-cross.svg"
                            }
                          />
                        </div>
                        <p className="modalheading">
                          We're sorry but your payment failed
                        </p>
                        <p className="paysaferesponse">{paysaferesponse}</p>
                        <p className="pb-4 modalparagraph">
                          {paymentErrorMessage}
                        </p>
                        <div className="button__group d-flex flex-column flex-md-row align-itmes-center justify-content-center">
                          <button
                            className="btn btn__continue btn_updatecard mr-0 mb-3 mb-md-0 mr-md-3"
                            onClick={this.gotoupdatecard}
                          >
                            Update Card
                          </button>
                          <p className="mb-0 my-auto d-none d-md-block">OR</p>
                          <button
                            className="btn btn__continue btn_contactsupport ml-0 ml-md-3"
                            onClick={this.gotocontactsupport}
                          >
                            Contact Support
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        ) : (
          ""
        )}
        <Footer />
      </>
    );
  }
}

export const mapStateToProps = (state) => ({
  userData: state.homePageData,
  authenticatedUserData: state.auth.loginSuccessResult,
  followingContent: state.homePageData.followingContent,
  addToWishList: state.homePageData.addToWishList,
  getUserData: state.userAccountData,
});

export default connect(mapStateToProps, {
  getUserByCategoryAction,
  getUserByNameAction,
  followContentAction,
  getUserDetails,
  addToWishListAction,
})(withRouter(ProfileListPage));
