import {
	REGISTER_SUCCESS,
	LOGIN_SUCCESS,
	GET_ERRORS,
	GET_USER_REGISTER_DATA,
	PAYMENT_SUCCESS,
	FORGOT_PASSWOD_SUCCESS,
	RESET_PASSWOD_SUCCESS,
	USER_ID_VERIFICATION_SUCCESS,
	UPDATE_PROFILE_SUCCESS,
	COUNTRIES_DATA,
	STATES_DATA,
	GET_SUBSCRIPTION_PRICE_RANGE,
	GET_NOTIFICATION,
	NOTIFICATION_UPDATE,
} from './types';
import axios from 'axios';
import {BASE_URL} from '../components/Constant';
import authHeader from '../utils/AuthHeader';

export const registerAction = (userPersonalData) => (dispatch) => {
	axios
		.post(BASE_URL+'/register',userPersonalData,{
			headers : {'content-type': 'multipart/form-data'}
		})
		.then(response => {
			if(response.data.isError){
				dispatch({
	                type: GET_ERRORS,
	                payload: response.data,
	            })
			}
			else{
				dispatch({
	                type: REGISTER_SUCCESS,
	                payload: response.data,
	            })
			}
			// dispatch(something(response));
			
		})
		.catch(error => {
			if(error.response){
				dispatch({
	                type: GET_ERRORS,
	                payload: error.response.data,
	            })
	        }
			
		});
}

export const userIdVerifyAction = (userIdentificationData,user_id) => (dispatch) => {
	axios
		.post(BASE_URL+'/verifyId/'+user_id,userIdentificationData,{
			headers : {'content-type': 'multipart/form-data'}
		})
		.then(response => {
			if(response.data.error){
				dispatch({
	                type: GET_ERRORS,
	                payload: response.data,
	            })
			}
			else{
				
				dispatch({
					type : USER_ID_VERIFICATION_SUCCESS,
					payload : response.data
				})
				
			}
		})
		.catch(error => {
		})
}

export const updateProfileAction = (userProfileData,user_id) => (dispatch) => {
	axios
		.post(BASE_URL+'/updateProfile/'+user_id,userProfileData,{
			headers : {'content-type': 'multipart/form-data'}
		})
		.then(response => {
			if(response.data.isError){
				dispatch({
	                type: GET_ERRORS,
	                payload: response.data,
	            })
			}
			else{
				dispatch({
	                type: UPDATE_PROFILE_SUCCESS,
	                payload: response.data,
	            })
			}
			
		})
		.catch(error => {
			dispatch({
                type: GET_ERRORS,
                payload: error.response.data,
            })
			
		});
}

export const getCountries = () => (dispatch) => {
	axios
		.get(BASE_URL+'/getCountries')
		.then(response => {
			if(response.data.isError){
				dispatch({
	                type: GET_ERRORS,
	                payload: response.data,
	            })
			}
			else{
				dispatch({
	                type: COUNTRIES_DATA,
	                payload: response.data,
	            })
			}
		})
		.catch(error => {
			
		})
}

export const getStatesAction = (CountryName) => (dispatch) => {
	axios
		.get(BASE_URL+'/getStates/'+CountryName)
		.then(response => {
			if(response.data.isError){
				dispatch({
	                type: GET_ERRORS,
	                payload: response.data,
	            })
			}
			else{
				dispatch({
	                type: STATES_DATA,
	                payload: response.data,
	            })
			}
		})
		.catch(error => {
			// 
		})
}	

export const userPaymentDetailsAction = (userPaymentData,user_id) => (dispatch) => {
	axios
		.post(BASE_URL+'/addPaymentDetails/'+user_id,userPaymentData)
		.then(response => {
			if(response.data.isError){
				dispatch({
					type : GET_ERRORS,
					payload : response.data
				})
			}
			else{
				dispatch({
					type : PAYMENT_SUCCESS,
					payload : response.data
				})
			}
		})
		.catch(error => {

		})
}


export const loginAction = (loginData) => (dispatch) => {
	axios
		.post(BASE_URL+'/login',loginData)
		.then(response => {
			let user_data = response.data
			localStorage.setItem('step',user_data.step);
			localStorage.setItem('url',user_data.url);
			if(user_data.isError){
				dispatch({
					type:'GET_ERRORS',
					payload : user_data
				})
			}
			else{
				let userData = {
					access_token : user_data.access_token,
					expires_at : user_data.expires_at,
					userId : user_data.data.userId,
					DisplayName : user_data.data.DisplayName,
					Username : user_data.data.Username,
					ProfilePic : user_data.data.ProfilePic,
					Role : user_data.data.Role,
					firstName : user_data.data.Forename,
					lastName : user_data.data.Surname,
					remember_me : user_data.remember_me,
					cardDetails : user_data.data.cardDetails,
					twoFactor : user_data.two_factor,
					country : user_data.data.Country,
					FirstSixDigit : user_data.data.FirstSixDigit,
					merchantRefnum:user_data.data.merchantRefnum,
					ProfilePicThumbnail:user_data.data.ProfilePicThumbnail
				}
				if(user_data.two_factor === 0){
					localStorage.setItem('user_data',encodeURIComponent(JSON.stringify(userData)));
				}
				else{
					localStorage.setItem('temp_user_data',encodeURIComponent(JSON.stringify(userData)));
				}
				localStorage.setItem('userRole',user_data.data.Role);
				dispatch(getLoginUserDataAction(userData));
			}

		})
		.catch(error => {
			dispatch({
				type:'GET_ERRORS',
				payload : error.response.data
			})
		})
}

export const getLoginUserDataAction = (userData) => {
	return {
		type : LOGIN_SUCCESS,
		payload : userData
	}
}

export const forgotPasswordAction = (emailData) => (dispatch) => {
	axios
		.post(BASE_URL+'/forgotPassword',emailData)
		.then(response => {
			dispatch({
				type : FORGOT_PASSWOD_SUCCESS,
				payload : response.data
			})
		})
		.catch(error => {
			dispatch({
				type : GET_ERRORS,
				payload : error.response.data
			})
		})
}

export const resetPasswordAction = (resetPasswordData) => (dispatch) => {
	axios
		.post(BASE_URL+'/resetPassword',resetPasswordData)
		.then(response => {
			dispatch({
				type : RESET_PASSWOD_SUCCESS,
				payload : response.data
			})
		})
		.catch(error => {
			dispatch({
				type : GET_ERRORS,
				payload : error.response.data
			})
		})
}

export const logoutAction = (loginUserId,historyProps) => (dispatch) => {
	localStorage.removeItem('user_data');
	localStorage.removeItem('wishListCount');
	localStorage.clear()
	dispatch(getLoginUserDataAction({}))

	axios
		.post(BASE_URL+'/logout/'+loginUserId)
		.then(response => {
		})
		.catch(error => {
		})
	if(historyProps && historyProps !== undefined){
		window.location.href = '/login'
	}
	else{
		window.location.href = "/login"
	}
}

export const emptyError = () => (dispatch) => {
	dispatch({

		type : GET_ERRORS,
		payload : []
	})
}

export const clearData = () => (dispatch) => {
	dispatch({

		type : REGISTER_SUCCESS,
		payload : []
	})
}

export const verifyEmailAction = (userid) => (dispatch) => {
	axios
		.post(BASE_URL+'/verifyemail/'+userid)
		.then(response => {
			if(response.data.data && !response.data.data.isError){
				dispatch({
					type : GET_USER_REGISTER_DATA,
					payload : response.data.data
				})
			}
			else{
				dispatch({
					type : GET_ERRORS,
					payload : response.data.data
				})
			}

		})
}

export const getSubscriptionRangeAction = () => (dispatch) => {
	axios
		.get(BASE_URL+'/getSubscriptionRange')
		.then(response => {
			if(!response.isError){
				dispatch({
					type : GET_SUBSCRIPTION_PRICE_RANGE,
					payload : response.data
				})
			}
			else{
				dispatch({
					type : GET_ERRORS,
					payload : response.data.data
				})
			}

		})
}

export const notificationShowAction = (userId) => (dispatch) => {
	axios
		.get(BASE_URL+'/NotificationShow/'+userId,{
			headers : authHeader(),
		})
		.then(response => {
			if(!response.isError){
				dispatch({
					type : GET_NOTIFICATION,
					payload : response.data
				})
			}
			else{
				dispatch({
					type : GET_ERRORS,
					payload : response.data.data
				})
			}

		})
}

export const notificationStatusUpdateAction = (userId) => (dispatch) => {
	axios
		.post(BASE_URL+'/NotificationUpdate/'+userId,{},{
			headers : authHeader(),
		})
		.then(response => {
			if(!response.isError){
				dispatch({
					type : NOTIFICATION_UPDATE,
					payload : response.data
				})
			}
			else{
				dispatch({
					type : GET_ERRORS,
					payload : response.data.data
				})
			}

		})
}

export const twoFactorAction = (userId,twoFactorData) => (dispatch) => {
	axios
		.post(BASE_URL+'/twoFactor/'+userId,twoFactorData)
		.then(response => {
			let user_data = response.data
			if(!response.data.isError){
				let userData = localStorage.getItem('temp_user_data')
				localStorage.setItem('user_data',userData);
				localStorage.removeItem('temp_user_data')
				dispatch(getLoginUserDataAction(JSON.parse(decodeURIComponent(userData))));
			}
			else{
				dispatch({
					type : GET_ERRORS,
					payload : response.data
				})
			}

		})
}
export const twoFactorSendCodeAgainAction = (userId) => (dispatch) => {
	axios
		.post(BASE_URL+'/twoFactorSendCodeAgain/'+userId)
		.then(response => {
			let user_data = response.data
			if(!response.data.isError){

			}
			else{
				dispatch({
					type : GET_ERRORS,
					payload : response.data
				})
			}

		})
}
