import React, { Component, useState } from "react";
import {connect} from 'react-redux';
import {Link , withRouter} from 'react-router-dom';
import {
    updatePaymentDetailsAction,
    emptyError,
    getAccountBalanceAction,
    withdrawBalanceAction,
} from '../../actions/accountSetting'
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import ModalComponent from "../ModalComponent";
import {ERROR_MESSAGE} from '../Constant';
import {logoutAction} from "../../actions/auth";
import {ReactComponent as InformationIcon} from '../../assets/images/svgs/information.svg'

export class AccountDetails extends Component {
    constructor(props){
        super(props)

        this.state = {
            AccountName : '',
            BankName:'',
            AccountNumber : '',
            SortCode : '',
            isError : false,
            errors : [],
            submitButtonClick : 0,//if click on submit and then go to another tab and come again to this tab it shows success toaster so toaster show only when there is click on button,
            CountryCode : '',
            updatePaymentClick : 0,
            ButtonLoader : false,
            errorMessage : '',
            modalShow : false,
            user_id : '',
            accountBalance : 0,
            withdrawButtonClick : 0,
            showPendingWithdraw:false,
            minPrice : '',
            maxPrice : '',
            minWithdrawl:'',
            maxWithdralAvoidFee:'',
            fees:'',
            cannotwithDraw:false

        }

        this.inputChange = this.inputChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.handleClose = this.handleClose.bind(this);
        this.show = this.show.bind(this);
        this.withdrawFund = this.withdrawFund.bind(this)
    }

    componentDidMount(){
        let user_id = this.props.authenticatedUserData.userId;

        this.setState({
            user_id : user_id
        })
        this.props.getAccountBalanceAction(user_id)
    }
    withdrawFund(){
        this.setState({
            withdrawButtonClick : 1,
        })
        this.props.withdrawBalanceAction(this.state.user_id, this.state.accountBalance)
    }

    UNSAFE_componentWillReceiveProps(nextProps){


        if(nextProps.TabName === 'Account'){
            this.setState({
                ButtonLoader : false,
            })

            if(!nextProps.userRegisterData.getSubscriptionPriceRange.isError){
                let minPrice = nextProps.userRegisterData.getSubscriptionPriceRange.min_value
                let maxPrice = nextProps.userRegisterData.getSubscriptionPriceRange.max_value
                let minWithdrawl = nextProps.userRegisterData.getSubscriptionPriceRange.min_withdrawl
                let maxWithdralAvoidFee = nextProps.userRegisterData.getSubscriptionPriceRange.max_withdral_avoid_fee
                let fees = nextProps.userRegisterData.getSubscriptionPriceRange.fees
                this.setState({
                    minPrice : minPrice,
                    maxPrice : maxPrice,
                    minWithdrawl:minWithdrawl,
                    maxWithdralAvoidFee:maxWithdralAvoidFee,
                    fees:fees
                })
            }

            if(nextProps.getUserData.usersData !== ''){
                let userData =  nextProps.getUserData.usersData;
                this.setState({
                    AccountName : userData.AccountName,
                    BankName : userData.BankName,
                    AccountNumber : userData.AccountNumber,
                    SortCode : userData.SortCode,
                    CountryCode : userData.CountryCode,
                })
            }

            if(nextProps.getUserData.updatedPaymentData && nextProps.getUserData.updatedPaymentData.data  && nextProps.getUserData.updatedPaymentData.data !== ''){
                let userData =  nextProps.getUserData.updatedPaymentData.data;
                this.setState({
                    AccountName : userData.AccountName,
                    BankName:userData.BankName,
                    AccountNumber : userData.AccountNumber,
                    SortCode : userData.SortCode,
                })
            }
            // if(nextProps.getUserData.updatedPaymentData && nextProps.getUserData.updatedPaymentData.data !== '' && !nextProps.errors.errors.isError){
            if(this.state.updatePaymentClick ===1){
                if(nextProps.getUserData.updatedPaymentData && nextProps.getUserData.updatedPaymentData.data !== '' && !nextProps.getUserData.updatedPaymentData.isError){
                    let errorMessage = ''
                    let modalShow = false
                    if(this.state.submitButtonClick === 1 && (this.state.AccountName !== null || this.state.AccountNumber !== null || this.state.SortCode !== null)){
                        // toast.success('Updated!')
                        errorMessage = nextProps.getUserData.updatedPaymentData.message
                        modalShow = true
                    }
                    this.setState({
                        errors : [],
                        isError : false,
                        submitButtonClick : 0,
                        errorMessage : errorMessage,
                        modalShow : modalShow,
                    })
                }
                else if(nextProps.getUserData.updatedPaymentData.isError){
                    this.setState({
                        errors : nextProps.getUserData.updatedPaymentData.error,
                        isError : nextProps.getUserData.updatedPaymentData.isError,
                        errorMessage : ERROR_MESSAGE,
                        modalShow : true,
                    })
                    // toast.error('Error!')
                }
            }
            if(nextProps.accountBalance && nextProps.accountBalance.balance){
                this.setState({
                    accountBalance : nextProps.accountBalance.balance,
                })
            }
            if(nextProps.accountBalance && nextProps.accountBalance.pendingWithdrawls){
                if(nextProps.accountBalance.pendingWithdrawls > 0){
                    this.setState({
                        showPendingWithdraw : true
                    })
                }
            }

            if(!nextProps.withdrawBalance.iswithdrawBalanceError && this.state.withdrawButtonClick === 1){
                this.setState({
                    withdrawButtonClick : 0,
                    isError : false,
                    errorMessage : nextProps.withdrawBalance.message,
                    modalShow : true,
                    showPendingWithdraw: true
                })
                // toast.success(nextProps.withdrawBalance.message);
            }
            else if(this.state.withdrawButtonClick === 1){
                let canwithdraw = false;
                if(nextProps.withdrawBalance.iswithdrawBalanceError){
                    if(nextProps.withdrawBalance.cannotwithDraw==true){
                        canwithdraw = true;
                    }
                }
                this.setState({
                    withdrawButtonClick : 0,
                    isError : true,
                    errorMessage : nextProps.withdrawBalance.message,
                    modalShow : true,
                    showPendingWithdraw: false,
                    cannotwithDraw : canwithdraw
                })
                // toast.error(nextProps.withdrawBalance.message)
            }
        }
        else{
            this.setState({
                updatePaymentClick : 0,
                isError : false,
                errors : [],
            })
        }
    }

    //on imput change save value in state
    inputChange(e){
        this.setState({
            [e.target.name] : e.target.value,
        })
    }

    handleClose() {
        this.setState({
            modalShow : false,
            errorMessage : '',
        })
    }

    show() {
        useState(false);
    }

    onSubmit(e){
        e.preventDefault();
        this.setState({
            ButtonLoader : true,
            submitButtonClick : 1,
            updatePaymentClick : 1,
        })
        //if there is error so error reducer length is not null so everytime its go inside error so if there is error after submit we clean the error reducer so it goes inside success
        if(this.state.isError){
            this.props.emptyError()    
        }
        let userPaymentData = {
            AccountName : this.state.AccountName,
            BankName: this.state.BankName,
            AccountNumber : this.state.AccountNumber,
            SortCode : this.state.SortCode,
        }
        let user_id = parseInt(this.props.authenticatedUserData.userId)
        this.props.updatePaymentDetailsAction(userPaymentData,user_id)
    }

  	render() {
        const {AccountName , AccountNumber , SortCode , CountryCode , ButtonLoader , errors , isError, modalShow , errorMessage,BankName,accountBalance,showPendingWithdraw,cannotwithDraw} = this.state


    	return (
      		<div className="container p-lg-5 p-sm-0 p-2 cardsetting_container ">
                {ButtonLoader ? 
                    <div id="preloader">
                        <div className="preloader_div">
                        </div>
                    </div>
                    :
                    ""
                }

                <div className="container p-5 closeacc_container account-withdraw-balance-tab-container">
                    <div>
                        <h3 className="pb-3" >Withdraw Balance</h3>
                        <p>Account Balance : <strong>{'£'+accountBalance}</strong></p>
                        <button className="btn btn-info mt-3 font-weight-bold" onClick={this.withdrawFund} disabled={showPendingWithdraw}>Request Balance Withdrawal</button>
                        <p></p>
                        {(showPendingWithdraw) ?  <p className="font-weight-bold">We are processing your withdrawal request, please check back later. We'll email you once completed.</p> : "" }
                    </div>
                    {(modalShow) ?
                        <ModalComponent isError={isError} errorMessage={errorMessage} show={this.show} handleClose={this.handleClose}/>
                        :
                        ""
                    }
                    <p></p>
                    <p className="pb-3">Please fill out your UK banking details in order for us to payout any withdrawal request to you. Please note you must have a balance of at least £{this.state.minWithdrawl} to withdraw and any withdrawals below £{this.state.maxWithdralAvoidFee} will be charged a £{this.state.fees} admin fee deducted from your payout</p>
               <p>If you wish to withdraw to a non-UK bank, please <Link to="/contact-us" className="doclinks">contact us</Link> to discuss a solution.</p>
                </div>

                {
                    (CountryCode === 'gb' || CountryCode === 'GB')
                    ?
                
                		<div className="col-lg-6 col-md-9 col-sm-12 account-withdraw-balance-tab">
                  			<form onSubmit={this.onSubmit}>

                    			<div className="form-group">
                      				<label htmlFor="cardholdername" className="label_text">
                        				Account Holder Name{" "}
                      				</label>
                      				<input type="text" className="form-control input_text" id="card_holder" name="AccountName" placeholder="Enter Account Holder Full Name"   onChange={this.inputChange} defaultValue={AccountName}/>

                                    {(isError && errors.AccountName) ?
                                        <div id="alert-box2" className="font-weight-bold mt-1">
                                            <span className="pr-2">
                                                <svg
                                                    width="1em"
                                                    height="1em"
                                                    viewBox="0 0 16 16"
                                                    className="bi bi-exclamation-circle"
                                                    fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                      fill-rule="evenodd"
                                                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                    />
                                                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                </svg>
                                            </span>
                                            {errors.AccountName}
                                        </div>
                                        :
                                        ""
                                    }
                                    <div id="alert-box3" className="mt-3 usernamealert">
            <span>
            <InformationIcon/>
        </span>
                                        Must match your bank's details for verification.
                                    </div>
                    			</div>
                                <div className="form-group">
                                    <label htmlFor="bankname" className="label_text">
                                        Bank Name{" "}
                                    </label>
                                    <input type="text" className="form-control input_text" id="bank_name" name="BankName" placeholder="Enter Bank Name"   onChange={this.inputChange} defaultValue={BankName}/>

                                    {(isError && errors.BankName) ?
                                        <div id="alert-box2" className="font-weight-bold mt-1">
                                            <span className="pr-2">
                                                <svg
                                                    width="1em"
                                                    height="1em"
                                                    viewBox="0 0 16 16"
                                                    className="bi bi-exclamation-circle"
                                                    fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                    />
                                                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                </svg>
                                            </span>
                                            {errors.BankName}
                                        </div>
                                        :
                                        ""
                                    }
                                </div>

                    			<div className="form-group">
                      				<label htmlFor="cardnumber" className="label_text">
                        				Account Number{" "}
                      				</label>
                      				<input type="text" className="form-control input_text" id="card_number" name="AccountNumber" placeholder="Enter Your Account Number" onChange={this.inputChange} defaultValue={AccountNumber} />

                                    {(isError && errors.AccountNumber) ?
                                        <div id="alert-box2" className="font-weight-bold mt-1">
                                            <span className="pr-2">
                                                <svg
                                                    width="1em"
                                                    height="1em"
                                                    viewBox="0 0 16 16"
                                                    className="bi bi-exclamation-circle"
                                                    fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                      fill-rule="evenodd"
                                                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                    />
                                                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                </svg>
                                            </span>
                                            {errors.AccountNumber}
                                        </div>
                                        :
                                        ""
                                    }
                    			</div>

                    			<div className="form-group w-lg-50 w-sm-100">
                      				<label htmlFor="ccv" className="label_text">
                        				Sort Code{" "}
                     	 			</label>
                      				<input type="text" className="form-control input_text" id="card_number" name="SortCode" placeholder="Enter Your Sort Code"  onChange={this.inputChange} defaultValue={SortCode} />

                                    {(isError && errors.SortCode) ?
                                        <div id="alert-box2" className="font-weight-bold mt-1">
                                            <span className="pr-2">
                                                <svg
                                                    width="1em"
                                                    height="1em"
                                                    viewBox="0 0 16 16"
                                                    className="bi bi-exclamation-circle"
                                                    fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                      fill-rule="evenodd"
                                                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                    />
                                                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                </svg>
                                            </span>
                                            {errors.SortCode}
                                        </div>
                                        :
                                        ""
                                    }
                    			</div>
                                <div id="alert-box4" className="mt-3 usernamealert">
            <span>
            <InformationIcon/>
        </span>
                                    Please check these details are correct, so you can be paid correctly and promptly.
                                </div>
                                {(showPendingWithdraw) ?
                                <div id="" className="mt-3 usernamealert alertbox">
            <span>
            <InformationIcon/>
        </span>
                                    We are processing your existing withdrawal request so you cannot change these details at this time, to urgently change/correct these details please contact support. We'll email you once the withdrawal is completed.
                                </div>
                                    : ""}
                                {!ButtonLoader ?
                    			    <button className="btn " id="submit_btn" disabled={showPendingWithdraw}>Submit</button>
                                :
                                    <button className="btn next font-weight-bold" type="button" disabled="disabled">Processing..</button> 
                                }
                  			</form>
                		</div>
                    :
                       ""
                }




                {(modalShow) ?
                    <ModalComponent isError={isError} errorMessage={errorMessage} show={this.show} handleClose={this.handleClose}/>
                :
                    ""
                }

      		</div>
    	);
  	}
}

const mapStateToProps = (state) => ({
    getUserData : state.userAccountData,
    authenticatedUserData : state.auth.loginSuccessResult,
    accountBalance : state.userAccountData.getAccountBalance,
    withdrawBalance : state.userAccountData.withdrawBalance,
    userRegisterData : state.auth
})


export default connect(
    mapStateToProps,
    {updatePaymentDetailsAction, emptyError,getAccountBalanceAction,withdrawBalanceAction , logoutAction}
)(withRouter(AccountDetails));
