import React, { Component , useState } from "react";
import {connect} from 'react-redux';
import {withRouter , Link} from 'react-router-dom';
import { updateProfileAction , emptyError} from '../../actions/accountSetting'
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Modal from "react-bootstrap/Modal";
import ModalComponent from "../ModalComponent";
import {ERROR_MESSAGE} from '../Constant';
import {getSubscriptionRangeAction} from "../../actions/auth";
import {ReactComponent as InformationIcon} from '../../assets/images/svgs/information.svg'
import {ReactComponent as ErrorinfoIcon} from '../../assets/images/svgs/errorinfo.svg'

export class General extends Component {

    constructor(props){
        super(props)

        this.state = {
            Forename : '',
            Surname : '',
            Email : '',
            DisplayName : '',
            ProfilePic : '',
            ProfilePicView : '',
            ProfileVideo : '',
            ProfileVideoView : '',
            ProfileBanner : '',
            ProfileBannerView :'' ,
            errors : [],
            isError : false,
            submitButtonClick : 0,//if click on submit and then go to another tab and come again to this tab it shows success toaster so toaster show only when there is click on button
            ButtonLoader : false,
            errorMessage : '',
            modalShow : false,
            Bio:'',
            SubscriptionPrice: '',
            minPrice : '',
            maxPrice : '',
            Role: '',
            CoverPhoto: '',
            Location : '',
            TwitterURL : '',
            AmazonURL : '',
            IDVerified:false
        }

        this.inputChange = this.inputChange.bind(this)
        this.inputChangePrice = this.inputChangePrice.bind(this)
        this.imageUpload = this.imageUpload.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.handleClose = this.handleClose.bind(this);
        this.show = this.show.bind(this);
    }
    componentDidMount(){
        this.props.getSubscriptionRangeAction()
    }

    UNSAFE_componentWillReceiveProps(nextProps,prevProps){
        this.setState({
            ButtonLoader : false,
        })
        if(!nextProps.userRegisterData.getSubscriptionPriceRange.isError){
            let minPrice = nextProps.userRegisterData.getSubscriptionPriceRange.min_value
            let maxPrice = nextProps.userRegisterData.getSubscriptionPriceRange.max_value
            this.setState({
                minPrice : minPrice,
                maxPrice : maxPrice,
            })
        }

        if(nextProps.getUserData.usersData && nextProps.getUserData.usersData !== ''){
            this.setState({
                Forename : nextProps.getUserData.usersData.Forename,
                Surname : nextProps.getUserData.usersData.Surname,
                Email : nextProps.getUserData.usersData.Email,
                DisplayName : nextProps.getUserData.usersData.DisplayName,
                ProfilePic : nextProps.getUserData.usersData.ProfilePic,
                ProfileBanner : nextProps.getUserData.usersData.ProfileBanner,
                Bio : nextProps.getUserData.usersData.Bio,
                SubscriptionPrice: nextProps.getUserData.usersData.SubscriptionPrice,
                Role:nextProps.getUserData.usersData.Role,
                Location:nextProps.getUserData.usersData.Location,
                TwitterURL:nextProps.getUserData.usersData.TwitterURL,
                AmazonURL:nextProps.getUserData.usersData.AmazonURL,
                Tags:nextProps.getUserData.usersData.Tags,
                ProfileVideo:nextProps.getUserData.usersData.ProfileVideo,
                IDVerified:nextProps.getUserData.usersData.IDVerified

            })
        }

        

        //updated profile response
        if(nextProps.getUserData.updatedProfileData && nextProps.getUserData.updatedProfileData && nextProps.getUserData.updatedProfileData.data !== ''){

            let ProfilePic = nextProps.getUserData.updatedProfileData.data.ProfilePic

            let ProfileBanner = nextProps.getUserData.updatedProfileData.data.ProfileBanner
            // if(this.state.ProfileBannerView !== ''){
            //     ProfileBanner = this.state.ProfileBanner
            // }
            this.setState({
                Forename : nextProps.getUserData.updatedProfileData.data.Forename,
                Surname : nextProps.getUserData.updatedProfileData.data.Surname,
                Email : nextProps.getUserData.updatedProfileData.data.Email,
                DisplayName : nextProps.getUserData.updatedProfileData.data.DisplayName,
                ProfilePic : ProfilePic,
                ProfileBanner : ProfileBanner,
                Bio:nextProps.getUserData.updatedProfileData.data.Bio,
                SubscriptionPrice: nextProps.getUserData.updatedProfileData.data.SubscriptionPrice,
                Location: nextProps.getUserData.updatedProfileData.data.Location,
                TwitterURL: nextProps.getUserData.updatedProfileData.data.TwitterURL,
                AmazonURL: nextProps.getUserData.updatedProfileData.data.AmazonURL,
                Tags: nextProps.getUserData.updatedProfileData.data.Tags,
                IDVerified:nextProps.getUserData.usersData.IDVerified
            })
        }

        if(nextProps.TabName === 'General'){
            if(nextProps.getUserData.updatedProfileData && nextProps.getUserData.updatedProfileData.data !== '' && !nextProps.errors.errors.isError){
                let errorMessage = ''
                let modalShow = false
                if(this.state.submitButtonClick === 1){
                    errorMessage = 'Profile Details Updated'
                    modalShow = true
                    // toast.success('Updated!')
                }
                this.setState({
                    errors : [],
                    isError : false,
                    submitButtonClick : 0,
                    errorMessage : errorMessage,
                    modalShow : modalShow,
                    ProfilePicView : '',
                    ProfileBannerView : '',
                })
            }
            else if(nextProps.errors.errors.isError && nextProps.errors.errors.length !== 0){
                this.setState({
                    errors : nextProps.errors.errors.error,
                    isError : nextProps.errors.errors.isError,
                    errorMessage : ERROR_MESSAGE,
                    modalShow : true,
                    Forename:this.state.Forename,
                    Surname:this.state.Surname,
                    Email:this.state.Email,
                    Bio:this.state.Bio,
                    SubscriptionPrice:this.state.SubscriptionPrice,
                    Location:this.state.Location,
                    TwitterURL:this.state.TwitterURL,
                    AmazonURL:this.state.AmazonURL,
                    Tags:this.state.Tags,
                    IDVerified:this.state.IDVerified
                })
                // toast.error('Error!')
            }
        }
    }

    inputChange(e){
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    inputChangePrice(e){
        this.setState({
            'SubscriptionPrice' : e.target.value
        })
    }


    imageUpload(e){

        var file = e.target.files[0]
        if (file) {
            var image_file = URL.createObjectURL(file)
            this.setState({[e.target.name+'View']: image_file})
            var field_name = e.target.name
            this.setState({
                [field_name]: file,
            })

            let reader = new FileReader()
            // let demo = ''
            reader.onload = (e) =>
                this.setState({
                    [field_name+'View'] : reader.result,
                })
            reader.readAsDataURL(file)
        }
    }

    handleClose() {
        this.setState({
            modalShow : false,
            errorMessage : '',
        })
    }

    show() {
        useState(false);
    }

    onSubmit(e){
        e.preventDefault();
        this.setState({
            ButtonLoader : true,
            submitButtonClick : 1,
        })
        //if there is error so error reducer length is not null so everytime its go inside error so if there is error after submit we clean the error reducer so it goes inside success
        if(this.state.isError){
            this.props.emptyError()    
        }

        let ProfilePhotoFlag = 0
        let ProfilePic = this.state.ProfilePic
        let generalPic = process.env.REACT_APP_MERCHANT_URL+'/images/default-avatar.png';
        if(ProfilePic !== '' && ProfilePic !== null && ProfilePic !== 'null' && ProfilePic!=generalPic){
            if(ProfilePic.name === undefined){
                ProfilePhotoFlag = 1
                ProfilePic = ''
            }
        } else{
            ProfilePhotoFlag = 0
        }

        let CoverPhotoFlag = 0
        let ProfileBanner = this.state.ProfileBanner
        if(ProfileBanner !== '' && ProfileBanner !== null && ProfileBanner !== 'null'){
            if(ProfileBanner.name === undefined){
                CoverPhotoFlag = 1
                ProfileBanner = ''
            }
        }
        let ProfileVideoFlag = 0
        let ProfileVideo = this.state.ProfileVideo
        let ProfileVideoName = ''
        if(ProfileVideo !== '' && ProfileVideo !== null && ProfileVideo !== 'null'){
            if(ProfileVideo.name === undefined){
                ProfileVideoFlag = 1
                ProfileVideo = ''
            } else{
                ProfileVideoName = ProfileVideo.type
            }
        }


        let userAccountData = new FormData();
        userAccountData.append('FirstName',this.state.Forename);
        userAccountData.append('LastName',this.state.Surname);
        userAccountData.append('Email',this.state.Email);
        userAccountData.append('DisplayName',this.state.DisplayName);
        userAccountData.append('ProfilePhoto',ProfilePic);
        userAccountData.append('ProfilePhotoFlag',ProfilePhotoFlag);
        if(this.state.Role=='ContentCreator'){
            userAccountData.append('CoverPhoto',ProfileBanner);
            userAccountData.append('CoverPhotoFlag',CoverPhotoFlag);
            userAccountData.append('Bio',this.state.Bio);
            userAccountData.append('SubscriptionPrice',this.state.SubscriptionPrice);
            userAccountData.append('ProfileVideo',ProfileVideo);
            userAccountData.append('ProfileVideoFlag',ProfileVideoFlag);
            userAccountData.append('Location',this.state.Location);
            userAccountData.append('TwitterURL',this.state.TwitterURL);
            userAccountData.append('AmazonURL',this.state.AmazonURL);
            userAccountData.append('Tags',this.state.Tags);
            userAccountData.append('ProfileVideoName',ProfileVideoName);
        }


        // let userAccountData = {
        //     FirstName : this.state.Forename,
        //     LastName : this.state.Surname,
        //     Email : this.state.Email,
        //     DisplayName : this.state.DisplayName,
        //     ProfilePhoto  : this.state.ProfilePic,
        //     CoverPhoto  : this.state.ProfileBanner
        // }
        this.props.updateProfileAction(userAccountData,this.props.authenticatedUserData.userId)
    }

    render() {
        const {Forename , Surname , Email , DisplayName , ProfilePic ,ProfilePicView , ProfileBanner , ProfileBannerView ,isError ,errors , ButtonLoader, modalShow , errorMessage, Bio,SubscriptionPrice,minPrice,maxPrice,Role,ProfileVideo , ProfileVideoView,Location,TwitterURL,AmazonURL, Tags,IDVerified } = this.state

        return (
            <div className="container">
                {ButtonLoader ? 
                    <div id="preloader">
                        <div className="preloader_div">
                        </div>
                    </div>
                    :
                    ""
                }
                <div className="general_setting p-sm-0 p-2">
                    <form onSubmit={this.onSubmit}>
                        <div className="row">
                            <div className="general_left col-lg-6 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="firstname" className="label_text">
                                        First Name{" "}<span className="text-danger">*</span>
                                    </label>
                                    <input  type="text" className="form-control input_text" id="" placeholder="Enter your Name" name="Forename" defaultValue={Forename} onChange={this.inputChange}  disabled={(IDVerified) ? true : false} />

                                    {(isError && errors.FirstName) ?
                                        <div id="alert-box2" className="font-weight-bold mt-1">
                                            <span className="pr-2">
                                                <ErrorinfoIcon/>
                                            </span>
                                            {errors.FirstName}
                                        </div>
                                        :
                                        ""
                                    }
                                </div>
                                <div className="form-group">
                                    <label htmlFor="lastname" className="label_text">
                                        Last Name{" "}<span className="text-danger">*</span>
                                    </label>
                                    <input type="text" className="form-control input_text" id="" placeholder="Your Last Name" name="Surname" defaultValue={Surname} onChange={this.inputChange} disabled={(IDVerified) ? true : false} />

                                    {(isError && errors.LastName) ? 
                                        <div id="alert-box2" className="font-weight-bold mt-1">
                                            <span className="pr-2">
                                        <ErrorinfoIcon/>
                                            </span>
                                            {errors.LastName}
                                        </div>
                                        :
                                        ""
                                    }
                                </div>
                                <div className="form-group">
                                    <label htmlFor="emailaddress" className="label_text">
                                        Email Address{" "}<span className="text-danger">*</span>
                                    </label>
                                    <input type="email" className="form-control input_text" id="" placeholder="Your email Address" name="Email" defaultValue={Email}  disabled="disabled"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="displayname" className="label_text">
                                        Display Name{" "}
                                    </label>
                                    <input type="text" className="form-control input_text"  id="" placeholder="Your Display Name" name="DisplayName" defaultValue={DisplayName} onChange={this.inputChange} minlength="3" maxlength="26" />
                                </div>
                                <div id="alert-box9" className="mt-3 align-items-center alertbox "><span><InformationIcon/></span>Your Display Name should be between 3 &amp; 26 characters.
                                </div>

                                {(Role=='ContentCreator') ?
                                <div className="form-group">
                                    <label htmlFor="bio" className="label_text">
                                        Bio{" "}
                                    </label>
                                    <input type="text" className="form-control input_text " id="" name="Bio" placeholder="Enter your Bio"  onChange={this.inputChange} defaultValue={Bio} minLength="20" maxlength="200"></input>
                                </div>
                                    : ""}

                                <div id="alert-box9" className="mt-3 align-items-center alertbox "><span><InformationIcon/></span>Your bio should be between 20 and 200 characters.
                                </div>
                                {(isError && errors.Bio) ?
                                    <div  className="font-weight-bold mt-1 alertbox-validation">
                                                        <span className="pr-2">
                                                            <ErrorinfoIcon/>
                                                        </span>
                                        {errors.Bio}
                                    </div>
                                    :
                                    ""
                                }
                                {(Role == 'ContentCreator') ?
                                    <div className="form-group">
                                        <label htmlFor="location" className="label_text">
                                            Location
                                        </label>
                                        <input type="text" className="form-control input_text" id="" name="Location" placeholder="Enter your Location"  onChange={this.inputChange} defaultValue={Location}></input>
                                    </div>
                                    :
                                    ""
                                }
                                {(Role == 'ContentCreator') ?
                                    <div className="form-group">
                                        <label htmlFor="tags" className="label_text">
                                            Tags
                                        </label>
                                        <input type="textarea" className="form-control input_text " id="" name="Tags" placeholder="Enter Tags"  onChange={this.inputChange} defaultValue={Tags}></input>
                                    </div>

                                    :
                                    ""
                                }
                                {(Role=='ContentCreator') ?
                                <div className="form-group">
                                    <label htmlFor="priceplan" className="label_text">
                                        Subscription price<span className="text-danger">*</span>
                                    </label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1" style={{borderTopLeftRadius:"20px",borderBottomLeftRadius:"20px",fontSize:"small",borderColor:"#00A99D"}}>£</span>
                                        </div>

                                        <input type="number" className="form-control input_text" id="" name="SubscriptionPrice" placeholder={"minimum "+minPrice + (maxPrice !== '0' && maxPrice !== '' && maxPrice !== 'null' && maxPrice !== null ? " maximum " + maxPrice : "")}  onChange={this.inputChangePrice} defaultValue={SubscriptionPrice} step="any"></input>
                                    </div>

                                    <div id="alert-box3" className="mt-3 px-1 custom_alert" style={{lineHeight: "20px"}}>
                                                    <span>
                                                    <InformationIcon/>
                                                    </span>
                                        This is the price (in £-GBP) that  your desirers (followers) will pay to follow you and access your content.
                                    </div>
                                    {(isError && errors.SubscriptionPrice) ?
                                        <div id="alert-box2" className="font-weight-bold mt-1">
                                                        <span className="pr-2">
                                                            <ErrorinfoIcon/>
                                                        </span>
                                            {errors.SubscriptionPrice}
                                        </div>
                                        :
                                        ""
                                    }
                                </div>
                                    : "" }

                            </div>

                            <div className="general_right col-lg-6 col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="profilephoto" className="label_text">
                                            Profile Photo{" "}  {(Role == 'ContentCreator') ? <span className="text-danger">*</span> : "" }
                                        </label>
                                        <div className=" pic_upload_box">
                                            <input type="file" id="" name="ProfilePic" onChange={this.imageUpload} accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.gif,.GIF,.webp"/>
                                            {(ProfilePic === '' || ProfilePic === null || ProfilePic === 'null') ?
                                            <Link to="#"><svg width="3rem" height="3rem" viewBox="0 0 16 16" className="bi bi-camera-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg" >
                                                <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                <path fillRule="evenodd" d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"  /> </svg>
                                                <span>Upload Profile Image</span>
                                            </Link>
                                            :
                                                (ProfilePicView !== '') ?
                                                    <div className="img_preview d-flex">
                                                        <img alt="" src={ProfilePicView ? ProfilePicView : process.env.PUBLIC_URL + "/frontend/images/photo.png"} />
                                                        <span>Upload Profile Image</span>
                                                    </div>
                                                :
                                                    <div className="img_preview d-flex">
                                                        <img alt="" src={ProfilePic ? ProfilePic : process.env.PUBLIC_URL + "/frontend/images/photo.png"} />
                                                        <span>Upload Profile Image</span>
                                                    </div>
                                            }
                                            
                                        </div>
                                </div>
                                <div id="alert-box4" className="mt-3 px-1 align-items-center custom_alert" style={{lineHeight: "20px"}}>
                                                    <span><InformationIcon/></span>
                                    Square crop photos will work best for your profile photo, landscape photos will work best for your cover photo. But please remember no nudity is permitted in these photos, save the good stuff for your paying followers in your posts!
                                </div>
                                {(isError && errors.ProfilePhoto) ?
                                    <div id="alert-box8" className="font-weight-bold mt-1 alertbox-validation">
                                                        <span className="pr-2">
                                                            <ErrorinfoIcon/>
                                                        </span>
                                        {errors.ProfilePhoto}
                                    </div>
                                    :
                                    ""
                                }


                                {(Role == 'ContentCreator') ?
                                    <div className="form-group">
                                        <label htmlFor="coverphoto" className="label_text">
                                            Cover Photo{" "}<span className="text-danger">*</span>
                                        </label>

                                        <div className=" pic_upload_box">
                                            <input type="file" id="" name="ProfileBanner" onChange={this.imageUpload}
                                                   accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.webp"/>
                                            {(ProfileBanner === '' || ProfileBanner === null || ProfileBanner === 'null') ?
                                                <Link to="#">
                                                    <svg width="3rem" height="3rem" viewBox="0 0 16 16"
                                                         className="bi bi-camera-fill" fill="currentColor"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                                        <path fillRule="evenodd"
                                                              d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"/>
                                                    </svg>
                                                    <span>Upload Cover Image</span>
                                                </Link>
                                                :
                                                (ProfileBannerView !== '') ?
                                                    <div className="img_preview d-flex">
                                                        <img alt=""
                                                             src={ProfileBannerView ? ProfileBannerView : process.env.PUBLIC_URL + "/frontend/images/photo.png"}/>
                                                        <span>Upload Cover Image</span>
                                                    </div>
                                                    :
                                                    <div className="img_preview d-flex">
                                                        <img alt=""
                                                             src={ProfileBanner ? ProfileBanner : process.env.PUBLIC_URL + "/frontend/images/photo.png"}/>
                                                        <span>Upload Cover Image</span>
                                                    </div>
                                            }

                                        </div>
                                    </div>
                                    :
                                    ""
                                }
                                {(isError && errors.CoverPhoto) ?
                                    <div id="alert-box6" className="font-weight-bold mt-1">
                                                        <span className="pr-2">
                                                            <ErrorinfoIcon/>
                                                        </span>
                                        {errors.CoverPhoto}
                                    </div>
                                    :
                                    ""
                                }
                                {(Role == 'ContentCreator') ?
                                    <div className="form-group">
                                        <label htmlFor="profilevideo" className="label_text">
                                            Add Profile Video
                                        </label>
                                        <div className=" pic_upload_box">
                                            <input type="file" id="" onChange={this.imageUpload} name="ProfileVideo"
                                                   accept=".gif,.GIF,.jpeg,.png"/>
                                            {(ProfileVideo === '' && ProfileVideoView === '') ?
                                                <Link to="#">
                                                    <svg width="3rem" height="3rem" viewBox="0 0 16 16"
                                                         className="bi bi-camera-fill" fill="currentColor"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                                        <path fillRule="evenodd"
                                                              d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"/>
                                                    </svg>
                                                    <span>Upload Image</span>
                                                </Link>
                                                :
                                                (ProfileVideoView !== '') ?
                                                    <div className="img_preview d-flex">
                                                        <img alt="" src={ProfileVideoView}/>
                                                        <span>Upload Image</span>
                                                    </div>
                                                    :
                                                    <div className="img_preview d-flex">
                                                        <img alt="" src={ProfileVideo}/>
                                                        <span>Upload Image</span>
                                                    </div>
                                            }

                                        </div>
                                        {(isError && errors.ProfileVideo) ?
                                            <div id="alert-box2" className="font-weight-bold mt-1">
                                                        <span className="pr-2">
                                                                <ErrorinfoIcon/>
                                                        </span>
                                                Your profile video must be one of the following file types: gif
                                                (animated).
                                            </div>
                                            :
                                            ""
                                        }

                                    </div>
                                    :
                                    ""
                                }
                                {(Role == 'ContentCreator') ?
                                <div className="form-group">
                                    <label htmlFor="twitter" className="label_text">
                                        Twitter Profile URL
                                    </label>
                                    <input type="text" className="form-control input_text" id="" name="TwitterURL" placeholder="Enter your twitter url"  onChange={this.inputChange} defaultValue={TwitterURL}></input>
                                </div>
                                    :
                                    ""
                                }
                                {(isError && errors.TwitterURL) ?
                                    <div id="alert-box6" className="font-weight-bold mt-1 alertbox-validation">
                                                        <span className="pr-2">
                                                            <ErrorinfoIcon/>
                                                        </span>
                                        The Twitter URL is invalid.
                                    </div>
                                    :
                                    ""
                                }
                                {(Role == 'ContentCreator') ?
                                <div className="form-group">
                                    <label htmlFor="amzon" className="label_text">
                                        Amazon Wishlist URL
                                    </label>
                                    <input type="text" className="form-control input_text" id="" name="AmazonURL" placeholder="Enter your amazon url"  onChange={this.inputChange} defaultValue={AmazonURL}></input>
                                </div>
                                    :
                                    ""
                                }
                                {(isError && errors.AmazonURL) ?
                                    <div className="font-weight-bold mt-1 alertbox-validation">
                                                        <span className="pr-2">
                                                            <ErrorinfoIcon/>
                                                        </span>
                                        The Amazon URL is invalid.
                                    </div>
                                    :
                                    ""
                                }


                                {!ButtonLoader ?
                                    <button className="btn info_btn mt-3" type="submit">Submit Information</button>
                                :
                                    <button className="btn info_btn mt-3" type="button" disabled="disabled">Processing..</button> 
                                }
                            </div>
                        </div>
                    </form>
                </div>

                {(modalShow) ?
                    <ModalComponent isError={isError} errorMessage={errorMessage} show={this.show} handleClose={this.handleClose}/>
                :
                    ""
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    getUserData : state.userAccountData,
    authenticatedUserData : state.auth.loginSuccessResult,
    errors : state.errors,
    userRegisterData : state.auth,
})

export default connect(
    mapStateToProps,
    {updateProfileAction , emptyError,getSubscriptionRangeAction}
)(withRouter(General));
