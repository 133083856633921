import {
    REGISTER_SUCCESS,
    LOGIN_SUCCESS,
    GET_USER_REGISTER_DATA,
    PAYMENT_SUCCESS,
    FORGOT_PASSWOD_SUCCESS,
    RESET_PASSWOD_SUCCESS,
    USER_ID_VERIFICATION_SUCCESS,
    UPDATE_PROFILE_SUCCESS,
    COUNTRIES_DATA,
    STATES_DATA,
    GET_SUBSCRIPTION_PRICE_RANGE,
    GET_NOTIFICATION,
    NOTIFICATION_UPDATE,
} from "../actions/types"

const initialState = {
    registerSuccessResult : "",
    loginSuccessResult : {},
    getRegisterUserData : "",
    paymentCardDetails : "",
    forgotPassword : "",
    resetPassword : "",
    idVerificaionSuccessResult : "",
    updateProfileSuccess : "",
    countriesData : "",
    statesData : "",
    getSubscriptionPriceRange : '',
    getNotificationData : '',
    notificationStatusUpdate : '',
    twoFactorSave : '',
}

export default function(state = initialState, action) {
    switch (action.type) {
        case REGISTER_SUCCESS:
            return {
                ...state,
                registerSuccessResult: action.payload
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                loginSuccessResult: action.payload
            }

        case GET_USER_REGISTER_DATA:
            return {
                ...state,
                getRegisterUserData: action.payload
            }
        case PAYMENT_SUCCESS:
            return {
                ...state,
                paymentCardDetails: action.payload
            }
        case FORGOT_PASSWOD_SUCCESS:
            return {
                ...state,
                forgotPassword: action.payload
            }
        case RESET_PASSWOD_SUCCESS:
            return {
                ...state,
                resetPassword: action.payload
            }
        case USER_ID_VERIFICATION_SUCCESS:
            return {
                ...state,
                idVerificaionSuccessResult: action.payload
            }
        case UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                updateProfileSuccess: action.payload
            }
        case COUNTRIES_DATA:
            return {
                ...state,
                countriesData: action.payload
            }
        case STATES_DATA:
            return {
                ...state,
                statesData: action.payload
            }
        case GET_SUBSCRIPTION_PRICE_RANGE:
            return {
                ...state,
                getSubscriptionPriceRange: action.payload
            }
        case GET_NOTIFICATION:
            return {
                ...state,
                getNotificationData: action.payload
            }
        case NOTIFICATION_UPDATE:
            return {
                ...state,
                notificationStatusUpdate: action.payload
            }
        default:
            return state
    }
}
