import React, { Component, useState } from "react";
import {loginAction , twoFactorAction ,emptyError,twoFactorSendCodeAgainAction} from '../../actions/auth';
import {connect} from 'react-redux';
import PropTypes from "prop-types"
import {withRouter , Link} from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Modal from "react-bootstrap/Modal";
import ModalComponent from "../ModalComponent";
import {ERROR_MESSAGE} from '../Constant';
import MetaTags from "react-meta-tags";

export class Login extends Component {
    constructor(props){
        super(props);

        this.state={
            email : '',
            password : '',
            remember_me : false,
            errors : [],
            isError : false,
            ButtonLoader : false,
            loginButtonClick : 0,
            errorMessage : '',
            modalShow : false,
            authenticationBox : false,
            code : '',
            authButtonClick : 0,
            loginUserId : 0,
            registrationStatus : 0,
            isResendDisabled: false
        }

        this.inputChange = this.inputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onAuthSubmit = this.onAuthSubmit.bind(this)
        this.handleClose = this.handleClose.bind(this);
        this.show = this.show.bind(this);
        this.resendTwoFactor = this.resendTwoFactor.bind(this)

        localStorage.removeItem('searchedBlog')
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        // this.setState({
        //     ButtonLoader : false
        // })
        if(!nextProps.loginSuccessResult.loginSuccessResult.isError && nextProps.loginSuccessResult.loginSuccessResult && Object.keys(nextProps.loginSuccessResult.loginSuccessResult).length !== 0  && this.state.authButtonClick === 0 && !nextProps.errors.errors.isError && this.state.loginButtonClick === 1){
            // toast.success(nextProps.loginSuccessResult.forgotPassword.message);
            let errorMessage = ''
            let modalShow = false
            let authenticationBox = true
            if(nextProps.loginSuccessResult.loginSuccessResult.twoFactor === 0){
                errorMessage = "Login Successfully."
                modalShow = true
                authenticationBox = false
            }
            else{
                errorMessage = "Verify Your Id."
            }
            this.setState({
                errors : [],
                isError : false,
                errorMessage :errorMessage,
                modalShow : modalShow,
                authenticationBox : authenticationBox,
                loginUserId : nextProps.loginSuccessResult.loginSuccessResult.userId,
                code : '',
                ButtonLoader : false,
                registrationStatus : 0,
            })
            if(nextProps.loginSuccessResult.loginSuccessResult.twoFactor === 0){
                setTimeout(function(){
                    this.props.history.push('/feed')
                }.bind(this),500)
            }
        }
        else if(nextProps.errors && nextProps.errors.errors && this.state.loginButtonClick === 1  && this.state.authButtonClick === 0){
            let errorMessage = ERROR_MESSAGE;
            let errors = nextProps.errors.errors.error
            let isError = nextProps.errors.errors.isError


            if(nextProps.errors.errors && nextProps.errors.errors.message){
                errorMessage = nextProps.errors.errors.message
                errors = nextProps.errors.errors.message
                isError = nextProps.errors.errors.isError
            }
            // toast.error(errorMessage);
            this.setState({
                errors : errors,
                isError : isError,
                loginButtonClick : 0,
                errorMessage : errorMessage,
                modalShow : true,
                ButtonLoader : false,
                registrationStatus : nextProps.errors.errors.registrationStatus
            })
        }


        if(!nextProps.loginSuccessResult.loginSuccessResult.isError && nextProps.loginSuccessResult.loginSuccessResult  && this.state.authButtonClick === 1 && !nextProps.errors.errors.isError && this.state.loginButtonClick === 0){
            this.setState({
                errors : [],
                isError : false,
                errorMessage :"Login Successfully.",
                modalShow : true,
                authButtonClick : 0,
                authenticationBox : false,
            })
            setTimeout(function(){
                this.props.history.push('/feed')
            }.bind(this),500)
        }
        else if(nextProps.errors && nextProps.errors.errors && this.state.loginButtonClick === 0  && this.state.authButtonClick === 1){
            // toast.error(errorMessage);
            let errors = ''
            let isError = true
            if(nextProps.errors.errors.error && nextProps.errors.errors.error.code){
                errors = nextProps.errors.errors.error.code
            }
            else if(nextProps.errors.errors.message){
                errors = nextProps.errors.errors.message
            }
            this.setState({
                errors : errors,
                isError : isError,
                authButtonClick : 0,
                errorMessage : errors,
                modalShow : true,
                ButtonLoader : false,
            })
        }
    }

    inputChange(e){
        let remember_me = this.state.remember_me;
        if(e.target.name === 'remember_me'){
            remember_me = !this.state.remember_me
        }
        this.setState({
            [e.target.name] : e.target.value,
            remember_me : remember_me
        })
    }
    resendTwoFactor(){
        this.setState({isResendDisabled : true})
        this.props.twoFactorSendCodeAgainAction(this.state.loginUserId);
        this.setState({
            errors : [],
            isError : false,
            errorMessage :'We have resent the security code to your email address. Please check your email inbox shortly and type the code here.',
            modalShow : true,
        })
        setTimeout(() => this.setState({ isResendDisabled: false }), 60000);
    }

    handleClose() {
        this.setState({
            modalShow : false,
            errorMessage : '',
        })
        if(localStorage.getItem('step') !='' && localStorage.getItem('step')!=5 && localStorage.getItem('step')!= 'undefined'){
            this.props.history.push(localStorage.getItem('url'))
        }
    }

    show() {
        useState(false);
    } 

    onSubmit(e){
        e.preventDefault()
        this.setState({
            ButtonLoader : true,
            loginButtonClick : 1,
            authButtonClick : 0,
        })

        if(this.state.isError){
            this.props.emptyError()
        }

        let remember_me = 'No';
        if(this.state.remember_me === true){
            remember_me = 'Yes';
        }

        var loginData={
            email : this.state.email,
            password : this.state.password,
            remember_me : remember_me
        }
        
        this.props.loginAction(loginData)
    }

    onAuthSubmit(e){
        e.preventDefault()

         if(this.state.isError){
            this.props.emptyError()
        }

        this.setState({
            ButtonLoader : true,
            authButtonClick : 1,
            loginButtonClick : 0,
        })

        var twoFactorData = {
            code : this.state.code
        }
        this.props.twoFactorAction(this.state.loginUserId,twoFactorData)
    }


    render() {
        const {errors , isError ,ButtonLoader , remember_me , modalShow , errorMessage , authenticationBox , code , registrationStatus} = this.state  

        return (
            <>
            <MetaTags>
                <title>Login | DesireMe</title>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="__OGURL__"/>
                <link rel="canonical" href="__OGURL__" />
                <meta property="og:image" content="https://desire.me/images/ogimage.png"/>
                <meta property="og:site_name" content="Desire Me"/>
                <meta name="title" content="__METATITLE__" />
            </MetaTags>
                <Header />
                    {ButtonLoader ? 
                        <div id="preloader">
                            <div className="preloader_div">
                            </div>
                        </div>
                        :
                        ""
                    }
                    <section id="loginpage">
                        <div className="container-fluid login_container">
                            <div className="login_box mx-auto ">
                                <h1 className="font-weight-bold pt-3 pb-4 text-center">Login</h1>
                                {
                                    (!authenticationBox)
                                    ?
                                        <form onSubmit={this.onSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="username" className="label_text">
                                                    Email{" "}<span className="text-danger">*</span>
                                                </label>
                                                <input type="email" className="form-control input_text" id="" name="email" placeholder="Enter Your Email" onChange={this.inputChange}/>
                                                
                                                {(isError && errors.email) ? 
                                                    <div id="alert-box2" className="font-weight-bold mt-1">
                                                        <span className="pr-2">
                                                            <svg
                                                            width="1em"
                                                            height="1em"
                                                            viewBox="0 0 16 16"
                                                            className="bi bi-exclamation-circle"
                                                            fill="currentColor"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                            <path
                                                            fill-rule="evenodd"
                                                            d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                            />
                                                            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                            </svg>
                                                        </span>
                                                        {errors.email}
                                                    </div>
                                                    :
                                                    ""
                                                }
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="password" className="label_text">
                                                    Password{" "}<span className="text-danger">*</span>
                                                </label>
                                                <input type="password" className="form-control input_text" id="" name="password" placeholder="Enter Your Password" onChange={this.inputChange}/>
                                                {(isError && errors.password) ? 
                                                    <div id="alert-box2" className="font-weight-bold mt-1">
                                                        <span className="pr-2">
                                                            <svg
                                                            width="1em"
                                                            height="1em"
                                                            viewBox="0 0 16 16"
                                                            className="bi bi-exclamation-circle"
                                                            fill="currentColor"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                            <path
                                                            fill-rule="evenodd"
                                                            d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                            />
                                                            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                            </svg>
                                                        </span>
                                                        {errors.password}
                                                    </div>
                                                    :
                                                    ""
                                                }
                                            </div>

                                            <div className="form-group form-checks checkout forget_link d-flex justify-content-between">
                                            
                                                <label className="control control--checkbox text-small">Remember Me
                                                    <input type="checkbox" name="remember_me" id="html" onChange={this.inputChange} defaultValue={remember_me}/>
                                                    <div className="control__indicator"></div>
                                                </label>


                                                <Link to="/forget-password">
                                                    {" "}
                                                    <p className="view_text pt-1">Forgotten password?</p>
                                                </Link>
                                            </div>
                                            {!ButtonLoader ?
                                                <button className="btn next font-weight-bold" type="submit">SUBMIT</button>  
                                                :
                                                <button className="btn next font-weight-bold" type="button" disabled="disabled">Processing..</button> 
                                            } 
                                        </form>
                                    :
                                        <form onSubmit={this.onAuthSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="username" className="label_text">
                                                    Please enter the two way authentication send to you via email{" "}<span className="text-danger">*</span>
                                                </label>
                                                <input type="number" className="form-control input_text" id="" name="code" placeholder="Enter Your Code" onChange={this.inputChange} value={code}/>
                                                
                                                {(isError) ? 
                                                    <div id="alert-box2" className="font-weight-bold mt-1">
                                                        <span className="pr-2">
                                                            <svg
                                                            width="1em"
                                                            height="1em"
                                                            viewBox="0 0 16 16"
                                                            className="bi bi-exclamation-circle"
                                                            fill="currentColor"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                            <path
                                                            fill-rule="evenodd"
                                                            d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                            />
                                                            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                            </svg>
                                                        </span>
                                                        {errors}
                                                    </div>
                                                    :
                                                    ""
                                                }
                                            </div>
                                            {!ButtonLoader ?
                                                <button className="btn next font-weight-bold" type="submit">SUBMIT</button>  
                                                :
                                                <button className="btn next font-weight-bold" type="button" disabled="disabled">Processing..</button> 
                                            }
                                {!this.state.isResendDisabled && <p className="resendtwofactor view_text pt-1" onClick={this.resendTwoFactor}>Resend Code?</p>}
                                <p style={{fontSize: 10, marginTop: 10 }}>
                                  Please wait 60 seconds before requesting another security code via email, please check your spam folder also. 
                                  Please also note that if you request a new code, your old security code emailed to you will no longer work.
                                </p>
                                        </form>
                                }
                                {(modalShow) ?
                                    <ModalComponent isError={isError} errorMessage={errorMessage} show={this.show} handleClose={this.handleClose} registrationStatus={registrationStatus}/>
                                :
                                    ""
                                }
                            </div>
                        </div>
                    </section>
                <Footer />
            </>
        );
    }
}

Login.propTypes = {
   loginAction : PropTypes.func.isRequired
}

export const mapStateToProps = (state) => ({
    loginSuccessResult : state.auth,
    errors : state.errors
})

export default connect(
    mapStateToProps,
    {loginAction , twoFactorAction , emptyError, twoFactorSendCodeAgainAction}
)(withRouter(Login));
