import React, { Component } from 'react';
import MessageIcon from './Messageicon';
import Chatlist from './Chatlist';
import Cardmain from './Cardmain';
import Slides from './Slides';
import Profile from './Profile';
import { connect } from 'react-redux';
import { getUsersByUserId, getMessagesBySenderId, sendMessagesById , deleteChat} from '../../../actions/chat';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import PaymentModal from './Paymentmodal';
import PaymentSetup from './PaymentSetup';
import DeleteConfirmModal from './DeleteConfirmModal';
import {BASE_URL,PUSHER_KEY,PUSHER_CLUSTER} from '../../Constant';
class MainChat extends Component {
   slideIndex = 1;
   constructor(props) {
      super(props);
      this.state = {
         currentUser: this.props.authenticatedUserData,
         selectedUser: null,
         messages: [],
         contacts: [],
         showPaymentModal:false,
         showPaymentSetupModal:false,
         showDeleteConfirmModal:false,
         loader:false,
         page: 0
      }
      this.showChat = this.showChat.bind(this);
   }
   componentDidMount() {      
      let user_data = JSON.parse(decodeURIComponent(localStorage.getItem('user_data')));
      window.Echo = new Echo({
         broadcaster: 'pusher',
         key: PUSHER_KEY,
         cluster: PUSHER_CLUSTER,
         encrypted: true,
         logToConsole: true,
         auth: {
            headers: {
               'X-CSRF-Token': user_data.access_token
            }
         },
         authEndpoint: BASE_URL+'/channels/authorize'
      });
      var userId = this.props.authenticatedUserData.userId;
      var channel = window.Echo.private(`chat.${userId}`);
      channel.listen('MessageSent', ((data) => {
         this.addRecievedMessage(data);
      }));
      var active_inactive = window.Echo.private(`status.${userId}`);
      active_inactive.listen('UserStatusEvent', ((data) => {
         this.changeUserActiveStatus(data);
      }));
      this.props.getUsersByUserId(this.props.authenticatedUserData.userId);

      
   }

   changeUserActiveStatus = async (data) => {
      let availableContacts = this.state.contacts;
      let contactIndex = availableContacts.findIndex(contact => contact.userId === data.userId);
      availableContacts[contactIndex] = {...availableContacts[contactIndex],...data};
      await this.setState({...this.state,contacts:availableContacts});
   }
   showPayModal = async () => {
      await this.setState({...this.state,showPaymentModal:true})
   }
   hidePayModal = async () => {
      await this.setState({...this.state,showPaymentModal:false})
   }
   showPaySetupModal = async () => {
      await this.setState({...this.state,showPaymentSetupModal:true})
   }
   hidePaySetupModal = async () => {
      await this.setState({...this.state,showPaymentSetupModal:false})
   }
   showDeleteModal = async () => {
      if(this.state.messages.length > 0){
         await this.setState({...this.state,showDeleteConfirmModal:true})
      }
   }
   hideDeleteModal = async (action) => {
      var loader = false
      if(action === "Yes"){
         this.deleteChat();
         loader = true
      }
      if(action==='No'){
         loader = false
      }
      await this.setState({...this.state,showDeleteConfirmModal:false,loader:loader})
   }
   addRecievedMessage = async(data) => {
      if(this.state.selectedUser && this.state.selectedUser.userId === data.senderId){
         let messages = this.state.messages;
         messages.push(data);
         await this.setState({...this.state,messages:messages});
      }else{
         let availableContacts = this.state.contacts;
         let contactIndex = availableContacts.findIndex(contact => contact.userId === data.senderId);
         availableContacts[contactIndex] = {...availableContacts[contactIndex],unRead:true,unReadCount:availableContacts[contactIndex].unReadCount ? ++availableContacts[contactIndex].unReadCount:1};
         await this.setState({...this.state,contacts:availableContacts});
      }
   }
   changeSelected = async (user) => {      
      if(this.state.selectedUser){
         if(this.state.selectedUser.userId !== user.userId){
            await this.setState({ ...this.state, selectedUser: null ,messages:[]});
         }
      }
      let availableContacts = this.state.contacts;
      let contactIndex = availableContacts.findIndex(contact => contact.userId === user.userId);
      availableContacts[contactIndex] = {...availableContacts[contactIndex],unRead:false,unReadCount:0};
      await this.setState({ ...this.state, selectedUser: user ,contacts:availableContacts, loader: true, page: 0});
      this.props.getMessagesBySenderId(user.userId, this.props.authenticatedUserData.userId, this.state.page);
   }
   showChat = (e) => {
      e.preventDefault();      
      let body =document.getElementsByClassName("card-body msg_card_body")                 
      if (window.innerWidth >= 991 ? body[0].scrollTop === 0 : body[1].scrollTop === 0) {         
         this.setState({page: this.state.page + 1, loader: true}, () => {
            this.props.getMessagesBySenderId(this.state.selectedUser.userId, this.props.authenticatedUserData.userId, this.state.page);
            window.innerWidth >= 991 ? body[0].removeEventListener("scroll", this.showChat) :
             body[1].removeEventListener("scroll", this.showChat)
         })        
      }  
   }
   removeSelectedUser = async () => {
      await this.setState({ ...this.state, selectedUser: null, messages: [] });
   }
   deleteChat = async () => {
      this.setState({...this.state,loader:true})
      this.props.deleteChat(this.state.selectedUser.userId, this.state.currentUser.userId);
      //for getting list of messages
      setTimeout(() => {
         this.props.getMessagesBySenderId(this.state.selectedUser.userId, this.state.currentUser.userId);
         this.setState({...this.state,loader:false})
      }, 100);
   }
   sendMessage = (data) => {      
      let dataToSend = new FormData();
      dataToSend.append('message',data.message);
      if(data.tip){
         dataToSend.append('tip',true)
         dataToSend.append('tipmessage',data.tipmessage)
         dataToSend.append('sendermessage',data.sendertipmessage)
      }
      let timelimit = 200
      if(data.fileElement){
         dataToSend.append('file',data.fileElement);
         timelimit = data.fileElement.type.match('video.*') ?  5000 : 1000
      }
      this.props.sendMessagesById(this.state.currentUser.userId, this.state.selectedUser.userId, dataToSend);      
      //for getting list of messages
      this.setState({page: 0})
      setTimeout(() => {
         this.props.getMessagesBySenderId(this.state.selectedUser.userId, this.state.currentUser.userId);
      }, timelimit);
   }
   async UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.userMessages && nextProps.userMessages.data.length > 0 ) {         
         await this.setState({
            ...this.state,
            messages: this.state.page === 0 ? nextProps.userMessages.data : nextProps.userMessages.data.concat(this.state.messages),//.concat(nextProps.userMessages.data),
            loader: false
         })
      
      let s = document.getElementsByClassName("card-body msg_card_body")
          
    const html = document.documentElement;
      if(window.innerWidth >= 991){
         s[0].scrollTop = this.state.page === 0 ? 100000 : html.scrollHeight + window.innerHeight ;
         s[0].addEventListener("scroll", this.showChat);
       }
      else{         
         s[1].scrollTop = this.state.page === 0 ? 100000 : html.scrollHeight - window.pageYOffset;
         s[1].addEventListener("scroll", this.showChat);
      }  
      }
      else{         
         await this.setState({loader: false})
      }
      if (nextProps.getUsers && nextProps.getUsers.data && nextProps.getUsers.data.length > 0) {
         await this.setState({
            ...this.state,
            contacts: nextProps.getUsers.data
         })
      }
   }
   render() {
      return (
         <section id="create_post_page" className="chat_messages_page">
            {this.state.loader && <div id = "preloader"><div className = "preloader_div"> </div></div>}
            <div className="container">
            {this.state.showPaymentModal ?
                                <PaymentModal show={this.state.showPaymentModal} onClicked={this.sendMessage} handleClose={this.hidePayModal} {...this.state}/>
                            :
                                null
                            }
               {this.state.showPaymentSetupModal ?
                                <PaymentSetup show={this.state.showPaymentSetupModal} onClicked={this.sendMessage} handleClose={this.hidePaySetupModal} {...this.state}/>
                            :
                                null
                            }
               {this.state.showDeleteConfirmModal ?
                                <DeleteConfirmModal show={this.state.showDeleteConfirmModal} handleClose={this.hideDeleteModal} {...this.state}/>
                            :
                                null
                            }
               <div className="ceate_post_content bg-white text-center text-lg-left">
                  <div className="row main-div-chat-outer">
                     <div className="col-lg-3 col-md-12 col-sm-12 createpost-chat-right mobile-hide">
                        <div className="createpost_heading pl-0 p pl-lg-5 mx-auto mx-lg-0 message_content_space">
                           <MessageIcon />
                        </div>
                        <div className="elipse">
                           <Profile isWeb="true" />
                        </div>
                        <div className="chat_button mt-3 col-12 row">
                        </div>
                        <div className="panel panel-white border-top-green mt-3">
                           <div className="panel-body chat">
                              <div className="row chat-wrapper">
                                 <div className="col-md-12">
                                    <div>
                                       <div className="slimScrollDiv" style={{ position: "relative", overflow: "hidden", width: "auto", height: "450px" }}>
                                          <Chatlist contacts={this.state.contacts} currentUser={this.state.currentUser} onClicked={this.changeSelected} />
                                          <div className="slimScrollBar" style={{ width: "7px", position: "absolute", top: "0px", opacity: "0.4", display: "none", borderRadius: "7px", zIndex: 99, right: "1px", height: "478.639px", background: "rgb(0,0,0)" }} ></div>
                                          <div className="slimScrollRail" style={{ width: "7px", height: "100%", position: "absolute", top: "0px", display: "none", borderRadius: "7px", opacity: "0.2", zIndex: 90, right: "1px", background: "rgb(51, 51, 51)" }}></div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-9 col-md-12 col-sm-12 createpost-right chat mobile-hide">
                        <Cardmain onDeleteButton={this.showDeleteModal} 
                                  smallDevice="false" showPaySetupModal={this.showPaySetupModal} 
                                  showPayModal={this.showPayModal} onClicked={this.sendMessage} 
                                  onBackButton={this.removeSelectedUser} {...this.state} />
                     </div>
                  </div>
                  <div className="none_screen_desireme">
                     <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 createpost-right">
                           <div className="createpost_heading pl-0 p pl-lg-5 mx-auto mx-lg-0 message_content_space mt-4">
                              <MessageIcon />
                           </div>
                           <div className="row elipse">
                              <Profile isWeb="false" />
                           </div>
                           <div className="chat_button mt-3 col-12 row">
                           </div>
                        </div>
                     </div>

                     {this.state.selectedUser &&  <div className="col-lg-12 col-md-12 col-sm-12 createpost-right chat none_screen_desireme">
                     {/* <div className="createpost_heading pl-0 p pl-lg-5 mx-auto mx-lg-0 message_content_space mt-5">
                        <MessageIcon />
                     </div> */}
                     <Cardmain onDeleteButton={this.showDeleteModal} smallDevice="true" showPaySetupModal={this.showPaySetupModal} showPayModal={this.showPayModal} onClicked={this.sendMessage} onBackButton={this.removeSelectedUser} {...this.state}  />
                  </div>}

                    {!this.state.selectedUser &&
                        <div className="slideshow-container">
                           <Slides contacts={this.state.contacts} onClicked={this.changeSelected} />                         
                        </div>
                     }
                     {/* <div style={{ textAlign: "center", marginTop: 50, marginBottom: 50 }}>
                        <span className="dot" onClick={() => this.currentSlide(1)}></span>
                        <span className="dot" onClick={() => this.currentSlide(2)}></span>
                        <span className="dot" onClick={() => this.currentSlide(3)}></span>
                     </div> */}
                  </div>
               </div>
            </div>
         </section>
      )
   }
   showSlides = (n) => {
      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");
      if (n > slides.length) { this.slideIndex = 1 }
      if (n < 1) { this.slideIndex = slides.length }
      for (i = 0; i < slides.length; i++) {
         slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
         dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[this.slideIndex - 1].style.display = "block";
      dots[this.slideIndex - 1].className += " active";
   }
   currentSlide = (n) => {
      this.showSlides(this.slideIndex = n);
   }
}
export const mapStateToProps = (state) => ({
   authenticatedUserData: state.auth.loginSuccessResult,
   getUsers: state.chatData.getUsers,
   userMessages: state.chatData.userMessages,
   deleteChat:state.chatData.deleteChat
})
export default connect(mapStateToProps, { getUsersByUserId, getMessagesBySenderId, sendMessagesById ,deleteChat })(MainChat);
