import {
    GET_ALL_POST_DATA,
    GET_ALL_POSTS_DATA,
    GET_POSTS_SEARCH_ACTIVITY_DATA,
    GET_USER_POST,
    GET_MOST_VIEW_POST_DATA,
    GET_POPULAR_PROFILES,
    GET_POPULAR_POSTS,
    GET_SEARCH_ACTIVITY_DATA,
    GET_RECENT_POST,
    LIKE_DISLIKE_POST,
    LIKE_DISLIKE_COMMENT,
    ADD_COMMENT,
    GET_USER_ALBUMS,
    ADD_ALBUM,
    ADD_POST,
    UPDATE_POST,
    DELETE_POST,
    VIEW_POST,
} from "../actions/types"

const initialState = {
    getAllPostData : "",
    getAllPostsData : "",
    getUserPost : "",
    getMostViewedPostData : '',
    getPopularProfiles : "",
    getPopularPosts : "",
    getSearchActivityData : '',
    getRecentPostData : "",
    getLikeDislikePostData : "",
    getLikeDislikeCommentData : "",
    addComment : '',
    getUserAlbums : '',
    addAlbum : "",
    addPost : "",
    updatePost : "",
    deletePost : '',
    viewPost : '',
}

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_POST_DATA:
            return {
                ...state,
                getAllPostData: action.payload
            }
        case GET_ALL_POSTS_DATA:
            return {
                ...state,
                getAllPostsData: action.payload
            }
        case GET_POSTS_SEARCH_ACTIVITY_DATA:
            return {
                ...state,
                getPostsSearchActivityData: action.payload
            }
        case GET_USER_POST:
            return {
                ...state,
                getUserPost: action.payload
            }
        case GET_MOST_VIEW_POST_DATA:
            return {
                ...state,
                getMostViewedPostData: action.payload
            }
        case GET_POPULAR_PROFILES:
            return {
                ...state,
                getPopularProfiles: action.payload
            }
        case GET_POPULAR_POSTS:
            return {
                ...state,
                getPopularPosts: action.payload
            }
        case GET_SEARCH_ACTIVITY_DATA:
            return {
                ...state,
                getSearchActivityData: action.payload
            }
        case GET_RECENT_POST:
            return {
                ...state,
                getRecentPostData: action.payload
            }
        case LIKE_DISLIKE_POST:
            return {
                ...state,
                getLikeDislikePostData: action.payload
            }
        case LIKE_DISLIKE_COMMENT:
            return {
                ...state,
                getLikeDislikeCommentData: action.payload
            }
        case ADD_COMMENT:
            return {
                ...state,
                addComment: action.payload
            }
        case GET_USER_ALBUMS:
            return {
                ...state,
                getUserAlbums: action.payload
            }
        case ADD_ALBUM:
            return {
                ...state,
                addAlbum: action.payload
            }
        case ADD_POST:
            return {
                ...state,
                addPost: action.payload
            }
        case UPDATE_POST:
            return {
                ...state,
                updatePost: action.payload
            }
        case DELETE_POST:
            return {
                ...state,
                deletePost: action.payload
            }
        case VIEW_POST:
            return {
                ...state,
                viewPost: action.payload
            }
        default:
            return state
    }
}
