import React, { Component,useState } from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from 'react-redux';
import { Link , withRouter } from 'react-router-dom';
import { sendTip } from '../../../actions/chat';
import {getUserDetails} from '../../../actions/accountSetting'
import {ReactComponent as ErrorinfoIcon} from "../../../assets/images/svgs/errorinfo.svg";
import {
    ACCOUNT_NUMBER,
    API_KEY, AUTHENTICATION_PURPOSE, BASE_URL,
    CURRENCY,
    DEVICE_CHANNEL,
    ENVIRONMENT,
    MERCHANT_URL,
    MESSAGE_CATEGORY
} from "../../Constant";
import axios from "axios";
import authHeader from "../../../utils/AuthHeader";
export class Paymentmodal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount:"",
            message:"",
            loggedinUser:"",
            charge:0,
            cvv:"",
            cardDetails : 0,
            PaysafeToken:"",
            followerList : [],
            wishList : [],
            vatadded:0,
            tip:false,
            tipmessage:'',
            sendertipmessage:'',
            Loader:false
        }
        this.inputChange = this.inputChange.bind(this)
        this.gotoupdatecard = this.gotoupdatecard.bind(this);
        this.openModal = this.openModal.bind(this)
        this.showAuthErrorPaysafe = this.showAuthErrorPaysafe.bind(this)
        this.showgetAuthorizationerror = this.showgetAuthorizationerror.bind(this)
    }
    componentDidMount(){
        let user_id = ''
        if(this.props.authenticatedUserData.userId){
            user_id = this.props.authenticatedUserData.userId
            this.props.getUserDetails(user_id)
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.getUserData && nextProps.getUserData.usersData && nextProps.getUserData.usersData.length !== ''){
            let Loader = this.state.Loader
            if(this.state.Loader){
                Loader = false
            }
            this.setState({
                cardDetails : nextProps.getUserData.usersData.cardDetails,
                followerList : nextProps.getUserData.usersData.followerList,
                wishList : nextProps.getUserData.usersData.wishList,
                Loader : Loader,
                PaysafeToken : nextProps.getUserData.usersData.PaysafeToken,
            })
        }
    }
    gotoupdatecard(){
        let TabName = btoa('CardsTab')
        localStorage.setItem('cardsTab',TabName)
        setTimeout(function(){
            this.props.history.push('/account')
        }.bind(this),200)
    }
    inputChange(e){
        this.state.cvv = e.target.value
    }
    /**
     * card type not supported error message
     */
    openModal(){
        this.setState({
            contentWriterId : 0,
            userName : '',
            paymentConfirmationModal : true,
            Loader : false,
            paymentError : 'Error Code 5050 : Card type is not supported',
            paymentErrorMessage : 'Error Code 5050 : Card type is not supported',
            paysaferesponse:'Merchant Account is not configured to support this card type'
        })
    }

    /**
     *
     * authentication credentials are invalid error
     */

    showAuthErrorPaysafe(){
        this.setState({
            contentWriterId : 0,
            userName : '',
            paymentConfirmationModal : true,
            Loader : false,
            paymentError : 'Error Code 5270 : Unauthorized access',
            paymentErrorMessage : 'Error Code 5270 : Unauthorized access',
            paysaferesponse:'The credentials do not have permission to access the requested data'
        })
    }
    /**
     * get authorization error modal
     *
     */
    showgetAuthorizationerror(){
        this.setState({
            contentWriterId : 0,
            userName : '',
            paymentConfirmationModal : true,
            Loader : false,
            paymentError : 'Error Code 5279 : Invalid credentials',
            paymentErrorMessage : 'Error Code 5279 : Invalid credentials',
            paysaferesponse:'The authentication credentials are invalid.'
        })
    }
    sendTip = () => {
         let dataToSend = {
            amount:this.state.amount,
            message:this.state.message
        }

        let message = this.state.message
        let contentWriterId = this.state.contentWriterId
        let subscriptionPrice = this.state.amount
            if(this.state.vatadded==1){
                subscriptionPrice = this.state.charge
            }

        let tip = this.state.amount
        let tipwithvat = this.state.charge
        let vatadded = this.state.vatadded
        let subscriptionPlan = this.state.subscriptionPlan
        var token = this.props.getUserData.usersData.PaysafeToken;
        let firstSixDigit = this.props.getUserData.usersData.FirstSixDigit
        let merchantRefNum = this.props.getUserData.usersData.merchantRefnum



        let cvv = this.state.cvv

        let length = cvv.length
        if(length == 0){
            this.setState({
                cvvError : true,
                cvvErrorMessage : 'CVV field is required'
            })

        }
        else if(length<3 || length >4){
            this.setState({
                cvvError : true,
                cvvErrorMessage : 'CVV length must be greater than 2 character and less than 4 character'
            })

        }
        if(length==3 || length ==4) {
            window.state = this.state
            window.props = this.props
            window.this = this

                this.setState({
                    Loader: true,
                    subscribeConfirmationModal: false,
                    loginWishListAlert: false,
                })
                if (this.state.cardDetails === 1) {
                    this.setState({
                        contentWriterId: contentWriterId,
                    })
                    let paymentData = {
                        amount: subscriptionPrice,
                        paysafe_token: this.state.PaysafeToken,
                    }
                    let subscriptionPlan = 1;
                    window.paysafe.threedsecure.start(API_KEY, {
                        environment: ENVIRONMENT,
                        accountId: ACCOUNT_NUMBER,
                        card: {
                            cardBin: firstSixDigit
                        }
                    }, function (deviceFingerprintingId, error) {
                        if (error) {
                            window.this.openModal()
                        } else {
                            var data = {
                                amount: subscriptionPrice * 100,
                                currency: CURRENCY,
                                currencyCode: CURRENCY,
                                merchantRefNum: merchantRefNum,
                                merchantUrl: MERCHANT_URL,
                                card: {paymentToken: token},
                                deviceChannel: DEVICE_CHANNEL,
                                messageCategory: MESSAGE_CATEGORY,
                                authenticationPurpose: AUTHENTICATION_PURPOSE,
                                deviceFingerprintingId: deviceFingerprintingId

                            }
                            axios.post(BASE_URL + '/authorization', {data}, {
                                headers: authHeader(),
                            })
                                .then(response => {

                                    if (response.data.status != 'COMPLETED') {
                                        var responsePayload = response.data.sdkChallengePayload

                                        window.paysafe.threedsecure.challenge(API_KEY, {
                                            environment: ENVIRONMENT,
                                            sdkChallengePayload: responsePayload
                                        }, function (authenticationID, error) {
                                            if (error) {
                                            } else {
                                                var data = {
                                                    authenticationID: authenticationID
                                                }
                                                axios.post(BASE_URL + '/getauthorization', {data}, {
                                                    headers: authHeader(),
                                                })
                                                    .then(getAuthorization => {
                                                        var paymentData = {
                                                            amount: subscriptionPrice,
                                                            paysafe_token: token,
                                                            cvv: cvv,
                                                            data: getAuthorization,
                                                            message : window.state.message,
                                                            tip:tip,
                                                            tipwithvat:tipwithvat,
                                                            vatadded: vatadded
                                                        }
                                                        window.props.sendTip(window.props.getUserData.usersData.userId, window.props.selectedUser.userId, subscriptionPlan, paymentData)
                                                        if (!window.state.message ) return;
                                                        window.props.onClicked(window.state);
                                                        window.this.setState({ ...window.state, message: '', file: null, objUrl: null, fileElement: null, Loader:false });
                                                        window.props.handleClose();
                                                        //}
                                                    })

                                            }
                                        });
                                    } else {

                                        var data = {
                                            authenticationID: response.data.id
                                        }
                                        axios.post(BASE_URL + '/getauthorization', {data}, {
                                            headers: authHeader(),
                                        })
                                            .then(getAuthorization => {
                                                var paymentData = {
                                                    amount: subscriptionPrice,
                                                    paysafe_token: token,
                                                    cvv: cvv,
                                                    data: getAuthorization,
                                                    message : window.state.message,
                                                    tip:tip,
                                                    tipwithvat:tipwithvat,
                                                    vatadded:vatadded
                                                }
                                                window.props.sendTip(window.props.getUserData.usersData.userId, window.props.selectedUser.userId, subscriptionPlan, paymentData)
                                                if (!window.state.message ) return;
                                                window.props.onClicked(window.state);
                                                window.this.setState({ ...window.state, message: '', file: null, objUrl: null, fileElement: null, Loader:false });
                                                window.props.handleClose();
                                            })

                                    }


                                })

                        }
                    });
                }

        }

        //this.props.sendTip(this.props.currentUser.userId, this.props.selectedUser.userId, dataToSend);
        //

    }
    onAmountChange = (e) => {
        let Country = this.props.getUserData.usersData.Country
        let userName = this.props.getUserData.usersData.Username
        let charge = parseFloat(e.target.value)
        let tipMessageamount = (Math.round(charge * 100) / 100).toFixed(2)
        //let tipmessage = userName + ' has just sent you a tip of £'+charge
        let tip = false
        if(Country=="United Kingdom"){
            let vat = 20;
            let vatToPay = (charge / 100) * vat;
            charge = charge + vatToPay;
            charge = (Math.round(charge * 100) / 100).toFixed(2);
            this.state.charge = charge;
            this.state.vatadded = 1
        }
        let isVat = ''
        if(Country=="United Kingdom"){
            isVat = '(£'+charge+' inc VAT)'
        }
        let tipmessage = userName + ' has just sent you a tip of £'+tipMessageamount
        let sendertipmessage = 'You just sent a tip of £'+tipMessageamount+ ' '+isVat+' to '+this.props.selectedUser.username
        this.setState({ ...this.state, amount: e.target.value,charge:charge ,tip:true,tipmessage:tipmessage,sendertipmessage:sendertipmessage});
    }
    onMessageChange = (e) => {
        let userName = this.props.getUserData.usersData.Username
        let tipMessageamount = (Math.round(this.state.amount * 100) / 100).toFixed(2)
        let charge = this.state.charge
        charge = (Math.round(charge * 100) / 100).toFixed(2);
        let Country = this.props.getUserData.usersData.Country
        let isVat = ''
        if(Country=="United Kingdom"){
            isVat = '(£'+charge+' inc VAT)'
        }
        let tipmessage = userName + ' has just sent you a tip of £'+tipMessageamount
        let sendertipmessage = 'You just sent a tip of £'+tipMessageamount+ ' '+isVat+ ' to '+this.props.selectedUser.username
        //let tipmessage = userName + ' has just sent you a tip of £'+charge
        this.setState({...this.state, message: e.target.value,tip:true,tipmessage:tipmessage,sendertipmessage:sendertipmessage });
    }
    render() {
        const {cvv,cvvError,cvvErrorMessage,loggedinUser,charge, Loader} = this.state
        return (
            <>
                <div className="modal__div">
                    <Modal size="lg" animation={false} show={this.props.show} onHide={this.props.handleClose} centered dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" className="custom__modal">
                        <Modal.Header closeButton style={{ border: "none" }}>
                            <Modal.Title id="example-custom-modal-styling-title">
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ width: "100%" }}>
        {(Loader) ?
        <div id = "preloader"><div className = "preloader_div"> < /div></div>
        :
            ""
        }
                            <div className="modal__body">
                                <div className="modal__text px-3 px-md-5 text-center">
                                    <p className="modalheading"> Send a tip to {this.props.selectedUser.name}</p>
                                    <p className="modalparagraph" >
                                        You can send a tip / payment to {this.props.selectedUser.name} with a little note, I'm sure they'll appreciate being desired by you {<img width="35px" height="35px" src={process.env.PUBLIC_URL + '/frontend/images/heart.png'}/>}
                                    </p>
                                </div>
            <div class="row">
            <div className="col-md-1"></div>
            <div className="form-group col-md-3">
            <label htmlFor="amount">Send Amount (£)</label>
        <input className="form-control input_text" type="number" id="amount" onChange={this.onAmountChange} value={this.state.amount} />
        </div>

        <div className="form-group col-md-7">
            <label htmlFor="message">Message {loggedinUser.cardType}</label>
        <input type="text" className="form-control input_text" id="email" onChange={this.onMessageChange}  value={this.state.message}/>
        </div><div className="col-md-1"></div>
            </div>
            <div className="row modal__text chat_paymentmodal">
            <div className="form-group col-md-1"></div>
            <div className="form-group col-md-3" align="center"><label htmlFor="cvv" className="cvv-popup-number"><b>Enter CVV</b> <b>({this.props.getUserData.usersData.cardType} **** {this.props.getUserData.usersData.cardLastDigit})</b> <a href="javascript:void(0)" onClick={this.gotoupdatecard}><u><b className="update-card-cvv">Update Card</b></u></a></label></div>
            <div className="form-group col-md-3"><input type="number" className="form-control input_text desire-cvv-update" id="cvv" placeholder="Enter Your CVV" name="cvv" onChange={this.inputChange} defaultValue={cvv}/>&nbsp;&nbsp;&nbsp;</div>
            <div className="form-group col-md-3" align="center"><button disabled={!this.state.amount} className="followbtn btn btn__continue mr-0 mb-3 mb-md-0 mr-md-3" type="submit" onClick={this.sendTip}>
            Send Tip
        </button>
        </div>
        <div className="form-group col-md-2 modal__text" id="subscription-modal">

        </div>
            {(this.state.cvvError) ?
            <div id="alert-box2" className="font-weight-bold mt-1">
                <span className="pr-2">
                <ErrorinfoIcon/>
                </span>
                {this.state.cvvErrorMessage}
            </div>
            :
                ""
            }

            </div>
            {(this.state.amount) ?
                                <div className="modal__text px-3 px-md-5 text-center">
                                    <p className="modalparagraph" >
                                    This will charge you card on file £{charge} (including VAT) straight away, just as a one-time payment.
                                    </p>
                                </div>
                                   :
                                        ""
            }
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </>
        );
    }
}
export const mapStateToProps = (state) => ({
    sendTip:state.chatData.sendTip,
    getUserData : state.userAccountData,
    authenticatedUserData: state.auth.loginSuccessResult,
 })
export default connect(mapStateToProps, { sendTip,getUserDetails })(withRouter(Paymentmodal));
