import {
    GET_USERS_BY_USER_ID,
    UPDATE_USER_ACTIVE_STATUS,
    GET_MESSAGE_BY_SENDERID,
    SEND_MESSAGE_BY_ID,
    DELETE_CHAT,
    SEND_TIP
} from "../actions/types"

const initialState = {
    getUsers: "",
    isUserActive: "",
    userMessages:"",
    sendMessages:""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_USERS_BY_USER_ID:
            return {
                ...state,
                getUsers: action.payload
            }
        case UPDATE_USER_ACTIVE_STATUS:
            return {
                ...state,
                isUserActive: action.payload
            }
        case GET_MESSAGE_BY_SENDERID:
            return {
                ...state,
                userMessages:action.payload
            }
        case SEND_MESSAGE_BY_ID:
            return {
                ...state,
                sendMessages:action.payload
            }
        case DELETE_CHAT:
            return {
                ...state,
                deleteChat:action.payload
            }
        case SEND_TIP:
            return {
                ...state,
                sendTip:action.payload
            }
        default:
            return state
    }
}
