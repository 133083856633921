import React, { Component, useState } from "react";
import Modal from "react-bootstrap/Modal";
export class PaymentSetup extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
            <div className="modal__div">                               
                    <Modal size="lg" animation={false} show={this.props.show} onHide={this.props.handleClose} centered dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" className="custom__modal">
                        <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
                        <Modal.Body style={{ width: "100%" }}>
                            <div className="modal__body">
                                <div className="modal__text px-3 px-md-5 pb-5 pb-md-5 text-center">
                                    <div className="pb-4">
                                        <img width="65px" height="65px" src={process.env.PUBLIC_URL + '/frontend/images/icon-cross.svg'}/>
                                    </div>
                                    <p className="pb-4" dangerouslySetInnerHTML={{__html: "Please setup payment"}}></p>
                                    <button className="btn btn__continue" onClick={this.props.handleClose}>Close</button>
                                    </div>
                                </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </>
        );
    }
}



export default PaymentSetup;
