import {
	CATEGORY_WISE_USERS_DATA,
	GET_USER_BY_SEARCH,
	FOLLOWING_CONTENT,
	ADD_TO_WISHLIST,
	GET_WISHLIST_DATA,
	REMOVE_WISHLIST_DATA,
	GET_ERRORS,
	UNFOLLOW
} from './types';

import axios from 'axios';
import {BASE_URL} from '../components/Constant';
import authHeader from '../utils/AuthHeader';

export const getUserByCategoryAction = (category, orderBy, loginUserId, start, limit) => (dispatch) => {
	axios
		.post(BASE_URL + '/getUsersbyCategory/' + category + "/" + orderBy + "/" + loginUserId + "/" + start + "/" + limit)
		.then(response => {
			if (!response.data.isError) {
				dispatch({
					type: CATEGORY_WISE_USERS_DATA,
					payload: response.data
				})
			}
		})
		.catch(error => {
		})
}

export const getUserByNameAction = (userName,loginUserId,start,limit) => (dispatch) => {
	axios
		.post(BASE_URL+'/getUsersbyName/'+userName+"/"+loginUserId+"/"+start+"/"+limit)
		.then(response => {
			dispatch({
				type : GET_USER_BY_SEARCH,
				payload : response.data	
			})
		})
		.catch(error => {

		})
}

export const followContentAction = (loginUserId,followerId,subscriptionPlan,paymentData) => (dispatch) => {
	axios
		.post(BASE_URL+'/followContent/'+loginUserId+"/"+followerId+"/"+subscriptionPlan,paymentData)
		.then(response => {
			dispatch({
				type : FOLLOWING_CONTENT,
				payload : response.data	
			})
		})
		.catch(error => {
			dispatch({
				type : GET_ERRORS,
				payload : error.response.data
			})
		})	
}
export const unfollowContentAction = (loginUserId,followerId) => (dispatch) => {
	axios
		.post(BASE_URL+'/unfollow/'+loginUserId+"/"+followerId,{},{
			headers : authHeader(),
		})
		.then(response => {
			dispatch({
				type : UNFOLLOW,
				payload : response.data
			})
		})
		.catch(error => {
			dispatch({
				type : GET_ERRORS,
				payload : error.response.data
			})
		})
}
export const addToWishListAction = (loginUserId,contentWriterId) => (dispatch) => {
	axios
		.post(BASE_URL+'/addToWishList/'+loginUserId+"/"+contentWriterId,{},{
			headers : authHeader(),
		})
		.then(response => {
			dispatch({
				type : ADD_TO_WISHLIST,
				payload : response.data	
			})
		})
		.catch(error => {
			dispatch({
				type : GET_ERRORS,
				payload : error.response.data
			})
		})	
}

export const getWishListAction = (loginUserId,start,limit) => (dispatch) => {
	axios
		.post(BASE_URL+'/getWishList/'+loginUserId+"/"+start+"/"+limit,{},{
			headers : authHeader(),
		})
		.then(response => {
			dispatch({
				type : GET_WISHLIST_DATA,
				payload : response.data	
			})
		})
		.catch(error => {

		})	
}

export const removeFromWishListAction = (loginUserId,writerId) => (dispatch) => {
	axios
		.post(BASE_URL+'/removeFromWishList/'+loginUserId+"/"+writerId,{},{
			headers : authHeader(),
		})
		.then(response => {
			dispatch({
				type : REMOVE_WISHLIST_DATA,
				payload : response.data	
			})
		})
		.catch(error => {
			// dispatch({
			// 	type : GET_ERRORS,
			// 	payload : error.response.data
			// })
		})	
}
