import {
    GET_USERS_DATA,
    PASSWORD_CHANGE_SUCCESS,
    PROFILE_UPDATE_SUCCESS,
    PAYMENT_UPDATE_SUCCESS,
    GET_ACCOUNT_BALANCE,
    WITHDRAW_BALANCE,
    CLOSE_ACCOUNT,
    REMOVE_CARD,
    NOTIFICATION_SETTING_UPDATE_SUCCESS,
    TWO_FACTOR_AUTH_SUCCESS
} from "../actions/types"

const initialState = {
    usersData : "",
    passwordChangeResult : "",
    updatedProfileData : "",
    accountClose : "",
    updatedPaymentData : "",
    getAccountBalance : "",
    withdrawBalance : "",
    removeCard : "",
    notificationsettingUpdateData : '',
}

export default function(state = initialState, action) {     
    switch (action.type) {        
        case GET_USERS_DATA:
            return {
                ...state,
                usersData: action.payload
            }
        case PASSWORD_CHANGE_SUCCESS:
            return {
                ...state,
                passwordChangeResult: action.payload
            }
        case TWO_FACTOR_AUTH_SUCCESS:            
            return {
                ...state,
                twoFactorAuth: action.payload
            }    
        case PROFILE_UPDATE_SUCCESS:
            return {
                ...state,
                updatedProfileData: action.payload
            }
        case CLOSE_ACCOUNT:
            return {
                ...state,
                accountClose: action.payload
            }
        case PAYMENT_UPDATE_SUCCESS:
            return {
                ...state,
                updatedPaymentData: action.payload
            }
        case GET_ACCOUNT_BALANCE:
            return {
                ...state,
                getAccountBalance: action.payload
            }
        case REMOVE_CARD:
            return {
                ...state,
                removeCard: action.payload
            }
        case WITHDRAW_BALANCE:
            return {
                ...state,
                withdrawBalance: action.payload
            }
        case NOTIFICATION_SETTING_UPDATE_SUCCESS:
            return {
                ...state,
                notificationsettingUpdateData: action.payload
            }
        default:
            return state
    }
}
