import React, { Component, useState } from "react";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {updateCardDetailsAction , removeUserCardAction, getUserDetails, emptyError} from '../../actions/accountSetting'
import {getStatesAction} from '../../actions/auth'
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import ModalComponent from "../ModalComponent";
import {ERROR_MESSAGE} from '../Constant';

export class Cards extends Component {
    constructor(props){
        super(props)

        this.state = {
            CountriesArray : [],
            Country : 'United Kingdom',
            CountryCode : 'gb',
            StatesArray : [],
            State : '',
            StateCode : '',
            cardNumber : '',
            cvv: '',
            cardHolderName : '',
            expiryDate : '',
            ZipCode : '',
            isError : false,
            paysafeError : false,
            errors : [],
            PaysafeToken : '',
            cardLastDigit : '',
            cardType: '',
            submitButtonClick : 0,//if click on submit and then go to another tab and come again to this tab it shows success toaster so toaster show only when there is click on button,
            countryChangeFlag : false,
            updatePaymentClick : 0,
            ButtonLoader : false,
            user_id : '',
            removeButtonClick : 0,
            errorMessage : '',
            modalShow : false,
            paysaferesponse : ''
        }

        this.inputChange = this.inputChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.handleDateChange= this.handleDateChange.bind(this)
        this.removeUserCard = this.removeUserCard.bind(this)
        this.handleClose = this.handleClose.bind(this);
        this.show = this.show.bind(this);
    }


    componentDidMount(){
        let user_id = this.props.authenticatedUserData.userId;

        this.setState({
            user_id : user_id
        })
        window.scrollTo(0,0)
    }

    removeUserCard(){
        this.setState({
            removeButtonClick : 1,
            ButtonLoader : true,
        })
        this.props.removeUserCardAction(this.state.user_id)
    }

    UNSAFE_componentWillReceiveProps(nextProps){

        if(nextProps.TabName === 'Cards'){
            this.setState({
                ButtonLoader : false,
            })

            if(this.state.CountriesArray.length === 0 && nextProps.getCountries && nextProps.getCountries.list && nextProps.getCountries.list.length > 0){

                this.setState({
                    CountriesArray : nextProps.getCountries.list
                })
            }

            //add && condition bcz if error comes and zipcode is enterd then it will take zipcode from the userdata
            if(nextProps.getUserData.usersData !== '' && ((!nextProps.getUserData.updatedPaymentData.isError && !nextProps.getUserData.updatedPaymentData.paysafeError) || !nextProps.getUserData.updatedPaymentData.paysafeError)){
                let userData =  nextProps.getUserData.usersData;
                let Country = ''
                let State = ''
                let CountryCode = ''
                let StateCode = ''
                if((userData.Country === null || userData.Country === 'null' || userData.Country === '') && !this.state.countryChangeFlag){
                    
                    Country = ''
                    CountryCode = ''
                }
                else if(this.state.countryChangeFlag && this.state.Country === ''){
                    Country = ''
                    CountryCode = ''
                }
                else{
                    Country = userData.Country
                    CountryCode = userData.CountryCode
                    if(Country !== this.state.Country && this.state.Country !== ''){
                        Country = this.state.Country
                        CountryCode = this.state.CountryCode
                    }
                    if(this.state.StatesArray.length === 0){
                        this.props.getStatesAction(Country)
                    }
                }

                if((userData.State === null || userData.State === 'null' || userData.State === '') && !this.state.countryChangeFlag){
                    State = ''
                    StateCode = ''
                }
                else if(this.state.countryChangeFlag && this.state.State === ''){
                    State = ''
                    StateCode = ''
                }
                else{
                    State = userData.State
                    StateCode = userData.StateCode
                    //add && bcz if state is null but state is already present in db then not showing
                    if(this.state.State === '' && ((userData.State === null || userData.State === 'null' || userData.State === ''))){
                        State = ''
                        StateCode = ''
                    }
                }

                let date = ''
                if(userData.cardEcpMonth !== null  && userData.cardEcpMonth !== 0){
                    let month = userData.cardEcpMonth
                    if(userData.cardEcpMonth <= 9){
                        month = '0'+userData.cardEcpMonth
                    }
                    date = new Date(month+'/01/'+userData.cardEcpYear)
                }



                this.setState({
                    // cardNumber : userData.cardNumber,
                    // expiryDate : userData.cardEcpYear+"-"+month,
                    // expiryDate : date,
                    CountryCode : CountryCode,
                    StateCode : StateCode,
                    ZipCode : userData.ZipCode,
                    Country : Country,
                    State : State,
                    PaysafeToken : userData.PaysafeToken,
                    cardLastDigit : userData.cardLastDigit,
                    cardType : userData.cardType,
                })
            }

            if(nextProps.getUserData.updatedPaymentData && nextProps.getUserData.updatedPaymentData.data  && nextProps.getUserData.updatedPaymentData.data !== '' && this.state.updatePaymentClick === 1){
                let userData =  nextProps.getUserData.updatedPaymentData.data;
                let Country = ''
                let State = ''
                if((userData.Country === null || userData.Country === 'null' || userData.Country === '') && !this.state.countryChangeFlag){
                    Country = ''
                }
                else if(this.state.countryChangeFlag && this.state.Country === ''){
                    Country = ''
                }
                else{
                    Country = userData.Country
                    if(this.state.StatesArray.length === 0){
                        this.props.getStatesAction(Country)
                    }
                }

                if((userData.State === null || userData.State === 'null' || userData.State === '') && !this.state.countryChangeFlag){
                    State = ''
                }
                else if(this.state.countryChangeFlag && this.state.State === ''){
                    State = ''
                }
                else{
                    State = userData.State
                }

                let date = ''
                if(userData.cardEcpMonth !== null){
                    let month = userData.cardEcpMonth
                    if(userData.cardEcpMonth <= 9){
                        month = '0'+userData.cardEcpMonth
                    }
                    date = new Date(month+'/01/'+userData.cardEcpYear)
                }
                this.setState({
                    // cardNumber : userData.cardNumber,
                    // expiryDate : userData.cardEcpYear+"-"+month,
                    // expiryDate : date,
                    CountryCode : userData.CountryCode,
                    StateCode : userData.StateCode,
                    ZipCode : userData.ZipCode,
                    Country : Country,
                    State : State,
                    PaysafeToken : userData.PaysafeToken,
                    cardLastDigit : userData.cardLastDigit,
                    cardType : userData.cardType,
                    updatePaymentClick : 0,
                    FirstSixDigit: userData.FirstSixDigit,
                    merchantRefnum:userData.merchantRefnum
                })

                //for updating user details(country code) for account tab
                
                this.props.getUserDetails(this.state.user_id)
            }



            if(this.state.StatesArray.length === 0 && nextProps.getStates && nextProps.getStates.list && nextProps.getStates.list.length > 0){
                this.setState({
                    StatesArray : nextProps.getStates.list,
                    State : ''
                })
            }
            // if(nextProps.getUserData.updatedPaymentData && nextProps.getUserData.updatedPaymentData.data !== '' && !nextProps.errors.errors.isError){

            if(!nextProps.getUserData.removeCard.isError && this.state.removeButtonClick === 1){
                this.setState({
                    PaysafeToken : '',
                    removeButtonClick : 0,
                    cardHolderName : '',
                    cardNumber : '',
                    expiryDate : '',
                    ZipCode : '',
                    errorMessage : nextProps.getUserData.removeCard.message,
                    modalShow : true,
                    State : '',
                    Country : 'United Kingdom',
                    CountryCode : 'gb',
                })
                //for updating user details(paysafe token) for card tab
            
                this.props.getUserDetails(this.state.user_id)
                // toast.success(nextProps.getUserData.removeCard.message);
                // this.props.history.push('/login')
            }
            else if(this.state.removeButtonClick === 1){
                this.setState({
                    removeButtonClick : 0,
                    errorMessage : 'Whoops! Something is wrong with Payment Card Process',
                    modalShow : true,
                })
                // toast.error('Error!')
            }

            if(this.state.updatePaymentClick ===1){
                if(nextProps.getUserData.updatedPaymentData && nextProps.getUserData.updatedPaymentData.data !== '' && !nextProps.getUserData.updatedPaymentData.isError && !nextProps.getUserData.updatedPaymentData.paysafeError){
                    let errorMessage = ''
                    let modalShow = false
                    if(this.state.submitButtonClick === 1 && (this.state.Country !== '' ||this.state.State !== '' || this.state.expiryDate !== '0-00' || this.state.cardNumber !== null || this.state.cardHolderName !== null || this.state.ZipCode !== null)){
                        errorMessage = nextProps.getUserData.updatedPaymentData.message
                        modalShow = true
                        // toast.success('Updated!')
                    }
                    this.setState({
                        errors : [],
                        isError : false,
                        submitButtonClick : 0,
                        errorMessage : errorMessage,
                        modalShow : modalShow,
                    })
                }
                else if(nextProps.getUserData.updatedPaymentData.isError && !nextProps.getUserData.updatedPaymentData.paysafeError && !this.state.countryChangeFlag){
                    this.setState({
                        errors : nextProps.getUserData.updatedPaymentData.error,
                        isError : nextProps.getUserData.updatedPaymentData.isError,
                        errorMessage : ERROR_MESSAGE,
                        modalShow : true,
                    })
                    // toast.error('Error!')
                }
                else if(nextProps.getUserData.updatedPaymentData.paysafeError && !this.state.countryChangeFlag){
                    this.setState({
                        errors : [],
                        isError : true,
                        errorMessage : nextProps.getUserData.updatedPaymentData.paysaferesponse,
                        modalShow : true,
                    })
                }
            }
        }
        else{
            this.setState({
                updatePaymentClick : 0,
                isError : false,
                errors : [],
            })
        }
    }

    //on imput change save value in state
    inputChange(e){
        e.preventDefault()
        if(e.target.name === 'Country'){
            var index = e.target.selectedIndex;
            var countryOptionElement = e.target.childNodes[index]
            var countryOption =  countryOptionElement.getAttribute('id');
            this.setState({
                StatesArray : [],
                State : " ",
                Country : e.target.value,
                countryChangeFlag :  true,
                CountryCode : countryOption,
            })
            this.props.getStatesAction(e.target.value)
        }

        if(e.target.name === 'State' && this.state.StatesArray.length !== 0 && this.state.CountryCode !== 'gb' && this.state.CountryCode !== 'GB'){
            var index = e.target.selectedIndex;
            var stateOptionElement = e.target.childNodes[index]
            var stateOption =  stateOptionElement.getAttribute('id');
            this.setState({
                StateCode : stateOption,
            })
        }

        this.setState({
            [e.target.name] : e.target.value,
        })
    }

    handleDateChange(e){
        this.setState({
            expiryDate : e
        })
    }

    handleClose() {
        this.setState({
            modalShow : false,
            errorMessage : '',
            paysaferesponse: '',
            paysafeError : false
        })
    }

    show() {
        useState(false);
    } 

    onSubmit(e){
        e.preventDefault();
        this.setState({
            ButtonLoader : true,
            submitButtonClick : 1,
            countryChangeFlag : false,
            updatePaymentClick : 1,
        })
        //if there is error so error reducer length is not null so everytime its go inside error so if there is error after submit we clean the error reducer so it goes inside success
        if(this.state.isError){
            this.props.emptyError()    
        }
        let month = '';
        let year = '';
        if(this.state.expiryDate !== ''){
            let date = new Date(this.state.expiryDate)
            month = date.getMonth() + 1;
            year = date.getFullYear()
        }
        let userPaymentData = {
            Country : this.state.Country,
            CountryCode : this.state.CountryCode,
            State : this.state.State,
            StateCode : this.state.StateCode,
            ZipCode : this.state.ZipCode,
            cardNumber : this.state.cardNumber,
            cardHolderName : this.state.cardHolderName,
            cardExpMonth : month,
            cardExpYear : year,
            cvv : this.state.cvv
        }
        let user_id = parseInt(this.props.authenticatedUserData.userId)
        this.props.updateCardDetailsAction(userPaymentData,user_id)
    }

  	render() {
        const {CountriesArray , StatesArray, Country , State , ZipCode, isError , errors,ButtonLoader , cardNumber ,cvv, cardHolderName , expiryDate , PaysafeToken , cardLastDigit , cardType, modalShow , errorMessage,CountryCode} = this.state


    	return (
      		<div className="container p-lg-5 p-sm-0 p-2 cardsetting_container ">
                {ButtonLoader ? 
                    <div id="preloader">
                        <div className="preloader_div">
                        </div>
                    </div>
                    :
                    ""
                }
        		<div className="col-lg-12 d-lg-flex align-items-center">
                    {
                        (PaysafeToken !== '' && PaysafeToken !== null && PaysafeToken !== 'null')
                        ?
                            <>
                                <p className="mb-0 cardno_shows">You are currently using: <b>{cardType} **** **** **** {cardLastDigit}</b></p>
                                <button className="btn btn-danger mt-3 mt-lg-0 ml-0 ml-lg-3 font-weight-bold" onClick={this.removeUserCard}>Remove Card</button>
                            </>
                        :
                    
              			<form onSubmit={this.onSubmit}>
                			<div className="form-group">
                  				<label htmlFor="formControlSelect1" className="label_text">
                    				Billing Country{" "}
                  				</label>
                  				<select className="form-control select_box" id="formControlSelect1"   onChange={this.inputChange} value={Country} name="Country">
                    				<option disabled value="">
                      					Please select Your Country
                    				</option>
    				                {CountriesArray ?
                                        CountriesArray.map(function(country){
                                            return <option key={country.id} value={country.name} id={country.code}>{country.name}</option>
                                        })
                                    :
                                    ""
                                    }
                  				</select>
                                {(isError && errors.Country) ?
                                    <div id="alert-box2" className="font-weight-bold mt-1">
                                        <span className="pr-2">
                                            <svg
                                                width="1em"
                                                height="1em"
                                                viewBox="0 0 16 16"
                                                className="bi bi-exclamation-circle"
                                                fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                  fill-rule="evenodd"
                                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                />
                                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                            </svg>
                                        </span>
                                        {errors.Country}
                                    </div>
                                    :
                                    ""
                                }
                			</div>

                            <div className="form-group">
                                <label htmlFor="formControlSelect2" className="label_text">
                                    County/Region/State{" "}
                                </label>
                                {(StatesArray.length === 0 || CountryCode === 'gb' || CountryCode === 'GB')
                                    ?
                                        <input type="text" className="form-control input_text" id="" name="State" placeholder="Enter State"  onChange={this.inputChange} value={State}></input>
                                    :
                                        <select className="form-control select_box" id="formControlSelect2"   onChange={this.inputChange} value={State} name="State">
                                            <option disabled value="">
                                                Please select Your State
                                            </option>
                                            {StatesArray ?
                                                StatesArray.map(function(state){
                                                    return <option key={state.id} value={state.name} id={state.state_code}>{state.name}</option>
                                                })
                                            :
                                            ""
                                            }
                                        </select>
                                }
                                {(isError && errors.State) ?
                                    <div id="alert-box2" className="font-weight-bold mt-1">
                                        <span className="pr-2">
                                            <svg
                                                width="1em"
                                                height="1em"
                                                viewBox="0 0 16 16"
                                                className="bi bi-exclamation-circle"
                                                fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                  fill-rule="evenodd"
                                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                />
                                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                            </svg>
                                        </span>
                                        {errors.State}
                                    </div>
                                    :
                                    ""
                                }
                            </div>
        <div className="form-group">
            <label htmlFor="ccv" className="label_text">
            Postcode / Zipcode{" "}
    </label>
        <input type="text" className="form-control input_text" id="card_number" placeholder="Enter Your ZipCode" name="ZipCode" onChange={this.inputChange} defaultValue={ZipCode} />
        {(isError && errors.ZipCode) ?
        <div id="alert-box2" className="font-weight-bold mt-1">
            <span className="pr-2">
            <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            className="bi bi-exclamation-circle"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
                >
                <path
            fillRule="evenodd"
            d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                />
                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
            </svg>
            </span>
            {errors.ZipCode}
        </div>
        :
            ""
        }
    </div>

                            <div className="form-group">
                                <label htmlFor="cardHolderName" className="label_text">
                                    Card Holder Name{" "}
                                </label>
                                <input type="text" className="form-control input_text" id="card_holder" name="cardHolderName" placeholder="Enter Card Holder Full Name"  onChange={this.inputChange} defaultValue={cardHolderName}/>
                                {(isError && errors.cardHolderName) ?
                                    <div id="alert-box2" className="font-weight-bold mt-1">
                                        <span className="pr-2">
                                            <svg
                                                width="1em"
                                                height="1em"
                                                viewBox="0 0 16 16"
                                                className="bi bi-exclamation-circle"
                                                fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                />
                                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                            </svg>
                                        </span>
                                        {errors.cardHolderName}
                                    </div>
                                    :
                                    ""
                                }
                            </div>

                            <div className="form-group">
                                <label htmlFor="cardnumber" className="label_text">
                                    Card Number{" "}
                                </label>
                                <input type="number" className="form-control input_text" id="card_number" placeholder="Enter Your Card Number" name="cardNumber" onChange={this.inputChange} defaultValue={cardNumber}/>

                                {(isError && errors.cardNumber) ?
                                    <div id="alert-box2" className="font-weight-bold mt-1">
                                        <span className="pr-2">
                                            <svg
                                                width="1em"
                                                height="1em"
                                                viewBox="0 0 16 16"
                                                className="bi bi-exclamation-circle"
                                                fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                />
                                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                            </svg>
                                        </span>
                                        {errors.cardNumber}
                                    </div>
                                    :
                                    ""
                                }
                            </div>


                			<div className="form-group w-lg-50 w-sm-100">
                  				<label htmlFor="expdate" className="label_text">
                    				Expiration Date{" "}
                  				</label>
                                {/*
                  				<input type="month" className="form-control input_text" id="" name="expiryDate" placeholder="Enter Your Expiration Date" defaultValue={expiryDate} onChange={this.inputChange}/>
                            */}
                            
                            <DatePicker className="datePicker input_text" selected={expiryDate} onChange={this.handleDateChange} dateFormat="MM/yy" showMonthYearPicker minDate={new Date()} />

                                {(isError && (errors.cardExpMonth || errors.cardExpYear)) ?
                                    <div id="alert-box2" className="font-weight-bold mt-1">
                                        <span className="pr-2">
                                            <svg
                                                width="1em"
                                                height="1em"
                                                viewBox="0 0 16 16"
                                                className="bi bi-exclamation-circle"
                                                fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                />
                                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                            </svg>
                                        </span>
                                        {errors.cardExpYear}
                                    </div>
                                    :
                                    ""
                                }

                			</div>
                            <div className="form-group w-lg-50 w-sm-100">
                                <label htmlFor="expdate" className="label_text">
                                    CVV
                                </label>
                                {/*
                  				<input type="month" className="form-control input_text" id="" name="expiryDate" placeholder="Enter Your Expiration Date" defaultValue={expiryDate} onChange={this.inputChange}/>
                            */}
                                <input type="text" className="form-control input_text" id="cvv" placeholder="Enter Your CVV" name="cvv" onChange={this.inputChange} defaultValue={cvv}/>

                                {(isError && (errors.cvv)) ?
                                    <div id="alert-box2" className="font-weight-bold mt-1">
                                        <span className="pr-2">
                                            <svg
                                                width="1em"
                                                height="1em"
                                                viewBox="0 0 16 16"
                                                className="bi bi-exclamation-circle"
                                                fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                />
                                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                            </svg>
                                        </span>
                                        {errors.cvv}
                                    </div>
                                    :
                                    ""
                                }

                            </div>


                            {!ButtonLoader ?
                			    <button className="btn " id="submit_btn">Submit</button>
                            :
                                <button className="btn next font-weight-bold" type="button" disabled="disabled">Processing..</button> 
                            }
              			</form>
                    }
        		</div>

                {(modalShow) ?
                    <ModalComponent isError={isError} errorMessage={errorMessage} show={this.show} handleClose={this.handleClose}/>
                :
                    ""
                }
      		</div>
    	);
  	}
}

const mapStateToProps = (state) => ({
    getUserData : state.userAccountData,
    getCountries : state.auth.countriesData,
    authenticatedUserData : state.auth.loginSuccessResult,
    getStates : state.auth.statesData
})


export default connect(
    mapStateToProps,
    {updateCardDetailsAction , removeUserCardAction,getUserDetails, emptyError , getStatesAction}
)(withRouter(Cards));
