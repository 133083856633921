import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  changePasswordAction,
  emptyError,
  twoFactorAuthAction,
} from "../../actions/accountSetting";
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Modal from "react-bootstrap/Modal";
import ModalComponent from "../ModalComponent";
import { ERROR_MESSAGE } from "../Constant";
import { ReactComponent as ErrorinfoIcon } from "../../assets/images/svgs/errorinfo.svg";
import { ReactComponent as InformationIcon } from "../../assets/images/svgs/information.svg";

export class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      old_password: "",
      password: "",
      password_confirmation: "",
      user_id: "",
      errors: [],
      isError: false,
      ButtonLoader: false,
      submitButtonClick: 0, //if click on submit and then go to another tab and come again to this tab it shows success toaster so toaster show only when there is click on button
      errorMessage: "",
      modalShow: false,
      is_2fa_enable: props.authenticatedUserData.twoFactor,
      flag: false,
      flag1: false,
    };

    this.inputChange = this.inputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.submit2Fa = this.submit2Fa.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.show = this.show.bind(this);
    this.handleCheckClick = this.handleCheckClick.bind(this);
  }

  componentDidMount() {
    let user_id = this.props.authenticatedUserData.userId;
    this.setState({
      user_id: user_id,
    });
  }

  handleCheckClick() {
    this.setState((prevState) => ({
      is_2fa_enable: !prevState.is_2fa_enable,
      flag: true,
    }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.TabName === "Security") {
      this.setState({
        ButtonLoader: false,
      });
      
      if (
        nextProps.getUserData.twoFactorAuth &&
        nextProps.getUserData.twoFactorAuth.status &&
        !this.state.submitButtonClick &&
        this.state.flag &&
        nextProps.getUserData.twoFactorAuth["2factorauth"] == this.state.is_2fa_enable

      ) {
        this.setState({
          modalShow: true,
          flag: false,
          errorMessage: nextProps.getUserData.twoFactorAuth.message,
        });
        return;
      }

      if (
        nextProps.getUserData.usersData.twoFactorEdit !== undefined &&
        !this.state.flag &&
        nextProps.getUserData.usersData.twoFactorEdit !=
          this.state.is_2fa_enable &&
        !this.state.submitButtonClick &&
        !this.state.flag1
      ) {
        let v = nextProps.getUserData.twoFactorAuth ? nextProps.getUserData.twoFactorAuth["2factorauth"]  : nextProps.getUserData.usersData.twoFactorEdit        
        this.setState({
          is_2fa_enable: v,
          flag1: true,
        });
      }
      if (
        nextProps.getUserData.passwordChangeResult &&
        nextProps.getUserData.passwordChangeResult !== "" &&
        !nextProps.errors.errors.isError
      ) {
        let errorMessage = "";
        let modalShow = false;
        if (this.state.submitButtonClick === 1) {
          errorMessage = nextProps.getUserData.passwordChangeResult.message;
          modalShow = true;
          // toast.success(nextProps.getUserData.passwordChangeResult.message)
        }
        this.setState({
          old_password: "",
          password: "",
          password_confirmation: "",
          isError: false,
          errors: [],
          submitButtonClick: 0,
          errorMessage: errorMessage,
          modalShow: modalShow,
        });
      } else {
        if (nextProps.errors.errors && nextProps.errors.errors.length !== 0) {
          this.setState({
            isError: true,
            errors: nextProps.errors.errors,
            errorMessage: ERROR_MESSAGE,
            modalShow: true,
          });
          // toast.error('Error!');
        }
      }
    }
  }

  inputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  submit2Fa(e) {
    e.preventDefault();
    if (!this.state.flag) return;
    this.setState({
      ButtonLoader: true,
      submitButtonClick: 0,
    });
    this.props.twoFactorAuthAction(
      this.state.is_2fa_enable,
      this.state.user_id
    );
  }
  onSubmit(e) {
    e.preventDefault();
    this.setState({
      ButtonLoader: true,
      submitButtonClick: 1,
    });

    if (this.state.isError) {
      this.props.emptyError();
    }
    const userPasswordData = {
      old_password: this.state.old_password,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
    };
    let user_id = this.state.user_id;
    this.props.changePasswordAction(userPasswordData, user_id);
  }

  handleClose() {
    this.setState({
      modalShow: false,
      errorMessage: "",
    });
  }

  show() {
    useState(false);
  }

  render() {
    const {
      isError,
      errors,
      ButtonLoader,
      modalShow,
      errorMessage,
      old_password,
      password,
      password_confirmation,
    } = this.state;
    return (
      <div className="changepwd_container container p-sm-0 p-2">
        {ButtonLoader ? (
          <div id="preloader">
            <div className="preloader_div"></div>
          </div>
        ) : (
          ""
        )}
        <div
          className="pwd_setting col-lg-6 col-sm-12"
          style={{ float: "left" }}
        >
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <label htmlFor="password" className="label_text">
                Old Password <span className="text-danger">*</span>
              </label>
              <input
                type="password"
                className="form-control input_text"
                id=""
                name="old_password"
                placeholder="Enter Old Password"
                onChange={this.inputChange}
                value={old_password}
              />
              {isError && errors.old_password ? (
                <div id="alert-box2" className="font-weight-bold mt-3">
                  <span className="pr-2">
                    <ErrorinfoIcon />
                  </span>
                  {errors.old_password}
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="form-group">
              <label htmlFor="password" className="label_text">
                New Password <span className="text-danger">*</span>
              </label>
              <input
                type="password"
                className="form-control input_text"
                id=""
                name="password"
                placeholder="Enter New Password"
                onChange={this.inputChange}
                value={password}
              />
              {isError && errors.password ? (
                <div id="alert-box2" className="font-weight-bold mt-3">
                  <span className="pr-2">
                    <ErrorinfoIcon />
                  </span>
                  {errors.password}
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="form-group">
              <label htmlFor="password_confirmation" className="label_text">
                Confirm Password{" "}
              </label>
              <input
                type="password"
                className="form-control input_text"
                id=""
                name="password_confirmation"
                placeholder="Re-Enter New Password "
                onChange={this.inputChange}
                value={password_confirmation}
              />
            </div>
            {!ButtonLoader ? (
              <button className="change_pwd_btn mt-4"> Change Password </button>
            ) : (
              <button
                className="btn next font-weight-bold"
                type="button"
                disabled="disabled"
              >
                Processing..
              </button>
            )}
          </form>
        </div>
        <div className="tfauth col-lg-6 col-sm-12">
          <form onSubmit={this.submit2Fa}>
            <div class="custom-control custom-checkbox form-group">              
              <input
                type="checkbox"
                id="showMedia"
                className="custom-control-input  input_checkbox"
                name="showMedia"
                checked={this.state.is_2fa_enable}
                onChange={this.handleCheckClick}
              />
              <label
                htmlFor="showMedia"
                className="custom-control-label label_text"
              >
                Enable Two-factor authentication
              </label>
            </div>
            <div id="alert-box9" className="mt-3 align-items-center alertbox ">
              <span>
                <InformationIcon />
              </span>
              By enabling two-factor authentication, this increases your account
              security and you will be required to verify any logins from any
              new devices or browsers, simply by entering a code that we'll
              email to you.
            </div>
            <button className="change_pwd_btn mt-4"> Submit </button>
          </form>
        </div>

        {modalShow ? (
          <ModalComponent
            isError={isError}
            errorMessage={errorMessage}
            show={this.show}
            handleClose={this.handleClose}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => (
  {
    getUserData: state.userAccountData,
    authenticatedUserData: state.auth.loginSuccessResult,
    errors: state.errors,
  }
);

export default connect(mapStateToProps, {
  changePasswordAction,
  emptyError,
  twoFactorAuthAction,
})(withRouter(ChangePassword));
