import React,{Component, useState}from "react";
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import {connect} from 'react-redux';
import { Link,withRouter} from 'react-router-dom';
import {saveContactFormDataAction} from '../actions/contactUs';
// import { ToastContainer, toast } from 'react-toastify';
import Modal from "react-bootstrap/Modal";
import ModalComponent from "./ModalComponent";
import {ERROR_MESSAGE} from './Constant';
import MetaTags from "react-meta-tags";

class ContactUs extends Component{

    constructor(props){
        super(props)

        this.state = {
            Fullname : '',
            message : '',
            Email : '',
            isError : false,
            errors : [],
            albumSubmitClick : 0,//set for toaster
            postSubmitClick : 0,//set for toaster
            ButtonLoader :  false,//when click on submit button disable
            submitClick : 0,
            errorMessage : '',
            modalShow : false,
        }

        this.inputChange = this.inputChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.handleClose = this.handleClose.bind(this);
        this.show = this.show.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps){

        if(this.state.submitClick === 1 && window.location.href.includes('contact-us')){
            this.setState({
                ButtonLoader : false,
                submitClick : 0,
            })
            if(nextProps.contactFormData && !nextProps.contactFormData.isError){
                this.setState({
                    Fullname : '',
                    message : '',
                    Email : '',
                    isError : false,
                    errors : [],
                    errorMessage : nextProps.contactFormData.message,
                    modalShow : true,
                })
                // toast.success(nextProps.contactFormData.message);
            }
            else{
                this.setState({
                    isError : nextProps.contactFormData.isError,
                    errors : nextProps.contactFormData.error,
                    errorMessage : ERROR_MESSAGE,
                    modalShow : true,
                })

                // toast.error('Error!');
            }
        }
    }

    //on imput change save value in state
    inputChange(e){
        e.preventDefault()
        this.setState({
            [e.target.name] : e.target.value
        })
    }


    //form submit
    onSubmit(e){
        e.preventDefault()
        this.setState({
            submitClick : 1,
            ButtonLoader : true
        })
        let contactFormData = {
            Fullname : this.state.Fullname,
            message : this.state.message.replace(/(?:\r\n|\r|\n)/g, '<br/>'),
            Email : this.state.Email,
        }
        this.props.saveContactFormDataAction(contactFormData)
    }


    handleClose() {
        this.setState({
            modalShow : false,
            errorMessage : '',
        })
    }

    show() {
        useState(false);
    }

    render()
    {
        const {Fullname , message , Email , isError , errors , ButtonLoader, modalShow , errorMessage} = this.state
        
        return (
            <>
            <MetaTags>
                <title>Contact Us | Contact Our Support Team | DesireMe</title>
                <meta name="description" content="__METADESCRIPTION__" />
                <meta name="title" content="__METATITLE__" />
                <meta property="og:description" content="__METADESCRIPTION__"/>
                <meta property="og:title" content="__METATITLE__"/>
                <meta property="og:url" content="__OGURL__"/>
                <link rel="canonical" href="__OGURL__" />
        </MetaTags>
                <Header />
                    {/*
                    <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />*/}
                    <section id="create_post_page" className={'contact-us'}>
                        {ButtonLoader ? 
                            <div id="preloader">
                                <div className="preloader_div">
                                </div>
                            </div>
                            :
                            ""
                        }
                        <div className="container py-5">
                            <div className="ceate_post_content bg-white text-center text-lg-left">
                                <div className="row pb-4">
                                    <div className="createpost_heading pl-0 pl-lg-5 mx-auto mx-lg-0">
                                        <h1 className="font-weight-bold">
                                            Contact Us
                                        </h1>
                                    </div>
                                </div>

                                <form onSubmit={this.onSubmit}>
                                    <div className="row">

                                        <div className="col-lg-6 col-md-12 col-sm-12 createpost-right">
                                    
                                            <div className="form-group">
                                                <label for="title" className="label_text">
                                                    Full Name{" "}<span className="text-danger">*</span>
                                                </label>
                                                <input type="text" className="form-control  input_text" id="" placeholder="Enter your Full Name" name="Fullname" value={Fullname} onChange={this.inputChange}/>



                                                {(isError && errors.Fullname) ?
                                                    <div id="alert-box2" className="font-weight-bold mt-1">
                                                        <span className="pr-2">
                                                            <svg
                                                                width="1em"
                                                                height="1em"
                                                                viewBox="0 0 16 16"
                                                                className="bi bi-exclamation-circle"
                                                                fill="currentColor"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                  fillRule="evenodd"
                                                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                                />
                                                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                            </svg>
                                                        </span>
                                                        {errors.Fullname}
                                                    </div>
                                                    :
                                                    ""
                                                }
                                            </div>
                                            <div className="form-group">
                                                <label for="tags" className="label_text">
                                                    Email{" "}<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="email" className="form-control input_text" id=""  placeholder="Enter your Email" name="Email" value={Email} onChange={this.inputChange}/>

                                                {(isError && errors.Email) ?
                                                    <div id="alert-box2" className="font-weight-bold mt-1">
                                                        <span className="pr-2">
                                                            <svg
                                                                width="1em"
                                                                height="1em"
                                                                viewBox="0 0 16 16"
                                                                className="bi bi-exclamation-circle"
                                                                fill="currentColor"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                  fillRule="evenodd"
                                                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                                />
                                                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                            </svg>
                                                        </span>
                                                        {errors.Email}
                                                    </div>
                                                    :
                                                    ""
                                                }
                                            </div>
                                            <div className="form-group">
                                                <label for="caption" className="label_text">
                                                    Message{" "}
                                                </label>
                                                <textarea className=" form-control" id="caption_textarea" placeholder="Enter your Message Here" name="message" onChange={this.inputChange} maxlength ="2000" value={message} />
                                            </div>
                                    
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="createpost-btns d-flex col-lg-12">
                                        {!ButtonLoader ? 
                                            <>
                                                <button className="btn btn3 mr-auto" type="submit" name="Submit">Submit</button>
                                            </>
                                            :
                                            <>
                                                <button className="btn btn3 mr-auto" type="submit" name="Submit" disabled="disabled">Submit</button>
                                            </>
                                        }
                                        </div>
                                    </div>

                                </form>
                            </div>

                            {(modalShow) ?
                                <ModalComponent isError={isError} errorMessage={errorMessage} show={this.show} handleClose={this.handleClose}/>
                            :
                                ""
                            }
                        </div>
                    </section>
                <Footer />
            </>
        );
    }
}

export const mapStateToProps = (state) => ({
    contactFormData : state.contactFormData.saveContatFormData,
})


export default connect(
    mapStateToProps , 
    {saveContactFormDataAction}
)(withRouter(ContactUs));
