import React, { Component} from "react";
import Modal from "react-bootstrap/Modal";
import {LOGIN_WHISHLIST_ERROR_MESSAGE} from './Constant';
import { Link } from 'react-router-dom';

export class LoginModalComponent extends Component {
    constructor(props){
        super(props);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
    }

    redirectToProfile(e){
        // e.preventDefault()
        localStorage.setItem('roleName','Desirer')
    }


    render() {
        return (
            <>
                <div className="modal__div">                               
                    <Modal size="lg" show={this.props.show} onHide={this.props.handleClose} centered dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" className="custom__modal">
                        <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
                        <Modal.Body style={{ width: "100%" }}>
                            <div className="modal__body">
                                <div className="modal__text px-3 px-md-5 pb-5 pb-md-5 text-center">
                                    <div className="pb-4">
                                        {((this.props.loginUserId !== null && this.props.loginUserId !== 'null' && this.props.loginUserId !== 0 && this.props.loginUserId !== ''))?
                                                <img width="65px" height="65px" src={process.env.PUBLIC_URL + '/frontend/images/icon-tick.svg'}/>
                                            :
                                                <img width="65px" height="65px" src={process.env.PUBLIC_URL + '/frontend/images/icon-cross.svg'}/>
                                        }
                                    </div>
                                    {((this.props.loginUserId !== null && this.props.loginUserId !== 'null' && this.props.loginUserId !== 0 && this.props.loginUserId !== '')) ?
                                            <>
                                                <p className="pb-4">Added To Desired List.</p>
                                                <Link to="/desired-list"><button className="btn btn__continue" onClick={this.props.handleClose}>Continue</button></Link>
                                            </>
                                        :
                                            <>
                                                <p>{LOGIN_WHISHLIST_ERROR_MESSAGE}</p>
                                                <div className="button__group d-flex flex-column flex-md-row align-itmes-center justify-content-center">
                                                    <Link to="/login"><button className="btn btn__continue mr-0 mb-3 mb-md-0 mr-md-3">Login Now</button></Link>
                                                    <p className="mb-0 my-auto d-none d-md-block">OR</p>
                                                    <Link to="/follow/step1"><button className="btn btn__black ml-0 ml-md-3" onClick={(e) => {this.redirectToProfile(e)}}>Sign-up</button></Link>
                                                </div>
                                            </>
                                    }
                                    </div>
                                </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </>
        );
    }
}



export default LoginModalComponent;
