import {
	SAVE_CONTACT_FORM_DATA,
	GET_ERRORS
} from './types';

import axios from 'axios';
import {BASE_URL} from '../components/Constant';

//page following user feed can see
export const saveContactFormDataAction = (contactFormData) => (dispatch) => {
	axios
		.post(BASE_URL+"/contactus",contactFormData)
		.then(response => {
			
			dispatch({
				type : SAVE_CONTACT_FORM_DATA,
				payload : response.data
			})
		})
		.catch(error => {

		})
}
