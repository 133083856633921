import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"

import {Provider} from "react-redux"
import 'bootstrap/dist/css/bootstrap.min.css';
import store from "./store"
// import logo from './logo.svg';
import './App.css';
import './assets/sass/style.sass';
import Home from './components/homepage/Home';
import ProfileListPage from './components/homepage/ProfileListPage';
import PostListPage from './components/homepage/PostListPage';
import Register from './components/auth/Profile';
import RegisterSuccess from './components/auth/RegisterSuccess';
import EmailVerify from './components/auth/EmailVerify';
import Login from './components/auth/Login';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import Blog from './components/blog/Blog';
import SingleBlog from './components/blog/SingleBlog';
import AccountSetting from './components/accountsetting/AccountSetting';
import PrivateRoute from './components/common/PrivateRoute';
import FollowingPage from './components/userPostPages/FollowingPage';
import Feed from './components/userPostPages/Feed';
import Post from './components/userPostPages/Post';
import NotFoundPage from './components/NotFoundPage';
import ContactUs from './components/ContactUs';
import DesiredList from './components/DesiredList';
import TermsConditions from './components/TermsConditions';
import FAQ from './components/FAQ';
import AboutUs from './components/AboutUs';
import PrivacyPolicy from './components/PrivacyPolicy';
import NotificationMobile from './components/layouts/NotificationMobile';
import {getLoginUserDataAction , logoutAction} from './actions/auth'
import Messages from './components/messages/Messages';
import ScrollToTop from './utils/ScrollToTop';
import {createBrowserHistory} from 'history';
const browserHistory = createBrowserHistory();


//login expire tume check && send authenticated data
if(localStorage.user_data){
    let authenticatedUserData = JSON.parse(decodeURIComponent(localStorage.getItem('user_data')));

    let expires_at = new Date(authenticatedUserData.expires_at)
    expires_at = expires_at.getTime();
    let currentTime = new Date();
    currentTime = currentTime.getTime();

    //call this action so after login we can get login user data throgh reducer
    store.dispatch(getLoginUserDataAction(authenticatedUserData))

    if(authenticatedUserData && expires_at < currentTime && authenticatedUserData.remember_me === 'No'){
        store.dispatch(logoutAction())
        // Redirect to login
        window.location.href = "/login"
    }
}

class App extends Component{
    render(){

        return(
            <Provider store={store}>
                <Router history={browserHistory}>
                    <ScrollToTop />
                    <Switch>

                        <Route
                            exact
                            path="/"
                            component={Home}
                        />

                        <Route 
                            path="/profiles"
                            component={ProfileListPage}//all showcase profile listing
                        />

                        <Route
                            path="/content"
                            component={PostListPage} //all showcase posts listing
                        />

                        <Route 
                            path="/profile/step1"
                            component={Register}
                        />

                        <Route 
                            path="/profile/success"
                            component={Register}
                        />
                        
                        <Route 
                            path="/profile/step2"
                            component={Register}
                        />
                        <Route 
                            path="/profile/step3"
                            component={Register}
                        />
                        <Route 
                            path="/profile/step4"
                            component={Register}
                        />
                        <Route
                            path="/follow/step1"
                            component={Register}
                        />
                        <Route
                            path="/follow/success"
                            component={Register}
                        />
                        
                        <Route
                            path="/follow/step2"
                            component={Register}
                        />

                        <Route 
                            path="/verify/:role/:userId" //email verify and redirect to profile
                            component={EmailVerify}
                        />
                        <Route 
                            path="/login"
                            component={Login}
                        />

                        <Route 
                            path="/forget-password"
                            component={ForgotPassword}
                        />

                        <Route 
                            path="/reset-password/:token"
                            component={ResetPassword}
                        />

                        <Route 
                            path="/blogs"
                            component={Blog}
                        />

                         <Route 
                            path="/blog/:slug"
                            component={SingleBlog}
                        />

                        <PrivateRoute 
                            path="/account"
                            component={AccountSetting}
                        />
                        {/*not private route because if not login redirect to not following page*/}
                        

                        <PrivateRoute 
                            path="/feed"//searched profile 
                            component={Feed}
                        />

                        {/*create post*/}
                        <PrivateRoute 
                            path="/post" 
                            component={Post}
                        />

                        <PrivateRoute 
                            path="/messages"
                            component={Messages}
                        />

                        <Route 
                            path="/contact-us" 
                            component={ContactUs}
                        />
                        <Route
                            path="/terms-conditions"
                            component={TermsConditions}
                        />
                        <Route
                            path="/privacy-policy"
                            component={PrivacyPolicy}
                        />
                        <Route
                            path="/faq"
                            component={FAQ}
                        />
                        <Route
                            path="/about-us"
                            component={AboutUs}
                        />

                        <Route path="/404" component={NotFoundPage} />
                        {/*whishlist*/}
                        <PrivateRoute 
                            path="/desired-list" 
                            component={DesiredList}
                        />

                        <PrivateRoute 
                            path="/notifications" 
                            component={NotificationMobile}
                        />
                        
                        <Route 
                            path="/:profileName"//searched profile 
                            component={FollowingPage}
                        />

                        

                    </Switch>
                </Router>
            </Provider>

        )
    }
}

export default App;
