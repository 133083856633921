import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import {ReactComponent as HeartIcon} from '../../assets/images/svgs/heart.svg'
import {ReactComponent as DesireIcon} from '../../assets/images/svgs/desire.svg'
import {ReactComponent as FollowerIcon} from '../../assets/images/svgs/follower.svg'

export class followCard extends Component {

    // constructor(props){
    //     super(props)
    // }

    redirectToProfile(roleName){
        localStorage.setItem('roleName',roleName)
    }
    
    render() {
        return (
            <section id="follow_card">
                <div className="container follow_card_container">
                    <div className="follow_card text-center">
                        {/*<img src={process.env.PUBLIC_URL + '/frontend/images/heart.png'} alt="search" />*/}
                        <HeartIcon/>
                        <h2 className="mb-0">Simply decide if you are posting or following</h2>
                        <small className="mb-0">Desire Me is so quick and easy to use for both our adult content creators (the desired) and followers (the desirer).<br /> Just choose an account type below to get started:</small>
                    </div>
                    <div className="row search_profile_card d-flex justify-content-between mx-2">

                        <Link to="/profile/step1" className="col-md-5 left_follow_card text-center mr-md-5 profile_card_hover" onClick={() => this.redirectToProfile('ContentCreator')}>
                            <div className="bg_img">
                                <DesireIcon/>
                            </div>
                            <h3 className="my-3">Be Desired</h3>
                            <small>Create your profile, add your content regularly and start earning from your following.</small><br />
                            <div>
                                <Link to="/profile/step1" className="btn left_follow_card_btn my-3" onClick={() => this.redirectToProfile('ContentCreator')}>Create Your Profile</Link>
                            </div>
                        </Link>
                        <Link to="/follow/step1" className="col-md-5 right_follow_card text-center profile_card_hover mt-md-0 mt-5" onClick={() => this.redirectToProfile('Desirer')}>
                            <div className="bg_img">
                            <FollowerIcon/>
                            </div>
                            <h3 className="my-3">Become a Desirer</h3>
                            <small>Start following profiles and watch your desired content today</small><br />
                            <div>
                                <Link to="/follow/step1" className="btn right_follow_card_btn my-3" onClick={() => this.redirectToProfile('Desirer')}>Start Following</Link> 
                            </div>
                        </Link>
                    </div>
                </div>
            </section>
        )
    }
}
export default followCard;
