import React from 'react';
import Cardheader from './Cardheader';
import Cardbody from './CardBody';
const Cardmain = (props) => {    
    let renderItems = null;
    if (props.selectedUser) {
        renderItems = (
            <div className="card chat_border_remove John_Doe_Jon">
                <Cardheader onDeleteButton={props.onDeleteButton} onBackButton={props.onBackButton} {...props} name={props.selectedUser.username} />
                <Cardbody showPaySetupModal={props.showPaySetupModal} showPayModal={props.showPayModal} {...props} />
            </div>
        )
    }
    return renderItems
}
export default Cardmain;
