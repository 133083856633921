import React from 'react';
import Mainchat from './UI/MainChat';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import AuxFile from '../../hoc/AuxFile';
import MetaTags from "react-meta-tags";

const messages = () => {
    return (
        <AuxFile>
            <MetaTags>
                <title>Messages | DesireMe</title>
                <meta name="description" content="__METADESCRIPTION__" />
                <meta name="title" content="__METATITLE__" />
                <meta property="og:description" content="__METADESCRIPTION__"/>
                <meta property="og:title" content="__METATITLE__"/>
                <meta property="og:url" content="__OGURL__"/>
                <link rel="canonical" href="__OGURL__" />
            </MetaTags>
            <Header />
            <Mainchat />
            <Footer />
        </AuxFile>
    )
}
export default messages;
