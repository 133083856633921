import React, { Component, useState } from 'react'
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {CloseAccountAction} from '../../actions/accountSetting'
import {logoutAction} from '../../actions/auth';
// import {toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Modal from "react-bootstrap/Modal";
import ModalComponent from "../ModalComponent";
import {ERROR_MESSAGE} from '../Constant';

export class CloseAccount extends Component {
    constructor(props){
        super(props)
        this.state = {
            user_id : '',
            closeButtonClick : 0,
            errorMessage : '',
            modalShow : false,
            showConfirmation : false
        }

        this.CloseAccount = this.CloseAccount.bind(this)
        this.handleClose = this.handleClose.bind(this);
        this.show = this.show.bind(this);
        this.CloseConfirmationModapPopup = this.CloseConfirmationModapPopup.bind(this)
        this.CloseConfirmationModapPopupClose =this.CloseConfirmationModapPopupClose.bind(this)
    }

    componentDidMount(){
        let user_id = this.props.authenticatedUserData.userId;

        this.setState({
            user_id : user_id
        })
    }

    CloseConfirmationModapPopup(){
        this.setState({
            showConfirmation : true
        })
    }
    CloseConfirmationModapPopupClose(){
        this.setState({
            showConfirmation : false
        })
    }
    CloseAccount(){
        this.setState({
            closeButtonClick : 1,
        })
        this.props.CloseAccountAction(this.state.user_id)
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.TabName === 'Close Account'){
            if(!nextProps.accountClose.isError && this.state.closeButtonClick === 1){
                this.setState({
                    isError : false,
                    errorMessage : nextProps.accountClose.message,
                    modalShow : true,
                })
                // toast.success(nextProps.accountClose.message);
                //this.props.logoutAction()
                // this.props.history.push('/login')
            }
            else if(this.state.closeButtonClick === 1){
                this.setState({
                    isError : true,
                    errorMessage : 'Whoops! Something is wrong',
                    modalShow : true,
                })
                // toast.error('Error!')
            }
        }
    }

    handleClose() {
        this.setState({
            modalShow: false,
            errorMessage: '',
        }, () => {
            this.props.logoutAction(this.state.user_id, this.props);
            this.props.history.push('/login')
        })
    }

    show() {
        useState(false);
    }

    render() {
        const {isError , modalShow , errorMessage ,showConfirmation } = this.state
        return (
            <div className="container p-5 closeacc_container">
                <div>
                  <h3 className="pb-3" >Close Your Account</h3>
                    <p>Upon clicking the close account button, we'll deactivate your account and it will be hidden from our website. You won't be able to login again without contacting our  <Link className={"view_text"} to="/contact-us">support team.</Link> </p>
                    <button className="btn btn-danger mt-3 font-weight-bold" onClick={this.CloseConfirmationModapPopup}>Close Account</button>
                </div>
                {(modalShow) ?
                    <ModalComponent isError={isError} errorMessage={errorMessage} show={this.show} handleClose={this.handleClose}/>
                :
                    ""
                }
                {
                    (showConfirmation) ?
                        <div className="modal__div">
                            <Modal size="lg" show={this.CloseConfirmationModapPopup} onHide={this.CloseConfirmationModapPopupClose} centered dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" className="custom__modal">
                                <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
                                <Modal.Body style={{ width: "100%" }}>
                                    <div className="modal__body">
                                        <div className="modal__text px-3 px-md-5 pb-5 pb-md-5 text-center">
                                            <p className="modalheading">Are you sure you want to close your account?</p>
                                            <p className="modalparagraph">This will hide your profile on Desire Me and you would need to contact our support team to regain access in the future.</p>
                                            <div className="button__group d-flex flex-column flex-md-row align-itmes-center justify-content-center">
                                                <button className="followbtn btn btn__continue mr-0 mb-3 mb-md-0 mr-md-3" type="button" onClick={this.CloseAccount}>Yes Close My Account</button>
                                                <p className="mb-0 my-auto d-none d-md-block">OR</p>
                                                <button className="btn btn__black ml-0 ml-md-3 close-account" type="button" onClick={this.CloseConfirmationModapPopupClose}>No Keep My Account</button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                     :
                        ""
                }
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    accountClose : state.userAccountData.accountClose,
    authenticatedUserData : state.auth.loginSuccessResult
})

export default connect(
    mapStateToProps,
    {CloseAccountAction , logoutAction}
)(withRouter(CloseAccount));
