import React, { useState, useEffect, useRef } from "react";
import AuxFile from "../../../hoc/AuxFile";
import Cardinput from "./Cardinput";
import ShowChatImageModal from "./ShowChatImageModal";
import ModalVideo from "react-modal-videojs";
import defaultVideoImage from "./../../../assets/images/video-default.svg";

const Cardbody = (props) => {
  const messageEl = useRef(null);
  const [fileDisplayModal, setFileDisplayModal] = useState(false);
  const [displayLightBox, setdisplayLightBox] = useState(false);
  const [displayType, setDisplayType] = useState(false);
  const [file, setFile] = useState("");
  const [show, setShow] = useState(true);
  

  let currentUser = props.currentUser.userId;
  let currentUserProfilepic = props.currentUser.ProfilePic;
  let selectedUserProfilepic = props.selectedUser.profile;
  const intervals = [
    { label: "yr", seconds: 31536000 },
    { label: "mon", seconds: 2592000 },
    { label: "day", seconds: 86400 },
    { label: "hr", seconds: 3600 },
    { label: "min", seconds: 60 },
    { label: "sec", seconds: 0 },
  ];

  const timeSince = (d) => {
    /* const date = new Date(d);
        const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
        const interval = intervals.find(i => i.seconds <= seconds);
        const count = seconds ? (Math.floor(seconds / interval.seconds) === Infinity ? '': Math.floor(seconds / interval.seconds)):'';
        return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;*/
    const diff = Number(new Date()) - new Date(d);
    const minute = 60 * 1000;
    const hour = minute * 60;
    const day = hour * 24;
    const month = day * 30;
    const year = day * 365;
    switch (true) {
      case diff < minute:
        const seconds = Math.round(diff / 1000);
        if (seconds == 0) {
          return "just now";
        }
        return `${seconds} ${seconds > 1 ? "seconds" : "second"} ago`;
      case diff < hour:
        return Math.round(diff / minute) + " minutes ago";
      case diff < day:
        return Math.round(diff / hour) + " hours ago";
      case diff < month:
        return Math.round(diff / day) + " days ago";
      case diff < year:
        return Math.round(diff / month) + " months ago";
      case diff > year:
        return Math.round(diff / year) + " years ago";
      default:
        return "";
    }
  };
  useEffect(() => {
    if (messageEl) {
      messageEl.current.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "auto" });
      });
    }
  }, []);

  const displayImage = (file) => {
    setDisplayType("image");
    if (file) displayFileModal(file);
  };

  const displayFileModal = (file) => {
    setFile(file);
    setFileDisplayModal(true);
  };

  const hideFileModal = () => {
    setFileDisplayModal(false);
    setFile("");
    setDisplayType("");
  };
  const displayVideoLightBox = (url) => {
    setFile(url);
    setdisplayLightBox(true);
  };
  const hideVideoLightBox = () => {
    setdisplayLightBox(false);
  };

  const hideModal = () => {      
    setdisplayLightBox(false);
      setShow(false)
  }

  return (
    <AuxFile>
      <div className="card-body msg_card_body" ref={messageEl} id="chat-message-body">
        {props.messages.map((message) => {
          return message.senderId === currentUser.toString() ? (
            <div className="message-chat-window">
              {message.sendermessage != "" && message.sendermessage != null && (
                <div className="tip">{message.sendermessage}</div>
              )}
              <div
                key={message.created_at}
                className="d-flex justify-content-end mb-5"
              >
                <div className="msg_cotainer_send">
                  {message.file && (
                    <div className="cursor_pointer">
                      {message.file.split("?").shift().split(".").pop() ===
                        "jpg" ||
                      message.file.split("?").shift().split(".").pop() ===
                        "JPG" ||
                      message.file.split("?").shift().split(".").pop() ===
                        "jpeg" ||
                      message.file.split("?").shift().split(".").pop() ===
                        "JPEG" ||
                      message.file.split("?").shift().split(".").pop() ===
                        "png" ||
                      message.file.split("?").shift().split(".").pop() ===
                        "PNG" ||
                      message.file.split("?").shift().split(".").pop() ===
                        "gif" ||
                      message.file.split("?").shift().split(".").pop() ===
                        "GIF" ||
                      message.file.split("?").shift().split(".").pop() ===
                        "webp" ? (
                        <span
                          onClick={() =>
                            displayImage(
                              message.file !== null &&
                                message.file !== "null" &&
                                message.file !== ""
                                ? message.file
                                : process.env.PUBLIC_URL +
                                    "/images/default-avatar.png"
                            )
                          }
                        >
                          <img
                            style={{ objectFit: "cover" }}
                            className="msg_image mb-3"
                            src={
                              message.file !== null &&
                              message.file !== "null" &&
                              message.file !== ""
                                ? message.file
                                : process.env.PUBLIC_URL +
                                  "/images/default-avatar.png"
                            }
                            alt="img"
                          />
                        </span>
                      ) : message.file.split("?").shift().split(".").pop() ===
                        "mp4" ? (
                        <span>
                          <video
                            onClick={() => displayVideoLightBox(message.file)}
                            poster={defaultVideoImage}
                            className="msg_image mb-3"
                            src={message.file}
                          />
                        </span>
                      ) : (
                        <span
                          onClick={() =>
                            displayImage(
                              process.env.PUBLIC_URL +
                                "/images/default-avatar.png"
                            )
                          }
                        >
                          <img
                            style={{ objectFit: "cover" }}
                            className="msg_image mb-3"
                            src={
                              process.env.PUBLIC_URL +
                              "/images/default-avatar.png"
                            }
                            alt="img"
                          />
                        </span>
                      )}
                    </div>
                  )}
                  {message.message && (
                    <div className="message-content">{message.message}</div>
                  )}
                  <span className="msg_time_special">
                    {timeSince(message.created_at)}{" "}
                  </span>
                </div>
                <div className="img_cont_msg">
                  <img
                    src={currentUserProfilepic}
                    className="rounded-circle user_second_img"
                    alt=""
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="message-chat-window">
              {message.istip && <div className="tip">{message.tipmessage}</div>}
              <div
                key={message.created_at}
                className="d-flex justify-content-start mb-5"
              >
                <div className="img_cont_msg">
                  <img
                    src={selectedUserProfilepic}
                    className="rounded-circle user_img_msg"
                    alt=""
                  />
                </div>
                <div className="msg_cotainer">
                  {message.file && (
                    <div className="cursor_pointer">
                      {message.file.split("?").shift().split(".").pop() ===
                        "jpg" ||
                      message.file.split("?").shift().split(".").pop() ===
                        "JPG" ||
                      message.file.split("?").shift().split(".").pop() ===
                        "jpeg" ||
                      message.file.split("?").shift().split(".").pop() ===
                        "JPEG" ||
                      message.file.split("?").shift().split(".").pop() ===
                        "png" ||
                      message.file.split("?").shift().split(".").pop() ===
                        "PNG" ||
                      message.file.split("?").shift().split(".").pop() ===
                        "gif" ||
                      message.file.split("?").shift().split(".").pop() ===
                        "GIF" ||
                      message.file.split("?").shift().split(".").pop() ===
                        "webp" ? (
                        <span
                          onClick={() =>
                            displayImage(
                              message.file !== null &&
                                message.file !== "null" &&
                                message.file !== ""
                                ? message.file
                                : ""
                            )
                          }
                        >
                          <img
                            style={{ objectFit: "cover" }}
                            className="msg_image mb-3"
                            src={
                              message.file !== null &&
                              message.file !== "null" &&
                              message.file !== ""
                                ? message.file
                                : process.env.PUBLIC_URL +
                                  "/images/default-avatar.png"
                            }
                            alt="img"
                          />
                        </span>
                      ) : message.file.split("?").shift().split(".").pop() ===
                        "mp4" ? (
                        <span>
                          <video
                            className="msg_image mb-3"
                            onClick={() => displayVideoLightBox(message.file)}
                            poster={defaultVideoImage}
                            src={message.file}
                          ></video>
                          <span
                            style={{ float: "left" }}
                          >{`${props.currentUser.Username}, has sent you a video, click play to view it.`}</span>
                        </span>
                      ) : (
                        <span
                          onClick={() =>
                            displayImage(
                              process.env.PUBLIC_URL +
                                "/images/default-avatar.png"
                            )
                          }
                        >
                          <img
                            style={{ objectFit: "cover" }}
                            className="msg_image mb-3"
                            src={
                              process.env.PUBLIC_URL +
                              "/images/default-avatar.png"
                            }
                            alt="img"
                          />
                        </span>
                      )}
                    </div>
                  )}
                  {message.message}
                  <span className="msg_time_send">
                    {timeSince(message.created_at)}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="videoLightbox">
        {displayLightBox ? (          

          <ModalVideo
            id={(new Date() * 1).toString()}
            source= {file}
            showModal= {true}
            show = {show}
            responsive = {true}
            autoplay= {false}
            bigPlayButton = {true}
            handleClose={hideVideoLightBox}
            bigPlayButtonCentered = {true}
            modalCloseButtonClass="cButton"            
            width={100}
          />
        ) : null}
      </div>
      <ShowChatImageModal
        displayType={displayType}
        show={fileDisplayModal}
        handleClose={hideFileModal}
        file={file}
      />
      <Cardinput
        showPaySetupModal={props.showPaySetupModal}
        showPayModal={props.showPayModal}
        {...props}
      />
    </AuxFile>
  );
};

export default Cardbody;
