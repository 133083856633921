import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorsReducer from "./errorsReducer";
import accountSettingReducer from "./accountSettingReducer";
import homePageReducer from "./homePageReducer";
import postReducer from "./postReducer";
import blogReducer from "./blogReducer";
import contactUsReducer from "./contactUsReducer";
import faqReducer from "./faqReducer";
import chatReducer from "./chatReducer";
export default combineReducers({
	auth : authReducer,
	errors : errorsReducer,
	userAccountData : accountSettingReducer,
	homePageData : homePageReducer,
	postData : postReducer,
	blogData : blogReducer,
	contactFormData : contactUsReducer,
	faqData : faqReducer,
	chatData:chatReducer
});
