import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
export class DeleteConfirmModal extends Component {
    constructor(props) {
        super(props);
    }
    handleClose = (action) => {
        this.props.handleClose(action);
    }
    render() {
        return (
            <>
            <div className="modal__div">                               
                    <Modal size="lg" animation={false} show={this.props.show} onHide={() => this.handleClose("close")} centered dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" className="custom__modal">
                        <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
                        <Modal.Body style={{ width: "100%" }}>
                            <div className="modal__body chat_module">
                                <div className="modal__text px-3 px-md-5 pb-5 pb-md-5 text-center">
            <div className="pb-4">
            <img width="65px" height="65px" src={process.env.PUBLIC_URL + '/frontend/images/icon-cross.svg'}/>
        </div>
                                    <p className="pb-4" dangerouslySetInnerHTML={{__html: "Are you sure you want to delete this chat?"}}></p>
                                    <button className="btn btn__continue" onClick={() => this.handleClose("Yes")}>Delete Chat</button>
                                    <button className="btn btn__continue ml-0 ml-md-3" onClick={() => this.handleClose("No")}>Cancel</button>
                                    </div>
                                </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </>
        );
    }
}



export default DeleteConfirmModal;
