import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import {getBlogsAction , getBlogCategoriesAction,getRecentBlogsAction , searchBlogAction} from '../../actions/blog';
import MetaTags from "react-meta-tags";
import { Button } from '../helpers/Button';
import {ReactComponent as SearchsolidIcon} from '../../assets/images/svgs/search-solid.svg'
import {ReactComponent as DateIcon} from '../../assets/images/svgs/date.svg'
import {ReactComponent as LipsIcon} from '../../assets/images/svgs/lips.svg'

export class Blog extends Component {
    constructor(props){
        super(props)

        this.state = {
            blogs : [],
            start : 0,
            limit : 3,
            totalCount : 0,
            blogCategories : [],
            Loader : false,
            blogDataLength : 0,
            recentBlogs : [],
            searchBlogValue : '', 
            searchButtonClick : 0,
            prevStart : 0,
            loadMoreButton : true,
            blogCategory : ''
        }
        this.handleScroll = this.handleScroll.bind(this)
        this.inputChange = this.inputChange.bind(this)
        this.searchedBlog = this.searchedBlog.bind(this)
        this.filterBlog = this.filterBlog.bind(this)
        this.replaceSlugPara = this.replaceSlugPara.bind(this)
    }

    componentDidMount(){
        //if coming from single blog
        // localStorage.removeItem('searchedBlog')
        // localStorage.removeItem('singleBlog')
        let searchUserId = this.props.location.search;
        const params = new URLSearchParams(searchUserId);
        let searchedBlog = params.get('value');
        let getBlogbycategory = params.get('category')
        if(searchedBlog !== null && searchedBlog !== 'null' && searchedBlog !== ''){
            this.setState({
                searchBlogValue : searchedBlog,
                start : 0,
            })
            this.searchedBlog(searchedBlog)
        }
        else{
            if(getBlogbycategory!==null && getBlogbycategory !=='null' && getBlogbycategory!==''){
                this.state.blogCategory = getBlogbycategory
            }
            this.props.getBlogsAction(this.state.start,this.state.limit,this.state.blogCategory)
        }
        this.props.getBlogCategoriesAction(0,4)
        this.props.getRecentBlogsAction(0,3)
        window.addEventListener('scroll', this.handleScroll);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        let blogsArray = [];
        let searchBlogDataArray = [];
        this.setState({
            Loader : false,
            // searchButtonClick : 0,
        })

        // let blogValue = ''
        let searchUserId = this.props.location.search;
        const params = new URLSearchParams(searchUserId);
        let blogValue = params.get('value');
        let start = this.state.start
        if(blogValue !== null && blogValue !== 'null' && blogValue !== ''){
            blogValue = blogValue
            start = this.state.prevStart
            // localStorage.getItem('searchedBlog',blogValue)
        }
        else{
            blogValue = this.state.searchBlogValue
        }

        if(blogValue !== '' && blogValue !== null && blogValue !== 'null'){
            //put start !== 0 condition bcz if i type po it will be added data again n again on every key change
            if(this.state.blogs.length !== 0  && blogValue !== '' && start !== 0){
                searchBlogDataArray.push(...this.state.blogs)
            }
            if(nextProps.searchedBlogs && nextProps.searchedBlogs.data && nextProps.searchedBlogs.data.length > 0){
                for(let i =0 ;i<nextProps.searchedBlogs.data.length ;i++){
                    let id = nextProps.searchedBlogs.data[i].id

                    if (!searchBlogDataArray.some(o => o.id === nextProps.searchedBlogs.data[i].id)){
                        searchBlogDataArray.push(...nextProps.searchedBlogs.data)
                    }   
                }
                // searchBlogDataArray.push(...nextProps.searchedBlogs.data)
                this.setState({
                    blogs : searchBlogDataArray,
                    totalCount : nextProps.searchedBlogs.count,
                    blogDataLength : searchBlogDataArray.length,
                    // start : this.state.start,
                    searchButtonClick : 0,
                })
            }
            else{
                if(nextProps.searchActivityData && nextProps.searchActivityData.data  && nextProps.searchActivityData.data.length === 0){
                    this.setState({
                        blogs : [],
                        start : 0,
                        searchButtonClick : 0,
                        totalCount: 0,
                        blogDataLength:0
                    })
                }
            }
        }
        else{
            if(this.state.blogs.length !== 0 && this.state.start !== 0){
                blogsArray.push(...this.state.blogs)
            }
            if(nextProps.blogs && nextProps.blogs.data && nextProps.blogs.data.length > 0){
                
                for(let i =0 ;i<nextProps.blogs.data.length ;i++){
                    let id = nextProps.blogs.data[i].id

                    if (!blogsArray.some(o => o.id === nextProps.blogs.data[i].id)){
                        blogsArray.push(...nextProps.blogs.data)
                    }   
                }

                // blogsArray.push(...nextProps.blogs.data)
                this.setState({
                    blogs : blogsArray,
                    totalCount : nextProps.blogs.count,
                    blogDataLength : blogsArray.length,
                    // start :  this.state.start,
                })
            }
            else{
                this.setState({
                    start : 0,
                    blogs:[],
                    totalCount: 0,
                    blogDataLength:0
                })
            }
        }

        if(nextProps.blogCategories && nextProps.blogCategories.data && nextProps.blogCategories.data.length > 0){
            this.setState({
                blogCategories : nextProps.blogCategories.data
            })
        }

        if(nextProps.recentBlogs && nextProps.recentBlogs.data && nextProps.recentBlogs.data.length > 0){
            this.setState({
                recentBlogs : nextProps.recentBlogs.data
            })
        }
    }

    getSingleBlogData(e,blogId,slug){
        e.preventDefault()
        var replaceSlug = this.replaceSlugPara(slug)
        localStorage.setItem('singleblog',replaceSlug)
        this.setState({
            start : 0,
            blogDataLength : 0,
            blogs : [],
            searchButtonClick : 0,
            prevStart : 0,
        })
        blogId = btoa(blogId)
        localStorage.removeItem('searchedBlog')
        this.props.history.push('/blog/'+slug)
        
    }
    replaceSlugPara(slug){

        const regex = /-/gi;

        return slug.replace(regex, ' ');
    }

    inputChange(e){
        this.setState({
            searchBlogValue : e.target.value,
            start : 0,
            prevStart : 0,
            blogDataLength : 0,
            blogs : [],
            searchButtonClick : 1,
        })

        if(e.target.value === ''){
            this.setState({
                searchButtonClick : 0,
                start : 0,
                loadMoreButton : true,
            })
            localStorage.removeItem('searchedBlog')
            this.props.getBlogsAction(0,this.state.limit,this.state.blogCategory)
            this.props.history.push('/blogs')
        }
    }
    filterBlog(e,blogCategory){
        this.state.blogCategory = blogCategory
        this.setState({
            Loader:true
        })
        localStorage.removeItem('searchedBlog')
        this.props.getBlogsAction(0,this.state.limit,blogCategory)
        this.props.history.push('/blogs')
    }
    searchedBlog(blogValue){
        // e.preventDefault()
        if(this.state.searchButtonClick === 1){
            if(this.state.searchBlogValue !== ''){
                this.setState({
                    blogs : [],
                    start : 0,
                    blogDataLength : 0,
                    searchButtonClick : 1,
                    loadMoreButton : true,
                })
                this.props.searchBlogAction(this.state.searchBlogValue,0,this.state.limit,this.state.blogCategory)
            } 
        }
        else{
            //if coming from single blog
            if(blogValue !== null && blogValue !== 'null' && blogValue !== ''){
                this.setState({
                    blogs : [],
                    prevStart : 0,
                    blogDataLength : 0,
                    searchButtonClick : 0,
                    loadMoreButton : true,
                })
                localStorage.setItem('searchedBlog',blogValue)
                // localStorage.removeItem('singleBlog')
                this.props.searchBlogAction(blogValue,0,this.state.limit,this.state.blogCategory)
            }
        }
    }

    //for mobile view load more button
    loadAllPost(e){
        e.preventDefault()
        this.setState({
            start : 3,
            Loader : true,
            loadMoreButton : false,
        })
        let limit = this.state.totalCount - 3
        let start = 3;
        let blogValue = localStorage.getItem('searchedBlog');
        if(this.state.searchBlogValue === ''){
            window.scrollTo(0,1400)
            this.props.getBlogsAction(start,limit,this.state.blogCategory)
        }
        else if(this.state.searchBlogValue !== ''){
            window.scrollTo(0,1400)
            this.props.searchBlogAction(this.state.searchBlogValue,start,limit,this.state.blogCategory)
        }
        else{
            this.setState({
                Loader : false
            })
        }
    }


    handleScroll = (e) => {
        e.preventDefault()
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight) {
            if (this.state.blogDataLength !== this.state.totalCount) {
                let blogValue = localStorage.getItem('searchedBlog');
                if (blogValue !== null && blogValue !== 'null' && blogValue !== '' && this.state.searchButtonClick === 0) {
                    if (this.state.blogs.length > 0) {
                        // if(this.state.prevStart !== this.state.start){
                        if (this.state.totalCount >= this.state.blogDataLength && blogValue !== '') {
                            let prevStart = this.state.prevStart + 3;
                            this.setState({
                                start: 0,
                                Loader: true,
                                searchBlogValue: blogValue,
                                scroll: 1,
                                prevStart: prevStart

                            })
                            // window.scrollTo(0,0)
                            // localStorage.setItem('searchedBlog',blogValue)
                            this.props.searchBlogAction(blogValue, prevStart, this.state.limit,this.state.blogCategory)
                        } else {
                            this.setState({
                                Loader: false
                            })
                        }
                        // }
                    }
                } else {
                    if (this.state.blogs.length > 0) {
                        let start = this.state.start + 3;
                        // let start = this.state.blogDataLength
                        if (this.state.start !== start) {//if removed search value then entry is coming double so put this codnition
                            this.setState({
                                start: start,
                                Loader: true,
                                scroll: 1,
                            })
                            if (this.state.totalCount >= this.state.blogDataLength && this.state.searchBlogValue === '') {
                                // window.scrollTo(0,0)
                                this.props.getBlogsAction(start, this.state.limit,this.state.blogCategory)
                            } else if (this.state.totalCount >= this.state.blogDataLength && this.state.searchBlogValue !== '') {
                                // window.scrollTo(0,0)
                                this.props.searchBlogAction(this.state.searchBlogValue, start, this.state.limit,this.state.blogCategory)
                            } else {
                                this.setState({
                                    Loader: false
                                })
                            }
                        }
                    }
                }
            }
        }
    }

    render() {
        const {blogs , blogCategories , totalCount, Loader , recentBlogs ,searchBlogValue , searchButtonClick, loadMoreButton,blogCategory} = this.state


        return (
            <>
            <MetaTags>
                <title>Blogs - Latest News, Tips and Creator Stories | DesireMe</title>
            <meta name="description" content="__METADESCRIPTION__" />
            <meta name="title" content="__METATITLE__" />
            <meta property="og:description" content="__METADESCRIPTION__"/>
            <meta property="og:title" content="__METATITLE__"/>
            <meta property="og:image" content="__OGIMAGE__"/>
            <meta property="og:url" content="__OGURL__"/>
            <link rel="canonical" href="__OGURL__" />
            </MetaTags>
                <Header />
                    <section id="blog_section">
                        {(Loader && totalCount !== blogs.length) ? 
                            <div id="preloader">
                                <div className="preloader_div">
                                </div>
                            </div>
                            :
                            ""
                        }
                        <div className="blog_img">
                            {/*
                            <img alt="" src={process.env.PUBLIC_URL + '/frontend/images/blog-header.png'} />
                            */}
                            <h1>Our Blog</h1>
                        </div>
                        <div className="blog">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="col-lg-7">
                                        {
                                            (blogs.length > 0)
                                            ?
                                            (blogs.map((blogData,blogKey) => {

                                                return(
                                                    <div className="blog_post" key={blogKey}>
                                                        <Link to={'/blog/'+blogData.slug} /*onClick={(e) => {this.getSingleBlogData(e,blogData.id,blogData.slug)}}*/><h3>{blogData.title}</h3></Link>
                                                        <div className="blog_post_heading d-flex mb-2">
                                                            <div className="blog_date">
                                                                <DateIcon/>
                                                                <span>{blogData.created}</span>
                                                            </div>
                                                            <div className="blog_category">
                                                                <LipsIcon/>
                                                                <span>{blogData.category}</span>
                                                            </div>
                                                        </div>
                                                        <div className="blog_post_img">
                                                            <Link to={'/blog/'+blogData.slug} /*onClick={(e) => {this.getSingleBlogData(e,blogData.id,blogData.slug)}}*/>
                                                                <img alt="" src={blogData.image} />
                                                            </Link>
                                                        </div>
                                                        <div className="blog_post_para my-3">
                                                            {(blogData.content.split(' ').length > 30) ? 
                                                            <p dangerouslySetInnerHTML={{__html: blogData.content.split(' ').slice(0,30).join(' ') + " ...."}}>
                                                                </p>
                                                            : <p dangerouslySetInnerHTML={{__html: blogData.content}}></p>
                                                        }
                                                        </div>
                                                        <div className="blog_post_btn">
                                                            {(blogData.content.split(' ').length > 30) ?
                                                                <Button onClick={(e) => this.getSingleBlogData(e,blogData.id,blogData.slug)}>
                                                                    Read more
                                                                </Button>

                                                            :
                                                                ""
                                                            }
                                                            
                                                        </div>
                                                    </div>

                                                )
                                            }))
                                            :
                                                <div className="d-flex justify-content-center align-items-center" style={{height:"400px"}}>
                                                    {(searchButtonClick === 1 && searchBlogValue !== '')
                                                    ?
                                                        <h4 className="" style={{textAlign:"center"}}>Click on search to get the result</h4>
                                                    :
                                                        (searchBlogValue !== '')
                                                        ?
                                                            <h4 className="" style={{textAlign:"center"}}>No Blog results found for {searchBlogValue} </h4>
                                                        :
                                                            <h4 className="" style={{textAlign:"center"}}>No Blogs </h4>
                                                    }
                                                </div>
                                        }

            {
                (loadMoreButton && totalCount !== 0 && totalCount > 3)
                    ?
            <Link to="#" className="mx-auto d-block d-lg-none d-flex btn show_btn justify-content-center" onClick ={(e) => {this.loadAllPost(e)}}>Load More</Link>
            :
                ""
            }
                                    </div>

                                    <div className="col-lg-4 ">
                                        <div className="side_post">
                                            <div className="side_post_search mb-3">
                                                <h4 className="mb-3">Search Blog</h4>
                                                <div className="side_post_box d-flex">
                                                    <input placeholder="Type to Search" onChange={this.inputChange} value={searchBlogValue}/>
                                                    <div className="side_post_search_img">
                                                        <Link to="#" onClick={(e) => {this.searchedBlog(e)}}><SearchsolidIcon/></Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="side_category mb-3">
                                                <h4 className="mb-3">Categories</h4>
                                                <div className="side_category_box">
                                                    <p className="mb-2 categoryfilterdata" onClick={(e) => {this.filterBlog(e,'')}}>All</p>
                                                    {blogCategories.map((categoryData,key) => {
                                                        return(
                                                            <p className="mb-2 categoryfilterdata" onClick={(e) => {this.filterBlog(e,categoryData.category)}}>{categoryData.category}</p>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div className="side_recent my-4">
                                                <h4 className="mb-3">Recent Posts</h4>
                                                <div className="side_recent_box">
                                                    {
                                                        recentBlogs.map((recentBlogData,key) => {

                                                            return(
                                                                <div className="side_recent_content d-flex mb-4">
                                                                    <Link to={'/blog/'+recentBlogData.slug} /*onClick={(e) => {this.getSingleBlogData(e,recentBlogData.id,recentBlogData.slug)}}*/><img alt="" src={recentBlogData.image} className="img-fluid" /></Link>
                                                                    <div className="side_recent_para">
                                                                        <Link to={'/blog/'+recentBlogData.slug} /*onClick={(e) => {this.getSingleBlogData(e,recentBlogData.id,recentBlogData.slug)}}*/><p className="mb-1">{recentBlogData.title} </p></Link>
                                                                        <small>{recentBlogData.created}</small>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                <Footer />
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    blogs : state.blogData.getBlogsData,
    blogCategories : state.blogData.getBlogCategories,
    recentBlogs : state.blogData.getRecentBlogs,
    searchedBlogs : state.blogData.getSearchedBlogData,
})


export default connect(
    mapStateToProps,
    {getBlogsAction,getBlogCategoriesAction, getRecentBlogsAction , searchBlogAction}
)(withRouter(Blog));

