import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Nav from "react-bootstrap/Nav";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import {
	getAllPostsAction,
	getAllPostsSearchAction,
	likeDislikeCommentAction,
	likeDislikePostAction,
	recentPostAction,
	replyToCommentAction,
	addCommentAction
} from "../../actions/post";
import {
	followContentAction,
} from "../../actions/homePage";
import { getUserDetails } from "../../actions/accountSetting";
import Modal from "react-bootstrap/Modal";
import LoginModalComponent from "../LoginModalComponent";
import MetaTags from "react-meta-tags";
import axios from "axios";
import {
	BASE_URL,
	API_KEY,
	ACCOUNT_NUMBER,
	ENVIRONMENT,
	MERCHANT_URL,
	DEVICE_CHANNEL,
	MESSAGE_CATEGORY,
	AUTHENTICATION_PURPOSE,
	CURRENCY,
} from "../Constant";
import authHeader from "../../utils/AuthHeader";
import { ReactComponent as PublicPostsIcon } from "../../assets/images/svgs/eye-icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/svgs/search.svg";
import { ReactComponent as AllPostsIcon } from "../../assets/images/svgs/all-posts-icon.svg";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import VideoPlayer from "../common/videojs";
import RenderModalSlider from "./../common/RenderModalSlider";
import { Badge } from "react-bootstrap";
import { isBlankNullUndefined, isBlankNull, dateFilter } from "../../utils/appUtils";


const WAIT_INTERVAL = 400;
const ENTER_KEY = 13;

export class PostListPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			start: 0,
			limit: 12,
			postData: [],
			postDataSearch: [],
			totalCount: 0,
			loginUserId: "",
			Loader: true,
			category: "All Posts",
			orderBy: "mostRecent",
			postsSearchValue: "",
			page: 1,
			subscribeConfirmationModal: false,
			loginWishListAlert: false,
			paymentConfirmationModal: false,
			contentWriterId: 0,
			userName: "",
			paymentError: false,
			cardDetails: 0,
			followerList: [],
			subscribeClick: 0, //for paymentconfirmation modal which is true in follow content props
			paymentErrorMessage: "",
			paysaferesponse: "",
			subscriptionPrice: 0,
			PaysafeToken: "",
			LoadAllProfiles: false,
			displayName: "",
			subscriptionMessage: "",
			cvv: "",
			cvvError: false,
			cvvErrorMessage: "",
			commentModalShow: false, //to show comment box on profile or add comment click
			singlePostData: [], //single post comment data
			singlePostDataIndex: "", //single post index number
			Comment: "",
			commentIndex: "", //parent comment index when click on reply,
			lastCommentId: 0,
			postButtonShow: 0, //when click on reply to comment if postbuttonshow is 0 then only show post bcz in reply we need name and if not write anything then it shows name only
			replyTo: 0,
			selectedIndex: [],
			selectedSlide: 0,
			replytousername: '',
			commentPostId: "", //in which post comment is going to write			
			recentPostData: [],
			postType: "Post",
			fireScrollAction: true,
		}

		this.searchActivity = this.searchActivity.bind(this);
		this.windowScroll = this.windowScroll.bind(this);
		this.paymentConfirmationModalOpen = this.paymentConfirmationModalOpen.bind(this);
		this.paymentConfirmationModalClose = this.paymentConfirmationModalClose.bind(this);
		this.subscribeConfirmationModalOpen = this.subscribeConfirmationModalOpen.bind(this);
		this.subscribeConfirmationModalClose = this.subscribeConfirmationModalClose.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.gotoupdatecard = this.gotoupdatecard.bind(this);
		this.gotocontactsupport = this.gotocontactsupport.bind(this);
		this.openModal = this.openModal.bind(this);
		this.showAuthErrorPaysafe = this.showAuthErrorPaysafe.bind(this);
		this.showgetAuthorizationerror = this.showgetAuthorizationerror.bind(this);
		this.inputChange = this.inputChange.bind(this);
		this.addComment = this.addComment.bind(this);
		this.handleCommentClose = this.handleCommentClose.bind(this);
		this.postLikeCountChanges = this.postLikeCountChanges.bind(this);
		this.commentLikeCountChanges = this.commentLikeCountChanges.bind(this);
		this.commentSubmit = this.commentSubmit.bind(this);
		this.triggerSearchActivity = this.triggerSearchActivity.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
	}


	componentDidMount() {
		let user_id = "";
		if (this.props.authenticatedUserData.userId) {
			user_id = this.props.authenticatedUserData.userId;
			this.props.getUserDetails(user_id);
		}

		let loginUserId = user_id;
		if (loginUserId === "" || loginUserId === undefined) {
			loginUserId = 0;
		}

		this.setState({
			category: this.state.category,
			orderBy: this.state.orderBy,
			loginUserId: loginUserId,
			start: 0,
			limit: this.state.limit
		});

		this.props.getAllPostsAction(
			this.state.category,
			this.state.orderBy,
			loginUserId,
			0,
			this.state.limit
		);

		window.addEventListener("scroll", this.windowScroll);
		window.scrollTo(0, 0);
	}

	componentWillUnmount() {
		this.setState({
			postData: [],
			postDataSearch: []
		})
	}


	windowScroll = (e) => {

		e.preventDefault();
		const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
		const body = document.body;
		const html = document.documentElement;
		const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
		const windowBottom = windowHeight + window.pageYOffset;


		if (windowBottom >= docHeight && this.state.fireScrollAction) {
			let user_id = (this.state.loginUserId === "") ? 0 : this.state.loginUserId;

			this.setState({
				LoadAllProfiles: true,
				fireScrollAction: false,
				Loader: true,
			});



			if (this.state.postsSearchValue !== '') {
				this.setState(
					{
						page: this.state.page + 1,
						start: this.state.limit * (this.state.page)
					},
					() => {
						this.props.getAllPostsSearchAction(
							this.state.postsSearchValue,
							this.state.category,
							this.state.orderBy,
							user_id,
							this.state.limit * (this.state.page - 1),
							this.state.limit
						)
					}
				);
			}
			else {
				this.setState(
					{
						page: this.state.page + 1,
						start: this.state.limit * (this.state.page)
					},
					() => {
						setTimeout(() => {
							this.props.getAllPostsAction(
								this.state.category,
								this.state.orderBy,
								user_id,
								this.state.limit * (this.state.page - 1),
								this.state.limit
							);
						}, 1500);
					}
				);
			}

		}
	};


	timer = null;
	componentWillMount() {
		this.timer = null;
	}
	searchActivity(e) {
		clearTimeout(this.timer);
		this.setState({ postsSearchValue: e.target.value });
		this.timer = setTimeout(this.triggerSearchActivity, WAIT_INTERVAL);
	}
	handleKeyDown(e) {
		if (e.keyCode === ENTER_KEY) { this.triggerSearchActivity(); }
	}
	triggerSearchActivity() {
		let postsSearchValue = this.state.postsSearchValue;

		this.setState({
			page: 1,
			totalCount: 0,
			postsSearchValue: postsSearchValue,
			postData: [],
			totalPostCount: 0,
			start: 0,
			postDataLength: 0,
		});
		let user_id = (this.state.loginUserId === "") ? 0 : this.state.loginUserId;
		if (postsSearchValue !== '') {
			this.props.getAllPostsSearchAction(
				postsSearchValue,
				this.state.category,
				this.state.orderBy,
				user_id,
				this.state.start,
				this.state.limit
			)
		} else {
			this.props.getAllPostsAction(
				this.state.category,
				this.state.orderBy,
				user_id,
				this.state.start,
				this.state.limit
			);
		}

	}

	inputChange(e) {
		this.setState({
			cvv: e.target.value
		});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			Loader: false,
			fireScrollAction: true
		});
		if (this.state.postsSearchValue !== "") {
			let searchActivityDataArray = [];

			if (
				nextProps.searchActivityData &&
				nextProps.searchActivityData.data &&
				nextProps.searchActivityData.data.length > 0
			) {
				if (this.state.start === 0 || this.state.page === 1) {
					this.setState({
						postData: nextProps.searchActivityData.data,
						totalCount: nextProps.searchActivityData.count,
					});
				} else {
					searchActivityDataArray.push(...this.state.postData);
					searchActivityDataArray.push(...nextProps.searchActivityData.data);
					this.setState({
						postData: searchActivityDataArray,
						totalCount: nextProps.searchActivityData.count,
					});
				}
			} else {
				if (
					nextProps.searchActivityData &&
					nextProps.searchActivityData.data &&
					nextProps.searchActivityData.data.length === 0 &&
					this.state.page === 1
				) {
					this.setState({
						postData: [],
						start: 0,
						totalCount: 0
					});
				}
			}

		} else {

			if (nextProps.allPostData && nextProps.allPostData.data && nextProps.allPostData.data.length !== "") {
				let postData = this.state.postData === undefined ? [] : this.state.postData;

				if (postData.length === 0) {
					postData = nextProps.allPostData.data;
				} else if (this.state.LoadAllProfiles) {
					postData.push(...nextProps.allPostData.data);
				}

				this.setState({
					postData: postData,
					totalCount: nextProps.allPostData.count,
					Loader: false,
				});
			}
		}

		if (
			nextProps.getUserData &&
			nextProps.getUserData.usersData &&
			nextProps.getUserData.usersData.length !== ""
		) {
			let Loader = this.state.Loader;
			if (this.state.Loader) {
				Loader = false;
			}

			let userData =
				this.state.userData === undefined ? [] : this.state.userData;

			if (userData.length === 0) {
				userData = nextProps.userData.categoryWiseUserData.data;
			}

			this.setState({
				cardDetails: nextProps.getUserData.usersData.cardDetails,
				followerList: nextProps.getUserData.usersData.followerList,
				Loader: Loader,
				PaysafeToken: nextProps.getUserData.usersData.PaysafeToken,
				userData: userData,
			});
		} else {
			this.setState({
				Loader: false,
			});
		}


		if (nextProps.followingContent && this.state.contentWriterId !== 0) {
			let userName = this.state.userName;
			let paymentConfirmationModal = false;
			let paymentError = false;
			let paymentErrorMessage = "";
			let paysaferesponse = "";
			if (this.state.subscribeClick === 1) {
				paymentConfirmationModal = true;
			}
			if (nextProps.followingContent.isError) {
				paymentError = true;
				paymentErrorMessage = nextProps.followingContent.error;
				paysaferesponse = nextProps.followingContent.paysafe;
			}
			this.setState({
				contentWriterId: 0,
				userName: "",
				paymentConfirmationModal: paymentConfirmationModal,
				Loader: false,
				paymentError: paymentError,
				paymentErrorMessage: paymentErrorMessage,
				paysaferesponse: paysaferesponse,
			});
			setTimeout(
				function () {
					let paymentConfirmationModal = false;
					if (
						!nextProps.followingContent.isError &&
						this.state.subscribeClick === 1
					) {
						// contentWriterId = btoa(contentWriterId)
						this.props.history.push("/" + userName);
					}
					if (
						nextProps.followingContent.isError &&
						this.state.subscribeClick === 1
					) {
						paymentConfirmationModal = true;
					}
					this.setState({
						paymentConfirmationModal: paymentConfirmationModal,
						subscribeClick: 0,
					});
				}.bind(this),
				800
			);
		}

	} //.UNSAFE_componentWillReceiveProps

	orderByClick(orderByVal) {

		this.setState({
			postData: [],
			orderBy: orderByVal,
			start: 0,
			page: 1,
			totalCount: 0,
			Loader: true
		});

		let user_id = this.state.loginUserId;
		if (this.state.loginUserId === "") {
			user_id = 0;
		}

		if (this.state.postsSearchValue !== "") {
			this.props.getAllPostsSearchAction(
				this.state.postsSearchValue,
				this.state.category,
				orderByVal,
				user_id,
				0,
				this.state.limit
			)
		} else {
			this.props.getAllPostsAction(
				this.state.category,
				orderByVal,
				user_id,
				0,
				this.state.limit
			);
		}

	} //.orderByClick

	categoryClick(e) {
		e.preventDefault();
		if (e.target.text !== undefined) {

			this.setState({
				category: e.target.text,
				postData: [],
				start: 0,
				page: 1,
				totalCount: 0,
				Loader: true
			});
			let user_id = this.state.loginUserId;
			if (this.state.loginUserId === "") {
				user_id = 0;
			}


			if (this.state.postsSearchValue !== "") {
				this.props.getAllPostsSearchAction(
					this.state.postsSearchValue,
					e.target.text,
					this.state.orderBy,
					user_id,
					0,
					this.state.limit
				)
			} else {
				this.props.getAllPostsAction(
					e.target.text,
					this.state.orderBy,
					user_id,
					0,
					this.state.limit
				);
			}
		}
	} //.categoryClick


	alertClose(e) {
		e.preventDefault();
		this.setState({
			loginWishListAlert: false,
		});
	}

	paymentConfirmationModalClose() {
		this.setState({
			paymentConfirmationModal: false,
			paymentError: false,
			subscribeClick: 0,
		});
	}

	gotoupdatecard() {
		this.setState({
			paymentConfirmationModal: false,
			paymentError: false,
			subscribeClick: 0,
		});
		let TabName = btoa("CardsTab");
		localStorage.setItem("cardsTab", TabName);
		setTimeout(
			function () {
				this.props.history.push("/account");
			}.bind(this),
			200
		);
	}

	gotocontactsupport() {
		this.setState({
			paymentConfirmationModal: false,
			paymentError: false,
			subscribeClick: 0,
		});
		setTimeout(
			function () {
				this.props.history.push("/contact-us");
			}.bind(this),
			200
		);
	}

	paymentConfirmationModalOpen() {
		//useState(false);
		this.setState({
			paymentConfirmationModal: true,
		});


	}

	isUserLogin() {
		return (this.state.loginUserId !== "" && this.state.loginUserId !== null && this.state.loginUserId !== "null" && this.state.loginUserId !== 0);
	}

	subscribeConfirmationModal(e, contentWriterId, userName, subscriptionPrice, displayName, subscriptionMessage) {
		e.preventDefault();

		if (this.state.loginUserId !== ""
			&& this.state.loginUserId !== null &&
			this.state.loginUserId !== "null" &&
			this.state.loginUserId !== 0
		) {
			this.setState({
				subscribeClick: 1,
				displayName: displayName,
				cvv: "",
				cvvError: false,
			});
			if (this.state.cardDetails === 1) {
				this.setState({
					subscribeConfirmationModal: true,
					contentWriterId: contentWriterId,
					userName: userName,
					displayName: displayName,
					subscriptionPrice: subscriptionPrice,
					subscriptionMessage: subscriptionMessage,
				});
			} else {
				this.setState({
					Loader: false,
					paymentError: true,
				});
				let TabName = btoa("CardsTab");
				localStorage.setItem("cardsTab", TabName);
				setTimeout(
					function () {
						this.props.history.push("/account");
					}.bind(this),
					200
				);
			}
		}
		//if not login
		else {
			this.setState({ loginWishListAlert: true });
		}
	} //.subscribeConfirmationModal

	subscribeConfirmationModalOpen() {
		//useState(false);
		this.setState({ subscribeConfirmationModal: true })
	}

	subscribeConfirmationModalClose() {
		this.setState({
			subscribeConfirmationModal: false,
			contentWriterId: 0,
			userName: "",
			subscriptionPrice: 0,
			subscribeClick: 0,
		});
	}

	/**
	 * card type not supported error message
	 */
	openModal() {
		this.setState({
			contentWriterId: 0,
			userName: "",
			paymentConfirmationModal: true,
			Loader: false,
			paymentError: "Error Code 5050 : Card type is not supported",
			paymentErrorMessage: "Error Code 5050 : Card type is not supported",
			paysaferesponse:
				"Merchant Account is not configured to support this card type",
		});
	}

	/**
	 *
	 * authentication credentials are invalid error
	 */
	showAuthErrorPaysafe() {
		this.setState({
			contentWriterId: 0,
			userName: "",
			paymentConfirmationModal: true,
			Loader: false,
			paymentError: "Error Code 5270 : Unauthorized access",
			paymentErrorMessage: "Error Code 5270 : Unauthorized access",
			paysaferesponse:
				"The credentials do not have permission to access the requested data",
		});
	}

	/**
	 * get authorization error modal
	 *
	 */
	showgetAuthorizationerror() {
		this.setState({
			contentWriterId: 0,
			userName: "",
			paymentConfirmationModal: true,
			Loader: false,
			paymentError: "Error Code 5279 : Invalid credentials",
			paymentErrorMessage: "Error Code 5279 : Invalid credentials",
			paysaferesponse: "The authentication credentials are invalid.",
		});
	}

	subscribe(e) {
		e.preventDefault();

		let contentWriterId = this.state.contentWriterId;
		let subscriptionPrice = this.state.subscriptionPrice;
		var token = this.props.getUserData.usersData.PaysafeToken;
		let firstSixDigit = this.props.getUserData.usersData.FirstSixDigit;
		let merchantRefNum = this.props.getUserData.usersData.merchantRefnum;

		let cvv = this.state.cvv;

		let length = cvv.length;
		if (length === 0) {
			this.setState({
				cvvError: true,
				cvvErrorMessage: "CVV field is required",
			});
		} else if (length < 3 || length > 4) {
			this.setState({
				cvvError: true,
				cvvErrorMessage:
					"CVV length must be greater than 2 character and less than 4 character",
			});
		}

		if (length === 3 || length === 4) {
			window.state = this.state;
			window.props = this.props;
			window.this = this;

			if (
				this.state.loginUserId !== "" &&
				this.state.loginUserId !== null &&
				this.state.loginUserId !== "null"
			) {
				this.setState({
					Loader: true,
					subscribeConfirmationModal: false,
					loginWishListAlert: false,
				});
				if (this.state.cardDetails === 1) {
					this.setState({
						contentWriterId: contentWriterId,
					});
					/*let paymentData = {
						amount: subscriptionPrice,
						paysafe_token: this.state.PaysafeToken,
					};*/
					let subscriptionPlan = 1;
					window.paysafe.threedsecure.start(
						API_KEY,
						{
							environment: ENVIRONMENT,
							accountId: ACCOUNT_NUMBER,
							card: {
								cardBin: firstSixDigit,
							},
						},
						function (deviceFingerprintingId, error) {
							if (error) {
								window.this.openModal();
							} else {
								var data = {
									amount: subscriptionPrice * 100,
									currency: CURRENCY,
									currencyCode: CURRENCY,
									merchantRefNum: merchantRefNum,
									merchantUrl: MERCHANT_URL,
									card: { paymentToken: token },
									deviceChannel: DEVICE_CHANNEL,
									messageCategory: MESSAGE_CATEGORY,
									authenticationPurpose: AUTHENTICATION_PURPOSE,
									deviceFingerprintingId: deviceFingerprintingId,
								};
								axios
									.post(
										BASE_URL + "/authorization",
										{ data },
										{
											headers: authHeader(),
										}
									)
									.then((response) => {
										if (response.data.status !== "COMPLETED") {
											var responsePayload = response.data.sdkChallengePayload;

											window.paysafe.threedsecure.challenge(
												API_KEY,
												{
													environment: ENVIRONMENT,
													sdkChallengePayload: responsePayload,
												},
												function (authenticationID, error) {
													if (error) {
													} else {
														var data = {
															authenticationID: authenticationID,
														};
														axios
															.post(
																BASE_URL + "/getauthorization",
																{ data },
																{
																	headers: authHeader(),
																}
															)
															.then((getAuthorization) => {
																/*if(getAuthorization.error!=''){
																														alert('heeeeeeee')
																														window.this.showgetAuthorizationerror()
																												} else{*/
																var paymentData = {
																	amount: subscriptionPrice,
																	paysafe_token: token,
																	cvv: cvv,
																	data: getAuthorization,
																};
																window.props.followContentAction(
																	window.state.loginUserId,
																	contentWriterId,
																	subscriptionPlan,
																	paymentData
																);

																//}
															});
													}
												}
											);
										} else {
											var data = {
												authenticationID: response.data.id,
											};
											axios
												.post(
													BASE_URL + "/getauthorization",
													{ data },
													{
														headers: authHeader(),
													}
												)
												.then((getAuthorization) => {
													var paymentData = {
														amount: subscriptionPrice,
														paysafe_token: token,
														data: getAuthorization,
														cvv: cvv,
													};
													window.props.followContentAction(
														window.state.loginUserId,
														contentWriterId,
														subscriptionPlan,
														paymentData
													);
												});
										}
									});
							}
						}
					);
				}
			}
		}
	}

	renderFiles(url, id) {
		let videoJsOptions = {
			autoplay: false,
			controls: true,
			responsive: true,
			fluid: true,
			sources: [
				{
					src: url,
					type: "video/mp4",
				},
			],
		};

		if (document.querySelector("#postvideos" + id)) {
			let player = videojs(document.querySelector("#postvideos" + id));
			player.src({
				src: url,
				type: "video/mp4" /*video type*/,
			});
		}

		return <VideoPlayer {...videoJsOptions} id={"postvideos" + id} />;
	}

	handleClose() {
		this.setState({
			loginWishListAlert: false,
		});
	}

	handleCommentClose() {
		this.setState({
			commentModalShow: false,
			singlePostData: []
		});
	}

	//when reply to any comment
	replyToComment(e, commentIndex, postId, username) {
		e.preventDefault();
		let userName = "@" + username + " ,";
		this.setState({
			Comment: userName,
			commentIndex: parseInt(commentIndex),
			postButtonShow: 1,
			replyTo: 1,
			commentPostId: postId,
			replytousername: username
		});
	}

	//react modal hide show functions start
	addComment(e, index) {

		e.preventDefault();
		
		let singlePostData = this.state.postData[index];
		this.setState({
			commentModalShow: true,
			singlePostData: singlePostData,
			singlePostDataIndex: index,
			Comment: ""
		});
		setTimeout(function () {
			var chatBoxDiv = document.getElementById("chat_box");
			chatBoxDiv.scrollTo(0, chatBoxDiv.scrollHeight);
		}, 100);
	}

	postLikeCountChanges(e, index) {
		e.preventDefault();
		if (localStorage.user_data) {
			let authenticatedUserData = JSON.parse(
				decodeURIComponent(localStorage.getItem("user_data"))
			);

			let expires_at = new Date(authenticatedUserData.expires_at);
			expires_at = expires_at.getTime();
			let currentTime = new Date();
			currentTime = currentTime.getTime();
			if (
				authenticatedUserData &&
				expires_at < currentTime &&
				authenticatedUserData.remember_me === "No"
			) {
				this.props.logoutAction();
				// Redirect to login
				window.location.href = "/login";
			} else {
				index = parseInt(index);
				let likedByMe = 0;
				let likes = this.state.postData[index].likes;
				if (this.state.postType === "recentPost") {
					likes = this.state.recentPostData[index].likes;
				}

				if (
					this.state.postType === "Post" &&
					this.state.postData[index].likedByMe === 1
				) {
					likedByMe = 0;
					likes = likes - 1;
				} else if (
					this.state.postType === "recentPost" &&
					this.state.recentPostData[index].likedByMe === 1
				) {
					likedByMe = 0;
					likes = likes - 1;
				} else {
					likedByMe = 1;
					likes = likes + 1;
				}

				if (this.state.singlePostDataIndex !== "") {
					this.state.singlePostData = {
						...this.state.singlePostData,
						likes: likes,
						likedByMe: likedByMe,
					};
					this.setState({
						...this.state.singlePostData,
					});
				}

				if (this.state.postType === "Post") {
					this.state.postData[index] = {
						...this.state.postData[index],
						likes: likes,
						likedByMe: likedByMe,
					};
					this.setState({
						...this.state.postData[index],
					});
					this.props.likeDislikePostAction(
						this.state.postData[index].id,
						this.state.loginUserId
					);
				} else {
					this.state.recentPostData[index] = {
						...this.state.recentPostData[index],
						likes: likes,
						likedByMe: likedByMe,
					};
					this.setState({
						...this.state.recentPostData[index],
					});
					this.props.likeDislikePostAction(
						this.state.recentPostData[index].id,
						this.state.loginUserId
					);
				}
			}
		} else {
			this.setState({
				loginWishListAlert: true,
			});
		}
	}

	//comment like count
	commentLikeCountChanges(e, commentIndex) {
		e.preventDefault();
		if (localStorage.user_data) {
			let authenticatedUserData = JSON.parse(
				decodeURIComponent(localStorage.getItem("user_data"))
			);

			let expires_at = new Date(authenticatedUserData.expires_at);
			expires_at = expires_at.getTime();
			let currentTime = new Date();
			currentTime = currentTime.getTime();
			if (
				authenticatedUserData &&
				expires_at < currentTime &&
				authenticatedUserData.remember_me === "No"
			) {
				this.props.logoutAction();
				// Redirect to login
				window.location.href = "/login";
			} else {
				commentIndex = parseInt(commentIndex);
				let likedByMe = 0;

				let likes = this.state.singlePostData.commentUsers[commentIndex].likes;

				if (
					this.state.singlePostData.commentUsers[commentIndex].likeByMe === 1
				) {
					likedByMe = 0;
					likes = likes - 1;
				} else {
					likedByMe = 1;
					likes = likes + 1;
				}

				this.state.singlePostData.commentUsers[commentIndex] = {
					...this.state.singlePostData.commentUsers[commentIndex],
					likes: likes,
					likeByMe: likedByMe,
				};
				this.setState({
					...this.state.singlePostData,
				});
				this.props.likeDislikeCommentAction(
					this.state.singlePostData.commentUsers[commentIndex].id,
					this.state.loginUserId
				);
			}
		}
	}



	//react modal hide show functions end

	//when click on post button of comment
	commentSubmit(e) {
		e.preventDefault();
		if (localStorage.user_data) {
			let authenticatedUserData = JSON.parse(
				decodeURIComponent(localStorage.getItem("user_data"))
			);

			let expires_at = new Date(authenticatedUserData.expires_at);
			expires_at = expires_at.getTime();
			let currentTime = new Date();
			currentTime = currentTime.getTime();
			if (
				authenticatedUserData &&
				expires_at < currentTime &&
				authenticatedUserData.remember_me === "No"
			) {
				this.props.logoutAction();
				// Redirect to login
				window.location.href = "/login";
			} else {
				let isError = false;
				let commentPostId = "";
				if (this.state.Comment === "") {
					isError = true;
					commentPostId = parseInt(e.target.getAttribute("postid"));
				}

				this.setState({
					isError: isError,
					commentPostId: commentPostId,
				});
				if (!isError) {
					let authenticatedUserData = this.props.authenticatedUserData;
					let authUserProfilePic = authenticatedUserData.ProfilePic;
					let authUserdisplayName = authenticatedUserData.DisplayName;
					let index = parseInt(e.target.id); //post index number

					let commentUserData = {};
					let replyCommentUserData = {};
					let getCommentId = 0;
					let splitComment = "";
					//comment created date
					const monthNames = [
						"January",
						"February",
						"March",
						"April",
						"May",
						"June",
						"July",
						"August",
						"September",
						"October",
						"November",
						"December",
					];
					var today = new Date();
					let commentCreatedDate =
						today.getDate() +
						" " +
						monthNames[today.getMonth()] +
						" " +
						today.getFullYear();

					if (this.state.replyTo === 1) {
						splitComment = this.state.Comment.substring(
							this.state.Comment.indexOf(",") + 1,
							this.state.Comment.length
						);

						replyCommentUserData = {
							comment: splitComment,
							profile: authUserProfilePic,
							displayName: authUserdisplayName
						};
						//@Aaki Modi coming in comment when click on reply to so remove this name from comment
						this.state.singlePostData.commentUsers[
							this.state.commentIndex
						].commentUsers.push(replyCommentUserData);

						getCommentId =
							this.state.singlePostData.commentUsers[this.state.commentIndex]
								.id;
					} else {
						commentUserData = {
							id: this.state.lastCommentId,
							comment: this.state.Comment,
							profile: authUserProfilePic,
							commentUsers: [],
							likes: 0,
							likeByMe: 0,
							commentDate: commentCreatedDate,
							displayName: authUserdisplayName
						};
						this.setState({
							lastCommentId: this.state.lastCommentId + 1,
						});

						//when comment without opening pop up
						if (this.state.singlePostData.length !== 0) {
							//push comemnt data to singlePost commentUsers param
							this.state.singlePostData.commentUsers.push(commentUserData);
						} else {
							if (this.state.postType === "Post") {
								this.state.postData[index].commentUsers.push(commentUserData);
							} else {
								this.state.recentPostData[index].commentUsers.push(
									commentUserData
								);
							}
						}
					}
					if (this.state.singlePostData.length !== 0) {
						this.state.singlePostData = {
							...this.state.singlePostData,
							totalComments: this.state.singlePostData.totalComments + 1,
						};
					}

					if (this.state.postType === "Post") {
						this.state.postData[index] = {
							...this.state.postData[index],
							totalComments: this.state.postData[index].totalComments + 1,
						};
						this.setState({
							...this.state.singlePostData, //for single post update in modal
							...this.state.postData[index], //for post update
						});
					} else {
						this.state.recentPostData[index] = {
							...this.state.recentPostData[index],
							totalComments: this.state.recentPostData[index].totalComments + 1,
						};
						this.setState({
							...this.state.singlePostData, //for single post update in modal
							...this.state.recentPostData[index], //for recent post update
						});
					}

					let Comment = this.state.Comment;
					if (splitComment !== "") {
						Comment = splitComment;
					}
					let commentData = {
						Comment: Comment,
					};
					if (this.state.replyTo === 1) {
						this.props.replyToCommentAction(
							getCommentId,
							this.state.loginUserId,
							commentData
						);
					} else {
						if (this.state.postType === "Post") {
							this.props.addCommentAction(
								this.state.postData[index].id,
								this.state.loginUserId,
								commentData
							);
						} else {
							this.props.addCommentAction(
								this.state.recentPostData[index].id,
								this.state.loginUserId,
								commentData
							);
						}
					}
					this.setState({
						Comment: "",
						commentIndex: "",
						replyTo: 0,
					});
				}
			}
		}
	}

	//when writing comment
	inputCommentChange(e, postId) {
		// e.preventDefault()
		let postButtonShow = 0;
		let Comment = e.target.value;
		let replytousername = this.state.replytousername
		if (this.state.Comment.includes("@") && this.state.commentIndex !== "") {
			if (
				e.target.value ===
				"@" +
				replytousername +
				" ,"
			) {
				postButtonShow = 1;
			}
			//if @name erase from middle or anyother way n then if not match then remove it
			if (!e.target.value.includes("@" + replytousername + " ,")) {
				Comment = this.state.Comment.replace(
					"@" +
					replytousername +
					" ,",
					""
				);
			}
		}
		let replyTo = this.state.replyTo;
		if (Comment === "") {
			replyTo = 0;
		}

		this.setState({
			Comment: Comment,
			postButtonShow: postButtonShow,
			isError: false,
			replyTo: replyTo,
			commentPostId: postId,
		});
	}

	render() {
		const {
			postData,
			totalCount,
			postsSearchValue,
			Loader,
			loginWishListAlert,
			subscribeConfirmationModal,
			paymentConfirmationModal,
			paymentError,
			loginUserId,
			followerList,
			paymentErrorMessage,
			paysaferesponse,
			orderBy,
			cvv,
			cvvError,
			cvvErrorMessage,
			commentModalShow,
			singlePostData,
			singlePostDataIndex,
			Comment,
			postButtonShow,
			commentPostId,
		} = this.state;

		const monthNames = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];
		let postCreatedDate = "";
		var today = new Date();
		if (Object.keys(singlePostData).length > 0 && singlePostData.created) {
			if (!isNaN(singlePostData.created)) {
				today.setHours(today.getHours() - singlePostData.created);
			} else if (isNaN(singlePostData.created)) {
				postCreatedDate = singlePostData.created;
			}

			if (
				!isNaN(singlePostData.created) ||
				(isNaN(singlePostData.created) &&
					singlePostData.created.includes("min"))
			) {
				// let getPostCreatedDate = today.toLocaleString();
				let getPostCreatedDate = new Date();
				postCreatedDate =
					getPostCreatedDate.getDate() +
					" " +
					monthNames[getPostCreatedDate.getMonth()] +
					" " +
					getPostCreatedDate.getFullYear();
			}

			//chat box div scroll to bottom
			var chatBoxDiv = document.getElementById("chat_box");
			if (chatBoxDiv !== null) {
				setTimeout(function () {
					chatBoxDiv.scrollTo(0, chatBoxDiv.scrollHeight);
				}, 100);
			}
		}
		return (
			<>
				{Loader ? (
					<div id="preloader">
						<div className="preloader_div"></div>
					</div>
				) : (
					""
				)}
				<MetaTags>
					<title>Browse Content | DesireMe</title>
					<meta property="og:type" content="website" />
					<meta property="og:url" content="__OGURL__" />
					<link rel="canonical" href="__OGURL__" />
					<meta
						property="og:image"
						content="https://desire.me/images/ogimage.png"
					/>
					<meta property="og:site_name" content="Desire Me" />
					<meta name="description" content="__METADESCRIPTION__" />
					<meta name="title" content="__METATITLE__" />
					<meta property="og:description" content="__METADESCRIPTION__" />
					<meta property="og:title" content="__METATITLE__" />
				</MetaTags>
				<Header />
				<section id="ProfileCardPage" className="clsPostContainer">
					<div className="container mt-5">
						<div className="show_profile_heading mb-4">
							<div className="d-flex align-items-center mb-2">
								{/*<img src={process.env.PUBLIC_URL + '/frontend/images/heart.png'} alt="search" className="show-profile-pic" />*/}
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="46.447"
									height="41.323"
									viewBox="0 0 46.447 41.323"
								>
									<g
										id="Group_68"
										data-name="Group 68"
										transform="translate(-755 -358)"
									>
										<path
											id="Path_627"
											data-name="Path 627"
											d="M797.762,362.044a12.49,12.49,0,0,0-9.29-4.044,11.685,11.685,0,0,0-7.3,2.52,14.909,14.909,0,0,0-2.95,3.081,14.928,14.928,0,0,0-2.951-3.081,11.681,11.681,0,0,0-7.3-2.52,12.49,12.49,0,0,0-9.291,4.044A14.517,14.517,0,0,0,755,371.959c0,3.928,1.464,7.524,4.607,11.316,2.811,3.393,6.853,6.836,11.532,10.824,1.6,1.362,3.409,2.905,5.289,4.55a2.727,2.727,0,0,0,3.591,0c1.88-1.644,3.693-3.189,5.291-4.551,4.679-3.987,8.72-7.43,11.531-10.823,3.143-3.792,4.607-7.388,4.607-11.316a14.517,14.517,0,0,0-3.685-9.914Zm0,0"
											fill="#1c1c1c"
										/>
									</g>
								</svg>
								<h1 className="mb-0 ml-3 cls-bp-title">Browse Content</h1>
							</div>

							 
						</div>

						<Row>
							<Col sm={12}>
								<Nav
									variant="pills"
									className="align-items-center mt-3 justify-content-center justify-content-lg-start"
								>


									<div className="form-group nav-item m-0 col-lg-5" >
										<div className="search_profile">

											<input
												placeholder="Type to Search"
												className="mr-2 border-0 input_text"
												onChange={(e) => { this.searchActivity(e) }}
												onKeyDown={(e) => { this.handleKeyDown(e) }}
												value={postsSearchValue} />
											<SearchIcon className="follow_search_pic d-none d-lg-inline" />
										</div>
									</div>


									<div className="form-group nav-item m-0">
										<select
											className="form-control select_box"
											id="formControlSelect1"
											name="orderBY"
											onChange={(e) => { this.orderByClick(e.target.value); }}
											value={orderBy}
										>
											<option value="mostRecent">Sort By : Recently Updated </option>
											<option value="Popular">Most Popular</option>
											<option value="MostLike">Most Liked</option>
											<option value="MostComments">Most Commented</option>
										</select>
									</div>
									<div className="show_profile_mai_main_box">
										<Nav.Item onClick={(e) => { this.categoryClick(e); }}>
											<Nav.Link eventKey="All Posts" className="btn nav_item_btn_white nav_item_link_btn nav_item_link font-weight-bold">
												<AllPostsIcon height={"1.5em"} width={"1.5em"} className={"mr-2 "} />All Posts
											</Nav.Link>
										</Nav.Item>
										<Nav.Item onClick={(e) => { this.categoryClick(e); }}>
											<Nav.Link className="btn nav_item_link_btn nav_item_link_btn_hover nav_item_link font-weight-bold">
												<PublicPostsIcon height={"1.5em"} width={"1.5em"} className={"mr-2 "} />Public Posts
											</Nav.Link>
										</Nav.Item>
									</div>

								</Nav>
							</Col>
						</Row>
						<div className="row mt-5">
							<div className="card-deck mb-5 col-12">
								<div className="row row_show_profile px-0 justify-content-center">
									{totalCount > 0 ? (

										postData.map((data, key) => (
											<div className="card border-none col-md-6 col-lg-4 px-0 mr-0" key={key}>
												{(data.is_public === 1 || followerList.includes(data.user.id) || loginUserId === data.user.id) ?
													(data.media[0] ?
														(
															<Link to="#" className="mx-auto" onClick={(e) => { this.addComment(e, key); }}>
																<div
																	className={`card_show_div overflow-hidden ${data.media[0].type === "video" && data.media[0].thumbnail !== 'undefined' && " clsSolidBorder"}`}
																	style={data.media[0].type === 'image' ?
																		(data.media[0].thumbnail !== 'undefined' && data.media[0].thumbnail ?
																			{ backgroundImage: `url("${data.media[0].thumbnail}")` }
																			:
																			{ backgroundImage: `url(/images/default-avatar.png)` }
																		) :
																		(data.media[0].type !== "video" ?
																			{ backgroundImage: `url(/images/default-avatar.png)` } : { backgroundImage: `url("${data.media[0].thumbnail}")`, backgroundSize: 'over' }
																		)
																	}
																>
																	{data.media[0].type === "video" &&
																		data.media[0].thumbnail !== 'undefined' &&
																		data.media[0].thumbnail !== 'undefined' &&
																		<img src="/images/video-play.png" alt="Play Video" className="img_center" width={'50px'} height='50px' />
																	}
																</div>
															</Link>
														)
														:
														(
															<div className="card_show_div clsDefaultAvatar"></div>
														)
													)
													:
													(
														<div className="card_show_div privatePost"></div>
													)
												}


												<div className="card-body">
													{!isBlankNullUndefined(data.caption) ?
														<>
															<div className="d-flex justify-content-center text-center card_heading">
																<p>{data.title}</p>
															</div>
															<div className={"d-flex justify-content-center text-center text-truncate height68"} >
																<p className="text-truncate clsWhiteSpaceNormal">
																	{data.caption} {data.tags && data.tags}
																</p>
															</div>
														</>
														:
														<>
															<div className="d-flex justify-content-center text-center card_heading height102">
																<p style={{ whiteSpace: 'normal' }}>
																	{data.title > 40 ? data.title.substring(0, 12) + '...' : data.title}
																</p>
															</div>
														</>
													}
														

													{loginUserId !== data.user.id ?
														(
															!followerList.includes(data.user.id) ?
																(
																	<div className={"d-flex justify-content-between card_subscribe mt-2  "}>
																		<Link to="#"
																			onClick={(e) => {
																				this.subscribeConfirmationModal(
																					e,
																					data.user.id,
																					data.user.username,
																					data.user.SubscriptionPriceWithVAT,
																					data.user.display_name,
																					data.user.message
																				);
																			}} className="subscribe_btn">
																			follow {data.user.username.length > 15 ? data.user.username.substring(0, 12) + '...' : data.user.username} {(data.is_public === 1) ? 'now' : <><br />to see this post</>}
																		</Link>
																	</div>
																)
																:
																(
																	''
																)
														)
														: ''
													}

													<div className={"d-flex justify-content-between align-items-center card_desired_list "}>
														<Link to={"/" + data.user.username} className="desired_list_btn">
															view {data.user.username.length > 15 ? data.user.username.substring(0, 12) + '...' : data.user.username} profile
														</Link>
													</div>
													
													<div className="text-center pt-2">
														<div
															className={"ml-3 d-inline-block cursor_pointer"}
															onClick={(e) => {
																(data.is_public === 1 || followerList.includes(data.user.id) || loginUserId === data.user.id) ?
																	(this.postLikeCountChanges(e, key))
																	:
																	(this.subscribeConfirmationModal(
																		e,
																		data.user.id,
																		data.user.username,
																		data.user.SubscriptionPriceWithVAT,
																		data.user.display_name,
																		data.user.message
																	))
															}}
														>
															<span>
																{data.likedByMe === 0 ? (
																	<img src={process.env.PUBLIC_URL + "/frontend/images/like.png"} alt="img" index={key} width="16px" height="16px" />
																) : (
																	<img src={process.env.PUBLIC_URL + "/frontend/images/heart_likes.webp"} alt="img" index={key} width="17px" height="17px" />
																)}
																<Badge variant="secondary" className="bg-transparent text-body align-self-center">{data.likes}</Badge>
															</span>
														</div>
														<div
															className={"ml-3 d-inline-block cursor_pointer"}
															onClick={(e) => {
																(data.is_public === 1 || followerList.includes(data.user.id) || loginUserId === data.user.id) ?
																	(this.isUserLogin() ? this.addComment(e, key) : this.setState({ loginWishListAlert: true }))
																	:
																	(this.subscribeConfirmationModal(
																		e,
																		data.user.id,
																		data.user.username,
																		data.user.SubscriptionPriceWithVAT,
																		data.user.display_name,
																		data.user.message
																	))
															}}
														>
															<span>
																<svg
																	width="1em" height="1em" viewBox="0 0 16 16"
																	className="bi bi-chat-square"
																	fill="currentColor"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path
																		fillRule="evenodd"
																		d="M14 1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h2.5a2 2 0 0 1 1.6.8L8 14.333 9.9 11.8a2 2 0 0 1 1.6-.8H14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
																	/>
																</svg>
															</span>
															<Badge color="white">{data.totalComments}</Badge>
														</div>
													</div>

												</div>
											</div>
										)
										)

									) : (
										<div
											className="d-flex justify-content-center align-items-center"
											style={{ height: "400px" }}
										>
											<h4
												className=""
												style={{ textAlign: "center" }}
											>
												No Profiles
											</h4>
										</div>
									)}


								</div>


							</div>
						</div>
					</div>
				</section>

				{subscribeConfirmationModal ? (
					<div className="modal__div">
						<Modal
							size="lg"
							show={subscribeConfirmationModal}
							onHide={this.subscribeConfirmationModalClose}
							centered
							dialogClassName="modal-90w"
							aria-labelledby="example-custom-modal-styling-title"
							className="custom__modal"
						>
							<Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
							<Modal.Body style={{ width: "100%" }}>
								<div className="modal__body">
									<div className="modal__text px-3 px-md-5 pb-5 pb-md-5 text-center">
										<p className="modalheading">
											Desire{" "}
											{this.state.displayName ? this.state.displayName : ""}
										</p>
										<p className="modalparagraph">
											{this.state.subscriptionMessage}
										</p>

										<div className="form-group" id="subscription-modal">
											<label htmlFor="cvv" className="cvv-popup-number">
												<b>Enter CVV</b>
												<b>
													{" "}
													({this.props.getUserData.usersData.cardType} ****{" "}
													{this.props.getUserData.usersData.cardLastDigit}){" "}
												</b>
												<Link to="#" onClick={this.gotoupdatecard}>
													<u>
														<b className="update-card-cvv">Update Card</b>
													</u>
												</Link>
											</label>
											<input
												type="number"
												className="form-control input_text desire-cvv-update"
												id="cvv"
												placeholder="Enter Your CVV"
												name="cvv"
												onChange={this.inputChange}
												defaultValue={cvv}
											/>
											&nbsp;&nbsp;&nbsp;
											<button
												className="followbtn btn btn__continue mr-0 mb-3 mb-md-0 mr-md-3"
												type="button"
												onClick={(e) => {
													this.subscribe(e);
												}}
											>
												Desire{" "}
												{this.state.displayName ? this.state.displayName : ""}
											</button>
											{cvvError ? (
												<div id="alert-box2" className="font-weight-bold mt-1">
													<span className="pr-2">
														<svg
															width="1em"
															height="1em"
															viewBox="0 0 16 16"
															className="bi bi-exclamation-circle"
															fill="currentColor"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fillRule="evenodd"
																d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
															/>
															<path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
														</svg>
													</span>
													{cvvErrorMessage}
												</div>
											) : (
												""
											)}
										</div>

										<div className="button__group d-flex flex-column flex-md-row align-itmes-center justify-content-center">
											<button
												className="btn btn__black ml-0 ml-md-3"
												type="button"
												onClick={this.subscribeConfirmationModalClose}
											>
												Cancel
											</button>
										</div>
									</div>
								</div>
							</Modal.Body>
						</Modal>
					</div>
				) : (
					""
				)}

				{loginWishListAlert ? (
					<LoginModalComponent
						loginUserId={loginUserId}
						show={loginWishListAlert}
						handleClose={this.handleClose}
					/>
				) : (
					""
				)}

				{paymentConfirmationModal ? (
					<div className="modal__div">
						<Modal
							size="lg"
							centered
							show={this.paymentConfirmationModal}
							onHide={this.paymentConfirmationModalClose}
							dialogClassName="modal-90w"
							aria-labelledby="example-custom-modal-styling-title"
							className="custom__modal"
						>
							<Modal.Header
								closeButton
								style={{ border: "none" }}
							></Modal.Header>
							<Modal.Body style={{ width: "100%" }}>
								<div className="modal__body">
									<div className="modal__text px-3 px-md-5 pb-5 pb-md-5 text-center">
										{!paymentError ? (
											<>
												<div className="pb-4">
													<img
														width="65px"
														height="65px"
														src={process.env.PUBLIC_URL + "/frontend/images/icon-tick.svg"}
														alt=""
													/>
												</div>
												<p className="modalheading">
													Successfully followed{" "}
													{this.state.displayName ? this.state.displayName : ""}
												</p>
												<p className="modalparagraph">
													Reloading the profile now to show{" "}
													{this.state.displayName ? this.state.displayName : ""}
													's content, please wait…
												</p>
											</>
										) : (
											<>
												<div className="pb-4">
													<img
														width="65px"
														height="65px"
														src={process.env.PUBLIC_URL + "/frontend/images/icon-cross.svg"}
														alt=""
													/>
												</div>
												<p className="modalheading">
													We're sorry but your payment failed
												</p>
												<p className="paysaferesponse">{paysaferesponse}</p>
												<p className="pb-4 modalparagraph">
													{paymentErrorMessage}
												</p>
												<div className="button__group d-flex flex-column flex-md-row align-itmes-center justify-content-center">
													<button
														className="btn btn__continue btn_updatecard mr-0 mb-3 mb-md-0 mr-md-3"
														onClick={this.gotoupdatecard}
													>
														Update Card
													</button>
													<p className="mb-0 my-auto d-none d-md-block">OR</p>
													<button
														className="btn btn__continue btn_contactsupport ml-0 ml-md-3"
														onClick={this.gotocontactsupport}
													>
														Contact Support
													</button>
												</div>
											</>
										)}
									</div>
								</div>
							</Modal.Body>
						</Modal>
					</div>
				) : (
					""
				)}

				{commentModalShow ? (
					<section id="profileonclick">
						<div>
							<Modal
								size="xl"
								show={commentModalShow}
								onHide={this.handleCommentClose}
								dialogClassName="modal-90w"
								aria-labelledby="example-custom-modal-styling-title"
								className="custom-modal-content"
							>
								<Modal.Header closeButton></Modal.Header>
								<Modal.Body style={{ width: "100%" }}>
									<div className="container d-flex p-0">
										<div
											className="pic_view d-flex align-items-center justify-content-center bg-dark"
											style={{ width: "65%" }}
										>
											{singlePostData.media && (
												<RenderModalSlider
													media={singlePostData.media}
													selectedSlide={this.state.selectedSlide}
												/>
											)}
										</div>

										<div className="chat_box bg-white" style={{ width: "35%" }}>
											<div className=" d-flex p-2 pl-3">
												<div>
													<img
														src={isBlankNull(singlePostData.user.profile) ? "/frontend/images/profile-image.png" : singlePostData.user.profile}
														className="icon_img" alt="img" />
												</div>

												<div className=" pl-3 d-flex flex-column">
													<p className="font-weight-bold m-0">
														<Link to={"/" + singlePostData.user.username}>
															{singlePostData.user.display_name}
														</Link>
													</p>

													<small className="font-weight-thin sub-title">
													{singlePostData.created && dateFilter(singlePostData.created)}

														{singlePostData.user.state !== null || singlePostData.user.country !== null ? (
															<>
																{(singlePostData.user.location && !isBlankNullUndefined(singlePostData.user.location)) ?
																	", " + singlePostData.user.location
																	:
																	(singlePostData.user.state && !isBlankNullUndefined(singlePostData.user.state)
																		? ", " + singlePostData.user.state : "") +
																	
																	(singlePostData.user.country && !isBlankNullUndefined(singlePostData.user.country) ?
																		", " + singlePostData.user.country : "")
																}
															</>
														) : (
															""
														)}
													</small>
												</div>
											</div>

											<hr />

											<div className="icon_count d-flex">
												<div className="pl-3 ">
													{singlePostData.likedByMe === 0 ? (
														<>
															<Link
																to="#"
																onClick={(e) => {
																	this.postLikeCountChanges(
																		e,
																		singlePostDataIndex
																	);
																}}
																className={
																	parseInt(loginUserId) !== 0
																		? ""
																		: "remove-pointer"
																}
															>
																<img
																	src={
																		process.env.PUBLIC_URL +
																		"/frontend/images/like.png"
																	}
																	alt="img"
																	className="red_like_img1"
																/>
															</Link>
															<Badge
																variant="secondary"
																className="bg-transparent text-body align-self-center"
															>
																{singlePostData.likes}
															</Badge>
														</>
													) : (
														<>
															<Link
																to="#"
																onClick={(e) => {
																	this.postLikeCountChanges(
																		e,
																		singlePostDataIndex
																	);
																}}
																className={
																	parseInt(loginUserId) !== 0
																		? ""
																		: "remove-pointer"
																}
															>
																<img
																	src={
																		process.env.PUBLIC_URL +
																		"/frontend/images/heart_likes.webp"
																	}
																	alt="img"
																	className="red_like_img1"
																/>
															</Link>
															<Badge
																variant="secondary"
																className="bg-transparent text-body align-self-center"
															>
																{singlePostData.likes}
															</Badge>
														</>
													)}
												</div>

												<div className="pl-1">
													<span>
														<svg
															width="1em"
															height="1em"
															viewBox="0 0 16 16"
															className="bi bi-chat-square"
															fill="currentColor"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fillRule="evenodd"
																d="M14 1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h2.5a2 2 0 0 1 1.6.8L8 14.333 9.9 11.8a2 2 0 0 1 1.6-.8H14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
															/>
														</svg>
													</span>
													<Badge color="white">
														{singlePostData.totalComments}
													</Badge>
												</div>
											</div>
											<hr />

											<div className="chats_box" id="chat_box">
												{singlePostData.commentUsers.map(
													(parentComment, parentCommentKey) => {
														return (
															<div key={parentCommentKey}>
																<div className="recevied_chat d-flex mx-auto mt-2">
																	<div className="pl-2  ">
																		<img
																			src={isBlankNull(parentComment.profile) ? "/images/default-avatar.png" : parentComment.profile}
																			className="icon_img" alt="img" />
																	</div>
																	<div className=" pl-3 d-flex flex-column">
																		<small className="font-weight-bold m-0">
																			{parentComment.displayName}
																		</small>
																		<small>{parentComment.comment}</small>
																		<small
																			className="font-weight-thin"
																			style={{ fontSize: 10 }}
																		>
																			{parentComment.commentDate}{" "}
																			{parseInt(loginUserId) !== 0 && (
																				<Link
																					to="#"
																					onClick={(e) =>
																						this.replyToComment(
																							e,
																							parentCommentKey,
																							singlePostData.id,
																							parentComment.displayName
																						)
																					}
																				>
																					<span
																						className="font-weight-bolder pl-3"
																						style={{ color: "#00A99D" }}
																					>
																						{" "}
																						Reply{" "}
																					</span>
																				</Link>
																			)}
																		</small>
																	</div>
																	<div className="ml-auto d-flex ">
																		{parentComment.likeByMe === 0 ? (
																			<>
																				<Link
																					to="#"
																					onClick={(e) => {
																						this.commentLikeCountChanges(
																							e,
																							parentCommentKey
																						);
																					}}
																				>
																					<img
																						src={
																							process.env.PUBLIC_URL +
																							"/frontend/images/like.png"
																						}
																						alt="img"
																						className="red_like_img1"
																					/>
																				</Link>
																				<Badge
																					variant="secondary"
																					className="bg-transparent text-body align-self-center"
																				>
																					{parentComment.likes}
																				</Badge>
																			</>
																		) : (
																			<>
																				<Link
																					to="#"
																					onClick={(e) => {
																						this.commentLikeCountChanges(
																							e,
																							parentCommentKey
																						);
																					}}
																				>
																					<img
																						src={
																							process.env.PUBLIC_URL +
																							"/frontend/images/heart_likes.webp"
																						}
																						alt="img"
																						className="red_like_img"
																					/>
																				</Link>
																				<Badge
																					variant="secondary"
																					className="bg-transparent text-body align-self-center"
																				>
																					{parentComment.likes}
																				</Badge>
																			</>
																		)}
																	</div>
																</div>

																{parentComment.commentUsers.map(
																	(subComment, subCommentKey) => (
																		<div className="sent_chat d-flex mt-2" key={subCommentKey}>
																			<div className="pl-1  ">
																				<img
																					src={isBlankNull(subComment.profile) ? "/frontend/images/username.png" : subComment.profile}
																					className="icon_img" alt="img"
																				/>
																			</div>
																			<div className=" pl-3 d-flex flex-column">
																				<small className="font-weight-bold m-0">
																					{subComment.displayName}
																				</small>
																				<small>{subComment.comment}</small>
																			</div>
																		</div>
																	)
																)}
															</div>
														);
													}
												)}
											</div>
											{singlePostData.totalComments === 0 ? <hr /> : ""}

											{parseInt(loginUserId) !== 0 && (
												<div className="input_box">
													<form
														onSubmit={this.commentSubmit}
														id={singlePostDataIndex}
													>
														<div className="form-group d-flex justify-content-between">
															<textarea
																htmlFor="commnet"
																name="Comment"
																className="borderd-0 w-100"
																placeholder="Type Something"
																onChange={(e) => {
																	this.inputCommentChange(e, singlePostData.id);
																}}
																value={
																	commentPostId === singlePostData.id
																		? Comment
																		: ""
																}
															/>
															{Comment !== "" && postButtonShow === 0 ? (
																<button className="btn pt-0 pb-0" type="submit">
																	post
																</button>
															) : (
																""
															)}
														</div>
													</form>
												</div>
											)}
										</div>
									</div>
								</Modal.Body>
							</Modal>
						</div>
					</section>
				) : (
					""
				)}

				<Footer />
			</>
		)
	}
}



const mapStateToProps = (state) => ({
	userData: state.homePageData,
	authenticatedUserData: state.auth.loginSuccessResult,
	allPostData: state.postData.getAllPostsData,
	addToWishList: state.homePageData.addToWishList,
	searchActivityData: state.postData.getPostsSearchActivityData,
	getUserData: state.userAccountData,
	followingContent: state.homePageData.followingContent,
});

export default connect(mapStateToProps, {
	getAllPostsAction,
	followContentAction,
	getUserDetails,
	getAllPostsSearchAction,
	recentPostAction,
	likeDislikePostAction,
	likeDislikeCommentAction,
	replyToCommentAction,
	addCommentAction,
})(withRouter(PostListPage));
