import React, { Component } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import General from "./General";
import ChangePassword from "./ChangePassword";
import Cards from './Cards'
import AccountDetails from './AccountDetails'
import CloseAccount from "./CloseAccount";
import WithdrawFund from "./WithdrawFund";
import Notifications from "./Notifications";
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getUserDetails ,emptySuccessResult , emptyError} from '../../actions/accountSetting'
import {getCountries} from '../../actions/auth'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MetaTags from "react-meta-tags";
import {ReactComponent as UsernameIcon} from '../../assets/images/svgs/username.svg'

export class AccountSetting extends Component {
    constructor(props){
        super(props)

        let TabName = '';
        if(localStorage.getItem('cardsTab') !== null){
          TabName = 'Cards'
        }
        else{
          TabName = 'General'
            /**
             * check if the request contains withdraw-balance parameter if yes redirect to withdraw balance tab
             */
            let search = this.props.location.search;
            const params = new URLSearchParams(search);
            const tab = params.get('tab');
            if(tab=='withdraw-balance'){
                TabName='Account'
                this.props.history.push('/account')
            }
        }
        this.state = {
            firstName : '',
            lastName : '',
            ProfilePic : '',
            TabName : TabName,
            Role : '',
        }
        
        this.TabChange = this.TabChange.bind(this)
         localStorage.removeItem('cardsTab')

    }

    componentDidMount(){  
        localStorage.removeItem('searchedBlog')
        localStorage.setItem('roleName',this.props.authenticatedUserData.Role)
        this.setState({
            firstName : this.props.authenticatedUserData.firstName,
            lastName : this.props.authenticatedUserData.lastName,
            ProfilePic : this.props.authenticatedUserData.ProfilePic,
            Role : this.props.authenticatedUserData.Role,
        })
        let user_id = this.props.authenticatedUserData.userId
        this.props.getUserDetails(user_id)
        this.props.getCountries();
        this.props.emptyError()
    }
    componentWillUnmount(){
    }

    componentWillReceiveProps(nextProps){
      if(nextProps.getUserData.usersData && nextProps.getUserData.usersData !== ''){
        this.setState({
            ProfilePic : nextProps.getUserData.usersData.ProfilePic,
            firstName : nextProps.getUserData.usersData.Forename,
            lastName : nextProps.getUserData.usersData.Surname,
        })
      }

        

      //updated profile response
      if(nextProps.getUserData.updatedProfileData && nextProps.getUserData.updatedProfileData && nextProps.getUserData.updatedProfileData.data !== ''){
        this.setState({
            ProfilePic : nextProps.getUserData.updatedProfileData.data.ProfilePic,
            firstName : nextProps.getUserData.updatedProfileData.data.Forename,
            lastName : nextProps.getUserData.updatedProfileData.data.Surname,
        })
      }
    }

    TabChange(e){      
      if(e.target.text === 'General' || e.target.text === 'Security' || e.target.text === 'Cards' || e.target.text == 'Account' || e.target.text == 'Notification' || e.target.text === 'Close Account' || e.target.text == 'Withdraw Fund'){
        this.setState({
          TabName : e.target.text
        })
      }

      // if(e.target.text === 'Account'){
      //   let user_id = this.props.authenticatedUserData.userId
      //   this.props.getUserDetails(user_id)
      // }

      //call this because if in genearl i update something and then go in change password and again come in genearl it will show toaster show clean the previous reducer data shoe toaster is not showing when come again 
      this.props.emptySuccessResult(e.target.text)
      this.props.emptyError()
      localStorage.removeItem('cardsTab')
    }

    render() {
        const {firstName , lastName , ProfilePic ,TabName , Role} = this.state
        return (
          <>
              <MetaTags>
                  <title>Account Settings | DesireMe</title>
                  <meta name="description" content="__METADESCRIPTION__" />
                  <meta name="title" content="__METATITLE__" />
                  <meta property="og:description" content="__METADESCRIPTION__"/>
                  <meta property="og:title" content="__METATITLE__"/>
                  <meta property="og:url" content="__OGURL__"/>
                  <link rel="canonical" href="__OGURL__" />
              </MetaTags>
              <Header />

                <ToastContainer
                    position="bottom-center"
                    autoClose={3000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                  <section id="account_setting">

                      <div className="container account_container p-5">

                        <div className="edit_profile p-5">

                          <div className="row d-flex justify-content-between ">

                            <div className="edit_profile_heading ">
                              <p className="editprofile_box d-flex pt-4">
                                <span><UsernameIcon/>
                                </span>
                                <h1 className="pl-3 font-weight-bold">Edit Profile</h1>
                              </p>
                            </div>

                            <div className="profile_name_box d-flex ">
                              <span>
                                <img src={ProfilePic} alt=""/>
                              </span>
                              <p className="pl-3 py-2 m-0">{firstName + " "+lastName}</p>
                            </div>

                          </div>

                          <div className="row">

                            <div className="profile_settings w-100">
                              <Tabs defaultActiveKey={TabName} className="custom_tabs" id="uncontrolled-tab-example" onClick = {this.TabChange}>
                                <Tab eventKey="General" title="General" className="custom_tab">
                                  <General TabName = {TabName}/>
                                </Tab>
                                <Tab eventKey="Change password" title="Security">
                                  <ChangePassword TabName = {TabName} getUserData={this.props.getUserData}/>
                                </Tab>
                                <Tab eventKey="Notification" title="Notification">
                                  <Notifications TabName = {TabName}/>
                                </Tab>
                                <Tab eventKey="Cards" title="Cards">
                                  <Cards TabName = {TabName}/>
                                </Tab>
                                {(Role === 'ContentCreator')
                                ?
                                  <Tab eventKey="Account" title="Withdraw Balance">
                                    <AccountDetails TabName = "Account"/>
                                  </Tab>
                                :
                                  ""
                                }
                                <Tab eventKey="Close Account" title="Close Account">
                                  <CloseAccount TabName = {TabName}/>
                                </Tab>
                              </Tabs>

                            </div>

                          </div>

                        </div>

                      </div>
                  </section>
              <Footer />
          </>
        );
    }
}


const mapStateToProps = (state) => ({
    authenticatedUserData : state.auth.loginSuccessResult,
    getUserData : state.userAccountData,
})


export default connect(
    mapStateToProps,
    {getUserDetails , emptySuccessResult , getCountries ,  emptyError}
)(withRouter(AccountSetting));
