import {GET_USERS_BY_USER_ID ,UPDATE_USER_ACTIVE_STATUS ,GET_ERRORS, GET_MESSAGE_BY_SENDERID, SEND_MESSAGE_BY_ID,DELETE_CHAT,SEND_TIP} from './types';
import axios from 'axios';
import authHeader from '../utils/AuthHeader';
import {BASE_URL} from '../components/Constant';

//get users by user id
export const getUsersByUserId = (loginUserId) => (dispatch) => {
	axios
		.get(BASE_URL+"/getUsers/"+loginUserId,{
			headers : authHeader(),
		})
		.then(response => {
			dispatch({
				type : GET_USERS_BY_USER_ID,
				payload : response.data
			})
		})
		.catch(error => {
			dispatch({
				type : GET_ERRORS,
				payload : error.response.data
			})
		})
}

//toggle user active / inactive
export const toggleUserActiveStatus = (userId,data) => (dispatch) => {
	axios
		.post(BASE_URL+"/ChangeUserActiveStatus/"+userId,data,{
			headers : authHeader(),
		})
		.then(response => {
			dispatch({
				type : UPDATE_USER_ACTIVE_STATUS,
				payload : response.data
			})
		})
		.catch(error => {
			dispatch({
				type : GET_ERRORS,
				payload : error.response.data
			})
		})
}
//get messages by sender Id getChat/4/6
export const getMessagesBySenderId = (senderId,recieverId,page) => (dispatch) => {
	let start = 20 * page
	let end = 20
	axios
		.get(BASE_URL+"/getChat/"+senderId+"/"+recieverId+"/"+start+"/"+end,{
			headers : authHeader(),
		})
		.then(response => {
			dispatch({
				type : GET_MESSAGE_BY_SENDERID,
				payload : response.data
			})
		})
		.catch(error => {
			dispatch({
				type : GET_ERRORS,
				payload : error.response.data
			})
		})
}
//send message
export const sendMessagesById = (senderId,recieverId,message) => (dispatch) => {
	axios
		.post(BASE_URL+"/userChat/"+senderId+"/"+recieverId,message,{
			headers : authHeader(), 'content-type': 'multipart/form-data' 
		})
		.then(response => {
			dispatch({
				type : SEND_MESSAGE_BY_ID,
				payload : response.data
			})
		})
		.catch(error => {
			dispatch({
				type : GET_ERRORS,
				payload : error.response.data
			})
		})
}
//delete chat 
export const deleteChat = (senderId,recieverId) => (dispatch) => {
	axios
		.get(BASE_URL+"/deleteUserChat/"+senderId+"/"+recieverId,{
			headers : authHeader(),
		})
		.then(response => {
			dispatch({
				type : DELETE_CHAT,
				payload : response.data
			})
		})
		.catch(error => {
			dispatch({
				type : GET_ERRORS,
				payload : error.response.data
			})
		})
}
//send tip
export const sendTip = (senderId,recieverId,plan,dataToSend) => (dispatch) => {
	axios
		.post(BASE_URL+"/payTip/"+senderId+"/"+recieverId+"/"+plan,dataToSend,{
			headers : authHeader(), 'content-type': 'multipart/form-data' 
		})
		.then(response => {
			dispatch({
				type : SEND_TIP,
				payload : response.data
			})
		})
		.catch(error => {
			dispatch({
				type : GET_ERRORS,
				payload : error.response.data
			})
		})
}
