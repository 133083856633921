export const isBlankNull = (str) => {
  return (str === "" || str === null || str === "null");
}

export const isBlankNullUndefined = (str) => {
  return (str === "" || str === null || str === "null" || str === "undefined");
}

export const isUndefined = (str) => {
  return (str === "undefined");
}


export const dateFilter = (jdate) => {
  return (
    (isNaN(jdate)
      ? jdate === "0 min"
        ? "Just Now"
        : false
          ? jdate + " ago"
          : jdate
      : jdate === 1
        ? jdate
        : jdate / 24 >= 1 &&
          jdate / 24 <= 10
          ? Math.floor(jdate / 24)
          : jdate) +
    (isNaN(jdate) || jdate / 24 > 10
      ? ""
      : jdate === 1
        ? " hour ago"
        : Math.floor(jdate / 24) >= 1
          ? Math.floor(jdate / 24) === 1
            ? " Day ago"
            : " Days ago"
          : " hours ago")

  )
}
