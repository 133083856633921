import CryptoJS from "crypto-js";
import { APP_KEY } from "../components/Constant";


export default function fnGetDecrypted(value) {
	if (value) {
		let encryptStr = CryptoJS.enc.Base64.parse(value);
		let encryptData = encryptStr.toString(CryptoJS.enc.Utf8);
		encryptData = JSON.parse(encryptData);
		let iv = CryptoJS.enc.Base64.parse(encryptData.iv);
		var decrypted = CryptoJS.AES.decrypt(encryptData.value, CryptoJS.enc.Base64.parse(APP_KEY), {
			iv: iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7
		});
		return CryptoJS.enc.Utf8.stringify(decrypted);
	}
	return '';
}