import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import authHeader from '../../utils/AuthHeader';

const PrivateRoute = ({component: Component, ...rest}) => {
    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            !authHeader() ?
            	<Redirect to="/login" />
            : 	
                <Component {...props} />
        )} />
    );
};

export default PrivateRoute;