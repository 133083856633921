import React from 'react';
import AuxFile from '../../../hoc/AuxFile';
import {Link} from "react-router-dom";

const Chatlistelement = (props) => {
    const sendParams = (contact) => {
        props.onClicked(contact);
    }
    return (
        <AuxFile>
            {props.contacts.length === 0 ? localStorage.getItem('userRole')=='ContentCreator'
                    ? <h6 style={{textAlign:"center"}}>Oops! You have no followers to message yet, why not add more content to help your profile stand out and become more Desired. <Link to="/post"><button class="btn btn3 send_primary_message"> Publish New Post</button></Link></h6> :
                      <h6 style={{textAlign:"center"}}>You are not following anyone to message yet, desire a user today to message them here. <Link to="/profiles?category=all"><button class="btn btn3 send_primary_message"> Browse Profiles</button></Link></h6> : null}
            {props.contacts.map(contact => {
                return (
                    <li onClick={()=>sendParams(contact)} key={contact.name} className={contact.status === "Online" || contact.status === "online" ? "active":""}>
                        <span className={contact.status === "Online" || contact.status === "online" ? "avatar available on_avatar":"avatar available"} >
                            <img src={(contact.profile!='' && contact.profile!='undefined') ? contact.profile : "https://staging.desire.me/images/default-avatar.png"} alt="avatar" className="img-circle" />
                        </span>
                        <div className="body">
                            <div className="header">
                                <span className="username">{contact.username}</span>
                                <small className="text-muted">
                                    {contact.unRead ? contact.unReadCount > 0 ? <div className="badge-chat bg-success float-right">{contact.unReadCount}</div>:null:null}
                                </small>
                            </div>
                            <p>{contact.description}</p>
                        </div>
                    </li>
                )
            })}
        </AuxFile>

    )
}
export default Chatlistelement;
