import React, { Component, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import { connect } from "react-redux";
import {
  getAllPostAction,
  serachActivityAction,
  likeDislikePostAction,
  addCommentAction,
  replyToCommentAction,
  likeDislikeCommentAction,
} from "../../actions/post";
import Modal from "react-bootstrap/Modal";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import VideoPlayer from "../common/videojs"



import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RenderModalSlider from "./../common/RenderModalSlider"
import RenderSlider from "./../common/RenderSlider"

export class AllFeed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      start: 0,
      limit: 2,
      postData: [],
      Loader: false,
      totalPostCount: 0,
      userId: "",
      feedFiles: [],
      selectedImageId: [],
      postDataLength: 0, //this is set to stop call API if all data comes,
      searchActivityValue: "",
      commentModalShow: false, //to show comment box on profile or add comment click
      singlePostData: [], //single post comment data
      singlePostDataIndex: "", //single post index number
      Comment: "",
      commentIndex: "", //parent comment index when click on reply,
      lastCommentId: 0,
      postButtonShow: 0, //when click on reply to comment if postbuttonshow is 0 then only show post bcz in reply we need name and if not write anything then it shows name only
      replyTo: 0,
      // showLessCaption : true,//for caption showing less or more
      loadMoreButton: true,
      captionClickPostId: 0,
      selectedIndex: [],
      selectedSlide: 0,
      replytousername:''
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.searchActivity = this.searchActivity.bind(this);
    this.postLikeCountChanges = this.postLikeCountChanges.bind(this);
    this.commentLikeCountChanges = this.commentLikeCountChanges.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.show = this.show.bind(this); //reaact modal by default function for showing modal
    this.commentSubmit = this.commentSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.viewUserPosts = this.viewUserPosts.bind(this);
    this.changeImage = this.changeImage.bind(this)

  }

  componentDidMount() {
    let user_id = this.props.authenticatedUserData.userId;
    this.setState({
      userId: user_id,
    });
    this.props.onRef(this);
  }


  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  searchActivity(e) {
    this.setState({
      searchActivityValue: e.target.value,
      postData: [],
      totalPostCount: 0,
      start: 0,
      postDataLength: 0,
      loadMoreButton: true,
    });
    this.props.searchActivityParent(e.target.value);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let postDataArray = [];
    let searchActivityDataArray = [];
    if (this.props.TabName === "All Activity") {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        window.addEventListener("scroll", this.handleScroll);
      }

      this.setState({
        Loader: false,
      });
      if (this.state.searchActivityValue !== "") {
        //put start !== 0 condition bcz if i type po it will be added data again n again on every key change
        if (
          this.state.postData.length !== 0 &&
          this.state.searchActivityValue !== "" &&
          this.state.start !== 0
        ) {
          searchActivityDataArray.push(...this.state.postData);
        }

        if (
          nextProps.searchActivityData &&
          nextProps.searchActivityData.data &&
          nextProps.searchActivityData.data.length > 0
        ) {
          searchActivityDataArray.push(...nextProps.searchActivityData.data);
          this.setState({
            postData: searchActivityDataArray,
            totalPostCount: nextProps.searchActivityData.count,
            postDataLength: searchActivityDataArray.length,
            lastCommentId: nextProps.searchActivityData.lastCommentId + 1,
          });
        } else {
          if (
            nextProps.searchActivityData &&
            nextProps.searchActivityData.data &&
            nextProps.searchActivityData.data.length === 0
          ) {
            this.setState({
              postData: [],
              start: 0,
            });
          }
        }
      } else {
        if (
          this.state.postData.length !== 0 &&
          this.state.searchActivityValue === "" &&
          this.state.start !== 0
        ) {
          postDataArray.push(...this.state.postData);
        }

        if (
          nextProps.allPostData &&
          nextProps.allPostData.data &&
          nextProps.allPostData.data.length > 0 &&
          this.state.searchActivityValue === ""
        ) {
          postDataArray.push(...nextProps.allPostData.data);
          this.setState({
            postData: postDataArray,
            totalPostCount: nextProps.allPostData.count,
            postDataLength: postDataArray.length,
            lastCommentId: nextProps.allPostData.lastCommentId + 1,
          });
        }
      }
    } else {
      this.setState({
        postData: [],
        start: 0,
        searchActivityValue: "",
      });
    }
  }

  onPlay(){
    // this.setState({
    //   commentModalShow: false,
    // });
  }

  renderFeed(url, id){    
    //this.setState({ur: url})
    let videoJsOptions = {
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: true,
      sources: [{
        src: url,
        type: 'video/mp4'
      }]
    }

   if (document.querySelector("#video"+ id)){     
    let player = videojs(document.querySelector(("#video"+ id)));
    player.src({
                  src: url,
                  type: 'video/mp4'/*video type*/
              });         
   }
   
    return <VideoPlayer { ...videoJsOptions } 
             id = {"video"+ id}            
             /> 
  }

  displayFeed(data) {    
    if (this.state.feedFiles[0] && data.media[0]) {
      let file = this.state.feedFiles.find((x) => x.id === data.id);
      if (file && file.type === "image") {
        return <img src={file.url} alt="img" />;
      } else if (file && file.type === "video") {
        return (
          this.renderFeed(file.url, data.id)
        );
      } else {
        return data.media[0].type === "image" ? (
          <img src={data.media[0].original} alt="img" />
        ) : (
          this.renderFeed(data.media[0].original, data.id)
        );
      }
    } else {
      return data.media[0] ? (
        data.media[0].type === "image" ? (
          <img src={data.media[0].original} alt="img" />
        ) : (
          this.renderFeed(data.media[0].original, data.id)
        )
      ) : null;
    }
  }
  viewUserPosts(e) {
    e.preventDefault();
    let searchUserName = e.target.getAttribute("data-name");
    this.props.history.push("/" + searchUserName);
  }  

  //when writing comment
  inputChange(e) {    
    let postButtonShow = 0;
    let Comment = e.target.value;
    let replytousername = this.state.replytousername
    if (this.state.Comment.includes("@") && this.state.commentIndex !== "") {
      if (
        e.target.value ===
        "@" +
          replytousername +
          " ,"
      ) {
        postButtonShow = 1;
      }
      //if @name erase from middle or anyother way n then if not match then remove it
      if (
        !e.target.value.includes(
          "@" +
            replytousername +
            " ,"
        )
      ) {
        
        Comment = Comment.replace(
          "@" +
            replytousername +
            " ,",
          ""
        );
      }
    }
    let replyTo = this.state.replyTo;
    if (Comment === "") {
      replyTo = 0;
    }    
    this.setState({
      Comment: Comment,
      postButtonShow: postButtonShow,
      replyTo: replyTo,
    });
  }

  //when reply to any comment
  replyToComment(e, commentIndex,username) {
    e.preventDefault();
    let userName = "@" + username + " ,";
    this.setState({
      Comment: userName,
      commentIndex: parseInt(commentIndex),
      postButtonShow: 1,
      replyTo: 1,
      replytousername:username
    });
  }

  //react modal hide show functions start
  addComment(e, index) {    
    e.preventDefault();
    if (localStorage.user_data) {
      let authenticatedUserData = JSON.parse(
        decodeURIComponent(localStorage.getItem("user_data"))
      );

      let expires_at = new Date(authenticatedUserData.expires_at);
      expires_at = expires_at.getTime();
      let currentTime = new Date();
      currentTime = currentTime.getTime();
      if (
        authenticatedUserData &&
        expires_at < currentTime &&
        authenticatedUserData.remember_me === "No"
      ) {
        this.props.logoutAction();
        // Redirect to login
        window.location.href = "/login";
      } else {
        this.setState({
          commentModalShow: true,
          singlePostData: this.state.postData[index],
          singlePostDataIndex: index,
          Comment: "",
        });

        //chat box scroll to bottom
        setTimeout(function () {
          var chatBoxDiv = document.getElementById("chat_box");
          chatBoxDiv.scrollTo(0, chatBoxDiv.scrollHeight);
        }, 100);
      }
    }    
    if (this.state.selectedIndex.length > 0){
      let selected = this.state.selectedIndex.find((x) => x.id === this.state.postData[index].id)
      if (selected){
        this.setState({selectedSlide: selected.index })
      }
      else{
        this.setState({selectedSlide: 0});
      }
      
    }
    
  }

  handleClose() {
    this.setState({
      commentModalShow: false,
      singlePostData: [],
      postButtonShow: 0,
      replyTo: 0,
    });
  }

  show() {
    useState(false);
  }

  //react modal hide show functions end

  //when click on post button of comment
  commentSubmit(e) {
    e.preventDefault();
    if (localStorage.user_data) {
      let authenticatedUserData = JSON.parse(
        decodeURIComponent(localStorage.getItem("user_data"))
      );

      let expires_at = new Date(authenticatedUserData.expires_at);
      expires_at = expires_at.getTime();
      let currentTime = new Date();
      currentTime = currentTime.getTime();
      if (
        authenticatedUserData &&
        expires_at < currentTime &&
        authenticatedUserData.remember_me === "No"
      ) {
        this.props.logoutAction();
        // Redirect to login
        window.location.href = "/login";
      } else {
        let authenticatedUserData = this.props.authenticatedUserData;
        let authUserProfilePic = authenticatedUserData.ProfilePic;
        let authUserDisplayName = authenticatedUserData.DisplayName;
        let index = parseInt(e.target.id); //post index number

        let commentUserData = {};
        let replyCommentUserData = {};
        let getCommentId = 0;
        let splitComment = "";
        //comment created date
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        var today = new Date();
        let commentCreatedDate =
          today.getDate() +
          " " +
          monthNames[today.getMonth()] +
          " " +
          today.getFullYear();

        if (this.state.replyTo === 1) {
          splitComment = this.state.Comment.substring(
            this.state.Comment.indexOf(",") + 1,
            this.state.Comment.length
          );
          replyCommentUserData = {
            comment: splitComment,
            profile: authUserProfilePic,
            displayName: authUserDisplayName,
          };
          //@Aaki Modi coming in comment when click on reply to so remove this name from comment
          this.state.singlePostData.commentUsers[
            this.state.commentIndex
          ].commentUsers.push(replyCommentUserData);

          getCommentId =
            this.state.singlePostData.commentUsers[this.state.commentIndex].id;
        } else {
          commentUserData = {
            id: this.state.lastCommentId,
            comment: this.state.Comment,
            profile: authUserProfilePic,
            commentUsers: [],
            likes: 0,
            likeByMe: 0,
            commentDate: commentCreatedDate,
            displayName: authUserDisplayName,
          };
          this.setState({
            lastCommentId: this.state.lastCommentId + 1,
          });
          //push comemnt data to singlePost commentUsers param
          this.state.singlePostData.commentUsers.push(commentUserData);
        }
        this.state.singlePostData = {
          ...this.state.singlePostData,
          totalComments: this.state.singlePostData.totalComments + 1,
        };
        this.state.postData[index] = {
          ...this.state.postData[index],
          totalComments: this.state.postData[index].totalComments + 1,
        };
        this.setState({
          ...this.state.singlePostData, //for single post update in modal
          ...this.state.postData[index], //for post update
        });

        let Comment = this.state.Comment;
        if (splitComment !== "") {
          Comment = splitComment;
        }
        let commentData = {
          Comment: Comment,
        };
        if (this.state.replyTo === 1) {
          this.props.replyToCommentAction(
            getCommentId,
            this.state.userId,
            commentData
          );
        } else {
          this.props.addCommentAction(
            this.state.postData[index].id,
            this.state.userId,
            commentData
          );
        }
        this.setState({
          Comment: "",
          commentIndex: "",
          replyTo: 0,
        });
      }
    }
  }

  //post like count
  postLikeCountChanges(e, index) {
    e.preventDefault();
    if (localStorage.user_data) {
      let authenticatedUserData = JSON.parse(
        decodeURIComponent(localStorage.getItem("user_data"))
      );

      let expires_at = new Date(authenticatedUserData.expires_at);
      expires_at = expires_at.getTime();
      let currentTime = new Date();
      currentTime = currentTime.getTime();
      if (
        authenticatedUserData &&
        expires_at < currentTime &&
        authenticatedUserData.remember_me === "No"
      ) {
        this.props.logoutAction();
        // Redirect to login
        window.location.href = "/login";
      } else {
        index = parseInt(index);
        let likedByMe = 0;

        let likes = this.state.postData[index].likes;
        if (this.state.postData[index].likedByMe === 1) {
          likedByMe = 0;
          likes = likes - 1;
        } else {
          likedByMe = 1;
          likes = likes + 1;
        }

        if (this.state.singlePostDataIndex !== "") {
          this.state.singlePostData = {
            ...this.state.singlePostData,
            likes: likes,
            likedByMe: likedByMe,
          };
          this.setState({
            ...this.state.singlePostData,
          });
        }

        this.state.postData[index] = {
          ...this.state.postData[index],
          likes: likes,
          likedByMe: likedByMe,
        };
        this.setState({
          ...this.state.postData[index],
        });
        this.props.likeDislikePostAction(
          this.state.postData[index].id,
          this.state.userId
        );
      }
    }
  }

  changeImage(mediaId, id, media, index) {       
    let sI = this.state.selectedIndex.filter(function (sI) {
      return sI.id !== id;
    })

    let selectedIndex = {id: id, index: index}
    sI.push(selectedIndex)

    this.setState({selectedIndex: sI })


    this.setState(
      {
        selectedImageId: this.state.selectedImageId.filter(function (media) {
          return media.split(":")[0] !== id.toString();
        }),
      },
      () => {
        this.setState({
          selectedImageId: [...this.state.selectedImageId, mediaId],
        });
      }
    );

    let imageObj = { id: id, url: media.original, type: media.type };
    let updateImages = [];
    let flag = 0;
    if (this.state.feedFiles.length > 0) {
      updateImages = this.state.feedFiles.map(function (obj) {
        // return obj.id === imageObj.id ? imageObj :  obj;
        if (obj.id === imageObj.id) {
          flag = 1;
          return imageObj;
        } else {
          return obj;
        }
      });

      if (flag === 0) {
        this.setState({
          feedFiles: updateImages.concat(imageObj),
        });
      } else {
        this.setState({
          feedFiles: updateImages,
        });
      }
    } else {
      this.setState({
        feedFiles: [...this.state.feedFiles, imageObj],
      });
    }
  }

  //comment like count
  commentLikeCountChanges(e, commentIndex) {
    e.preventDefault();
    if (localStorage.user_data) {
      let authenticatedUserData = JSON.parse(
        decodeURIComponent(localStorage.getItem("user_data"))
      );

      let expires_at = new Date(authenticatedUserData.expires_at);
      expires_at = expires_at.getTime();
      let currentTime = new Date();
      currentTime = currentTime.getTime();
      if (
        authenticatedUserData &&
        expires_at < currentTime &&
        authenticatedUserData.remember_me === "No"
      ) {
        this.props.logoutAction();
        // Redirect to login
        window.location.href = "/login";
      } else {
        commentIndex = parseInt(commentIndex);
        let likedByMe = 0;

        let likes = this.state.singlePostData.commentUsers[commentIndex].likes;

        if (
          this.state.singlePostData.commentUsers[commentIndex].likeByMe === 1
        ) {
          likedByMe = 0;
          likes = likes - 1;
        } else {
          likedByMe = 1;
          likes = likes + 1;
        }

        this.state.singlePostData.commentUsers[commentIndex] = {
          ...this.state.singlePostData.commentUsers[commentIndex],
          likes: likes,
          likeByMe: likedByMe,
        };
        this.setState({
          ...this.state.singlePostData,
        });
        this.props.likeDislikeCommentAction(
          this.state.singlePostData.commentUsers[commentIndex].id,
          this.state.userId
        );
      }
    }
  }

  handleScroll = (e) => {
    e.preventDefault();
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (
      windowBottom >= docHeight &&
      this.props.TabName === "All Activity" &&
      window.location.href.includes("feed")
    ) {
      if (this.state.postData.length > 0) {
        let start = this.state.start + 2;
        if (this.state.totalPostCount >= this.state.postDataLength) {
          this.setState({
            start: start,
            Loader: true,
          });
        }
        if (
          this.state.totalPostCount !== this.state.postDataLength &&
          this.state.searchActivityValue === "" &&
          this.state.Loader
        ) {
          this.props.getAllPostAction(
            this.state.userId,
            start,
            this.state.limit
          );
        } else if (
          this.state.totalPostCount !== this.state.postDataLength &&
          this.state.searchActivityValue !== ""
        ) {
          this.props.serachActivityAction(
            this.state.searchActivityValue,
            this.state.userId,
            this.state.start,
            this.state.limit
          );
        } else {
          this.setState({
            Loader: false,
          });
        }
      }
    }
  };

  //for mobile view load more button
  loadAllPost(e) {
    e.preventDefault();
    if (
      this.props.TabName === "All Activity" &&
      window.location.href.includes("feed")
    ) {
      this.setState({
        start: 2,
        Loader: true,
        loadMoreButton: false,
      });
      if (this.state.searchActivityValue === "") {
        window.scrollTo(0, 500);
        this.props.getAllPostAction(
          this.state.userId,
          2,
          this.state.totalPostCount - 2
        );
      } else if (this.state.searchActivityValue !== "") {
        window.scrollTo(0, 500);
        this.props.serachActivityAction(
          this.state.searchActivityValue,
          this.state.userId,
          2,
          this.state.totalPostCount - 2
        );
      } else {
        this.setState({
          Loader: false,
        });
      }
    }
  }

  captionReadMore(e, postId) {
    e.preventDefault();
    this.setState({
      // showLessCaption : false,
      captionClickPostId: postId,
    });
  }

  captionReadLess(e) {
    e.preventDefault();
    this.setState({
      // showLessCaption : true,
      captionClickPostId: 0,
    });
  }

  render() {  
    const {
      postData,
      Loader,
      totalPostCount,
      searchActivityValue,
      commentModalShow,
      singlePostData,
      singlePostDataIndex,
      Comment,
      postButtonShow,
      loadMoreButton,
      captionClickPostId,
    } = this.state;
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let postCreatedDate = "";
    var today = new Date();
    if (Object.keys(singlePostData).length > 0 && singlePostData.created) {
      if (!isNaN(singlePostData.created)) {
        today.setHours(today.getHours() - singlePostData.created);
      } else if (isNaN(singlePostData.created)) {
        postCreatedDate = singlePostData.created;
      }

      if (
        !isNaN(singlePostData.created) ||
        (isNaN(singlePostData.created) &&
          singlePostData.created.includes("min"))
      ) {
        // let getPostCreatedDate = today.toLocaleString();
        let getPostCreatedDate = new Date();
        postCreatedDate =
          getPostCreatedDate.getDate() +
          " " +
          monthNames[getPostCreatedDate.getMonth()] +
          " " +
          getPostCreatedDate.getFullYear();
      }

      //chat box div scroll to bottom
      var chatBoxDiv = document.getElementById("chat_box");
      if (chatBoxDiv !== null) {
        setTimeout(function () {
          chatBoxDiv.scrollTo(0, chatBoxDiv.scrollHeight);
        }, 100);
      }
    }

    return (
      <div className="all_activity" id="all_feed">
        {Loader && totalPostCount !== postData.length ? (
          <div id="preloader">
            <div className="preloader_div"></div>
          </div>
        ) : (
          ""
        )}

        {commentModalShow ? (
          <section id="profileonclick">
            <div>
              <Modal
                size="xl"
                show={this.show}
                onHide={this.handleClose}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                className="custom-modal-content"
              >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body style={{ width: "100%" }}>
                  <div className="container d-flex p-0">
                    <div
                      className="pic_view d-flex align-items-center justify-content-center bg-dark "
                      style={{ width: "65%" }}
                    >
                      {" "}
                      {singlePostData.media[0] && (
                        <RenderModalSlider media= {singlePostData.media} selectedSlide = {this.state.selectedSlide}/>
                      )}
                      {!singlePostData.media[0] && null}
                    </div>

                    <div
                      className="chat_box bg-white"
                      style={{
                        width: !singlePostData.media[0] ? "100%" : "35%",
                      }}
                    >
                      <div className=" d-flex p-2 pl-3">
                        <div>
                          <img
                            src={
                              singlePostData.profile !== null &&
                              singlePostData.profile !== "null" &&
                              singlePostData.profile !== ""
                                ? singlePostData.profile
                                : process.env.PUBLIC_URL +
                                  "/frontend/images/comment image profile.png"
                            }
                            className="icon_img"
                            alt="img"
                          />
                        </div>

                        <div className=" pl-3 d-flex flex-column">
                          <p className="font-weight-bold m-0">
                            {singlePostData.displayName}
                          </p>

                          <small
                            className="font-weight-thin"
                            style={{ color: "#cecece" }}
                          >
                            {postCreatedDate}
                          </small>
                        </div>
                      </div>

                      <hr />
                      <div className="icon_count d-flex">
                        <div className="pl-3 ">
                          {singlePostData.likedByMe === 0 ? (
                            <>
                              <Link
                                to="#"
                                onClick={(e) => {
                                  this.postLikeCountChanges(
                                    e,
                                    singlePostDataIndex
                                  );
                                }}
                              >
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/frontend/images/like.png"
                                  }
                                  alt="img"
                                  className="red_like_img1"
                                />
                              </Link>
                              <Badge
                                variant="secondary"
                                className="bg-transparent text-body align-self-center"
                              >
                                {singlePostData.likes}
                              </Badge>
                            </>
                          ) : (
                            <>
                              <Link
                                to="#"
                                onClick={(e) => {
                                  this.postLikeCountChanges(
                                    e,
                                    singlePostDataIndex
                                  );
                                }}
                              >
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/frontend/images/heart_likes.webp"
                                  }
                                  alt="img"
                                  className="red_like_img1"
                                />
                              </Link>
                              <Badge
                                variant="secondary"
                                className="bg-transparent text-body align-self-center"
                              >
                                {singlePostData.likes}
                              </Badge>
                            </>
                          )}
                        </div>

                        <div className="pl-1">
                          <span>
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-chat-square"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M14 1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h2.5a2 2 0 0 1 1.6.8L8 14.333 9.9 11.8a2 2 0 0 1 1.6-.8H14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                              />
                            </svg>
                          </span>
                          <Badge color="white">
                            {singlePostData.totalComments}
                          </Badge>
                        </div>
                      </div>
                      <hr />

                      <div className="chats_box" id="chat_box">
                        {singlePostData.commentUsers.map(
                          (parentComment, parentCommentKey) => {
                            return (
                              <>
                                <div className="recevied_chat d-flex mx-auto mt-2">
                                  <div className="pl-2  ">
                                    <img
                                      src={
                                        parentComment.profile !== null &&
                                        parentComment.profile !== "null"
                                          ? parentComment.profile
                                          : process.env.PUBLIC_URL +
                                            "/frontend/images/comment image profile.png"
                                      }
                                      className="icon_img"
                                      alt="img"
                                    />
                                  </div>
                                  <div className=" pl-3 d-flex flex-column">
                                    <small className="font-weight-bold m-0">
                                      {parentComment.displayName}
                                    </small>
                                    <small>{parentComment.comment}</small>
                                    <small
                                      className="font-weight-thin"
                                      style={{ fontSize: 10 }}
                                    >
                                      {parentComment.commentDate}{" "}
                                      <Link
                                        to="#"
                                        onClick={(e) =>
                                          this.replyToComment(
                                            e,
                                            parentCommentKey,
                                            parentComment.displayName
                                          )
                                        }
                                      >
                                        <span
                                          className="font-weight-bolder pl-3"
                                          style={{ color: "#00A99D" }}
                                        >
                                          {" "}
                                          Reply{" "}
                                        </span>
                                      </Link>
                                    </small>
                                  </div>
                                  <div className="ml-auto d-flex ">
                                    {parentComment.likeByMe === 0 ? (
                                      <>
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            this.commentLikeCountChanges(
                                              e,
                                              parentCommentKey
                                            );
                                          }}
                                        >
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/frontend/images/like.png"
                                            }
                                            alt="img"
                                            className="red_like_img1"
                                          />
                                        </Link>
                                        <Badge
                                          variant="secondary"
                                          className="bg-transparent text-body align-self-center"
                                        >
                                          {parentComment.likes}
                                        </Badge>
                                      </>
                                    ) : (
                                      <>
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            this.commentLikeCountChanges(
                                              e,
                                              parentCommentKey
                                            );
                                          }}
                                        >
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/frontend/images/heart_likes.webp"
                                            }
                                            alt="img"
                                            className="red_like_img"
                                          />
                                        </Link>
                                        <Badge
                                          variant="secondary"
                                          className="bg-transparent text-body align-self-center"
                                        >
                                          {parentComment.likes}
                                        </Badge>
                                      </>
                                    )}
                                  </div>
                                </div>

                                {parentComment.commentUsers.map(
                                  (subComment, subCommentKey) => (
                                    <div className="sent_chat d-flex mt-2">
                                      <div className="pl-1  ">
                                        <img
                                          src={
                                            subComment.profile !== null &&
                                            subComment.profile !== "null"
                                              ? subComment.profile
                                              : process.env.PUBLIC_URL +
                                                "/frontend/images/comment image profile.png"
                                          }
                                          className="icon_img"
                                          alt="img"
                                        />
                                      </div>
                                      <div className=" pl-3 d-flex flex-column">
                                        <small className="font-weight-bold m-0">
                                          {subComment.displayName}
                                        </small>
                                        <small>{subComment.comment}</small>
                                      </div>
                                    </div>
                                  )
                                )}
                              </>
                            );
                          }
                        )}
                      </div>
                      {singlePostData.totalComments !== 0 ? <hr /> : ""}

                      <div className="input_box">
                        <form
                          onSubmit={this.commentSubmit}
                          id={singlePostDataIndex}
                        >
                          <div className="form-group d-flex justify-content-between">
                            <textarea
                              for="commnet"
                              name="Comment"
                              className="borderd-0 w-100"
                              placeholder="Type Something"
                              onChange={this.inputChange}
                              value={Comment}
                            />
                            {Comment !== "" && postButtonShow === 0 ? (
                              <button className="btn" type="submit">
                                post
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </section>
        ) : (
          ""
        )}

        <div className="d-flex my-4 all_activity_search justify-content-between align-items-center">
          <input
            placeholder="Search Activity"
            className="search_activity"
            onChange={this.searchActivity}
            value={searchActivityValue}
          />
          <img
            src={process.env.PUBLIC_URL + "/frontend/images/search-solid.png"}
            alt="search"
            className=""
          />
        </div>
        {totalPostCount > 0 ? (
          postData.map((data, key) => (
            <div className="all_activity_box p-4 my-4" key={key}>
              <div className="d-flex all_activity_box_content">
                <Link to="#" style={{ cursor: "unset" }}>
                  <img
                    data-name={data.username}
                    onClick={this.viewUserPosts}
                    src={
                      data.profile !== null &&
                      data.profile !== "null" &&
                      data.profile !== ""
                        ? data.profile
                        : process.env.PUBLIC_URL +
                          "/frontend/images/comment image profile.png"
                    }
                    alt="img"
                    className="add-cursor"
                  />
                </Link>
                <div className="all_activity_box_heading px-3 py-1">
                  <Link to="#" style={{ cursor: "unset" }}>
                    <p
                      data-name={data.username}
                      onClick={this.viewUserPosts}
                      className="mb-1 add-cursor"
                    >
                      {data.displayName}
                    </p>
                  </Link>
                  <small>
                    {isNaN(data.created)
                      ? data.created === "0 min"
                        ? "Just Now"
                        : data.created.includes("min")
                        ? data.created + " ago"
                        : data.created
                      : data.created === 1
                      ? data.created
                      : data.created / 24 >= 1 && data.created / 24 <= 10
                      ? Math.floor(data.created / 24)
                      : data.created}
                    {isNaN(data.created) || data.created / 24 > 10
                      ? ""
                      : data.created === 1
                      ? " hour ago"
                      : Math.floor(data.created / 24) >= 1
                      ? Math.floor(data.created / 24) === 1
                        ? " Day ago"
                        : " Days ago"
                      : " hours ago"}
                  </small>
                </div>
                <small className="py-1">added new media</small>
              </div>
              <p className="mt-3 post-title-caption">
                {data.title.concat(" " + data.caption).split(" ").length >
                26 ? (
                  captionClickPostId !== data.id ? (
                    <>
                      <p>
                        {data.title.concat(" " + data.caption).split(" ")
                          .length > 26
                          ? data.title.split(" ").slice(0, 26).join(" ") +
                            " ...."
                          : data.title}
                        <Link
                          to="#"
                          style={{ paddingLeft: "10px" }}
                          onClick={(e) => {
                            this.captionReadMore(e, data.id);
                          }}
                        >
                          <small style={{ color: "#cecece" }}>Read More</small>
                        </Link>
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="title">{data.title}</p>
                      <p className="caption" style={{ color: "#6B6B6B" }}>
                        {(data.caption==null || data.caption=='null') ? '' : data.caption}
                      </p>
                      <Link
                        to="#"
                        style={{
                          color: "#cecece !important",
                          paddingLeft: "10px",
                        }}
                        onClick={(e) => {
                          this.captionReadLess(e);
                        }}
                      >
                        <small style={{ color: "#cecece" }}>Read Less</small>
                      </Link>
                    </>
                  )
                ) : (
                  <>
                    <p className="title">{data.title}</p>
                    <p className="caption" style={{ color: "#6B6B6B" }}>
                      {(data.caption==null || data.caption=='null') ? '' : data.caption}
                    </p>
                  </>
                )}
              </p>
              <div className="all_activity_box_img">                
              
              <Link
                  to="#"
                  onClick={(e) => {
                    this.addComment(e, key);
                  }}
                > 
                {this.displayFeed(data)}
                </Link>
              </div>

              {data.media.length > 1 && (

                <RenderSlider data= {data} changeImage = {this.changeImage}                
                selectedImageId = {this.state.selectedImageId}/>

              )}
              <div className="all_activity_box_like my-3">
                <ul className="d-flex">
                  {data.likedByMe === 0 ? (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          this.postLikeCountChanges(e, key);
                        }}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL + "/frontend/images/like.png"
                          }
                          alt="img"
                          index={key}
                        />
                      </Link>
                      <Badge
                        variant="secondary"
                        className="bg-transparent text-body align-self-center"
                      >
                        {data.likes}
                      </Badge>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          this.postLikeCountChanges(e, key);
                        }}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/frontend/images/heart_likes.webp"
                          }
                          alt="img"
                          className="red_like_img"
                          index={key}
                        />
                      </Link>
                      <Badge
                        variant="secondary"
                        className="bg-transparent text-body align-self-center"
                      >
                        {data.likes}
                      </Badge>
                    </li>
                  )}

                  <li className="ml-3" 
                    onClick={(e) => {
                      this.addComment(e, key);
                    }}
                    style={{cursor: "pointer"}}
                    >
                    <span>
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        className="bi bi-chat-square"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M14 1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h2.5a2 2 0 0 1 1.6.8L8 14.333 9.9 11.8a2 2 0 0 1 1.6-.8H14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                        />
                      </svg>
                    </span>
                    <Badge color="white">{data.totalComments}</Badge>
                  </li>
                  <li className="m-right">
                    <Link
                      to="#"
                      onClick={(e) => {
                        this.addComment(e, key);
                      }}
                    >
                      Add a Comment
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          ))
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <h4 className="pt-5" style={{ textAlign: "center" }}>
              No Posts
            </h4>
          </div>
        )}
        {loadMoreButton && totalPostCount !== 0 && totalPostCount > 2 ? (
          <Link
            to="#"
            className="mx-auto d-block d-lg-none d-flex btn show_btn justify-content-center"
            onClick={(e) => {
              this.loadAllPost(e);
            }}
          >
            Load More
          </Link>
        ) : (
          ""
        )}

        {/*<div className="all_activity_box p-4 my-4">
                    <div className="d-flex all_activity_box_content">
                        <Link to="/"><img src={process.env.PUBLIC_URL + '/frontend/images/comment image profile.png'} alt="img" className="" /></Link>
                        <div className="all_activity_box_heading px-3 py-1">
                            <Link to="/"><p className="mb-1">Jane Williams</p></Link>
                            <small>1 hour</small>
                        </div>
                        <small className="py-1">added new media            </div>
                    <p className="mt-3">Lorem Ipsum dolor sit amet….</p>
                    <div className="all_activity_box_img">
                        <Link to="/"><img src={process.env.PUBLIC_URL + '/frontend/images/girl7.jpg'} alt="img" className="" /></Link>
                    </div>
                    <div className="all_activity_box_like my-3">
                        <ul className="d-flex">
                            <li><Link to="/"><img src={process.env.PUBLIC_URL + '/frontend/images/like.png'} alt="img" /></Link><Badge variant="secondary" className="bg-transparent text-body align-self-center">433</Badge></li>
                            <li className="ml-3"><Link to="/"><img src={process.env.PUBLIC_URL + '/frontend/images/comment.png'} alt="img" /></Link><Badge variant="secondary" className="bg-transparent text-body align-self-center">433</Badge></li>
                            <li className="m-right"><Link to="/">Add a Comment</Link></li>
                        </ul>
                    </div>
                </div>*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allPostData: state.postData.getAllPostData,
  authenticatedUserData: state.auth.loginSuccessResult,
  searchActivityData: state.postData.getSearchActivityData,
});

export default connect(mapStateToProps, {
  getAllPostAction,
  serachActivityAction,
  likeDislikePostAction,
  addCommentAction,
  replyToCommentAction,
  likeDislikeCommentAction,
})(withRouter(AllFeed));
