import React, { Component } from "react";
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import MetaTags from "react-meta-tags";

// helper components
import { Button } from "./helpers/Button";

class NotFoundPage extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {

        const { history } = this.props

        return (
            <>
            <MetaTags>
            <title>Page Not Found | DesireMe</title>
        <meta name="description" content="__METADESCRIPTION__" />
            <meta name="title" content="__METATITLE__" />
            <meta property="og:description" content="__METADESCRIPTION__" />
            <meta property="og:title" content="__METATITLE__" />
            <meta property="og:url" content="__OGURL__" />
            </MetaTags>
            <Header />

            <section id="blog_section" className="about_page page_not_found">

            <div className="blog_img">
            <h1>Page Not Found (404)</h1>
        </div>

        <div className="container py-5" id="">
            <div className="ceate_post_content bg-white text-center text-lg-left">
            <div className="row pb-4">
            <div className="createpost_heading pl-0 pl-lg-5 mx-auto mx-lg-0">
            <h2 className="font-weight-bold">Whoops! Sorry we couldn't locate the page you are looking for.</h2>

        <div className="about-content" align="center">
            <p>Please try using the navigation links above or return  to home below</p>
            <p className="description-link">
            <Button onClick={() => history.push('/')}>
        Return to Home
        </Button>

        </p>
        </div>

        </div>
        </div>

        </div>
        </div>
        </section>
        <Footer />
        </>
    );
    }
}

export const mapStateToProps = (state) => ({

})


export default connect(
    mapStateToProps,
    {}
)(withRouter(NotFoundPage));
