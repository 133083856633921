import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {ReactComponent as ArrowrightIcon} from '../../assets/images/svgs/arrow-right.svg'
import {ReactComponent as ArrowleftIcon} from '../../assets/images/svgs/arrow-left.svg'
import {ReactComponent as ScrolldownIcon} from '../../assets/images/svgs/scrolldown.svg'
import {ReactComponent as SearchIcon} from '../../assets/images/svgs/search.svg'
import VideoPlayer from "../common/videojs";

function SampleNextArrow(props) {
    const { className,  onClick } = props;
    return (
        <div className={className} onClick={onClick} >
        <ArrowrightIcon className="arrow_img_custom" onClick={onclick}/>
        </div>

    );
}

function SamplePrevArrow(props) {
    const { className,  onClick } = props;
    return (
        <div className={className} onClick={onClick}>
    <ArrowleftIcon className="arrow_img_custom" onClick={onclick}/>
        </div>
    );
}



class Banner extends Component {
    constructor(props){
        super(props)

        this.state = {
            profileName : '',
            isError : false,
            userId : '',
            data: {}
        }

        this.inputChange = this.inputChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    async componentDidMount(){
        const response = await fetch(process.env.REACT_APP_BASE_URL+'/bannermedias');
        const urls = await response.json();
        this.setState({data: urls})
        let user_id = ''
        if(this.props.authenticatedUserData.userId)
        {
            user_id = this.props.authenticatedUserData.userId
        }
        this.setState({
            userId : user_id
        })
    }

    redirectToProfile(roleName){
        localStorage.setItem('roleName',roleName)
        this.props.history.push("/profile/step1")
    }


    inputChange(e){
        let isError = ''
        if(e.target.value !== ''){
            isError = false
        }
        this.setState({
            profileName : e.target.value,
            isError : isError
        })
    }

    onSubmit(e){
        e.preventDefault()
        if(this.state.profileName === '' || this.state.profileName === null || this.state.profileName === 'null'){
            this.setState({
                isError : true
            })
        }
        else{
            this.setState({
                isError : false
            })
            let profileName = btoa(this.state.profileName)
            this.props.history.push('/profiles?name='+profileName)
        }
    }



    render() {
        let videoURL = this.state.data.demovideo
        let poster = this.state.data.demothumbnail

        if(window.innerWidth <= 991){
            //to check portait or landscape mode
            if(window.innerHeight > window.innerWidth){
                videoURL = this.state.data.demovideomobile
                poster = this.state.data.demothumbnailmobile
            }
        }
        let videoJsOptions = {
            autoplay: false,
            controls: true,
            responsive: true,
            fluid: true,
            poster: poster,
            sources: [
                {
                    src: videoURL,
                    type: "video/mp4",
                },
            ],
        };

        const {isError , userId} = this.state
        const settings = {
            dots: false,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: <SamplePrevArrow />,
            nextArrow: <SampleNextArrow />

        };

        const header1 = process.env.PUBLIC_URL+"/frontend/images/header1.jpg";
        const header2 = process.env.PUBLIC_URL+"/frontend/images/header2.jpg";
        const header3 = process.env.PUBLIC_URL+"/frontend/images/header3.jpg";
  
        return (
            <section id="banner_section">
                <div className="search_dropdown d-none d-lg-block">
                    <p>Search</p>
                    <ScrolldownIcon/>
                </div>
                <form onSubmit={this.onSubmit}>
                    <div className="search_profile_item d-flex justify-content-between align-items-center px-4 ">
                        <p className="mb-0">Search Profiles</p>
                        <div className={"search_profile "+ ((isError) ? "search_profile_error" : "")}>
                            <input className="mr-2 border-0" placeholder="What are you looking for" name="profileSearch" onChange={this.inputChange}/>

                            <SearchIcon className="follow_search_pic d-none d-lg-inline"/>
                        </div>
                        <button type="submit" className="btn search_profiles_btn">Submit</button>
                    </div> 
                </form>
                <div className="banner_design container-fluid pl-0 pr-0">
                    {videoJsOptions.poster && <Slider {...settings}>
                    <div className="banner_1">
                      <VideoPlayer {...videoJsOptions} id={"banner-video"}></VideoPlayer>
                        </div>
                        <div className="banner_1">
                            <div className="banner-bg-img" style={{backgroundImage:'url('+header1+')'}} >
                                <div className="row m-0 banner_left_row">
                                    <div className="banner_left col-lg-6 pl-0 pr-0  ">
                                        <div className="banner_text_box">
                                            <h1 className="banner_heading">
                                                Be desired everyday and get paid
                                            </h1>
                                            <p className="banner_text pt-4 pb-4">
                                                As an adult content creator, our desirers frequently pay to follow your content updates on Desire Me.
                                            </p>
                                            {userId !== '' 
                                                ?
                                                    ""
                                                :
                                                    <button className="profile_btn btn mb-5  " onClick={() => this.redirectToProfile('ContentCreator')}> Create Your Profile </button>
                                            }
                                        </div>
                                    </div>
                                    <div className="banner_right col-lg-6 pr-0 pl-0">
                                        <img alt="" className="right_img img-fluid" src={process.env.PUBLIC_URL+'/frontend/images/header1.jpg'} ></img>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="banner_2">
                            <div className="banner-bg-img" style={{backgroundImage:'url('+header2+')'}} >
                                <div className="row m-0 banner_left_row">
                                    <div className="banner_left col-lg-6 pl-0 pr-0  ">
                                        <div className="banner_text_box">
                                            <h1 className="banner_heading w-75"> Fulfil your heart’s desire today and start following </h1>
                                            <p className="banner_text pt-4 pb-4"> Find users you desire, easily follow them and access their content quickly </p>
                                            {userId !== '' 
                                                ?
                                                    ""
                                                :
                                                    <button className="profile_btn btn mb-5  " onClick={() => this.redirectToProfile('ContentCreator')}> Register Now </button>
                                            }
                                        </div>
                                    </div>
                                    <div className="banner_right col-lg-6 pr-0 pl-0">
                                        <img alt="" className="right_img img-fluid" src={process.env.PUBLIC_URL+'/frontend/images/header2.jpg'} ></img>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="banner_3">
                            <div className="banner-bg-img" style={{backgroundImage:'url('+header3+')'}} >
                                <div className="row m-0 banner_left_row">
                                    <div className="banner_left col-lg-6 pl-0 pr-0  ">
                                        <div className="banner_text_box">
                                            <h1 className="banner_heading">Be yourself, completely</h1>
                                            <p className="banner_text pt-4 pb-4"> Desire Me is a relaxed and friendly place to express who you really are </p>
                                            {userId !== '' 
                                                ?
                                                    ""
                                                :
                                                    <button className="profile_btn btn mb-5  " onClick={() => this.redirectToProfile('ContentCreator')}> Create Your Profile </button>
                                            }
                                        </div>
                                    </div>
                                    <div className="banner_right col-lg-6 pr-0 pl-0">
                                        <img alt="" className="right_img img-fluid" src={process.env.PUBLIC_URL + '/frontend/images/header3.jpg'} ></img>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                    }
                </div>
            </section> //banner_section close
        );
    }
}


export const mapStateToProps = (state) => ({
    authenticatedUserData : state.auth.loginSuccessResult,
})


export default connect(
    mapStateToProps
)(withRouter(Banner));
