import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import authHeader from '../../utils/AuthHeader';
import {
	emptyError,
	getLoginUserDataAction,
	logoutAction,
	notificationShowAction,
	notificationStatusUpdateAction
} from '../../actions/auth';
import {getUsersByUserId} from '../../actions/chat';
import {getUserByNameAction} from '../../actions/homePage';
import PropTypes from "prop-types"
import {connect} from 'react-redux';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import ContentLoader from 'react-content-loader'
import Echo from 'laravel-echo';
import {BASE_URL,PUSHER_KEY,PUSHER_CLUSTER} from '../Constant';
import {ReactComponent as PostIcon} from '../../assets/images/svgs/post.svg'
import {ReactComponent as MessageIcon} from '../../assets/images/svgs/message.svg'
import {ReactComponent as FeedIcon} from '../../assets/images/svgs/feed.svg'
import {ReactComponent as NotificationsIcon} from '../../assets/images/svgs/notifications.svg'
import {ReactComponent as UserIcon} from '../../assets/images/svgs/user.svg'
import {ReactComponent as AccountIcon} from '../../assets/images/svgs/account.svg'
import {ReactComponent as LikeIcon} from '../../assets/images/svgs/like.svg'
import {ReactComponent as SearchIcon} from '../../assets/images/svgs/search.svg'


class Header extends Component{
	constructor(props){
		super(props)
		this.logout = this.logout.bind(this)
		this.state = {
			URLEndSegment : '',
			Role : '',
			showWritingSearchBox : false,//writing serach box flag
			showSearchResultBox : false,
			searchedUserData : [],
			searchedValue : '',
			userId : '',
			notificationBoxShow : false,
			notificationData : [],
			contentLoaderFlag : 0,
			sideBarSearchInput : '',
			isError : false,
			Username: '',
			isUnreadMessage: false,
		}

		this.searchClick = this.searchClick.bind(this)
		this.getUserBySearch = this.getUserBySearch.bind(this)
		this.getUserPost = this.getUserPost.bind(this)
		this.bodyClick = this.bodyClick.bind(this)
		this.expireTokenCheck = this.expireTokenCheck.bind(this)
		this.sideBarSearch = this.sideBarSearch.bind(this)
		this.viewLoginUserPost = this.viewLoginUserPost.bind(this)
	}

	//this function call bcz App.js calls only on page refresh , so to check expire time call this
	expireTokenCheck(){
		if(localStorage.user_data){
		    let authenticatedUserData = JSON.parse(decodeURIComponent(localStorage.getItem('user_data')));

		    let expires_at = new Date(authenticatedUserData.expires_at)
		    expires_at = expires_at.getTime();
		    let currentTime = new Date();
		    currentTime = currentTime.getTime();
		    if(authenticatedUserData && expires_at < currentTime && authenticatedUserData.remember_me === 'No'){
		        this.props.logoutAction()
		        // Redirect to login
		        window.location.href = "/login"
		    }
		}
	}

	logout(){
		this.props.logoutAction(this.state.userId,this.props);
	}

	redirectToProfile(roleName){
		if(localStorage.getItem('emailVerifyTime') === null || localStorage.getItem('emailVerifyTime') === 'null' || localStorage.getItem('emailVerifyTime') === ''){
			localStorage.setItem('roleName',roleName)
		}
		//when come from profile or desire URL so not go in componentDidMount
		let RoleName = localStorage.getItem('roleName');
		this.setState({
			Role : RoleName
		})

        this.props.emptyError()
	}

	searchClick(e){
		e.preventDefault()
		this.setState({
			showWritingSearchBox : true,
			showSearchResultBox : false
		})
	}

	getUserBySearch(e){
		let searchedValue = e.target.value
		this.setState({
			searchedValue : searchedValue
		})

		let loginUserId = this.state.userId
        if(loginUserId === '' || loginUserId === undefined){
            loginUserId = 0
        }

		if(e.target.value !== ''){
			this.props.getUserByNameAction(searchedValue,loginUserId,0,0)
		}
		else{//if no value in writing search result and serach box not display
			this.setState({
				// showWritingSearchBox : false,
				showSearchResultBox : false
			})
		}
	}

	getUserPost(e){
		e.preventDefault()
		let searchUserName = e.target.id
		localStorage.setItem('searchUserName',searchUserName)
		//if click on serach box value then search box going to close
		this.setState({
			showWritingSearchBox : false,
			showSearchResultBox : false,
			searchedValue : ''
		})
		this.props.history.push('/'+searchUserName)
	}
	handleNewMessages = () => {
		this.props.getUsersByUserId(this.props.authenticatedUserData.userId);
		let user_data = JSON.parse(decodeURIComponent(localStorage.getItem('user_data')));
		window.Echo = new Echo({
			broadcaster: 'pusher',
			key: PUSHER_KEY,
			cluster: PUSHER_CLUSTER,
			encrypted: true,
			logToConsole: true,
			auth: {
			   headers: {
				  'X-CSRF-Token': user_data.access_token
			   }
			},
			authEndpoint: BASE_URL+'/channels/authorize'
		 });
		 var userId = this.props.authenticatedUserData.userId;
		 var channel = window.Echo.private(`chat.${userId}`);
		 channel.listen('MessageSent', ((data) => {
			this.setState({...this.state,isUnreadMessage:true})
		 }));
	}

	componentDidMount(){
		this.expireTokenCheck()		
		let urlEndSegment = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
		if(urlEndSegment !== "messages" && localStorage.user_data){
			let authenticatedUserData = JSON.parse(decodeURIComponent(localStorage.getItem('user_data')));
		    let expires_at = new Date(authenticatedUserData.expires_at)
		    expires_at = expires_at.getTime();
		    let currentTime = new Date();
		    currentTime = currentTime.getTime();
		    if(!(authenticatedUserData && expires_at < currentTime && authenticatedUserData.remember_me === 'No')){
				this.handleNewMessages();
		    }
		}
		if(urlEndSegment === "messages"){	
			this.setState({...this.state,isUnreadMessage:false})
		}
		//when come from different URL like login n all
		let RoleName = this.props.authenticatedUserData.Role
		let userId = this.props.authenticatedUserData.userId
		let Username = this.props.authenticatedUserData.Username
		if(RoleName === undefined && (localStorage.getItem('roleName') !== null  || localStorage.getItem('roleName') !== 'null' || localStorage.getItem('roleName') !== '')){
			RoleName = localStorage.getItem('roleName')
		}
		this.setState({
			URLEndSegment : urlEndSegment,
			Role : RoleName,
			userId : userId,
			Username:Username
		})

		// if(userId !== '' && userId !== undefined && userId !== null && userId !== 'null'){
		// 	this.props.notificationShowAction(userId)
		// }

		//body is defined in Home.js
		// if(urlEndSegment === ''){
			// var body = document.getElementById('body');

			window.addEventListener("click", this.bodyClick);
		// }
	}

	bodyClick(e){
		// e.preventDefault()
		//except search field and search box if click anywhere field and box will be hide
		if(e.target.id ===  'searchBox' || e.target.value === 0 || e.target.id === 'search-icon'){

		}
		else{
			this.setState({
				showWritingSearchBox : false,
				showSearchResultBox : false,
				searchedUserData : [],
				searchedValue : ''
			})
		}

		//once input border red then next time when box open border not remainibg to red
		if(e.target.className === 'navbar-toggler-icon'){
			this.setState({
				isError : false,
			})
		}

		let notificationBoxShow = false
		if(e.target.getAttribute('data-id') === 'notification-box'){
			notificationBoxShow = true
		}
		else if(e.target.getAttribute('data-id') === 'notification-icon'){
			notificationBoxShow = !this.state.notificationBoxShow
		}

		this.setState({
			notificationBoxShow : notificationBoxShow,
			notificationData : [],
			contentLoaderFlag : 0,
		})
	}

	UNSAFE_componentWillReceiveProps(nextProps){
		this.expireTokenCheck()
		let searchedUserData = []
		let showSearchResultBox = false

		if (nextProps.searchedUserData.data && nextProps.searchedUserData.data.length > 0 && this.state.searchedValue !== '') {
			if(this.state.searchedValue == nextProps.searchedUserData.searchedValue) {
				searchedUserData.push(nextProps.searchedUserData.data)
				this.state.searchedUserData = searchedUserData
				this.state.showSearchResultBox = true
			}
		} else if (nextProps.searchedUserData.data && nextProps.searchedUserData.data.length <= 0 && this.state.searchedValue !== '') {
			this.state.searchedUserData = searchedUserData
			this.state.showSearchResultBox = false
		}


		if(nextProps.notificationData && nextProps.notificationData.data && nextProps.notificationData.data.length > 0){
			this.setState({
				notificationData : nextProps.notificationData.data
			})

			setTimeout(function(){
				this.props.notificationStatusUpdateAction(this.state.userId)
			}.bind(this),500)
		}
		else{
			this.setState({
				contentLoaderFlag : 1,
			})
		}
		if (nextProps.getUsers && nextProps.getUsers.data && nextProps.getUsers.data.length > 0) {
			let urlEndSegment = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
			if(urlEndSegment !== "messages"){
				let totalUnreadCount = nextProps.getUsers.data.map(o => o.unReadCount).reduce((a, c) => { return a + c });
				if(totalUnreadCount > 0){
					this.setState({
						...this.state,
						isUnreadMessage:true
					})
				}else{
					this.setState({
						...this.state,
						isUnreadMessage:false
					})
				}
			}
		 }
	}

	viewLoginUserPost(e){
        e.preventDefault()
         let searchUserName = btoa(this.state.userId)
		 let userName = this.state.Username
         this.props.history.push('/'+userName)
     }

    browseProfile(e){
    	e.preventDefault()
    	let category = 'all'
    	if(this.state.URLEndSegment.includes('profiles')){
    		localStorage.setItem('redirectToProfilePageCategory',category)
    		this.props.history.push('/');
    		// window.location.href = "/profiles?name="+profileName
    	}
    	else{
        	this.props.history.push('/profiles?category='+category)
    	}
    }

    removeWishListCount(e){
    	e.preventDefault()
    	localStorage.removeItem('wishListCount')
    	this.props.history.push('/desired-list')
    }
    account(e){
		e.preventDefault()
		//localStorage.removeItem('wishListCount')
		this.props.history.push('/account')
	}

    notifictionShow(e){
    	e.preventDefault()
    	this.setState({
    		notificationData : [],
    	})
    	if((this.state.URLEndSegment.includes('profiles'))){
    		this.props.history.push(this.state.URLEndSegment)
    	}
    	this.props.notificationShowAction(this.state.userId)
    }

    navSearch(e){
    	e.preventDefault()
    	if(this.state.sideBarSearchInput === '' || this.state.sideBarSearchInput === null || this.state.sideBarSearchInput === 'null'){
            this.setState({
                isError : true,
            })
        }
        else{
        	this.setState({
                isError : false,
            })
	    	let profileName = btoa(this.state.sideBarSearchInput)
	    	if(this.state.URLEndSegment.includes('profiles')){
	    		localStorage.setItem('redirectToProfilePage',profileName)
	    		this.props.history.push('/');
	    		// window.location.href = "/profiles?name="+profileName
	    	}
	    	else{
	        	this.props.history.push('/profiles?name='+profileName)
	    	}
	   	}
    }

    sideBarSearch(e){
    	this.setState({
    		sideBarSearchInput : e.target.value,
    		isError : false,
    	})
    }

	render(){
		const {URLEndSegment , Role , showWritingSearchBox , showSearchResultBox , searchedUserData , notificationBoxShow , notificationData , contentLoaderFlag , isError} = this.state

		return(
			<Navbar expand="lg">
				<div className="container pl-0 pr-0 py-2">
					<Navbar.Brand><Link to="/">
						<svg xmlns="http://www.w3.org/2000/svg" width="260" height="51.66" viewBox="0 0 260 51.66">
							  <g id="Group_45" data-name="Group 45" transform="translate(-1081.874 -410.564)">
							    <path id="Path_41" data-name="Path 41" d="M1117.082,431.343a21.269,21.269,0,0,0-2-2.159,19.451,19.451,0,1,0,5.705,13.749V411.773h-3.7Zm-4.6,22.744a15.822,15.822,0,1,1,4.6-11.153A15.291,15.291,0,0,1,1112.482,454.087Z" transform="translate(0 -0.686)" fill="#1c1c1c"/>
							    <rect id="Rectangle_68" data-name="Rectangle 68" width="3.726" height="37.907" transform="translate(1200.911 423.358)" fill="#1c1c1c"/>
							    <path id="Path_42" data-name="Path 42" d="M1396.608,439.722a15.364,15.364,0,0,0-3.29-.334,15.707,15.707,0,0,0-12.182,5.7,20.265,20.265,0,0,0-5.14,13.672V477.68h3.624V458.739a16.337,16.337,0,0,1,4.112-10.922,12.693,12.693,0,0,1,12.875-4.06Z" transform="translate(-166.977 -16.364)" fill="#1c1c1c"/>
							    <path id="Path_43" data-name="Path 43" d="M1457.667,453.887a20.084,20.084,0,0,0-5.269-9.329,19.467,19.467,0,1,0-.025,27.5,19.947,19.947,0,0,0,5.14-8.84h-3.855a16.118,16.118,0,0,1-3.88,6.244,15.754,15.754,0,0,1-26.908-11.153,14.79,14.79,0,0,1,.565-4.369Zm-30.2-6.733a15.822,15.822,0,0,1,22.307,0,14.4,14.4,0,0,1,2.518,3.213h-27.344A14.414,14.414,0,0,1,1427.471,447.154Z" transform="translate(-191.487 -16.06)" fill="#1c1c1c"/>
							    <path id="Path_44" data-name="Path 44" d="M1232.924,423.483a4.222,4.222,0,0,1-2.425-1.019,4.112,4.112,0,0,1,2.9-7.016,4.092,4.092,0,0,1,2.955,1.208,3.9,3.9,0,0,1,.875,1.208l3.161-2.391-1.413-1.413a7.75,7.75,0,0,0-5.577-2.288,7.492,7.492,0,0,0-5.5,2.288,7.883,7.883,0,0,0-1.621,8.82c1.571,3.34,4.861,4.065,8.159,4.447a15.68,15.68,0,0,1,9.5,26.759,15.822,15.822,0,0,1-22.307,0,16.078,16.078,0,0,1-1.942-2.355c-.017-.008-.032-.019-.049-.028a37.98,37.98,0,0,1-2.831-5.89c-.052-1.741,1-3.933,6.412-4.312h-15.1c7.635.535,6.594,4.673,6.017,6.069a26.62,26.62,0,0,1-2.56,4.318h-.028a16.115,16.115,0,0,1-1.84,2.2,15.753,15.753,0,0,1-26.907-11.153,14.774,14.774,0,0,1,.565-4.369l34.232-.051a20.08,20.08,0,0,0-5.268-9.329,19.468,19.468,0,1,0-.026,27.5,19.2,19.2,0,0,0,3.372-4.493,19.105,19.105,0,0,0,3.358,4.493,19.435,19.435,0,1,0,13.887-33.2Zm-45.519,8.3a15.822,15.822,0,0,1,22.307,0,14.42,14.42,0,0,1,2.519,3.213h-27.344A14.4,14.4,0,0,1,1187.4,431.78Z" transform="translate(-55.199 -0.686)" fill="#1c1c1c"/>
							    <path id="Path_45" data-name="Path 45" d="M1318.588,467.377c-.9-2.225-2.373-6.321-5.062-7.046-1.346-.363-3.046.655-2.987,1.69.025.439.367.881,1.187,1.222C1313.929,464.157,1317.559,464.821,1318.588,467.377Z" transform="translate(-129.815 -28.21)" fill="#1c1c1c"/>
							    <path id="Path_46" data-name="Path 46" d="M1196.1,483.784c.579,2.328,1.477,6.588,4.041,7.678,1.283.546,3.106-.228,3.192-1.261.036-.438-.241-.923-1.007-1.374C1200.267,487.617,1196.764,486.458,1196.1,483.784Z" transform="translate(-64.846 -41.568)" fill="#1c1c1c"/>
							    <path id="Path_47" data-name="Path 47" d="M1355.855,411.773a4.336,4.336,0,1,0,4.337,4.337A4.337,4.337,0,0,0,1355.855,411.773Z" transform="translate(-153.081 -0.686)" fill="#1c1c1c"/>
							    <path id="Path_48" data-name="Path 48" d="M1647.481,475.346c-.077.14-.153.286-.225.445a17.122,17.122,0,0,1-4.477,6.044,15.963,15.963,0,0,1-4.085,2.546,13.051,13.051,0,0,1-5.316,1.035,3.218,3.218,0,0,1-2.071-.587,2.887,2.887,0,0,1-.979-1.623,6.057,6.057,0,0,1-.028-2.406,18.621,18.621,0,0,1,.728-2.882,16,16,0,0,0,3.442.224,16.189,16.189,0,0,0,4.729-1.007,12.271,12.271,0,0,0,3.861-2.239,16.692,16.692,0,0,0,3.05-3.358,12.033,12.033,0,0,0,1.763-3.833,6.782,6.782,0,0,0,.028-3.61,4.691,4.691,0,0,0-2.1-2.658,8.79,8.79,0,0,0-4.7-1.035,15.929,15.929,0,0,0-8.255,2.1,18.362,18.362,0,0,0-5.792,5.289,19.365,19.365,0,0,0-3.078,6.855,15.256,15.256,0,0,0-.112,6.855,9.181,9.181,0,0,0,9.737,7.387,15.682,15.682,0,0,0,6.464-1.287,20.033,20.033,0,0,0,5.008-3.134,20.256,20.256,0,0,0,3.5-3.861c.3-.442.567-.86.806-1.258A5.158,5.158,0,0,1,1647.481,475.346Zm-12.844-4.424a28.328,28.328,0,0,1,2.63-3.414,15.873,15.873,0,0,1,2.714-2.434,4.453,4.453,0,0,1,2.462-.923,1.521,1.521,0,0,1,1.679,1.259,5.148,5.148,0,0,1-.364,2.938,10.644,10.644,0,0,1-2.155,3.33,9.681,9.681,0,0,1-3.637,2.49,11.073,11.073,0,0,1-3.05.727,11.253,11.253,0,0,1-2.547-.056A37.447,37.447,0,0,1,1634.637,470.922Z" transform="translate(-307.503 -28.293)" fill="#1c1c1c"/>
							    <path id="Path_49" data-name="Path 49" d="M1544,411.372c-5.893-1.816-14.675-1.452-23.038,10.594a56.381,56.381,0,0,0-4.716,8.33,44.721,44.721,0,0,0-9.845-10.844c-6.955-5.122-12.587-3.831-16.086-1.846a12.683,12.683,0,0,0-3.284,2.7,22.092,22.092,0,0,1,5.337,3.451,6.229,6.229,0,0,1,1.019-.738c6.213-3.524,13.143,4.037,17.863,11,.978,1.443,1.929,2.963,2.907,4.648l3.511,6.049,2.146-6.656c.081-.251,8.264-25.08,22.354-20.739,2.6.8,5.589,2.969,5.471,6.447-.149,4.4-4.13,9.5-6.764,12.874-1.647,2.11-3.528,4.335-5.92,7-2.925,3.263-6.124,6.589-10.087,10.479.915,1.591,1.671,4.217.593,8.1l.425-.407c5.676-5.448,9.9-9.769,13.706-14.012,2.489-2.776,4.456-5.1,6.192-7.329,3.13-4.01,7.861-10.069,8.078-16.5C1554.059,418.227,1550.188,413.278,1544,411.372Z" transform="translate(-230.013 0)" fill="#1c1c1c"/>
							  </g>
							</svg>
					</Link></Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav"/>
					<Navbar.Collapse id="basic-navbar-nav" className="navBar">
						<Nav className="header_content navBar">
							<ul className="d-flex justify-content-end align-self-center navDiv navBar">
								<li className="search_mobile d-block d-lg-none navBar">
									<div className="searchbox-mobile d-flex navBar">
										<input type="text" onChange={this.sideBarSearch} placeholder="Type to Search" className={"navBar inputValue"+((isError) ? " border border-danger" : "")}/>
										<span className="bg-search-icon navBar" onClick={(e) => {this.navSearch(e)}}>
											<svg xmlns="http://www.w3.org/2000/svg" width="25.356" height="25.36" viewBox="0 0 25.356 25.36" className="bg-search-icon navBar">
												<path id="search-solid" d="M25.011,21.926l-4.938-4.938a1.188,1.188,0,0,0-.842-.347h-.807a10.3,10.3,0,1,0-1.783,1.783v.807a1.188,1.188,0,0,0,.347.842l4.938,4.938a1.184,1.184,0,0,0,1.679,0l1.4-1.4A1.194,1.194,0,0,0,25.011,21.926ZM10.3,16.641A6.339,6.339,0,1,1,16.641,10.3,6.336,6.336,0,0,1,10.3,16.641Z" fill="#1c1c1c" className="bg-search-icon navBar"/>
											</svg>
										</span>
                                	</div>
								</li>
								<li className="navBar">
									<Link to="/" className={"navLink"+((URLEndSegment === '' || URLEndSegment.includes('search')) ? ' header_link_active' : '')}>Home</Link>
								</li>
								<li className="navBar">
									<Link to="#" onClick={(e) => {this.browseProfile(e)}} className={"navLink"+((URLEndSegment.includes('profiles')) ? ' header_link_active' : '')}>Browse Profiles</Link>
								</li>
								<li className="navBar">
									<Link to="/content" className={"navLink" + ((URLEndSegment.includes('content')) ? ' header_link_active' : '')}>Browse Content</Link>
								</li>								
								<li className="navBar">
									<Link to="/blogs" className={"navLink"+((URLEndSegment === 'blogs' || window.location.href.includes('/blog/')) ? ' header_link_active' : '')}>Blog</Link>
								</li>
								<li className="navBar">
									<Link to="/faq" className={"navLink"+((URLEndSegment.includes('faq')) ? ' header_link_active' : '')}>FAQs</Link>
								</li>
								<li className="navBar">
									<Link to="/contact-us" className={"navLink"+((URLEndSegment === 'contact-us') ? ' header_link_active' : '')}>Contact</Link>
								</li>
								{(authHeader() && Role === 'ContentCreator')?
									<li className={"li-box mr-2"+ ((URLEndSegment === 'post') ? ' li_box_active' : '')}>
										<Link to="/post" className={"navLink"+((URLEndSegment === 'post') ? ' li_box_active' : '')}><PostIcon/> </Link>
									</li>
									:
									(authHeader() && localStorage.getItem('roleName') == 'ContentCreator') ?

										<li className={"li-box mr-2 navBar"+ ((URLEndSegment === 'post') ? ' li_box_active' : '')}>
											<Link to="/post"
												  className={"navLink" + ((URLEndSegment === 'post') ? ' li_box_active' : '')}> <PostIcon/></Link>
										</li>
										:
										""


								}
									
								{!authHeader() ?
									<>
										<li className="navBar">
											<Link to="/login" className={"navLink"+((URLEndSegment === 'login' || URLEndSegment === 'forget-password') ? ' header_link_active' : '')}>Login</Link>
										</li>
										<li className="navBar">											
											<Link to={localStorage.getItem('emailVerifyTime') && Role === 'Desire' ? window.location.pathname : "/profile/step1"} className={'navLink li_box_none btn header_btn ' + (((URLEndSegment === 'step1' || URLEndSegment === 'step2') && Role === 'ContentCreator') ? 'header_btn_active' : '')} onClick={() => this.redirectToProfile('ContentCreator')}>Create Your Profile</Link>
										</li>
									</>
								:
									""
								}
								{authHeader() ?
									<>
										{<li className={"li-box mr-2 "+((URLEndSegment === 'messages') ? "li_box_active" : "")}>
											<Link onClick={() => this.setState({...this.state,isUnreadMessage:false})} to="/messages" className="li_box_none">{this.state.isUnreadMessage ? <div className="badge bg-success message_chat"></div>: ""}<MessageIcon/></Link>
										</li>}
										<li className={"li-box mr-2 navBar "+((URLEndSegment === 'feed') ? "li_box_active" : "")}>
											<Link to="/feed" className="navLink li_box_none">
											<FeedIcon/></Link>
										</li>

										<li className={"li-box mr-2 navBar "+((notificationBoxShow) ? "li_box_active" : "")} style={{position : "relative"}} onClick={(e) => {this.notifictionShow(e)}} data-id="notification-icon">
											<Link to="#" className="navLink li_box_none">
											<NotificationsIcon/>
										</Link>
											{
												(notificationBoxShow)
												?
													(window.innerWidth > 991)
													?
													<div className="notification__box" data-id="notification-box">
														<div className="notify__msg" data-id="notification-box">

															{
																(notificationData.length > 0)
																?
																	notificationData.map((data,key) => { 
																	return (
																	<>
																	{(key === 0)
																		?
																		<h6 className="heading" data-id="notification-box">{(data.day !== 'Today' && data.day !== 'Yesterday') ? "Earlier" : data.day}</h6>
																		:
																			(data.day === 'Yesterday' && notificationData[key-1].day !== 'Yesterday')
																			?
																				<h6 className="heading" data-id="notification-box">Yesterday</h6>
																			:
																				(data.day !== 'Today' && data.day !== 'Yesterday')
																				?
																					(notificationData[key-1].day === 'Yesterday')
																					?
																						<h6 className="heading" data-id="notification-box">Earlier</h6>
																					:
																						""
																				:
																					""
																	}
																	<li className="notify__li d-flex align-items-center" data-id="notification-box">
																		<p className={"svg__tag "+((data.status === 1) ? "grey__bg" : "")} data-id="notification-box">
																			{data.isLike === 1
																			?
																				<svg xmlns="http://www.w3.org/2000/svg" width="21.457" height="17.472" data-id="notification-box"><path data-name="Path 2994" d="M20.821 2.949A6.4 6.4 0 0013.897.166a7.071 7.071 0 00-2.412 1.083 5.631 5.631 0 00-.756.549 5.624 5.624 0 00-.756-.549A7.071 7.071 0 007.561.166 6.4 6.4 0 00.637 2.949c-1.2 2.167-.53 4.681.591 6.729a15.27 15.27 0 003.412 4.124 19.976 19.976 0 001.846 1.424q.585.4 1.2.76.733.431 1.5.8a14.618 14.618 0 011.546.682 14.619 14.619 0 011.546-.682q.765-.373 1.5-.8.611-.36 1.2-.76a19.967 19.967 0 001.846-1.424 15.27 15.27 0 003.412-4.124c1.116-2.048 1.785-4.562.585-6.729z" fill="#edffff" data-id="notification-box"/></svg>
																				:
																					(data.isPost === 1)
																					?
																						<svg xmlns="http://www.w3.org/2000/svg" width="22.08" height="20.513" data-id="notification-box"><path data-name="Path 2995" d="M19.872 0H2.208A2.208 2.208 0 000 2.208v12.11a2.208 2.208 0 002.208 2.208H8.15l2.89 3.987 2.891-3.987h5.941a2.208 2.208 0 002.208-2.208V2.208A2.208 2.208 0 0019.872 0z" fill="#fff" data-id="notification-box"/></svg>
																					:
																						""
																			}
																		</p>
																		<div className="msg__text" data-id="notification-box">
																			<p data-id="notification-box">{data.content}</p>
																			<p data-id="notification-box"><b data-id="notification-box">{data.name}</b></p>
																		</div>
																	</li>
																	</>
																	)
																	})
																:
																	(contentLoaderFlag === 0)
																	?
																		<>
																		<ContentLoader
																		    height={100}
																		    speed={1}
																		    backgroundColor={'#fff'}
																		    foregroundColor={'#999'}
																		    viewBox="0 0 380 70"
																		  >
																		    <rect x="48" y="8" rx="3" ry="3" width="140" height="6" /> 
																		    <rect x="48" y="26" rx="3" ry="3" width="115" height="6" /> 
																		    <circle cx="15" cy="15" r="15" />
																		</ContentLoader>
																		<ContentLoader
																		    height={100}
																		    speed={1}
																		    backgroundColor={'#fff'}
																		    foregroundColor={'#999'}
																		    viewBox="0 0 380 70"
																		  >
																		    <rect x="48" y="8" rx="3" ry="3" width="140" height="6" /> 
																		    <rect x="48" y="26" rx="3" ry="3" width="115" height="6" /> 
																		    <circle cx="15" cy="15" r="15" />
																		</ContentLoader>
																		<ContentLoader
																		    height={100}
																		    speed={1}
																		    backgroundColor={'#fff'}
																		    foregroundColor={'#999'}
																		    viewBox="0 0 380 70"
																		  >
																		    <rect x="48" y="8" rx="3" ry="3" width="140" height="6" /> 
																		    <rect x="48" y="26" rx="3" ry="3" width="115" height="6" /> 
																		    <circle cx="15" cy="15" r="15" />
																		</ContentLoader>
																		</>
																	:
																		<p style={{textAlign:"center"}}>No Notificatios</p>
															}
														</div>
													</div>
													:
														<div className="notification__box">
															<div className="notify__msg">
																<div className="title d-flex align-items-center d-none">
																	<div className="left">
																		<svg xmlns="http://www.w3.org/2000/svg" width="15.857" height="18.122"><path d="M7.929 18.122a2.265 2.265 0 002.264-2.265H5.665a2.265 2.265 0 002.264 2.265zm7.624-5.3c-.684-.735-1.963-1.84-1.963-5.461A5.589 5.589 0 009.061 1.87v-.737a1.132 1.132 0 10-2.264 0v.737a5.589 5.589 0 00-4.529 5.492c0 3.621-1.28 4.726-1.963 5.461a1.106 1.106 0 00-.3.768 1.134 1.134 0 001.136 1.133h13.58a1.133 1.133 0 001.136-1.133 1.105 1.105 0 00-.305-.768z" fill="#f2ffff"/></svg>
																	</div>
																	<h5 className="right">Notifications</h5>
																	
																</div>
																{
																	(notificationData.length > 0)
																	?
																		notificationData.map((data,key) => { 
																		return (
																		<>
																		{(key === 0)
																			?
																			<h6 className="heading" data-id="notification-box">{(data.day !== 'Today' && data.day !== 'Yesterday') ? "Earlier" : data.day}</h6>
																			:
																				(data.day === 'Yesterday' && notificationData[key-1].day !== 'Yesterday')
																				?
																					<h6 className="heading" data-id="notification-box">Yesterday</h6>
																				:
																					(data.day !== 'Today' && data.day !== 'Yesterday')
																					?
																						(notificationData[key-1].day === 'Yesterday')
																						?
																							<h6 className="heading" data-id="notification-box">Earlier</h6>
																						:
																							""
																					:
																						""
																		}
																		<li className="notify__li d-flex align-items-center" data-id="notification-box">
																			<p className={"svg__tag "+((data.status === 1) ? "grey__bg" : "")} data-id="notification-box">
																				{data.isLike === 1
																				?
																					<svg xmlns="http://www.w3.org/2000/svg" width="21.457" height="17.472" data-id="notification-box"><path data-name="Path 2994" d="M20.821 2.949A6.4 6.4 0 0013.897.166a7.071 7.071 0 00-2.412 1.083 5.631 5.631 0 00-.756.549 5.624 5.624 0 00-.756-.549A7.071 7.071 0 007.561.166 6.4 6.4 0 00.637 2.949c-1.2 2.167-.53 4.681.591 6.729a15.27 15.27 0 003.412 4.124 19.976 19.976 0 001.846 1.424q.585.4 1.2.76.733.431 1.5.8a14.618 14.618 0 011.546.682 14.619 14.619 0 011.546-.682q.765-.373 1.5-.8.611-.36 1.2-.76a19.967 19.967 0 001.846-1.424 15.27 15.27 0 003.412-4.124c1.116-2.048 1.785-4.562.585-6.729z" fill="#edffff" data-id="notification-box"/></svg>
																					:
																						(data.isPost === 1)
																						?
																							<svg xmlns="http://www.w3.org/2000/svg" width="22.08" height="20.513" data-id="notification-box"><path data-name="Path 2995" d="M19.872 0H2.208A2.208 2.208 0 000 2.208v12.11a2.208 2.208 0 002.208 2.208H8.15l2.89 3.987 2.891-3.987h5.941a2.208 2.208 0 002.208-2.208V2.208A2.208 2.208 0 0019.872 0z" fill="#fff" data-id="notification-box"/></svg>
																						:
																							""
																				}
																			</p>
																			<div className="msg__text" data-id="notification-box">
																				<p data-id="notification-box">{data.content}</p>
																				<p data-id="notification-box"><b data-id="notification-box">{data.name}</b></p>
																			</div>
																		</li>
																		</>
																		)
																		})
																	:
																		(contentLoaderFlag === 0)
																		?
																			<>
																			<ContentLoader
																			    height={100}
																			    speed={1}
																			    backgroundColor={'#fff'}
																			    foregroundColor={'#999'}
																			    viewBox="0 0 380 70"
																			  >
																			    <rect x="48" y="8" rx="3" ry="3" width="140" height="6" /> 
																			    <rect x="48" y="26" rx="3" ry="3" width="115" height="6" /> 
																			    <circle cx="15" cy="15" r="15" />
																			</ContentLoader>
																			<ContentLoader
																			    height={100}
																			    speed={1}
																			    backgroundColor={'#fff'}
																			    foregroundColor={'#999'}
																			    viewBox="0 0 380 70"
																			  >
																			    <rect x="48" y="8" rx="3" ry="3" width="140" height="6" /> 
																			    <rect x="48" y="26" rx="3" ry="3" width="115" height="6" /> 
																			    <circle cx="15" cy="15" r="15" />
																			</ContentLoader>
																			<ContentLoader
																			    height={100}
																			    speed={1}
																			    backgroundColor={'#fff'}
																			    foregroundColor={'#999'}
																			    viewBox="0 0 380 70"
																			  >
																			    <rect x="48" y="8" rx="3" ry="3" width="140" height="6" /> 
																			    <rect x="48" y="26" rx="3" ry="3" width="115" height="6" /> 
																			    <circle cx="15" cy="15" r="15" />
																			</ContentLoader>
																			</>
																		:
																			<p style={{textAlign:"center"}}>No Notificatios</p>
																}
															</div>
														</div>
												:
													""
											}
										</li>

										{(authHeader() && Role === 'ContentCreator') ?
											<li className={"li-box mr-2 " + ((URLEndSegment==this.state.Username) ? 'li_box_active' : '')}>
											{this.state.userName}
											<Link to="#" title="Profile" onClick={(e) => this.viewLoginUserPost(e)}
													  className="li_box_none"> <UserIcon/></Link>
											</li>
											:
											""
										}
										<li className={"li-box mr-2 navBar " + ((URLEndSegment === 'account') ? "li_box_active" : "")}>
											
											<Link to="#" onClick={(e) => {this.account(e)}} className="navLink li_box_none"><AccountIcon/></Link>
										</li>
										<li className={"li-box mr-2 navBar position-relative "+((URLEndSegment === 'desired-list') ? "li_box_active" : "")}>
											<Link to="#" onClick={(e)=>{this.removeWishListCount(e)}} className="li_box_none navLink"><LikeIcon/></Link>
											{/*
											<Badge variant="danger" className="notification_header">{(wishListCount !== 0 && wishListCount !== 'undefined' && wishListCount !== '0')?wishListCount:""}</Badge>
										*/}
										</li>
										<li className="navBar">
											<Link to="#" onClick={this.logout}>Logout</Link>
										</li>
									</>
								:
								""
								}
								<li className="pr-0 search_none navBar">
									<Link to="#" onClick={this.searchClick} className="li_box_none navLink">
											<SearchIcon/>
									</Link>
								</li>
							</ul>
							{showWritingSearchBox ?
								<div className="search_box_input d-none d-lg-block navBar">
									<input type="text" placeholder="Type to Search..." onChange={this.getUserBySearch} id="searchBox"  className="navBar"/>
								</div>
								:
								""
							}

							{/*in following page after loader serach result box still open due to above reducer value not change so put this condition*/}
							{
								(showSearchResultBox && showWritingSearchBox) ?
									<div className="search_input_div d-none d-lg-block">
									{
										<ul className="search_box_ul text-decoration-none navBar">
											{
												searchedUserData[0].map((data,key) => (
													<li onClick={this.getUserPost} id={data.Username} className="search_box_li" defaultValue="searchBox" key={key}>{data.Username}</li>
												))
											}
										</ul>
									}
									</div>
								:
									""

							}
						</Nav>
					</Navbar.Collapse>
				</div>
			</Navbar>
		)
	}
} 

Header.propTypes = {
   logoutAction : PropTypes.func.isRequired
}

export const mapStateToProps = (state) => ({
	searchedUserData : state.homePageData.searchedUser,
    authenticatedUserData : state.auth.loginSuccessResult,
	notificationData : state.auth.getNotificationData,
	getUsers: state.chatData.getUsers,
    errors : state.errors
})


export default connect(
	mapStateToProps , 
	{logoutAction , getUserByNameAction ,getLoginUserDataAction , notificationShowAction, getUsersByUserId , notificationStatusUpdateAction , emptyError}
)(withRouter(Header));
