import React, { Component, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Badge from "react-bootstrap/Badge";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import {
  followContentAction,
  getWishListAction,
  removeFromWishListAction,
} from "../actions/homePage";
import { getUserDetails } from "../actions/accountSetting";
import Modal from "react-bootstrap/Modal";
import LoginModalComponent from "./LoginModalComponent";
import {
  BASE_URL,
  API_KEY,
  ACCOUNT_NUMBER,
  ENVIRONMENT,
  MERCHANT_URL,
  DEVICE_CHANNEL,
  MESSAGE_CATEGORY,
  AUTHENTICATION_PURPOSE,
  CURRENCY,
} from "../components/Constant";
import axios from "axios";
import authHeader from "../utils/AuthHeader";
import MetaTags from "react-meta-tags";
import { ReactComponent as HeartbigIcon } from "../assets/images/svgs/heartbig.svg";
import InfiniteScroll from "react-infinite-scroll-component";

export class DesiredList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: 0,
      limit: 20,
      Loader: true,
      page: 1,
      userData: [],
      totalCount: 0,
      // pageCount : 0,
      // pageNumber : 1,
      isCategory: 0,
      // fingerPrintId : '',
      loginUserId: "",
      loginAlert: false,
      paymentConfirmationModal: false,
      contentWriterId: 0,
      userName: "",
      paymentError: false,
      cardDetails: 0,
      followerList: [],
      wishList: [],
      wishListCount: 0,
      subscribeClick: 0, //for paymentconfirmation modal which is true in follow content props
      paymentErrorMessage: "",
      paysaferesponse: "",
      subscribeConfirmationModal: false,
      subscriptionPrice: 0,
      PaysafeToken: "",
      LoadAllProfiles: false,
      cvv: "",
      cvvError: false,
      cvvErrorMessage: "",
    };

    this.viewAllProfiles = this.viewAllProfiles.bind(this);
    this.paymentConfirmationModalOpen =
      this.paymentConfirmationModalOpen.bind(this);
    this.paymentConfirmationModalClose =
      this.paymentConfirmationModalClose.bind(this);
    this.subscribeConfirmationModalOpen =
      this.subscribeConfirmationModalOpen.bind(this);
    this.subscribeConfirmationModalClose =
      this.subscribeConfirmationModalClose.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.show = this.show.bind(this);
    this.gotoupdatecard = this.gotoupdatecard.bind(this);
    this.gotocontactsupport = this.gotocontactsupport.bind(this);
    this.openModal = this.openModal.bind(this);
    this.showAuthErrorPaysafe = this.showAuthErrorPaysafe.bind(this);
    this.showgetAuthorizationerror = this.showgetAuthorizationerror.bind(this);
    this.inputChangeCVV = this.inputChangeCVV.bind(this);
  }

  viewUserPost(e, userName) {
    e.preventDefault();
    let searchUserName = userName;
    this.props.history.push("/" + searchUserName);
  }
  inputChangeCVV(e) {
    this.state.cvv = e.target.value;
  }

  componentDidMount() {
    //for empty props data
    // this.props.getWishListAction(0, this.state.start,this.state.limit)
    localStorage.removeItem("searchedBlog");
    //set in blog page
    localStorage.removeItem("searchedBlog");
    localStorage.removeItem("wishListCount");
    localStorage.setItem("wishListClick", "click");
    // let category = decodeURIComponent(localStorage.getItem('category'))
    let user_id = "";
    if (this.props.authenticatedUserData.userId) {
      user_id = this.props.authenticatedUserData.userId;
      // setTimeout(function(){
      this.props.getWishListAction(user_id, this.state.start, this.state.limit);
      // }.bind(this),500)
    }
    this.setState({
      loginUserId: user_id,
    });
    window.addEventListener("scroll", this.viewAllProfiles);
    // localStorage.removeItem('category')
  }

  // redirectToProfile(roleName){
  //     localStorage.setItem('roleName',roleName)
  // }

  removeFromDesiredList(e, writerId, index) {
    e.preventDefault();

    this.state.userData.splice(index, 1);
    this.setState({
      Loader: true,
      userData: this.state.userData,
    });
    this.props.removeFromWishListAction(this.state.loginUserId, writerId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.userData &&
      nextProps.userData.data &&
      nextProps.userData.data.length !== 0
    ) {
      let userData = this.state.userData;
      if (userData && userData.length === 0) {
        userData = nextProps.userData.data;
        this.props.getUserDetails(this.state.loginUserId);
      } else if (this.state.LoadAllProfiles) {
        userData.push(...nextProps.userData.data);
      }

      this.setState({
        userData: userData,
        totalCount: nextProps.userData.count,
        // pageCount : pageCount,
        isCategory: 1,
        // Loader : true,
      });
    } else {
      //if no data then on refresh loader is not stopped for that put this condition
      this.setState({
        Loader: false,
      });
    }

    if (
      nextProps.getUserData &&
      nextProps.getUserData.usersData &&
      nextProps.getUserData.usersData.length !== ""
    ) {
      let Loader = this.state.Loader;
      if (this.state.Loader) {
        Loader = false;
      }
      let userData = this.state.userData;
      if (this.state.LoadAllProfiles) {
        this.setState({
          cardDetails: nextProps.getUserData.usersData.cardDetails,
          followerList: nextProps.getUserData.usersData.followerList,
          wishList: nextProps.getUserData.usersData.wishList,
          Loader: Loader,
          PaysafeToken: nextProps.getUserData.usersData.PaysafeToken,
          // userData : userData,//for refreshing userData
        });
      } else {
        this.setState({
          cardDetails: nextProps.getUserData.usersData.cardDetails,
          followerList: nextProps.getUserData.usersData.followerList,
          wishList: nextProps.getUserData.usersData.wishList,
          Loader: Loader,
          PaysafeToken: nextProps.getUserData.usersData.PaysafeToken,
          userData: nextProps.userData.data, //for refreshing userData
        });
      }
      // this.setState({
      //     cardDetails : nextProps.getUserData.usersData.cardDetails,
      //     followerList : nextProps.getUserData.usersData.followerList,
      //     wishList : nextProps.getUserData.usersData.wishList,
      //     Loader : Loader,
      //     PaysafeToken : nextProps.getUserData.usersData.PaysafeToken,
      //     userData : userData,//for refreshing userData
      // })
    }

    if (nextProps.followingContent && this.state.contentWriterId !== 0) {
      let contentWriterId = this.state.contentWriterId;
      let userName = this.state.userName;
      // localStorage.removeItem('fingerPrintId')
      let paymentConfirmationModal = false;
      let paymentError = false;
      let paymentErrorMessage = "";
      let paysaferesponse = "";
      if (this.state.subscribeClick === 1) {
        paymentConfirmationModal = true;
      }
      if (nextProps.followingContent.isError) {
        paymentError = true;
        paymentErrorMessage = nextProps.followingContent.error;
        paysaferesponse = nextProps.followingContent.paysafe;
      }
      this.setState({
        contentWriterId: 0,
        userName: "",
        paymentConfirmationModal: paymentConfirmationModal,
        Loader: false,
        paymentError: paymentError,
        paymentErrorMessage: paymentErrorMessage,
        paysaferesponse: paysaferesponse,
      });
      setTimeout(
        function () {
          let paymentConfirmationModal = false;
          if (
            !nextProps.followingContent.isError &&
            this.state.subscribeClick === 1
          ) {
            this.props.history.push("/" + userName);
          }
          if (
            nextProps.followingContent.isError &&
            this.state.subscribeClick === 1
          ) {
            paymentConfirmationModal = true;
          }
          this.setState({
            paymentConfirmationModal: paymentConfirmationModal,
            subscribeClick: 0,
          });
        }.bind(this),
        800
      );
    }
  }

  viewAllProfiles(e) {
    e.preventDefault();
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;

    if (window.scrollY <= 100 && this.state.loginAlert) {
      this.setState({
        loginAlert: false,
      });
    }
    if (windowBottom >= docHeight) {
      let limit = 20;
      this.setState({
        LoadAllProfiles: true,
        Loader: true,
        categoryClick: 0,
      });
      this.setState(
        {
          page: this.state.page + 1,
        },
        () => {
          this.props.getWishListAction(
            this.state.loginUserId,
            this.state.limit * (this.state.page - 1),
            limit
          );
        }
      );
    }
  }

  alertClose(e) {
    e.preventDefault();
    this.setState({
      loginAlert: false,
    });
  }

  paymentConfirmationModalClose() {
    this.setState({
      paymentConfirmationModal: false,
      paymentError: false,
      subscribeClick: 0,
    });
  }
  gotoupdatecard() {
    this.setState({
      paymentConfirmationModal: false,
      paymentError: false,
      subscribeClick: 0,
    });
    let TabName = btoa("CardsTab");
    localStorage.setItem("cardsTab", TabName);
    setTimeout(
      function () {
        this.props.history.push("/account");
      }.bind(this),
      200
    );
  }
  gotocontactsupport() {
    this.setState({
      paymentConfirmationModal: false,
      paymentError: false,
      subscribeClick: 0,
    });
    setTimeout(
      function () {
        this.props.history.push("/contact-us");
      }.bind(this),
      200
    );
  }

  paymentConfirmationModalOpen() {
    useState(false);
  }

  subscribeConfirmationModal(
    e,
    contentWriterId,
    userName,
    subscriptionPrice,
    displayName,
    subscriptionMessage
  ) {
    e.preventDefault();
    this.setState({
      subscribeClick: 1,
      displayName: displayName,
      userName: userName,
      subscriptionPrice: subscriptionPrice,
      subscriptionMessage: subscriptionMessage,
      cvv: "",
      cvvError: false,
    });
    if (this.state.cardDetails === 1) {
      this.setState({
        subscribeConfirmationModal: true,
        contentWriterId: contentWriterId,
        userName: userName,
        subscriptionPrice: subscriptionPrice,
      });
    } else {
      this.setState({
        Loader: false,
        paymentError: true,
      });
      let TabName = btoa("CardsTab");
      localStorage.setItem("cardsTab", TabName);
      setTimeout(
        function () {
          this.props.history.push("/account");
        }.bind(this),
        200
      );
    }
  }

  subscribeConfirmationModalOpen() {
    useState(false);
  }

  subscribeConfirmationModalClose() {
    this.setState({
      subscribeConfirmationModal: false,
      contentWriterId: 0,
      userName: "",
      subscriptionPrice: 0,
      subscribeClick: 0,
    });
  }
  /**
   * card type not supported error message
   */
  openModal() {
    this.setState({
      contentWriterId: 0,
      userName: "",
      paymentConfirmationModal: true,
      Loader: false,
      paymentError: "Error Code 5050 : Card type is not supported",
      paymentErrorMessage: "Error Code 5050 : Card type is not supported",
      paysaferesponse:
        "Merchant Account is not configured to support this card type",
    });
  }

  /**
   *
   * authentication credentials are invalid error
   */

  showAuthErrorPaysafe() {
    this.setState({
      contentWriterId: 0,
      userName: "",
      paymentConfirmationModal: true,
      Loader: false,
      paymentError: "Error Code 5270 : Unauthorized access",
      paymentErrorMessage: "Error Code 5270 : Unauthorized access",
      paysaferesponse:
        "The credentials do not have permission to access the requested data",
    });
  }
  /**
   * get authorization error modal
   *
   */
  showgetAuthorizationerror() {
    this.setState({
      contentWriterId: 0,
      userName: "",
      paymentConfirmationModal: true,
      Loader: false,
      paymentError: "Error Code 5279 : Invalid credentials",
      paymentErrorMessage: "Error Code 5279 : Invalid credentials",
      paysaferesponse: "The authentication credentials are invalid.",
    });
  }

  subscribe(e) {
    e.preventDefault();

    let contentWriterId = this.state.contentWriterId;
    let subscriptionPrice = this.state.subscriptionPrice;
    var token = this.props.getUserData.usersData.PaysafeToken;
    let firstSixDigit = this.props.getUserData.usersData.FirstSixDigit;
    let merchantRefNum = this.props.getUserData.usersData.merchantRefnum;

    let cvv = this.state.cvv;

    let length = cvv.length;
    if (length == 0) {
      this.setState({
        cvvError: true,
        cvvErrorMessage: "CVV field is required",
      });
    } else if (length < 3 || length > 4) {
      this.setState({
        cvvError: true,
        cvvErrorMessage:
          "CVV length must be greater than 2 character and less than 4 character",
      });
    }

    if (length == 3 || length == 4) {
      window.state = this.state;
      window.props = this.props;
      window.this = this;

      this.setState({
        Loader: true,
        subscribeConfirmationModal: false,
        loginAlert: false,
      });
      if (this.state.cardDetails === 1) {
        this.setState({
          contentWriterId: contentWriterId,
        });
        let paymentData = {
          amount: subscriptionPrice,
          paysafe_token: this.state.PaysafeToken,
        };
        let subscriptionPlan = 1;
        window.paysafe.threedsecure.start(
          API_KEY,
          {
            environment: ENVIRONMENT,
            accountId: ACCOUNT_NUMBER,
            card: {
              cardBin: firstSixDigit,
            },
          },
          function (deviceFingerprintingId, error) {
            if (error) {
              window.this.openModal();
            } else {
              var data = {
                amount: subscriptionPrice * 100,
                currency: CURRENCY,
                currencyCode: CURRENCY,
                merchantRefNum: merchantRefNum,
                merchantUrl: MERCHANT_URL,
                card: { paymentToken: token },
                deviceChannel: DEVICE_CHANNEL,
                messageCategory: MESSAGE_CATEGORY,
                authenticationPurpose: AUTHENTICATION_PURPOSE,
                deviceFingerprintingId: deviceFingerprintingId,
              };
              axios
                .post(
                  BASE_URL + "/authorization",
                  { data },
                  {
                    headers: authHeader(),
                  }
                )
                .then((response) => {
                  if (response.data.status != "COMPLETED") {
                    var responsePayload = response.data.sdkChallengePayload;

                    window.paysafe.threedsecure.challenge(
                      API_KEY,
                      {
                        environment: ENVIRONMENT,
                        sdkChallengePayload: responsePayload,
                      },
                      function (authenticationID, error) {
                        if (error) {
                        } else {
                          var data = {
                            authenticationID: authenticationID,
                          };
                          axios
                            .post(
                              BASE_URL + "/getauthorization",
                              { data },
                              {
                                headers: authHeader(),
                              }
                            )
                            .then((getAuthorization) => {
                              /*if(getAuthorization.error!=''){
                                                        alert('heeeeeeee')
                                                        window.this.showgetAuthorizationerror()
                                                    } else{*/
                              var paymentData = {
                                amount: subscriptionPrice,
                                paysafe_token: token,
                                data: getAuthorization,
                                cvv: cvv,
                              };
                              window.props.followContentAction(
                                window.state.loginUserId,
                                contentWriterId,
                                subscriptionPlan,
                                paymentData
                              );

                              //}
                            });
                        }
                      }
                    );
                  } else {
                    var data = {
                      authenticationID: response.data.id,
                    };
                    axios
                      .post(
                        BASE_URL + "/getauthorization",
                        { data },
                        {
                          headers: authHeader(),
                        }
                      )
                      .then((getAuthorization) => {
                        var paymentData = {
                          amount: subscriptionPrice,
                          paysafe_token: token,
                          data: getAuthorization,
                          cvv: cvv,
                        };
                        window.props.followContentAction(
                          window.state.loginUserId,
                          contentWriterId,
                          subscriptionPlan,
                          paymentData
                        );
                      });
                  }
                });
            }
          }
        );
      }
    }
  }

  handleClose() {
    this.setState({
      loginAlert: false,
    });
  }

  show() {
    useState(false);
  }

  render() {
    const {
      userData,
      totalCount,
      loginAlert,
      Loader,
      paymentConfirmationModal,
      paymentError,
      loginUserId,
      followerList,
      wishList,
      wishListCount,
      paymentErrorMessage,
      paysaferesponse,
      subscribeConfirmationModal,
      LoadAllProfiles,
      cvv,
      cvvError,
      cvvErrorMessage,
    } = this.state;
    const profileImage = process.env.PUBLIC_URL + "/images/default-avatar.png";
    // const items = []
    // if(pageCount > 1){
    //     for(let i=1 ; i <= pageCount ;i++){
    //         items.push(<li className="page-item" key={i}><Link to="#" onClick={(e) => {this.pageNumberClick(e)}} className={"page-link page_link_hover " + ((pageNumber === i) ? "page-link-active" : "" )}>{i}</Link></li>)
    //     }
    // }

    return (
      <>
        <MetaTags>
          <title>Desired List | DesireMe</title>
          <meta name="description" content="__METADESCRIPTION__" />
          <meta name="title" content="__METATITLE__" />
          <meta property="og:description" content="__METADESCRIPTION__" />
          <meta property="og:title" content="__METATITLE__" />
          <meta property="og:url" content="__OGURL__" />
          <link rel="canonical" href="__OGURL__" />
        </MetaTags>
        {Loader ? (
          <div id="preloader">
            <div className="preloader_div"></div>
          </div>
        ) : (
          ""
        )}
        <Header wishListCount={wishListCount} />
        <section id="ProfileCardPage">
          <div className="container mt-5">
            <div className="show_profile_heading mb-4">
              <div className="d-flex align-items-center mb-2">
                {/*<img src={process.env.PUBLIC_URL + '/frontend/images/heart.png'} alt="search" className="show-profile-pic" />*/}
                <HeartbigIcon />
                <h1 className="mb-0 ml-3">Desired List</h1>
              </div>
            </div>
            <div className="row ">
              <div className="card-deck mb-5 col-12">
                <div className="row row_show_profile px-0 justify-content-center">
                  {userData && userData.length > 0 ? (
                    userData.map((data, key) => (
                      <div
                        className="card border-none col-md-4 col-lg-4 px-0 mr-0"
                        key={key}
                      >
                        <Link
                          to="#"
                          onClick={(e) => {
                            this.viewUserPost(e, data.Username);
                          }}
                        >
                          <div
                            className="card_show_div"
                            style={{
                              backgroundImage:
                                data.ProfilePic !== ""
                                  ? "url(" + data.ProfilePic + ")"
                                  : "url(" + profileImage + ")",
                            }}
                          >
                            {data.superStar === 1 ? (
                              <button className="btn superstar_btn">
                                <img
                                  alt=""
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/frontend/images/star.svg"
                                  }
                                  className="mr-2"
                                />
                                Superstars
                              </button>
                            ) : data.risingStar === 1 ? (
                              <button className="btn risingstar_btn">
                                <img
                                  alt=""
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/frontend/images/rising star.png"
                                  }
                                  className="mr-2"
                                />
                                Rising stars
                              </button>
                            ) : (
                              ""
                            )}
                            <div className="show_img">
                              <Link
                                to="#"
                                onClick={(e) => {
                                  this.viewUserPost(e, data.Username);
                                }}
                              >
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/frontend/images/show.png"
                                  }
                                  className=""
                                  alt=""
                                />
                              </Link>
                            </div>
                          </div>
                        </Link>
                        <div className="card-body">
                          <div className="d-flex justify-content-between card_heading">
                            <Link
                              className={"profile-url"}
                              to={
                                "/" + data.Username
                              } /*onClick={(e)=>{this.viewUserPost(e,data.Username)}}*/
                            >
                              {data.DisplayName !== "" &&
                              data.DisplayName !== null &&
                              data.DisplayName !== "null"
                                ? data.DisplayName
                                : data.Username}
                            </Link>
                            <Link
                              className={"profile-url"}
                              to={
                                "/" + data.Username
                              } /*onClick={(e)=>{this.viewUserPost(e,data.Username)}}*/
                            >
                              {data.Username !== "" ? "@" + data.Username : ""}
                            </Link>
                          </div>
                          <div
                            className={
                              "d-flex justify-content-between align-items-center card_desired_list " +
                              (followerList.includes(data.userId) ||
                              loginUserId === data.userId
                                ? "card_desired_list_remove"
                                : "")
                            }
                          >
                            <div className="d-flex">
                              <Link
                                to="#"
                                onClick={(e) => {
                                  this.viewUserPost(e, data.Username);
                                }}
                              >
                                {/*<img src={process.env.PUBLIC_URL + '/frontend/images/videos.png'} className="mr-2"  alt=""/>*/}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="23.655"
                                  height="15.206"
                                  viewBox="0 0 23.655 15.206"
                                  className="mr-2"
                                >
                                  <path
                                    id="Path_2608"
                                    data-name="Path 2608"
                                    d="M40.321,367.744a.845.845,0,0,0-.822-.037l-5.536,2.768v-2.012a2.534,2.534,0,0,0-2.534-2.534H19.6a2.534,2.534,0,0,0-2.534,2.534V378.6a2.534,2.534,0,0,0,2.534,2.535H31.429a2.534,2.534,0,0,0,2.534-2.535v-2.012l5.536,2.773a.845.845,0,0,0,1.223-.76V368.463A.846.846,0,0,0,40.321,367.744Zm-14.806,9.167a3.379,3.379,0,1,1,3.379-3.379A3.379,3.379,0,0,1,25.515,376.911Z"
                                    transform="translate(-17.067 -365.929)"
                                    fill="#1c1c1c"
                                  />
                                </svg>
                              </Link>
                              <Badge
                                variant="secondary"
                                className="bg-transparent text-body align-self-center"
                              >
                                {data.videoCount}
                              </Badge>
                            </div>
                            <Link
                              to="#"
                              onClick={(e) => {
                                this.removeFromDesiredList(e, data.userId, key);
                              }}
                              className="desired_list_btn"
                            >
                              Undesire
                            </Link>
                          </div>
                          <div
                            className={
                              "d-flex justify-content-between card_subscribe mt-2 " +
                              (followerList.includes(data.userId) ||
                              loginUserId === data.userId
                                ? "card_subscribe_remove"
                                : "")
                            }
                          >
                            <div className="d-flex">
                              <Link
                                to="#"
                                onClick={(e) => {
                                  this.viewUserPost(e, data.Username);
                                }}
                              >
                                {/*<img src={process.env.PUBLIC_URL + '/frontend/images/images.png'} className="mr-2" alt="" />*/}

                                <svg
                                  id="Group_132"
                                  data-name="Group 132"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22.524"
                                  height="16.088"
                                  viewBox="0 0 22.524 16.088"
                                  className="mr-2"
                                >
                                  <path
                                    id="Path_16"
                                    data-name="Path 16"
                                    d="M130.621,365.929h-17.7a2.413,2.413,0,0,0-2.413,2.413V379.6a2.413,2.413,0,0,0,2.413,2.413h17.7a2.413,2.413,0,0,0,2.413-2.413V368.342A2.413,2.413,0,0,0,130.621,365.929Zm-17.7,1.609h17.7a.8.8,0,0,1,.8.8v6.907l-5.062-5.062a.8.8,0,0,0-1.138,0l-6.671,6.671-2.649-2.649a.8.8,0,0,0-1.137,0l-2.649,2.649v-8.516A.8.8,0,0,1,112.924,367.538Z"
                                    transform="translate(-110.511 -365.929)"
                                    fill="#1c1c1c"
                                  />
                                  <ellipse
                                    id="Ellipse_1"
                                    data-name="Ellipse 1"
                                    cx="2.413"
                                    cy="2.413"
                                    rx="2.413"
                                    ry="2.413"
                                    transform="translate(4.827 3.218)"
                                    fill="#1c1c1c"
                                  />
                                </svg>
                              </Link>
                              <Badge
                                variant="secondary"
                                className="bg-transparent text-body align-self-center"
                              >
                                {data.imageCount}
                              </Badge>
                            </div>
                            <Link
                              to="#"
                              onClick={(e) => {
                                this.subscribeConfirmationModal(
                                  e,
                                  data.userId,
                                  data.Username,
                                  data.SubscriptionPriceWithVAT,
                                  data.DisplayName,
                                  data.message
                                );
                              }}
                              className="subscribe_btn"
                            >
                              subscribe
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "400px" }}
                    >
                      <h4 className="" style={{ textAlign: "center" }}>
                        No Profiles added in Desired List
                      </h4>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        {subscribeConfirmationModal ? (
          <div className="modal__div">
            <Modal
              size="lg"
              show={this.subscribeConfirmationModalOpen}
              onHide={this.subscribeConfirmationModalClose}
              centered
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              className="custom__modal"
            >
              <Modal.Header
                closeButton
                style={{ border: "none" }}
              ></Modal.Header>
              <Modal.Body style={{ width: "100%" }}>
                <div className="modal__body">
                  <div className="modal__text px-3 px-md-5 pb-5 pb-md-5 text-center">
                    <p className="modalheading">
                      Desire{" "}
                      {this.state.displayName ? this.state.displayName : ""}
                    </p>
                    <p className="modalparagraph">
                      {this.state.subscriptionMessage}
                    </p>

                    <div className="form-group" id="subscription-modal">
                      <label htmlFor="cvv" className="cvv-popup-number">
                        <b>Enter CVV </b>
                        <b>
                          ({this.props.getUserData.usersData.cardType} ****{" "}
                          {this.props.getUserData.usersData.cardLastDigit}){" "}
                        </b>
                        <a
                          href="javascript:void(0)"
                          onClick={this.gotoupdatecard}
                        >
                          <u>
                            <b className="update-card-cvv">Update Card</b>
                          </u>
                        </a>
                      </label>
                      <input
                        type="number"
                        className="form-control input_text desire-cvv-update"
                        id="cvv"
                        placeholder="Enter Your CVV"
                        name="cvv"
                        onChange={this.inputChangeCVV}
                        defaultValue={cvv}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <button
                        className="followbtn btn btn__continue mr-0 mb-3 mb-md-0 mr-md-3"
                        type="button"
                        onClick={(e) => {
                          this.subscribe(e);
                        }}
                      >
                        Desire{" "}
                        {this.state.displayName ? this.state.displayName : ""}
                      </button>
                      {this.state.cvvError ? (
                        <div id="alert-box2" className="font-weight-bold mt-1">
                          <span className="pr-2">
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-exclamation-circle"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                            </svg>
                          </span>
                          {this.state.cvvErrorMessage}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="button__group d-flex flex-column flex-md-row align-itmes-center justify-content-center">
                      <button
                        className="btn btn__black ml-0 ml-md-3"
                        type="button"
                        onClick={this.subscribeConfirmationModalClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        ) : (
          ""
        )}

        {loginAlert ? (
          <LoginModalComponent
            loginUserId={loginUserId}
            show={this.show}
            handleClose={this.handleClose}
          />
        ) : (
          ""
        )}

        {paymentConfirmationModal ? (
          <div className="modal__div">
            <Modal
              size="lg"
              centered
              show={this.paymentConfirmationModalOpen}
              onHide={this.paymentConfirmationModalClose}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              className="custom__modal"
            >
              <Modal.Header
                closeButton
                style={{ border: "none" }}
              ></Modal.Header>
              <Modal.Body style={{ width: "100%" }}>
                <div className="modal__body">
                  <div className="modal__text px-3 px-md-5 pb-5 pb-md-5 text-center">
                    {!paymentError ? (
                      <>
                        <div className="pb-4">
                          <img
                            width="65px"
                            height="65px"
                            src={
                              process.env.PUBLIC_URL +
                              "/frontend/images/icon-tick.svg"
                            }
                          />
                        </div>
                        <p className="modalheading">
                          Successfully followed{" "}
                          {this.state.displayName ? this.state.displayName : ""}
                        </p>
                        <p className="modalparagraph">
                          Reloading the profile now to show{" "}
                          {this.state.displayName ? this.state.displayName : ""}
                          's content, please wait…
                        </p>
                      </>
                    ) : (
                      <>
                        <div className="pb-4">
                          <img
                            width="65px"
                            height="65px"
                            src={
                              process.env.PUBLIC_URL +
                              "/frontend/images/icon-cross.svg"
                            }
                          />
                        </div>
                        <p className="modalheading">
                          We're sorry but your payment failed
                        </p>
                        <p className="paysaferesponse">{paysaferesponse}</p>
                        <p className="pb-4 modalparagraph">
                          {paymentErrorMessage}
                        </p>

                        <div className="button__group d-flex flex-column flex-md-row align-itmes-center justify-content-center">
                          <button
                            className="btn btn__continue btn_updatecard mr-0 mb-3 mb-md-0 mr-md-3"
                            onClick={this.paymentConfirmationModalClose}
                          >
                            Update Card
                          </button>
                          <p className="mb-0 my-auto d-none d-md-block">OR</p>
                          <button
                            className="btn btn__continue btn_contactsupport ml-0 ml-md-3"
                            onClick={this.paymentConfirmationModalClose}
                          >
                            Contact Support
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        ) : (
          ""
        )}
        <Footer />
      </>
    );
  }
}

export const mapStateToProps = (state) => ({
  userData: state.homePageData.getWishListData,
  authenticatedUserData: state.auth.loginSuccessResult,
  followingContent: state.homePageData.followingContent,
  getUserData: state.userAccountData,
  removeWishListData: state.homePageData.removeWishListData,
});

export default connect(mapStateToProps, {
  followContentAction,
  getUserDetails,
  getWishListAction,
  removeFromWishListAction,
})(withRouter(DesiredList));
