import React from 'react';
import { Link , withRouter } from 'react-router-dom';
import {ReactComponent as ArrowIcon} from '../../../assets/images/svgs/arrow-lefttt.svg'
const cardHeader = (props) => {
    return (
        <div className="card-header msg-header">
            <div className="back_icon_cls mobile_icon_app"><ArrowIcon onClick={props.onBackButton}/>
        <div className="msg-header-img">
        <Link to={'/'+props.name}> <img src={(props.selectedUser.profile!='' && props.selectedUser.profile!='undefined') ? props.selectedUser.profile : "https://staging.desire.me/images/default-avatar.png"} alt="" />
        </Link></div>
        <div className="name-active">
        <h3><Link to={'/'+props.name}>{props.name} </Link></h3>
        </div>
        <img style={{cursor:"pointer"}} onClick={props.onDeleteButton} src={process.env.PUBLIC_URL + '/frontend/images/Group 386.png'} className="delete_icon" alt=""/>
        </div>
        </div>
)
}
export default cardHeader;
