import React, { useState, useRef } from 'react';
import Slider from "react-slick";

import 'video.js/dist/video-js.css';

import { ReactComponent as ArrowrightIcon } from "../../assets/images/svgs/arrow-right.svg";
import { ReactComponent as ArrowleftIcon } from "../../assets/images/svgs/arrow-left.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
    infinite: false,
    speed: 500,
    accessibility: true,
    focusOnChange: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
};
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowrightIcon className="arrow_img_custom" onClick={onclick} />
      </div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowleftIcon className="arrow_img_custom" onClick={onclick} />
      </div>
    );
  }
    
const RenderSlider=(props)=> {
  const data = props.data
  const selectedImageId = props.selectedImageId
  const sliderRef = React.useRef(null);
  React.useEffect(() => {    
    const track = sliderRef.current.innerSlider.list.querySelector('.slick-track');
    const focusSlider = setTimeout(() => {
      const slide = track.querySelector('.slick-slide');
      slide.focus();
    }, 0);
    return () => clearTimeout(focusSlider);
  }, []); 
  
  const getSettings = (length) =>{
    return {
      infinite: length >= 4 ? true: false,
      speed: 500,
      accessibility: true,
      focusOnChange: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      focusOnSelect: true,
  
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            infinite: length >= 4 ? true: false,
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            infinite: length >= 2 ? true: false,
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
  };

  }
      return (
        <Slider {...getSettings(data.media.length)}
        ref={sliderRef} >
                  {data.media.map((entry, index) => {
                    let mediaId = entry.thumbnail
                    ? data.id + ":" + entry.thumbnail
                    : data.id + ":" + entry.original;
                    return entry.type === "image" ? (
                      <div>
                        <img
                          height={100}
                          className={`${
                            selectedImageId.includes(
                              mediaId
                            )
                              ? "active"
                              : ""
                          } createpost_img input_preview_img`}
                          style={{
                            borderRadius: "10px",
                            width: "100%",
                          }}
                          src={entry.thumbnail}
                          alt="img-icon"
                          tabIndex="-1"
                          onClick={(e) => props.changeImage(
                            mediaId,
                            data.id,
                            entry, index)}
                        />
                      </div>
                    ) : (
                      <div className={`${
                        selectedImageId.includes(
                          mediaId
                        )
                          ? "active"
                          : ""
                      } feed_video_preview`}>
                        <video
                          src={entry.original}
                          className="video_preview"
                          style={{ width: 100 }}
                          tabIndex="-1"
                          alt=""
                          onClick={(e) => props.changeImage(mediaId,
                            data.id,
                            entry, index)}
                        />
                      </div>
                    );
                  })}
                </Slider>
        );
  }
  
export default RenderSlider;

