import React, { Component , useState } from 'react';
import { Link , withRouter } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Badge from 'react-bootstrap/Badge'
import {getUserByCategoryAction , followContentAction , addToWishListAction} from '../../actions/homePage'
import {getUserDetails} from '../../actions/accountSetting'
import {connect} from 'react-redux';
import Modal from "react-bootstrap/Modal";
import LoginModalComponent from "../LoginModalComponent";
import {BASE_URL,API_KEY,ACCOUNT_NUMBER,ENVIRONMENT,MERCHANT_URL,DEVICE_CHANNEL,MESSAGE_CATEGORY,AUTHENTICATION_PURPOSE,CURRENCY} from "../Constant";
import axios from "axios";
import authHeader from "../../utils/AuthHeader";
import {ReactComponent as RisingstarIcon} from '../../assets/images/svgs/risingstar.svg'
import {ReactComponent as StarIcon} from '../../assets/images/svgs/star.svg'
import {ReactComponent as HeartbigIcon} from '../../assets/images/svgs/heartbig.svg'
import {ReactComponent as ArrowrightIcon} from '../../assets/images/svgs/arrowrightlarge.svg'
import {ReactComponent as ErrorinfoIcon} from '../../assets/images/svgs/errorinfo.svg'

class ShowProfile extends Component {

    constructor(props){
        super(props)

        this.state = {
            selectedCategory : 'All',
            orderBy: "Popular",
            start : 0,//for pagination
            limit : 8,//for pagination 
            userData : [],
            totalCount : 0,
            // fingerPrintId : '',
            loginUserId : '',
            loginWishListAlert : false, 
            Loader : false,
            paymentConfirmationModal : false,
            contentWriterId : 0,
            userName : '',
            paymentError : false,
            cardDetails : 0,
            followerList : [],
            wishList : [],
            // wishListCount : 0,
            subscribeClick : 0 , //for paymentconfirmation modal which is true in follow content props
            paymentErrorMessage : '',
            subscribeConfirmationModal : false,
            subscriptionPrice : 0,
            PaysafeToken : '',
            displayName : '',
            subscriptionMessage : '',
            paysaferesponse : '',
            cvv: '',
            cvvError : false,
            cvvErrorMessage : ''
        }
        this.categoryClick = this.categoryClick.bind(this)
        this.viewAllCategory = this.viewAllCategory.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
        this.paymentConfirmationModalOpen = this.paymentConfirmationModalOpen.bind(this)
        this.paymentConfirmationModalClose = this.paymentConfirmationModalClose.bind(this)
        this.subscribeConfirmationModalOpen = this.subscribeConfirmationModalOpen.bind(this)
        this.subscribeConfirmationModalClose = this.subscribeConfirmationModalClose.bind(this)
        this.handleClose = this.handleClose.bind(this);
        this.show = this.show.bind(this);
        this.gotoupdatecard = this.gotoupdatecard.bind(this);
        this.gotocontactsupport = this.gotocontactsupport.bind(this);
        this.openModal = this.openModal.bind(this);
        this.showAuthErrorPaysafe = this.showAuthErrorPaysafe.bind(this);
        this.showgetAuthorizationerror = this.showgetAuthorizationerror.bind(this);
        this.inputChange = this.inputChange.bind(this)
    }

    componentDidMount(){ 
        let user_id = ''
        if(this.props.authenticatedUserData.userId){
            user_id = this.props.authenticatedUserData.userId
            this.props.getUserDetails(user_id)
        }
        this.setState({
            loginUserId : user_id
        })

        let loginUserId = user_id
        if(loginUserId === '' || loginUserId === undefined){
            loginUserId = 0
        }
        this.props.getUserByCategoryAction(this.state.selectedCategory,this.state.orderBy,loginUserId,this.state.start,this.state.limit)
        window.addEventListener('scroll', this.handleScroll);
    }

    redirectToProfile(roleName){
        localStorage.setItem('roleName',roleName)
    }

    risingstar(e){
        var item = document.getElementById('left-tabs-example-tab-Risingstar')
        item.click();
    }
    superstar(e){
        var item = document.getElementById('left-tabs-example-tab-Superstars')
        item.click();
    }

    orderByClick(orderByVal) {
        this.setState({
            orderBy: orderByVal
        }, () => {
            document.getElementById("left-tabs-example-tab-" + this.state.selectedCategory).click();
        });
    }

    categoryClick(e){
        this.setState({
            selectedCategory : e.target.text
        })
        let user_id = this.state.loginUserId
        if(this.state.loginUserId === ''){
            user_id = 0
        }
        this.props.getUserByCategoryAction(encodeURIComponent(e.target.text),this.state.orderBy,user_id,this.state.start,this.state.limit)
    }
    inputChange(e){
        this.state.cvv = e.target.value
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.homePageData.categoryWiseUserData !==''){
            this.setState({
                userData : nextProps.homePageData.categoryWiseUserData.data,
                totalCount : nextProps.homePageData.categoryWiseUserData.userCount
            })
        }

        if(nextProps.getUserData && nextProps.getUserData.usersData && nextProps.getUserData.usersData.length !== ''){
            let Loader = this.state.Loader
            if(this.state.Loader){
                Loader = false
            }
            this.setState({
                cardDetails : nextProps.getUserData.usersData.cardDetails,
                followerList : nextProps.getUserData.usersData.followerList,
                wishList : nextProps.getUserData.usersData.wishList,
                Loader : Loader,
                PaysafeToken : nextProps.getUserData.usersData.PaysafeToken,
            })
        }

        if(nextProps.followingContent && this.state.contentWriterId !== 0){
            let contentWriterId = this.state.contentWriterId
            let userName = this.state.userName
            // localStorage.removeItem('fingerPrintId')

            let paymentConfirmationModal = false
            let paymentError = false
            let paymentErrorMessage = ''
            let paysaferesponse = ''
            if(this.state.subscribeClick === 1){
                paymentConfirmationModal = true
            }
            if(nextProps.followingContent.isError){
                paymentError = true
                paymentErrorMessage = nextProps.followingContent.error
                paysaferesponse = nextProps.followingContent.paysafe
            }
            this.setState({
                contentWriterId : 0,
                userName : '',
                paymentConfirmationModal : paymentConfirmationModal,
                Loader : false, 
                paymentError : paymentError,
                paymentErrorMessage : paymentErrorMessage,
                paysaferesponse : paysaferesponse
            })
            setTimeout(function(){
                let paymentConfirmationModal = false
                if(!nextProps.followingContent.isError && this.state.subscribeClick === 1){
                    
                    // contentWriterId = btoa(contentWriterId)
                    this.props.history.push('/'+userName) 
                }
                if(nextProps.followingContent.isError && this.state.subscribeClick === 1){
                    paymentConfirmationModal = true
                }
                this.setState({
                    paymentConfirmationModal : paymentConfirmationModal,
                    subscribeClick : 0,
                })

            }.bind(this),800)
        }

        if(nextProps.addToWishList && this.state.contentWriterId !== 0 && this.state.subscribeClick === 0){
            this.setState({
                contentWriterId : 0,
                userName : '',
            })
            if(!nextProps.addToWishList.isError){
                this.setState({
                    loginWishListAlert : true,
                })
                // this.props.addToDesiredList(1);//for increasing count
                this.props.getUserDetails(this.state.loginUserId)
                // window.scrollTo(0, 0);
            }
        }


    }

    viewUserPost(e,profileName){
        e.preventDefault()
        let searchUserName = profileName
        localStorage.setItem('userprofileName',profileName)
        this.props.history.push('/'+searchUserName)
    }

    viewAllCategory(e){
        e.preventDefault()
        // localStorage.setItem('category',this.state.selectedCategory)
        localStorage.setItem('category',this.state.orderBy);
        let category = this.state.selectedCategory.toLowerCase();
        this.props.history.push('/profiles?category='+category)
    }

    handleScroll(){
        if(window.scrollY <= 100){
            this.setState({
                loginWishListAlert : false
            })
        }
    }

    alertClose(e){
        e.preventDefault()
        this.setState({
            loginWishListAlert : false
        })
    }

    paymentConfirmationModalClose() {
        this.setState({
            paymentConfirmationModal : false,
            paymentError : false,
            subscribeClick : 0,
        })
    }
    gotoupdatecard(){
        this.setState({
            paymentConfirmationModal : false,
            paymentError : false,
            subscribeClick : 0,
        })
        let TabName = btoa('CardsTab')
        localStorage.setItem('cardsTab',TabName)
        setTimeout(function(){
            this.props.history.push('/account')
        }.bind(this),200)
    }
    gotocontactsupport(){
        this.setState({
            paymentConfirmationModal : false,
            paymentError : false,
            subscribeClick : 0,
        })
        setTimeout(function(){
            this.props.history.push('/contact-us')
        }.bind(this),200)
    }

    paymentConfirmationModalOpen() {
        useState(false);
    } 

    subscribeConfirmationModal(e, contentWriterId,userName,subscriptionPrice,displayName,subscriptionMessage){
        e.preventDefault()
        if(this.state.loginUserId !== '' && this.state.loginUserId !== null && this.state.loginUserId !== 'null'){
            this.setState({
                subscribeClick : 1,
                displayName : displayName,
                subscriptionPrice:subscriptionPrice,
                cvv : '',
                cvvError : false
            })
            if(this.state.cardDetails === 1){
                this.setState({
                    subscribeConfirmationModal : true,
                    contentWriterId : contentWriterId,
                    userName : userName,
                    displayName : displayName,
                    subscriptionPrice : subscriptionPrice,
                    subscriptionMessage: subscriptionMessage
                })
            }
            else{
                this.setState({
                    Loader : false,
                    paymentError : true,
                })
                let TabName = btoa('CardsTab')
                localStorage.setItem('cardsTab',TabName)
                setTimeout(function(){
                    this.props.history.push('/account')
                }.bind(this),200)
            }
        }
        //if not login
        else{
            this.setState({
                loginWishListAlert : true,
            })
        }
    }

    subscribeConfirmationModalOpen() {
        useState(false);
    } 

    subscribeConfirmationModalClose(){
        this.setState({
            subscribeConfirmationModal : false,
            contentWriterId : 0,
            userName : '',
            subscriptionPrice : 0,
            subscribeClick : 0,
        })
    }

    /**
     * card type not supported error message
     */
    openModal(){
        this.setState({
            contentWriterId : 0,
            userName : '',
            paymentConfirmationModal : true,
            Loader : false,
            paymentError : 'Error Code 5050 : Card type is not supported',
            paymentErrorMessage : 'Error Code 5050 : Card type is not supported',
            paysaferesponse:'Merchant Account is not configured to support this card type'
        })
    }

    /**
     *
     * authentication credentials are invalid error
     */

    showAuthErrorPaysafe(){
        this.setState({
            contentWriterId : 0,
            userName : '',
            paymentConfirmationModal : true,
            Loader : false,
            paymentError : 'Error Code 5270 : Unauthorized access',
            paymentErrorMessage : 'Error Code 5270 : Unauthorized access',
            paysaferesponse:'The credentials do not have permission to access the requested data'
        })
    }
    /**
     * get authorization error modal
     *
     */
    showgetAuthorizationerror(){
        this.setState({
            contentWriterId : 0,
            userName : '',
            paymentConfirmationModal : true,
            Loader : false,
            paymentError : 'Error Code 5279 : Invalid credentials',
            paymentErrorMessage : 'Error Code 5279 : Invalid credentials',
            paysaferesponse:'The authentication credentials are invalid.'
        })
    }
    subscribe(e){
        e.preventDefault()
        let contentWriterId = this.state.contentWriterId
        let subscriptionPrice = this.state.subscriptionPrice

        let subscriptionPlan = this.state.subscriptionPlan
        var token = this.props.getUserData.usersData.PaysafeToken;
        let firstSixDigit = this.props.getUserData.usersData.FirstSixDigit
        let merchantRefNum = this.props.getUserData.usersData.merchantRefnum

        let cvv = this.state.cvv

        let length = cvv.length
        if(length == 0){
            this.setState({
                cvvError : true,
                cvvErrorMessage : 'CVV field is required'
            })

        }
        else if(length<3 || length >4){
            this.setState({
                cvvError : true,
                cvvErrorMessage : 'CVV length must be greater than 2 character and less than 4 character'
            })

        }
        if(length==3 || length ==4) {
            window.state = this.state
            window.props = this.props
            window.this = this


            //if login
            if (this.state.loginUserId !== '' && this.state.loginUserId !== null && this.state.loginUserId !== 'null') {
                this.setState({
                    Loader: true,
                    subscribeConfirmationModal: false,
                    loginWishListAlert: false,
                })
                if (this.state.cardDetails === 1) {
                    this.setState({
                        contentWriterId: contentWriterId,
                    })
                    let paymentData = {
                        amount: subscriptionPrice,
                        paysafe_token: this.state.PaysafeToken,
                    }
                    let subscriptionPlan = 1;
                    window.paysafe.threedsecure.start(API_KEY, {
                        environment: ENVIRONMENT,
                        accountId: ACCOUNT_NUMBER,
                        card: {
                            cardBin: firstSixDigit
                        }
                    }, function (deviceFingerprintingId, error) {
                        if (error) {
                            window.this.openModal()
                        } else {
                            var data = {
                                amount: subscriptionPrice * 100,
                                currency: CURRENCY,
                                currencyCode: CURRENCY,
                                merchantRefNum: merchantRefNum,
                                merchantUrl: MERCHANT_URL,
                                card: {paymentToken: token},
                                deviceChannel: DEVICE_CHANNEL,
                                messageCategory: MESSAGE_CATEGORY,
                                authenticationPurpose: AUTHENTICATION_PURPOSE,
                                deviceFingerprintingId: deviceFingerprintingId

                            }
                            axios.post(BASE_URL + '/authorization', {data}, {
                                headers: authHeader(),
                            })
                                .then(response => {

                                    if (response.data.status != 'COMPLETED') {
                                        var responsePayload = response.data.sdkChallengePayload

                                        window.paysafe.threedsecure.challenge(API_KEY, {
                                            environment: ENVIRONMENT,
                                            sdkChallengePayload: responsePayload
                                        }, function (authenticationID, error) {
                                            if (error) {
                                            } else {
                                                var data = {
                                                    authenticationID: authenticationID
                                                }
                                                axios.post(BASE_URL + '/getauthorization', {data}, {
                                                    headers: authHeader(),
                                                })
                                                    .then(getAuthorization => {

                                                        /*if(getAuthorization.error!=''){
                                                            alert('heeeeeeee')
                                                            window.this.showgetAuthorizationerror()
                                                        } else{*/
                                                        var paymentData = {
                                                            amount: subscriptionPrice,
                                                            paysafe_token: token,
                                                            cvv: cvv,
                                                            data: getAuthorization
                                                        }
                                                        window.props.followContentAction(window.state.loginUserId, contentWriterId, subscriptionPlan, paymentData)

                                                        //}
                                                    })

                                            }
                                        });
                                    } else {

                                        var data = {
                                            authenticationID: response.data.id
                                        }
                                        axios.post(BASE_URL + '/getauthorization', {data}, {
                                            headers: authHeader(),
                                        })
                                            .then(getAuthorization => {
                                                var paymentData = {
                                                    amount: subscriptionPrice,
                                                    paysafe_token: token,
                                                    cvv: cvv,
                                                    data: getAuthorization
                                                }
                                                window.props.followContentAction(window.state.loginUserId, contentWriterId, subscriptionPlan, paymentData)
                                            })

                                    }


                                })

                        }
                    });
                }
            }
        }
    }

    addToWishList(e,contentWriterId){
        e.preventDefault()
        //if login
        if(this.state.loginUserId !== ''){
            this.setState({
                Loader : true,
                contentWriterId : contentWriterId, 
                paymentConfirmationModal : false,  
            })
            this.props.addToWishListAction(this.state.loginUserId,contentWriterId)
        }
        //if not login
        else{
            this.setState({
                loginWishListAlert : true,
            })
        }
    }
    
    handleClose() {
        this.setState({
            loginWishListAlert : false,
        })
    }

    show() {
        useState(false);
    }

    render() {
        const {selectedCategory , orderBy, userData , totalCount , loginWishListAlert , Loader , paymentConfirmationModal , paymentError , loginUserId , followerList, wishList, paymentErrorMessage,paysaferesponse, subscribeConfirmationModal,cvv,cvvError,cvvErrorMessage} = this.state
        
        const profileImage = process.env.PUBLIC_URL + '/images/default-avatar.png';
        return (
            <>
                {Loader 
                ? 
                    <div id="preloader">
                        <div className="preloader_div">
                        </div>
                    </div>
                    :
                    ""
                }
                <div>
                    <section id="show_profile">
                        <div className="container">
                            <div className="show_profile_heading">
                                <div className="d-md-flex align-items-center mb-2">
                                    {/*<img src={process.env.PUBLIC_URL + '/frontend/images/heart.png'} alt="search" className="show-profile-pic" />*/}
                                    <HeartbigIcon/>
                                    <h2 className="mb-0 ml-3">Showcased Profiles</h2>
                                </div>
                                <div className="paragraph_show">
                                    <small className="mb-0">Rising Stars, Superstars... on Desire Me all our sellers are stars but take a look at who you Desire and would like to start following today. It's quick, easy and fun - so what are you waiting for?</small>
                                </div>
                            </div>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="All">
                                <Row>
                                    <Col sm={12}>
                                        <Nav variant="pills" className="align-items-center mt-3 justify-content-center justify-content-lg-start cls-home-profile-nav">
                                            <Nav.Item className="pl-md-0" onClick={this.categoryClick}>
                                                <Nav.Link eventKey="All" className={"p-0 " + ((selectedCategory === 'All') ? "show_profile_link_active" : "")}>All</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item onClick={this.categoryClick}>
                                                <Nav.Link eventKey="Male" className={"p-0 " + ((selectedCategory === 'Male') ? "show_profile_link_active" : "")}>Male</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item onClick={this.categoryClick}>
                                                <Nav.Link eventKey="Female" className={"p-0 " + ((selectedCategory === 'Female') ? "show_profile_link_active" : "")}>Female</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item onClick={this.categoryClick}>
                                                <Nav.Link eventKey="Couples" className={"p-0 " + ((selectedCategory === 'Couples') ? "show_profile_link_active" : "")}>Couples</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item onClick={this.categoryClick}>
                                                <Nav.Link eventKey="Trans" className={"p-0 " + ((selectedCategory === 'Trans') ? "show_profile_link_active" : "")}>Trans</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item onClick={this.categoryClick} className={"hidenav"}>
                                                <Nav.Link eventKey="Online Now" className={"p-0 " + ((selectedCategory === 'Online Now') ? "show_profile_link_active" : "")}>Online Now</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item onClick={this.categoryClick} className={"hidenav"}>
                                                <Nav.Link eventKey="Risingstar" className={"p-0 " + ((selectedCategory === 'Risingstar') ? "show_profile_link_active" : "")}>Risingstar</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item onClick={this.categoryClick} className={"hidenav"}>
                                                <Nav.Link eventKey="Superstars" className={"p-0 " + ((selectedCategory === 'Superstars') ? "show_profile_link_active" : "")}>Superstars</Nav.Link>
                                            </Nav.Item>   
                                            <div className="show_profile_mai_main_box">
                                                <Nav.Item className="risin_btn_main_btn" onClick={this.risingstar}>
                                                    <Nav.Link className="btn p-0 nav_item_btn_white nav_item_link_btn nav_item_link"><RisingstarIcon className={'risingstar'} />Rising star</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="superstar_btn_main_btn" onClick={this.superstar}>
                                                    <Nav.Link className="btn nav_item_link_btn nav_item_link_btn_hover nav_item_link"><StarIcon height={'1.5em'} width={'1.5em'} className={'superstar mr-2'} />Superstars</Nav.Link>
                                                </Nav.Item>
                                            </div>
                                            <div className="form-group nav-item m-0">
                                                <select
                                                    className="form-control select_box"
                                                    id="formControlSelect1"
                                                    name="orderBY"
                                                    onChange={(e) => { this.orderByClick(e.target.value); }}
                                                    value={this.state.orderBy}
                                                >
                                                    <option disabled value="">Sort By :</option>
                                                    <option value="Popular">Popular (most followers)</option>
                                                    <option value="Recently">Recently Updated</option>
                                                    <option value="Most">Most Content</option>
                                                </select>
                                            </div>
                                            <Nav.Item className="left_auto view_all pr-0" onClick ={this.viewAllCategory}>
                                                <Nav.Link className="p-0 nav_item_link">View All
                                                    <ArrowrightIcon/>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col sm={12}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey={selectedCategory} className="mt-5">


                                                <div className="row">
                                                    <div className="card-deck mb-5 col-12">
                                                            {
                                                                (totalCount > 0)
                                                                ?
                                                                userData.map((data,key) => (
                                                                    (key <= 7) ?
                                                                        <div className="card border-none" key={data.id}>
                                                                            <Link to={'/'+data.Username} /*onClick={(e) => {this.viewUserPost(e,data.Username)}}*/>
                                                                                <div className="card_show_div" style={{backgroundImage:
                                                                (data.ProfileVideo !== '') ?'url('+data.ProfileVideo+')' : (data.ProfilePic !== '') ?'url('+data.ProfilePic+')' : 'url('+profileImage+')'}}>
                                                                                    {(data.superStar === 1)
                                                                                        ?
                                                                                            <button className="btn superstar_btn"><StarIcon height={'1.3em'} width={'1.3em'} className={'superstar mr-2'}/>Superstars</button>
                                                                                        :
                                                                                        

                                                                                            (data.risingStar === 1)
                                                                                            ?
                                                                                                <button className="btn risingstar_btn"><RisingstarIcon className={'risingstar mr-2'}/>Rising stars</button>
                                                                                            :
                                                                                                ""
                                                                                    }
                                                                                    <div className="show_img">
                                                                                        <Link to={'/'+data.Username} /*onClick={(e) => {this.viewUserPost(e,data.Username)}}*/><img alt="" src={process.env.PUBLIC_URL + '/frontend/images/show.png'} className="" /></Link>
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                            <div className="card-body">
                                                                                <div className="d-flex justify-content-between card_heading">
            <Link className={'profile-url'} to={'/'+data.Username} /*onClick={(e)=>{this.viewUserPost(e,data.Username)}}*/>{(data.DisplayName !== '' && data.DisplayName !== null && data.DisplayName !== 'null') ? data.DisplayName : data.Username}</Link>
            <Link className={'profile-url'} to={'/'+data.Username} /*onClick={(e)=>{this.viewUserPost(e,data.Username)}}*/>{(data.Username) !== '' ? '@'+ data.Username : ""}</Link>
                                                                                                   </div>
                                                                                <div className={"d-flex justify-content-between align-items-center card_desired_list "+((followerList.includes(data.id) || loginUserId === data.id) ? "card_desired_list_remove" : "")}>
                                                                                    <div className="d-flex">
                                                                                        <Link to={'/'+data.Username} /*onClick={(e)=>{this.viewUserPost(e,data.Username)}}*/>
                                                                                            {/*<img alt="" src={process.env.PUBLIC_URL + '/frontend/images/videos.png'} className="mr-2" />*/}
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="23.655" height="15.206" viewBox="0 0 23.655 15.206" className="mr-2">
                                                                                                <path id="Path_2608" data-name="Path 2608" d="M40.321,367.744a.845.845,0,0,0-.822-.037l-5.536,2.768v-2.012a2.534,2.534,0,0,0-2.534-2.534H19.6a2.534,2.534,0,0,0-2.534,2.534V378.6a2.534,2.534,0,0,0,2.534,2.535H31.429a2.534,2.534,0,0,0,2.534-2.535v-2.012l5.536,2.773a.845.845,0,0,0,1.223-.76V368.463A.846.846,0,0,0,40.321,367.744Zm-14.806,9.167a3.379,3.379,0,1,1,3.379-3.379A3.379,3.379,0,0,1,25.515,376.911Z" transform="translate(-17.067 -365.929)" fill="#1c1c1c"/>
                                                                                            </svg>
                                                                                        </Link>
                                                                                        <Badge variant="secondary" className="bg-transparent text-body align-self-center">{data.videoCount}</Badge>
                                                                                    </div>

                                                                                    {(loginUserId !== data.id)
                                                                                    ?
                                                                                        (!followerList.includes(data.id) && !wishList.includes(data.id))
                                                                                        ?
                                                                                            <Link to="#" className="desired_list_btn" onClick={(e) => {this.addToWishList(e,data.id)}}>add to my desired list</Link>
                                                                                        :
                                                                                            (!followerList.includes(data.id))
                                                                                            ?
                                                                                                <Link to="#" style={{cursor:"unset"}} onClick={(e) => {e.preventDefault()}} className="desired_list_btn">Desired</Link>
                                                                                            :
                                                                                                ""
                                                                                    :
                                                                                        ""
                                                                                    }
                                                                                </div>
                                                                                <div className={"d-flex justify-content-between card_subscribe mt-2 "+((followerList.includes(data.id) || loginUserId === data.id) ? "card_subscribe_remove" : "")}>
                                                                                    <div className="d-flex">
                                                                                        <Link to={'/'+data.Username} /*onClick={(e)=>{this.viewUserPost(e,data.Username)}}*/>
                                                                                            {/*<img alt="" src={process.env.PUBLIC_URL + '/frontend/images/images.png'} className="mr-2" />*/}
                                                                                            <svg id="Group_132" data-name="Group 132" xmlns="http://www.w3.org/2000/svg" width="22.524" height="16.088" viewBox="0 0 22.524 16.088" className="mr-2">
                                                                                                <path id="Path_16" data-name="Path 16" d="M130.621,365.929h-17.7a2.413,2.413,0,0,0-2.413,2.413V379.6a2.413,2.413,0,0,0,2.413,2.413h17.7a2.413,2.413,0,0,0,2.413-2.413V368.342A2.413,2.413,0,0,0,130.621,365.929Zm-17.7,1.609h17.7a.8.8,0,0,1,.8.8v6.907l-5.062-5.062a.8.8,0,0,0-1.138,0l-6.671,6.671-2.649-2.649a.8.8,0,0,0-1.137,0l-2.649,2.649v-8.516A.8.8,0,0,1,112.924,367.538Z" transform="translate(-110.511 -365.929)" fill="#1c1c1c"/>
                                                                                                <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="2.413" cy="2.413" rx="2.413" ry="2.413" transform="translate(4.827 3.218)" fill="#1c1c1c"/>
                                                                                            </svg>
                                                                                        </Link>
                                                                                        <Badge variant="secondary" className="bg-transparent text-body align-self-center">{data.imageCount}</Badge>
                                                                                    </div>
                                                                                    {(loginUserId !== data.id)
                                                                                    ?
                                                                                        (!followerList.includes(data.id))
                                                                                        ?
                                                                                            <Link to="#" onClick={(e)=>{this.subscribeConfirmationModal(e,data.id,data.Username,data.SubscriptionPriceWithVAT,data.DisplayName,data.message)}} className="subscribe_btn">subscribe</Link>
                                                                                        :
                                                                                            ""
                                                                                    :
                                                                                        ""
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    :
                                                                    ""
                                                                ))
                                                                :
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <h4 className="pt-5" style={{textAlign:"center"}}>No Profiles</h4>
                                                                </div>
                                                            }
                                                    </div>
                                                </div>
                                                {(totalCount > 8) ?
                                                    <Link to="#" className="mx-auto d-flex btn show_btn justify-content-center" onClick ={this.viewAllCategory}>Load More</Link>
                                                    :
                                                    ""
                                                }

                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                    </section>
                </div>

                {


                    (subscribeConfirmationModal)
                    ?
                        <div className="modal__div">                               
                            <Modal size="lg" show={this.subscribeConfirmationModalOpen} onHide={this.subscribeConfirmationModalClose} centered dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" className="custom__modal">
                                <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
                                <Modal.Body style={{ width: "100%" }}>
                                    <div className="modal__body">
                                        <div className="modal__text px-3 px-md-5 pb-5 pb-md-5 text-center">
                    <p className="modalheading">Desire {((this.state.displayName) ? this.state.displayName : "")}</p>
                    <p className="modalparagraph">{(this.state.subscriptionMessage)}</p>


                                            <div className="form-group" id="subscription-modal">
                                            <label htmlFor="cvv" class="cvv-popup-number"><b>Enter CVV</b> <b>({this.props.getUserData.usersData.cardType} **** {this.props.getUserData.usersData.cardLastDigit})</b> <a href="javascript:void(0)" onClick={this.gotoupdatecard}><u><b className="update-card-cvv">Update Card</b></u></a></label>
                                            <input type="number" className="form-control input_text desire-cvv-update" id="cvv" placeholder="Enter Your CVV" name="cvv" onChange={this.inputChange} defaultValue={cvv}/>&nbsp;&nbsp;&nbsp;
                                            <button className="followbtn btn btn__continue mr-0 mb-3 mb-md-0 mr-md-3" type="button" onClick={(e) => {this.subscribe(e)}}>Desire {((this.state.displayName) ? this.state.displayName : "")}</button>

                                                {(this.state.cvvError) ?
                                                    <div id="alert-box2" className="font-weight-bold mt-1">
                                        <span className="pr-2">
                                            <ErrorinfoIcon/>
                                        </span>
                                                        {this.state.cvvErrorMessage}
                                                    </div>
                                                    :
                                                    ""
                                                }

                                            </div>
                                        <div className="button__group d-flex flex-md-row align-itmes-center justify-content-center">
                                            <button className="btn btn__black ml-0 ml-md-3" type="button" onClick={this.subscribeConfirmationModalClose}>Cancel</button>
                                        </div>
                                    </div>
                        </div>
                    </Modal.Body>
                            </Modal>
                        </div>

                    :
                        ""


                }


                {(loginWishListAlert)
                ?
                    <LoginModalComponent loginUserId={loginUserId} show={this.show} handleClose={this.handleClose}/>
                    
                :
                    ""
                }

                {(paymentConfirmationModal)
                    ?
                        <div className="modal__div">
                            <Modal size="lg" centered show={this.paymentConfirmationModalOpen} onHide={this.paymentConfirmationModalClose} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" className="custom__modal" >
                                <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
                                <Modal.Body style={{ width: "100%" }}>
                                    <div className="modal__body">
                                        <div className="modal__text px-3 px-md-5 pb-5 pb-md-5 text-center">
                                        {
                                            (!paymentError)
                                            ?   
                                                    <>
                                                        <div className="pb-4">
                                                            <img width="65px" height="65px" src={process.env.PUBLIC_URL + '/frontend/images/icon-tick.svg'}/>
                                                        </div>
                <p className="modalheading">Successfully followed {((this.state.displayName) ? this.state.displayName : "")}</p>
                <p className="modalparagraph">Reloading the profile now to show {((this.state.displayName) ? this.state.displayName : "")}'s content, please wait…</p>

                </>
                                                :
                                                    <>
                                                        <div className="pb-4">
                                                            <img width="65px" height="65px" src={process.env.PUBLIC_URL + '/frontend/images/icon-cross.svg'}/>
                                                        </div>
                <p className="paysaferesponse">{paysaferesponse}</p>
                    <p className="pb-4 modalparagraph">{paymentErrorMessage}</p>
                    <div className="button__group d-flex flex-column flex-md-row align-itmes-center justify-content-center">
                    <button className="btn btn__continue btn_updatecard mr-0 mb-3 mb-md-0 mr-md-3" onClick={this.gotoupdatecard}>Update Card</button>
                <p className="mb-0 my-auto d-none d-md-block">OR</p>
                    <button className="btn btn__continue btn_contactsupport ml-0 ml-md-3" onClick={this.gotocontactsupport}>Contact Support</button>
                </div>
                                                    </>
                                        }
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>

                    :
                        ""
                }
            </>
        )
    }
}

export const mapStateToProps = (state) => ({
    homePageData : state.homePageData,
    authenticatedUserData : state.auth.loginSuccessResult,
    followingContent : state.homePageData.followingContent,
    addToWishList : state.homePageData.addToWishList,
    getUserData : state.userAccountData,
})

export default connect(
    mapStateToProps,
    {getUserByCategoryAction , followContentAction , getUserDetails , addToWishListAction}
)(withRouter(ShowProfile));
