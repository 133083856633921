import {
	GET_USERS_DATA,
	PASSWORD_CHANGE_SUCCESS,
	TWO_FACTOR_AUTH_SUCCESS,
	PROFILE_UPDATE_SUCCESS,
	CLOSE_ACCOUNT,
	PAYMENT_UPDATE_SUCCESS,
	GET_ACCOUNT_BALANCE,
	WITHDRAW_BALANCE,
	REMOVE_CARD,
	NOTIFICATION_SETTING_UPDATE_SUCCESS,
	GET_ERRORS,
} from './types';

import axios from 'axios';
import authHeader from '../utils/AuthHeader';
import {BASE_URL} from '../components/Constant';



export const getUserDetails = (user_id) => (dispatch) =>  {
	axios
		.post(BASE_URL+'/getDetails/'+user_id,{},{
			headers : authHeader(),
		})
		.then(response => {
			if(!response.data.isError){
				dispatch({
					type : GET_USERS_DATA,
					payload : response.data.data
				})
			}
			else{
				dispatch({
					type : GET_ERRORS,
					payload : response.data
				})
			}
		})
		.catch(error => {
		})
}


export const updateProfileAction = (userAccountData, UserId) => (dispatch) => {
	axios
		.post(BASE_URL+'/profileSettings/'+UserId,userAccountData,{
			headers : authHeader(), 'content-type': 'multipart/form-data'
		})
		.then(response => {
			if(response.data.isError){
				dispatch({
					type : GET_ERRORS,
					payload : response.data
				})
			}
			else{
				let userData = JSON.parse(decodeURIComponent(localStorage.getItem('user_data')));
				userData.DisplayName = response.data.data.DisplayName;
				userData.ProfilePic = response.data.data.ProfilePic;
				localStorage.setItem('user_data',encodeURIComponent(JSON.stringify(userData)));
				dispatch({
					type : PROFILE_UPDATE_SUCCESS,
					payload : response.data
				})

			}
		})
		.catch(error => {
			dispatch({
				type : GET_ERRORS,
				payload : error.response.data
			})
		})

}

export const twoFactorAuthAction =  (status,user_id) => (dispatch) => {	
	axios
		.post(BASE_URL+'/twoFactorStatusChange/'+user_id+'/'+status,{
			headers : authHeader()
			//body: {status: status,userId: user_id}

		})
		.then(response => {
			dispatch({
				type : TWO_FACTOR_AUTH_SUCCESS,
				payload : response.data
			})
		})
		.catch(error => {
			dispatch({
				type : GET_ERRORS,
				payload : error.response.data.errors
			})
		})
}

export const changePasswordAction = (userPasswordData,user_id) => (dispatch) => {
	axios
		.post(BASE_URL+'/changePassword/'+user_id,userPasswordData,{
			headers : authHeader(),
		})
		.then(response => {
			dispatch({
				type : PASSWORD_CHANGE_SUCCESS,
				payload : response.data
			})
		})
		.catch(error => {
			dispatch({
				type : GET_ERRORS,
				payload : error.response.data.errors
			})
		})
}

export const updatePaymentDetailsAction = (paymentData,userId) => (dispatch) => {
	axios
		.post(BASE_URL+"/updatePaymentDetails/"+userId,paymentData,{
			headers : authHeader(),
		})
		.then(response => {
			dispatch({
				type : PAYMENT_UPDATE_SUCCESS,
				payload : response.data	
			})
		})
		.catch(error => {
			// dispatch({
			// 	type : GET_ERRORS,
			// 	payload : error.response.data.errors
			// })
		})
}

export const updateCardDetailsAction = (paymentData,userId) => (dispatch) => {
	axios
		.post(BASE_URL+"/updateCardDetails/"+userId,paymentData,{
			headers : authHeader(),
		})
		.then(response => {
			dispatch({
				type : PAYMENT_UPDATE_SUCCESS,
				payload : response.data	
			})
		})
		.catch(error => {
			// dispatch({
			// 	type : GET_ERRORS,
			// 	payload : error.response.data.errors
			// })
		})
}

export const emptyError = () => (dispatch) => {
	dispatch({

		type : GET_ERRORS,
		payload : []
	})
}

export const emptySuccessResult = (TabName) => (dispatch) => {	
	let action_name = '';
	if(TabName === 'Security'){
		action_name = PROFILE_UPDATE_SUCCESS;
	}
	else if(TabName === 'General'){
		action_name = PASSWORD_CHANGE_SUCCESS;
	}
	dispatch({

		type : action_name,
		payload : ""
	})
}

export const CloseAccountAction = (userId) => (dispatch) => {
	axios
		.post(BASE_URL+'/closeAccount/'+userId,{},{
			headers : authHeader(),
		})
		.then(response => {
			if(response.data.isError){
				dispatch({
					type : GET_ERRORS,
					payload : response.data
				})
			}
			else{
				dispatch({
					type : CLOSE_ACCOUNT,
					payload : response.data
				})

			}
		})
		.catch(error => {
			dispatch({
				type : GET_ERRORS,
				payload : error.response.data.errors
			})
		})
}

export const removeUserCardAction = (userId) => (dispatch) => {
	axios
		.post(BASE_URL+'/removeUserCard/'+userId,{},{
			headers : authHeader(),
		})
		.then(response => {
			if(response.data.isError){
				dispatch({
					type : GET_ERRORS,
					payload : response.data
				})
			}
			else{
				dispatch({
					type : REMOVE_CARD,
					payload : response.data
				})

			}
		})
		.catch(error => {
			dispatch({
				type : GET_ERRORS,
				payload : error.response.data.errors
			})
		})
}

export const getAccountBalanceAction = (userId) => (dispatch) => {
	axios
		.get(BASE_URL+'/getAccountBalance/'+userId,{
			headers : authHeader(),
		})
		.then(response => {
			if(response.data.isError){
				dispatch({
					type : GET_ERRORS,
					payload : response.data
				})
			}
			else{
				dispatch({
					type : GET_ACCOUNT_BALANCE,
					payload : response.data
				})

			}
		})
		.catch(error => {
			dispatch({
				type : GET_ERRORS,
				payload : error.response.data.errors
			})
		})
}

export const withdrawBalanceAction = (userId, accountBalance) => (dispatch) => {
	axios
		.post(BASE_URL+'/withdrawBalance/'+userId+"/"+accountBalance,{},{
			headers : authHeader(),
		})
		.then(response => {
			if(response.data.isError){
				dispatch({
					type : GET_ERRORS,
					payload : response.data
				})
			}
			else{
				dispatch({
					type : WITHDRAW_BALANCE,
					payload : response.data
				})

			}
		})
		.catch(error => {
			dispatch({
				type : GET_ERRORS,
				payload : error.response.errors
			})
		})
}

export const updateNotificationSettingAction = (notificationSettingData,userId) => (dispatch) => {
	axios
		.post(BASE_URL+"/NotificationSettingUpdate/"+userId,notificationSettingData,{
			headers : authHeader(),
		})
		.then(response => {
			dispatch({
				type : NOTIFICATION_SETTING_UPDATE_SUCCESS,
				payload : response.data	
			})
		})
		.catch(error => {
			// dispatch({
			// 	type : GET_ERRORS,
			// 	payload : error.response.data.errors
			// })
		})
}
