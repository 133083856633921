import React, { Component, useState } from "react";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import {resetPasswordAction} from '../../actions/auth';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Modal from "react-bootstrap/Modal";
import ModalComponent from "../ModalComponent";
import {ERROR_MESSAGE} from '../Constant';
import MetaTags from "react-meta-tags";

export class Resetpassword extends Component {
    constructor(props){
        super(props);

        this.state = {
            email : '',
            token : '',
            password : '',
            password_confirmation : '',
            errors : [],
            isError : false,
            ButtonLoader : false,
            errorMessage : '',
            modalShow : false,
        }
        this.onSubmit = this.onSubmit.bind(this)
        this.inputChange = this.inputChange.bind(this)
        this.handleClose = this.handleClose.bind(this);
        this.show = this.show.bind(this);
    }

    componentDidMount(){
        let search = this.props.location.search;
        const params = new URLSearchParams(search);
        const token = params.get('token');
        this.setState({
            token : token
        })
    }


    UNSAFE_componentWillReceiveProps(nextProps){
        this.setState({
            ButtonLoader : false
        })
        if(nextProps.resetPasswordSuccess.resetPassword && nextProps.resetPasswordSuccess.resetPassword !== ''){
            // toast.success(nextProps.resetPasswordSuccess.resetPassword.message);
            this.setState({
                errors : [],
                isError : false,
                errorMessage : nextProps.resetPasswordSuccess.resetPassword.message,
                modalShow : true,
            })
            var that = this
            setTimeout(function(){
                that.props.history.push('/login')
            },3000);
        }
        else{
            // toast.error('Error!');
            this.setState({
                errors : nextProps.errors.errors.errors,
                isError : true,
                errorMessage : ERROR_MESSAGE,
                modalShow : true,
            })
        }
    }

    //on imput change save value in state
    inputChange(e){
        this.setState({
            [e.target.name] : e.target.value,
        })
    }

    onSubmit(e){
        e.preventDefault();
        this.setState({
            ButtonLoader : true
        })
        var resetPasswordData = {
            email : this.state.email,
            token : this.state.token,
            password : this.state.password,
            password_confirmation : this.state.password_confirmation
        }

        this.props.resetPasswordAction(resetPasswordData)
    }

    handleClose() {
        this.setState({
            modalShow : false,
            errorMessage : '',
        })
    }

    show() {
        useState(false);
    }



    render() {
        const {errors , isError , ButtonLoader, modalShow , errorMessage} = this.state;
        return (
            <>
                <MetaTags>
                    <title>Reset Password | DesireMe</title>
                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content="__OGURL__"/>
                    <link rel="canonical" href="__OGURL__" />
                    <meta property="og:image" content="https://desire.me/images/ogimage.png"/>
                    <meta property="og:site_name" content="Desire Me"/>
                    <meta name="description" content="__METADESCRIPTION__" />
                    <meta name="title" content="__METATITLE__" />
                    <meta property="og:description" content="__METADESCRIPTION__"/>
                    <meta property="og:title" content="__METATITLE__"/>
                </MetaTags>
                <Header />
                    {ButtonLoader ? 
                        <div id="preloader">
                            <div className="preloader_div">
                            </div>
                        </div>
                        :
                        ""
                    }
                    <section id="forgetpwd">
                        <div className="container p-5">
                            <div className="forget_box shadow rounded w-50 mx-auto p-4" style={{ background : "#FAFBFD"}}>
                                <h3 className="text-center font-weight-bold">
                                  Reset Your Password
                                </h3>

                            <form onSubmit={this.onSubmit}>
                            {/*
                                <ToastContainer
                                    position="bottom-center"
                                    autoClose={5000}
                                    hideProgressBar={true}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                /> */}
                                <div className="form-group">
                                    <label for="username" className="label_text">
                                      Email{" "}<span className="text-danger">*</span>
                                    </label>
                                    <input type="email" className="form-control input_text" id="" name="email" placeholder="Enter Your Email" onChange={this.inputChange}/>
                                    {(isError && errors.email) ? 
                                        <div id="alert-box2" className="font-weight-bold mt-1">
                                            <span className="pr-2">
                                                <svg
                                                width="1em"
                                                height="1em"
                                                viewBox="0 0 16 16"
                                                className="bi bi-exclamation-circle"
                                                fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                fill-rule="evenodd"
                                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                />
                                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                </svg>
                                            </span>
                                            {errors.email}
                                        </div>
                                        :
                                        ""
                                    }
                                </div>


                                <div className="form-group">
                                    <label for="password" className="label_text">
                                        New Password{" "}<span className="text-danger">*</span>
                                    </label>
                                    <input type="password" className="form-control input_text" id="" name="password" placeholder="Enter New Password" onChange={this.inputChange} />
                                    {(isError && errors.password) ? 
                                        <div id="alert-box2" className="font-weight-bold mt-1">
                                            <span className="pr-2">
                                                <svg
                                                    width="1em"
                                                    height="1em"
                                                    viewBox="0 0 16 16"
                                                    className="bi bi-exclamation-circle"
                                                    fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      fill-rule="evenodd"
                                                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                    />
                                                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                </svg>
                                            </span>
                                            {errors.password}
                                        </div>
                                        :
                                        ""
                                    }
                                </div>

                                <div className="form-group">
                                    <label for="firstname" className="label_text">
                                        Confirm Password{" "}
                                    </label>
                                    <input type="password" className="form-control input_text" id="" name="password_confirmation" placeholder="Re-Enter New Password " onChange={this.inputChange} />
                                    {(isError && errors.password_confirmation) ? 
                                        <div id="alert-box" className="font-weight-bold mt-1">
                                            <span className="pr-2">
                                                <svg
                                                    width="1em"
                                                    height="1em"
                                                    viewBox="0 0 16 16"
                                                    className="bi bi-exclamation-circle"
                                                    fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      fill-rule="evenodd"
                                                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                    />
                                                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                </svg>
                                            </span>
                                            {errors.password_confirmation}
                                        </div>
                                        :
                                        ""
                                    }
                                </div>
                                {!ButtonLoader ?
                                    <button className="btn next font-weight-bold" type="submit">SUBMIT</button> 
                                :
                                    <button className="btn next font-weight-bold" type="button" disabled="disabled">Processing..</button> 
                                } 
                            </form>
                          </div>
                        </div>

                        {(modalShow) ?
                            <ModalComponent isError={isError} errorMessage={errorMessage} show={this.show} handleClose={this.handleClose}/>
                        :
                            ""
                        }
                    </section>
                <Footer />
            </>
        );
    }
}

export const mapStateToProps = (state) => ({
    resetPasswordSuccess : state.auth,
    errors : state.errors
})

export default connect(
    mapStateToProps,
    {resetPasswordAction}
)(withRouter(Resetpassword));
