import React, { Component } from 'react';
import { Link , withRouter } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import AllFeed from './AllFeed';
import PopularFeed from './PopularFeed';
import MostViewFeed from './MostViewFeed';
import {connect} from 'react-redux';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import {getAllPostAction , getMostViewedPostAction , getPopularProfileAction ,getPopularPostAction , serachActivityAction} from '../../actions/post';
import MetaTags from "react-meta-tags";
export class Feed extends Component {

    constructor(props){
        super(props)

        this.state = {
            TabName : "All Activity",
            userId : '',
            start : 0,
            limit : 2,
            popularProfiles : [],
        }
        this.handleScroll = this.handleScroll.bind(this)
        this.TabChange = this.TabChange.bind(this)
        this.viewUserPost = this.viewUserPost.bind(this)
        this.searchActivityParent = this.searchActivityParent.bind(this)
    }

    componentDidMount(){
        localStorage.removeItem('searchedBlog')
        localStorage.removeItem('emailVerifyTime');//if email verify storage is not clear
        let user_id = this.props.authenticatedUserData.userId
        this.setState({
            userId : user_id
        })
        this.props.getPopularProfileAction(user_id,0,4)
        if(this.state.TabName === 'All Activity'){
            this.props.getAllPostAction(user_id,this.state.start,this.state.limit)
        }

        // window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll = (e) => {
        e.preventDefault()
        if(this.state.TabName === 'All Activity' && window.location.href.includes('feed')){
            this.AllFeed.handleScroll();
        }
        // else if(this.state.TabName !== 'Most Viewed'){
        //     this.MostViewFeed.handleScroll();
        // }
        // else if(this.state.TabName !== 'Popular Activity'){
        //     this.PopularFeed.handleScroll();
        // }
    }

    searchActivityParent(searchValue){
        //serachValue coming from child component on serach type
        let searchActivityValue = searchValue
        if(searchActivityValue !== ''){
            this.props.serachActivityAction(searchActivityValue,this.state.userId,this.state.start,this.state.limit)
        }
        else{
            if(this.state.TabName === 'All Activity'){
                this.props.getAllPostAction(this.state.userId,this.state.start,this.state.limit)
            }
            else if(this.state.TabName === 'Most Viewed'){
                this.props.getMostViewedPostAction(this.state.userId,this.state.start,this.state.limit)
            }
            else if(this.state.TabName === 'Popular Activity'){
                this.props.getPopularPostAction(this.state.userId,this.state.start,this.state.limit)
            }
        }
    }


    viewUserPost(e){
        e.preventDefault()
        let searchUserName = e.target.id
        this.props.history.push('/'+searchUserName)
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        //popular profile data
        if(nextProps.popularProfilesData && nextProps.popularProfilesData.data && nextProps.popularProfilesData.data.length > 0){
            this.setState({
                popularProfiles : nextProps.popularProfilesData.data
            })
        }
    }

    TabChange(e){
        if(e.target.text === 'All Activity' && this.state.TabName !== 'All Activity'){
            this.props.getAllPostAction(this.state.userId,this.state.start,this.state.limit)
        }
        else if(e.target.text === 'Most Viewed' && this.state.TabName !== 'Most Viewed'){
            this.props.getMostViewedPostAction(this.state.userId,this.state.start,this.state.limit)
        }
        else if(e.target.text === 'Popular Activity' && this.state.TabName !== 'Popular Activity'){
            this.props.getPopularPostAction(this.state.userId,this.state.start,this.state.limit)
        }
        this.setState({
            TabName : e.target.text
        })
    }

    render() {
        const {TabName , popularProfiles} = this.state
        return (
            <>
            <MetaTags>
                <title>Feeds | DesireMe</title>
                <meta name="description" content="__METADESCRIPTION__" />
                <meta name="title" content="__METATITLE__" />
                <meta property="og:description" content="__METADESCRIPTION__"/>
                <meta property="og:title" content="__METATITLE__"/>
                <meta property="og:url" content="__OGURL__"/>
                <link rel="canonical" href="__OGURL__" />
        </MetaTags>
                <Header />
                    <section id="feedpage">
                        <div className="container">
                            <div className="feedpage_heading d-flex my-3">
                                <Link to="/"><img src={process.env.PUBLIC_URL + '/frontend/images/feed.png'} alt="img" /></Link>
                                <h1 className="ml-3">Recent Activity</h1>
                            </div>
                            <div className="row d-flex justify-content-between">
                                <div className="col-lg-7 left_feed">
                                    <Tabs defaultActiveKey="Activity" id="uncontrolled-tab-example" onClick={this.TabChange}>
                                        <Tab eventKey="Activity" title="All Activity" className="feed_link_active">
                                            <AllFeed TabName={TabName} searchActivityParent={this.searchActivityParent} onRef={ref => (this.AllFeed = ref)}/>
                                        </Tab>
                                        <Tab eventKey="Poplar" title="Popular Activity">
                                            <PopularFeed TabName={TabName} searchActivityParent={this.searchActivityParent} onRef={ref => (this.PopularFeed = ref)}/>
                                        </Tab>
                                        <Tab eventKey="MostView" title="Most Viewed">
                                            <MostViewFeed TabName={TabName} searchActivityParent={this.searchActivityParent} onRef={ref => (this.MostViewFeed = ref)}/>
                                        </Tab>
                                    </Tabs>
                                </div>
                                <div className="col-4 d-lg-block d-none right_feed">
                                    <div className="right_feed_box">
                                        <div className="right_feed_box_heading">
                                            <Link to="/"><img src={process.env.PUBLIC_URL + '/frontend/images/popular profile.png'} alt="img" /><small className="pl-2">Popular Profiles</small></Link>
                                        </div>
                                        {
                                            popularProfiles.map((data,key) => (
                                                <div className="right_feed_box_content d-flex p-2 pl-3 mt-3" key={key}>
                                                    <div>
                                                        <img
                                                            src={(data.ProfilePic !== '' && data.ProfilePic !== null && data.ProfilePic !== 'null') ? data.ProfilePic :
                                                              process.env.PUBLIC_URL +
                                                                "/images/default-avatar.png"
                                                            } alt=""
                                                        />
                                                    </div>
                                                    <div className=" pl-3 text-truncate">
                                                        <p className="font-weight-bold m-0 text-truncate">{data.DisplayName}</p>
                                                        <Link to={'/'+data.Username} onClick={this.viewUserPost}>
                                                            {" "}
                                                            <small className="view_text" id={data.Username}>View Profile</small>
                                                        </Link>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                <Footer />
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    authenticatedUserData : state.auth.loginSuccessResult,
    popularProfilesData : state.postData.getPopularProfiles,
})


export default connect(
    mapStateToProps,
    {getAllPostAction ,getMostViewedPostAction ,getPopularProfileAction , getPopularPostAction ,serachActivityAction}
)(withRouter(Feed));
