import {
    GET_BLOGS,
    GET_BLOG_DETAIL,
    GET_BLOG_CATEGORIES,
    GET_RECENT_BLOGS,
    GET_SEARCHED_BLOGS,
} from "../actions/types"

const initialState = {
    getBlogsData : "",
    getBlogDetails : "",
    getBlogCategories : "",
    getRecentBlogs : "",
    getSearchedBlogData : "",
}

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_BLOGS:
            return {
                ...state,
                getBlogsData: action.payload
            }
        case GET_BLOG_DETAIL:
            return {
                ...state,
                getBlogDetails : action.payload
            }
        case GET_BLOG_CATEGORIES:
            return {
                ...state,
                getBlogCategories : action.payload
            }
        case GET_RECENT_BLOGS:
            return {
                ...state,
                getRecentBlogs : action.payload
            }
        case GET_SEARCHED_BLOGS:
            return {
                ...state,
                getSearchedBlogData : action.payload
            }
        default:
            return state
    }
}
